import { useTranslation } from "react-i18next";
import { isMobile, isMobileOnly } from "react-device-detect";
import { ThemeContext } from "styled-components";
import { handleOrientationChange } from "common/src/helpers/isPortrait";
import React, { useContext, useEffect, useState } from "react";
import {
  ContentCard,
  ContentTopWrapper,
  StyledBonus,
  StyledCashback,
  StyledHisory,
  StyledLimits,
  StyledNewPassword,
  StyledProfile,
  StyledPromotion,
  StyledTransactions,
  StyledVerification,
  StyledBonusABC,
  StyledCashbackABC,
  StyledHisoryABC,
  StyledLimitsABC,
  StyledNewPasswordABC,
  StyledProfileABC,
  StyledPromotionABC,
  StyledTransactionsABC,
  StyledVerificationABC,
  StyledProfilePUB,
  StyledCashbackPUB,
  StyledBonusPUB,
  StyledNewPasswordPUB,
  StyledTransactionsPUB,
  StyledHistoryPUB,
  StyledPromotionsPUB,
  StyledLimitsPUB,
  StyledVerificationPUB,
  StyledInnerDescription,
  StyledTournamentsABC,
  StyledTournamentsPUB,
} from "../styledComponents/StyledContentTop";
import {
  StyledLink,
  StyledNavLink,
} from "../styledComponents/CommonStyledComponents";
import { SERVER_SETUP } from "../helpers/constants";
/* import { StyledMaterialIcon } from "../styledComponents/StyledHeader"; */

const ProfileMenu = ({ profileMenu, pages, showProfileMenu }) => {
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const { t } = useTranslation("");
  const themeContext = useContext(ThemeContext);
  const { geoIP } = serverSetup;

  const plc = themeContext.skin === "plc";
  const abc = themeContext.skin === "abc";
  const pub = themeContext.skin === "pub";
  const rac = themeContext.skin === "rac";
  const nbc = themeContext.skin === "nbc";
  const bnk = themeContext.skin === "bnk";
  const yet = themeContext.skin === "yet";
  const fun = themeContext.skin === "fun";
  const cac = themeContext.skin === "cac";
  const hyc = themeContext.skin === "hyc";
  const yak = themeContext.skin === "yak";
  const qbc = themeContext.skin === "qbc";

  const isSE =
    localStorage.getItem("pc") === "se" || geoIP.isoCode.toLowerCase() === "se"
      ? true
      : false;

  const YETICB =
    (localStorage.getItem("pc") === "za" ||
      geoIP.isoCode.toLowerCase() === "za" ||
      localStorage.getItem("pc") === "dk" ||
      geoIP.isoCode.toLowerCase() === "dk" ||
      localStorage.getItem("pc") === "ie" ||
      geoIP.isoCode.toLowerCase() === "ie" ||
      localStorage.getItem("pc") === "nz" ||
      geoIP.isoCode.toLowerCase() === "nz" ||
      localStorage.getItem("pc") === "gg" ||
      geoIP.isoCode.toLowerCase() === "gg" ||
      localStorage.getItem("pc") === "je" ||
      geoIP.isoCode.toLowerCase() === "je" ||
      localStorage.getItem("pc") === "im" ||
      geoIP.isoCode.toLowerCase() === "im" ||
      localStorage.getItem("pc") === "is" ||
      geoIP.isoCode.toLowerCase() === "is") &&
    yet
      ? true
      : false;

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("orientationchange", handleOrientationChange);
      return () =>
        window.removeEventListener(
          "orientationchange",
          handleOrientationChange
        );
    }
  }, [handleOrientationChange]);

  /* const handleScrollToLobby = () => {
    document.getElementById("scroll-to-category-onlogin").scrollIntoView();
  }; */

  const accountRoute = isMobileOnly
    ? true
    : location.pathname === pages["my-account"] ||
      location.pathname === pages["my-profile"] ||
      location.pathname === pages["cashback"] ||
      location.pathname === pages["bonuses"] ||
      location.pathname === pages["change-password"] ||
      location.pathname === pages["transactions"] ||
      location.pathname === pages["casino-history"] ||
      location.pathname === pages["my-limits"] ||
      location.pathname === pages["complaints"] ||
      location.pathname === pages["my-verification"];

  return (
    <>
      <ContentTopWrapper
        showProfileMenu={profileMenu}
        profileMenu={isMobile || showProfileMenu}
        accountroute={accountRoute ? 1 : 0}
      >
        <StyledNavLink to={pages["my-profile"]} id="span-right" profile="true">
          <ContentCard
            showProfileMenu={profileMenu}
            profileMenu={isMobile || showProfileMenu}
            profile="true"
          >
            {(abc || (rac && isMobileOnly)) && (
              <StyledProfileABC accountroute={!accountRoute ? 1 : 0} />
            )}
            {plc && <StyledProfile />}
            {(pub || nbc || bnk || yet || fun || cac || hyc || yak || qbc) && (
              <StyledProfilePUB />
            )}
          </ContentCard>
          {t("label_profile", "My Profile")}
        </StyledNavLink>
        {!isSE && (abc || nbc || YETICB) && (
          <StyledNavLink to={pages["cashback"]} id="span-right" cashback="true">
            <ContentCard
              showProfileMenu={profileMenu}
              profileMenu={isMobile || showProfileMenu}
              cashback="true"
            >
              {abc && (
                <StyledCashbackABC accountroute={!accountRoute ? 1 : 0} />
              )}
              {plc && <StyledCashback />}
              {(nbc || bnk || yet || qbc) && <StyledCashbackPUB />}
            </ContentCard>
            {t("label_cashback", "Cashback")}
          </StyledNavLink>
        )}
        {pages["bonuses"] && (
          <StyledNavLink to={pages["bonuses"]} id="span-right" profile="true">
            <ContentCard
              showProfileMenu={profileMenu}
              profileMenu={isMobile || showProfileMenu}
              profile="true"
            >
              {(abc || (rac && isMobileOnly)) && (
                <StyledBonusABC accountroute={!accountRoute ? 1 : 0} />
              )}
              {plc && <StyledBonus />}
              {(pub ||
                nbc ||
                bnk ||
                yet ||
                fun ||
                cac ||
                hyc ||
                yak ||
                qbc) && <StyledBonusPUB />}
            </ContentCard>
            {t("label_bonuses", "Bonuses")}
          </StyledNavLink>
        )}
        <StyledNavLink
          to={pages["change-password"]}
          id="span-right"
          cashback="true"
        >
          <ContentCard
            showProfileMenu={profileMenu}
            profileMenu={isMobile || showProfileMenu}
            cashback="true"
          >
            {(abc || (rac && isMobileOnly)) && (
              <StyledNewPasswordABC accountroute={!accountRoute ? 1 : 0} />
            )}
            {plc && <StyledNewPassword />}
            {(pub || nbc || bnk || yet || fun || cac || hyc || yak || qbc) && (
              <StyledNewPasswordPUB />
            )}
          </ContentCard>
          {showProfileMenu
            ? t("label_new_password", "New Password")
            : t("label_change_password", "Change Password")}
        </StyledNavLink>
        {pages["transactions"] && (
          <StyledNavLink
            to={pages["transactions"]}
            cashback="true"
            id="span-right"
          >
            <ContentCard
              showProfileMenu={profileMenu}
              profileMenu={isMobile || showProfileMenu}
              cashback="true"
            >
              {(abc || (rac && isMobileOnly)) && (
                <StyledTransactionsABC accountroute={!accountRoute ? 1 : 0} />
              )}
              {plc && <StyledTransactions />}
              {(pub ||
                nbc ||
                bnk ||
                yet ||
                fun ||
                cac ||
                hyc ||
                yak ||
                qbc) && <StyledTransactionsPUB />}
            </ContentCard>
            {t("label_transactions", "Transactions")}
          </StyledNavLink>
        )}
        <StyledNavLink
          to={pages["casino-history"]}
          cashback="true"
          id="span-right"
        >
          <ContentCard
            showProfileMenu={profileMenu}
            profileMenu={isMobile || showProfileMenu}
            cashback="true"
          >
            {(abc || (rac && isMobileOnly)) && (
              <StyledHisoryABC accountroute={!accountRoute ? 1 : 0} />
            )}
            {plc && <StyledHisory />}
            {(pub || nbc || bnk || yet || fun || cac || hyc || yak || qbc) && (
              <StyledHistoryPUB />
            )}
          </ContentCard>
          {showProfileMenu
            ? t("label_history", "History")
            : t("label_casino_history", "Casino History")}
        </StyledNavLink>
        <StyledNavLink
          to={pages["news-and-promotions"]}
          profile="true"
          id="span-right"
        >
          <ContentCard
            showProfileMenu={profileMenu}
            profileMenu={isMobile || showProfileMenu}
            profile="true"
          >
            {(abc || (rac && isMobileOnly)) && (
              <StyledPromotionABC accountroute={!accountRoute ? 1 : 0} />
            )}
            {plc && <StyledPromotion />}
            {(pub || nbc || bnk || yet || fun || cac || hyc || yak || qbc) && (
              <StyledPromotionsPUB />
            )}
          </ContentCard>
          {showProfileMenu || isMobile
            ? t("label_promotions", "Promotions")
            : t("label_news_and_promotions", "News and Promotions")}
        </StyledNavLink>
        {pages["tournaments"] && (
          <StyledNavLink
            to={pages["tournaments"]}
            profile="true"
            id="span-right"
          >
            <ContentCard
              showProfileMenu={profileMenu}
              profileMenu={isMobile || showProfileMenu}
              profile="true"
            >
              {(abc || (rac && isMobileOnly)) && (
                <StyledTournamentsABC accountroute={!accountRoute ? 1 : 0} />
              )}
              {(pub ||
                nbc ||
                bnk ||
                yet ||
                fun ||
                cac ||
                hyc ||
                yak ||
                qbc) && <StyledTournamentsPUB />}
            </ContentCard>
            {t("label_tournaments_header", "Tournaments")}
          </StyledNavLink>
        )}
        {pages["my-limits"] && (
          <StyledNavLink to={pages["my-limits"]} profile="true" id="span-right">
            <ContentCard
              showProfileMenu={profileMenu}
              profileMenu={isMobile || showProfileMenu}
              profile="true"
            >
              {(abc || (rac && isMobileOnly)) && (
                <StyledLimitsABC accountroute={!accountRoute ? 1 : 0} />
              )}
              {plc && <StyledLimits />}
              {(pub ||
                nbc ||
                bnk ||
                yet ||
                fun ||
                cac ||
                hyc ||
                yak ||
                qbc) && <StyledLimitsPUB />}
            </ContentCard>
            {t("label_limits", "Limits")}
          </StyledNavLink>
        )}
        <StyledNavLink
          to={pages["my-verification"]}
          profile="true"
          id="span-right"
        >
          <ContentCard
            showProfileMenu={profileMenu}
            profileMenu={isMobile || showProfileMenu}
            profile="true"
          >
            {(abc || (rac && isMobileOnly)) && (
              <StyledVerificationABC accountroute={!accountRoute ? 1 : 0} />
            )}
            {plc && <StyledVerification />}
            {(pub || nbc || bnk || yet || fun || cac || hyc || yak || qbc) && (
              <StyledVerificationPUB />
            )}
          </ContentCard>
          {t("label_verification", "Verification")}
        </StyledNavLink>
        {!isMobileOnly &&
          (abc || (rac && isMobileOnly)) &&
          accountRoute &&
          location.pathname !== pages["complaints"] && (
            <StyledNavLink
              /* as="div" */
              profile="true"
              id="span-right"
              to={pages["index"]}
              /* onClick={handleScrollToLobby} */
            >
              <ContentCard
                showProfileMenu={profileMenu}
                profileMenu={isMobile || showProfileMenu}
                profile="true"
              >
                {/* <StyledMaterialIcon as="span" goback></StyledMaterialIcon> */}
                <p>&#8617;</p>
              </ContentCard>
              <StyledInnerDescription>
                {t("label_go_back_to_games", "Go back to games")}
              </StyledInnerDescription>
            </StyledNavLink>
          )}
      </ContentTopWrapper>
      {(isMobileOnly && pub) || nbc || yet || (isMobile && rac) || cac || qbc
        ? null
        : isMobile /* && !showProfileMenu */ && (
            <StyledLink
              btnlogout="true"
              btnprofilemenu={showProfileMenu ? 1 : 0}
              to={pages["logout"]}
              dangerouslySetInnerHTML={{ __html: t("label_logout", "Logout") }}
            ></StyledLink>
          )}
    </>
  );
};

export default ProfileMenu;
