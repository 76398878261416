import React, { useEffect, useState } from "react";
import { SERVER_SETUP } from "common/src/helpers/constants";
import { getToken } from "common/src/helpers/session";
/* import { NeedToLogin } from "../modal/NeedToLogin"; */
import { HoverGamePar, StyleGameLink } from "../styledComponents/StyledGame";
import { NoFreePlayUKDialog } from "../modal/NoFreePlayUKDialog";
import { browserVersion, isMobileSafari, isSafari } from "react-device-detect";
//import { LiveDealers } from "./LiveDealers";

export const Game = ({
  games,
  gamesForCategory,
  showAllGames,
  width,
  skin,
  gameImagePath,
  tables,
  providerIsChosen,
}) => {
  const gridAreas = [
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eight",
    "nineth",
  ];
  let inRow = 5;

  if (width > 950 && width <= 1250) {
    inRow = 4;
  } else if (width > 770 && width <= 950) {
    inRow = 3;
  } else if (width <= 770) {
    inRow = 2;
  }

  const colonNum =
    games.length > 0 && games.length < inRow
      ? 1
      : Math.ceil(games.length / inRow);
  const lastInRow = games.length < inRow ? games.length : inRow;
  const firstInLastColon = colonNum === 1 ? 1 : (colonNum - 1) * inRow + 1;
  //console.log(firstInLastColon);

  const notFullColon = colonNum > 1 && games.length < colonNum * inRow;
  const bottomRightCorner = notFullColon && (colonNum - 1) * inRow;
  //console.log(bottomRightCorner);
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));

  const [cantPlayLive, setCantPlayLive] = useState(false);
  const [noFreePlay, setnoFreePlay] = useState(false);
  const [handleHover, setHandleHover] = useState(false);
  const [forcePng, setForcePng] = useState(false);
  const canProceed = (event, category) => {
    /* if (!getToken()  && category === "Live Casino") {
      event.preventDefault();
      setCantPlayLive(true);
    } */
    if (
      !getToken() &&
      category !==
        "Sports Book" /* && serverSetup.geoIP.isoCode.toLowerCase() === "gb" */
    ) {
      event.preventDefault();
      setnoFreePlay(true);
    }
  };
  useEffect(() => {
    if ((isMobileSafari || isSafari) && parseInt(browserVersion) < 14)
      setForcePng(true);
  }, []);

  return (
    <>
      {showAllGames === undefined &&
        games.map((game, index) => {
          let WebPimg =
            game.banner.match(/\.(webp)/g) !== null
              ? game.banner.replace(".webp", "_big_lobby.webp")
              : game.banner.match(/\.(jpg)/g) !== null
              ? game.banner.replace(".jpg", "_big_lobby.webp")
              : game.banner.replace(".png", "_big_lobby.webp");

          let onlyPNG =
            game.banner.match(/\.(png)/g) !== null
              ? game.banner.replace(".png", "_big_lobby.png")
              : game.banner.match(/\.(webp)/g) !== null
              ? game.banner.replace(".webp", "_big_lobby.png")
              : game.banner.replace(".jpg", "_big_lobby.png");

          let imgPath = forcePng ? onlyPNG : WebPimg;
          return (
            <StyleGameLink
              key={index}
              className={
                skin === "rac" && !providerIsChosen && gridAreas[index]
              }
              maintentnece={game.maintenance}
              lastInRow={lastInRow === index + 1}
              bottomLeftCorner={firstInLastColon === index + 1}
              bottomRightCorner={bottomRightCorner === index + 1}
              to={`${game.gameLink}`}
              onClick={(e) => canProceed(e, game.gameType)}
            >
              <HoverGamePar>{game.name}</HoverGamePar>
              <picture>
                <img
                  alt={game.name}
                  srcSet={
                    serverSetup.cdn.replace("http:", "").replace("https:", "") +
                    gameImagePath +
                    imgPath
                  }
                />
              </picture>
            </StyleGameLink>
          );
        })}
      {skin !== "plc" && (
        <NoFreePlayUKDialog
          showModal={noFreePlay}
          handleCancel={() => setnoFreePlay(false)}
          setModal={(e) => setnoFreePlay(e)}
          skin={skin}
          //handleProceed={() => window.location.replace(pages["register"])}
        />
      )}
    </>
  );
};
