import { gql } from "@apollo/client";

// Graphql query basic server setup
export const QUERY_QUERY_SERVER = gql`
  {
    server {
      cdn
      currentLanguage
      isSessionValid
      sessionTimeout
      template
      geoIP {
        ipAddress
        isoCode
      }
      languages {
        code
        name
      }
      currencies {
        code
        html
        title
        separatorDecimals
        separatorThousands
        decimals
        positionBefore
        separated
      }
      countries {
        code
        name
        age
        phoneCode
        nationality
      }
      gameCategories {
        name
        title
        isVisible
      }
      banners {
        name
        image
        alt
        sslLink
        banner
        description
        url
        order
        currency
        section
      }
      footerLogos {
        footerImage
        footerImageDark
      }
      footerLogosGames {
        footerLogo
        footerLogoDark
        name
        providerType
      }
      pages {
        blockedCountries
        isEnabled
        metaDescription
        metaKeywords
        name
        noFollow
        noIndex
        title
        url
      }
    }
  }
`;

// Graphql query server time from server query
export const QUERY_SERVER_TIME = gql`
  {
    serverTime {
      serverTime
    }
  }
`;

// Check if bank id already exist in database
export const QUERY_CHECK_BANK_ID = gql`
  query CheckBankID($identID: String!) {
    checkBankID(identID: $identID) {
      ok
    }
  }
`;

// Check if email already exist in database
export const QUERY_CHECK_EMAIL = gql`
  query CheckEmail($email: String!) {
    checkEmail(email: $email) {
      ok
    }
  }
`;

// Check of phone exist in database
export const QUERY_CHECK_PHONE = gql`
  query CheckPhone($phone: String!) {
    checkPhone(phone: $phone) {
      ok
    }
  }
`;

// Activate player account
export const QUERY_ACTIVATE = gql`
  query Activate($code: String!) {
    activate(code: $code) {
      url
    }
  }
`;

// Get ME informations
export const QUERY_ME = gql`
  {
    me {
      player {
        username
        sessionID
        firstName
        middleName
        lastName
        email
        leaderboard
        nickName
        country
        state
        city
        zipCode
        address
        phone
        gender
        title
        dateOfBirth
        nationality
        dateOfRegistration
        affiliateTrackingCode
        language
        isVerified
        receiveNews
        receiveSMS
        receiveBonus
        isTesting
        lastLogin
        abode
        buildingNumber
        buildingName
        currency
        tc
        gdprAccepted
        realityCheck
        doubleIP
        pep
        isVip
        riskyCountry
        bonusBlocked
        callcreditStatus
        callcreditTime
        kycpID
        nationalID
        isCashbackAbuser
        currentLoginTime
        betsSinceLastLogin
        winsSinceLastLogin
        limitsLocked
        ragAffordability
        requestAffordability
        pragmaticRTPNotification
      }
      lockStatus {
        deposit
        withdrawal
        liveCasino
        casino
        sportsbook
      }
      firstDeposit
      deposits {
        depositType
        description
        transactionTime
        transactionFee
        paymentProvider
        width
        height
        mobile
        order
        minimum
        maximum
        isTesting
        depositImage
        depositImageDark
      }
      grossResults
    }
  }
`;

// Get ME deposits
export const QUERY_ME_DEPOSITS = gql`
  {
    me {
      player {
        isTesting
        currency
        country
      }
      lockStatus {
        deposit
      }
      firstDeposit
      deposits {
        depositType
        description
        transactionTime
        transactionFee
        paymentProvider
        mobile
        minimum
        maximum
        isTesting
        depositImage
        depositImageDark
      }
    }
  }
`;

export const QUERY_WITHDRAWAL_LIST = gql`
  {
    getAvailableWithdrawalTypes {
      availableWithdrawalTypes {
        depositType
        description
        transactionTime
        transactionFee
        paymentProvider
        width
        height
        mobile
        order
        minimum
        maximum
        isAvailable
        isTesting
        depositImage
        withdrawalImage
        footerImage
        footerImageDark
        depositImageDark
        withdrawalImageDark
      }
    }
  }
`;

// Get ME withdrawals - withdrawals were moved from "me" to separate call above
// export const QUERY_ME_WITHDRAWALS = gql`
//   {
//     me {
//       player {
//         isTesting
//         currency
//         country
//       }
//       lockStatus {
//         withdrawal
//       }
//       firstDeposit
//       withdrawals {
//         depositType
//         description
//         transactionTime
//         transactionFee
//         paymentProvider
//         width
//         height
//         mobile
//         order
//         minimum
//         maximum
//         isAvailable
//         isTesting
//         withdrawalImage
//         withdrawalImageDark
//       }
//     }
//   }
// `;

// Get ME for responsible_gaming
export const QUERY_ME_CHECK = gql`
  {
    me {
      player {
        isTesting
        currency
        country
        requestAffordability
      }
    }
  }
`;

// Get Nett deposit limit
export const QUERY_NETT_LIMIT = gql`
  {
    getNettDepositLimit {
      limitType
    }
  }
`;

// Get ME for check if deposits are available
export const QUERY_ME_LOCKS = gql`
  {
    me {
      lockStatus {
        deposit
        withdrawal
        liveCasino
        casino
        sportsbook
      }
    }
  }
`;

// Logout player from system
export const QUERY_LOGOUT = gql`
  {
    logout {
      ok
    }
  }
`;

// Check if player session is still valid
export const QUERY_VALIDATE_SESSION = gql`
  {
    validateSession {
      ok
    }
  }
`;

// Graphql get games
export const QUERY_GET_GAME_LOBBY = gql`
  query GetGameLobby($country: String!, $gameCategory: String!) {
    getGameLobby(country: $country, gameCategory: $gameCategory) {
      lobby {
        categoryName
        games {
          gameType
          banner
          new
          isTesting
          vip
          vendor
          hot
          gameLink
          name
          jackpot
          amount
          gameID
          internalID
          maintenance
          subGameType
        }
      }
    }
  }
`;

// Graphql get games
export const QUERY_GET_GAMES_FOR_EXCLUD = gql`
  query SearchGames(
    $query: String!
    $device: String!
    $country: String!
    $themeSearch: String!
    $vendorSearch: String!
    $subVendorSearch: String!
  ) {
    searchGames(
      query: $query
      device: $device
      country: $country
      themeSearch: $themeSearch
      vendorSearch: $vendorSearch
      subVendorSearch: $subVendorSearch
    ) {
      games {
        vendor
        subVendor
        name
        gameID
        internalID
      }
    }
  }
`;

// Graphql get game types
export const QUERY_GET_GAME_TYPES_FOR_EXCLUSION = gql`
  {
    getGameTypes {
      gameTypes {
        name
      }
    }
  }
`;

// Graphql get game-theme types
export const QUERY_GET_GAME_THEMES = gql`
  {
    getGameThemes {
      gameThemes {
        name
        image
        darkImage
      }
    }
  }
`;

// Graphql query get game details
export const QUERY_GET_GAME = gql`
  query GetGame($gameID: String!) {
    getGame(gameID: $gameID) {
      game {
        vendor
        subVendor
        subGameType
        name
        client
        gameID
        tableType
        internalID
        textDescription
        gameType
        popUpBanner
      }
      launchURI
      launchParameters {
        gameName
        staticServer
        gameServer
        liveCasinoHost
        casinoBrand
        sessionId
        language
        helpUrl
        walletMode
        gameId
        tableId
        lobbyURL
        pluginUrl
        width
        height
        enforceRatio
      }
    }
  }
`;

// Get recent winners
export const QUERY_GET_RECENT_WINNERS = gql`
  query GetRecentWinners($currency: String!) {
    getRecentWinners(currency: $currency) {
      recent {
        country
        details
        firstName
        gameID
        gameType
        lastName
        nickName
        win
        banner
      }
      week {
        nickName
        firstName
        lastName
        country
        win
        cWin
        currency
        details
        gameType
        gameID
        banner
      }
      month {
        nickName
        firstName
        lastName
        country
        win
        cWin
        currency
        details
        gameType
        gameID
        banner
      }
    }
  }
`;

// Get game vendors
export const QUERY_GET_GAME_VENDORS = gql`
  {
    getGameVendors {
      vendors
    }
  }
`;

// Query player gaming session
export const QUERY_GET_GAME_ROUNDS = gql`
  query GetGameRounds($from: String!, $to: String!) {
    getGameRounds(from: $from, to: $to) {
      gameRounds {
        gameName
        status
        transactionType
        internalID
        externalID
        amount
        transactionTime
        available
      }
    }
  }
`;

// Game participate in tournament
export const QUERY_PARTICIPATE_IN_TOURNAMENT = gql`
  query ParticipateInTournament($gameID: String!) {
    participateInTournament(gameID: $gameID) {
      tournaments {
        maxBet
        uniqueID
        tournamentType
        startingAt
        minNumberOfRounds
        minBet
        endingAt
        duration
        tournamentName
        games
        maxNumberOfRounds
        tournamentDescription
        tournamentDescriptionImage
        prizes {
          first {
            currency
            value
          }
          second {
            currency
            value
          }
          third {
            currency
            value
          }
          fourth {
            currency
            value
          }
          tenth {
            currency
            value
          }
          fiftieth {
            currency
            value
          }
          twentieth {
            currency
            value
          }
        }
      }
    }
  }
`;

export const QUERY_LAST_TOURNAMENTS = gql`
  query GetLastXTournaments($limit: Float, $currency: String!) {
    getLastXTournaments(limit: $limit, currency: $currency) {
      active {
        games {
          gameName
          gameCategory
          details
        }
        uniqueId
        tournamentName
        startingAt
        endingAt
        prizes {
          first {
            currency
            value
          }
          second {
            currency
            value
          }
          third {
            currency
            value
          }
          fourth {
            currency
            value
          }
          tenth {
            currency
            value
          }
          fiftieth {
            currency
            value
          }
          twentieth {
            currency
            value
          }
        }
        tournamentDescription
        tournamentDescriptionImage
        tournamentType
        leaderboard {
          leaderboard
          betAmount
          winAmount
          netAmount
          netAmountF
          numberOfBets
          numberOfWins
          originalCurrency
        }
      }
      lastX {
        games {
          gameName
          gameCategory
          details
        }
        uniqueId
        tournamentName
        startingAt
        endingAt
        prizes {
          first {
            currency
            value
          }
          second {
            currency
            value
          }
          third {
            currency
            value
          }
          fourth {
            currency
            value
          }
          tenth {
            currency
            value
          }
          fiftieth {
            currency
            value
          }
          twentieth {
            currency
            value
          }
        }
        tournamentDescription
        tournamentDescriptionImage
        tournamentType
        leaderboard {
          leaderboard
          betAmount
          winAmount
          netAmount
          netAmountF
          numberOfBets
          numberOfWins
          originalCurrency
        }
      }
    }
  }
`;

// Get tourmant leaderboard results
export const QUERY_GET_TOURNAMENT_LEADERBOARD = gql`
  query GetTournamentLeaderboard($uniqueID: String!, $currency: String!) {
    getTournamentLeaderboard(uniqueID: $uniqueID, currency: $currency) {
      leaderboard {
        leaderboard
        betAmount
        winAmount
        netAmount
        netAmountF
        numberOfBets
        numberOfWins
        originalCurrency
      }
    }
  }
`;

// Get exchange rate based on currency
export const QUERY_GET_EXCHANGE_RATE = gql`
  query GetExchangeRage($currency: String!) {
    getExchangeRage(currency: $currency) {
      rate
    }
  }
`;

// Search games
export const QUERY_SEARCH_GAMES = gql`
  query SearchGames(
    $query: String!
    $device: String!
    $country: String!
    $themeSearch: String!
    $vendorSearch: String!
    $subVendorSearch: String!
  ) {
    searchGames(
      query: $query
      device: $device
      country: $country
      themeSearch: $themeSearch
      vendorSearch: $vendorSearch
      subVendorSearch: $subVendorSearch
    ) {
      games {
        gameType
        banner
        new
        isTesting
        vip
        vendor
        subVendor
        hot
        gameLink
        name
        jackpot
        amount
        gameID
        maintenance
        subGameType
      }
    }
  }
`;

// Graphql reset password check code
export const QUERY_RESET_PASSWORD_CHECK_CODE = gql`
  query CheckResetPassword($code: String!) {
    checkResetPassword(code: $code) {
      ok
    }
  }
`;

// Graphql get balance
export const QUERY_GET_BALANCE = gql`
  {
    getBalance {
      balance
      bonus
      point
      reserve
      real
      realWithLimits
      pendingWithdrawal
      total
      withdrawalID
    }
  }
`;

// Gaphql get cashback informations
export const QUERY_GET_CASHBACK = gql`
  {
    getCashback {
      nickName
      amount
      totalAmount
      createdAt
      expiresAt
    }
  }
`;

// Graphql redeem cashback
export const QUERY_REDEEM_CASHBACK = gql`
  {
    redeemCashback {
      ok
    }
  }
`;

// Graphql check experian status
export const QUERY_CHECK_EXPERIAN = gql`
  {
    checkExperian {
      lock
    }
  }
`;

// Graphql check experian status
export const QUERY_HOOYU_REQUEST_LINK = gql`
  {
    getHooyuRequestLink {
      link
      journeyCount
    }
  }
`;

// Graphql query edd/sow status
export const QUERY_EDD_SOW_STATUS = gql`
  {
    eddSowStatus {
      ok
    }
  }
`;

// Graphql create Kycp profile
export const QUERY_CREATE_KYCP = gql`
  {
    createKycp {
      ok
    }
  }
`;

// Graphql get financials total
export const QUERY_GET_FINANCIAL_TOTALS = gql`
  {
    getFinancials {
      depositTotal
      cashBonusTotal
      withdrawalTotal
      bonusTotal
    }
  }
`;

// Graphql get transactions list
export const QUERY_GET_TRANSACTIONS = gql`
  query GetTransactions($from: String, $to: String, $type: String) {
    getTransactions(from: $from, to: $to, transactionType: $type) {
      depositAmount
      withdrawalAmount
      transactions {
        amount
        depositType
        completedAt
        status
        transactionType
      }
    }
  }
`;

// Graphql get banktransfer data
export const QUERY_GET_BANKTRANSFER = gql`
  {
    getBanktransfer {
      id
      accountHolder
      accountNumber
      iBan
      bic
      sortCode
      bankName
      bankCountry
      clearingNumber
      bankBranch
      bankIFSC
      bankAddress
      isActive
    }
  }
`;

// Graphql get banktransfer data
export const QUERY_GET_BANKTRANSFER_ALL = gql`
  {
    getAllBanktransfer {
      banktransfer {
        id
        accountHolder
        accountNumber
        iBan
        bic
        sortCode
        bankName
        bankCountry
        clearingNumber
        bankBranch
        bankIFSC
        bankAddress
        isActive
        accountType
      }
    }
  }
`;

// Graphql get caily remaining withdrawal
export const QUERY_GET_DAILY_REMAINING_WITHDRAWAL = gql`
  {
    getDailyRemainingWithdrawal {
      amount
    }
  }
`;

// Graphql player locks status
export const QUERY_PLAYER_LOCKS = gql`
  {
    getPlayerLocks {
      deposit
      withdrawal
      liveCasino
      casino
      sportsbook
    }
  }
`;

// Graphql player leaderboard name
export const QUERY_GET_LEADERBOARD_NAME = gql`
  query GetLeaderboardName($leaderboard: String!) {
    getLeaderboardName(leaderboard: $leaderboard) {
      ok
    }
  }
`;

// Graphql cancel all bonuses
export const QUERY_CANCEL_ALL_BONUSES = gql`
  {
    cancelAllBonuses {
      ok
    }
  }
`;

// Graphql get active bonuses
export const QUERY_GET_ACTIVE_BONUSES = gql`
  {
    getActiveBonuses {
      bonuses {
        currency
        amountToWager
        wagerAmount
        wageredAmount
      }
    }
  }
`;

// Graphql get loyalty points setup
export const QUERY_GET_LOYALTY_POINTS = gql`
  {
    getLoyaltyPoints {
      coefficient
      multiplier
    }
  }
`;

// Graphql get blocked games
export const QUERY_GET_BLOCKED_GAMES = gql`
  {
    getBlockedGames {
      games {
        gameName
        blockedAt
        expiresAt
      }
    }
  }
`;

// Graphql get blocked games
export const QUERY_GET_BLOCKED_GAME_TYPES = gql`
  {
    getBlockedGameTypes {
      types {
        name
        blockedAt
        expiresAt
      }
    }
  }
`;

// Graphql get player timeout
export const QUERY_GET_TIMEOUT = gql`
  {
    getTimeout {
      duration
      status
      expiresAt
      createdAt
      reset
    }
  }
`;

// Graphql delete player timeout
export const QUERY_DELETE_TIMEOUT = gql`
  {
    deleteTimeout {
      ok
    }
  }
`;

// Graphql get nl player last session stats
export const QUERY_GET_LAST_SESSION_STATS = gql`
  {
    getLastSessionStats {
      bet
      win
      gross
      initialBalance
    }
  }
`;

// Graphql get nl player current session stats
export const QUERY_GET_CURRENT_SESSION_STATS = gql`
  {
    getCurrentSessionStats {
      bet
      win
      gross
      initialBalance
    }
  }
`;

// Graphql get player limits
export const QUERY_GET_LIMITS = gql`
  {
    getLimits {
      limits {
        limitType
        status
        period
        expiresAt
        remainingAmount
        calculationPeriod
        limit
        createdAt
        initialAmount
        nationalID
      }
    }
  }
`;

// Graphql clear player limits
export const QUERY_CLEAR_LIMITS = gql`
  {
    clearLimits {
      ok
    }
  }
`;

// Graphql ack responsible gaming popup
export const QUERY_ACK_RESPONSIBLE_GAMING_ALERT = gql`
  {
    ackResponsibleGamingAlert {
      ok
    }
  }
`;

// Graphql get multilotto limit
export const QUERY_GET_MULTILOTTO_LIMIT = gql`
  query GetMultilottoLimits($amount: Float!) {
    getMultilottoLimits(amount: $amount) {
      ok
    }
  }
`;

// Graphql get multilotto tickets
export const QUERY_GET_MULTILOTTO_TICKETS = gql`
  query GetMultilottoTickets($gameID: String!, $currency: String!) {
    getMultilottoTickets(gameID: $gameID, currency: $currency) {
      tickets {
        numbers
        bonusNumbers
        amount
        drawID
        drawings
        createdAt
      }
    }
  }
`;

// Graphql get player bonuses
export const QUERY_GET_PLAYER_BONUSES = gql`
  {
    getPlayerBonuses {
      bonuses {
        status
        bonusType
        uniqueID
        createdAt
        expiresAt
        instance
        bonusDescription
        bonusTerms
        bonusImage
        currency
        balance
        wageringAmount
        wagered
      }
    }
  }
`;

// Graphql get available bonuses
export const QUERY_GET_AVAILABLE_BONUSES = gql`
  {
    getAvailableBonuses {
      bonuses {
        bonusImage
        uniqueID
        promoName
        bonusCode
        bonusType
        dateTo
        daysValid
        bonusTerms
        dateFrom
        bonusDescription
        numberOfFreeSpins
        percentage
        activationType
        criteria
        minimumDeposit
        maximumDeposit
      }
    }
  }
`;

// Get username for token
export const QUERY_GET_USERNAME_FOR_TOKEN = gql`
  query GetUserNameForToken($token: String!) {
    getUserNameForToken(token: $token) {
      nickName
    }
  }
`;

// Set token and get nickname
export const QUERY_SET_TOKEN_GET_NICKNAME = gql`
  query SetTokenGetNickname($token: String!) {
    setTokenGetNickname(token: $token) {
      nickName
      sessionID
    }
  }
`;

// Get Reality Check
export const QUERY_REALITY_CHECK = gql`
  query GetRealityCheckStats($period: Float!) {
    getRealityCheckStats(period: $period) {
      display
      stats {
        period
        sessionID
        bet
        win
        currency
      }
    }
  }
`;

// Get session timeout
export const QUERY_SESSION_TIMEOUT = gql`
  query GetSessionTimeout($period: Float!) {
    getSessionTimeout(period: $period) {
      timeout
      allow
    }
  }
`;

//Get news
export const QUERY_GET_NEWS_AND_PROMOTIONS = gql`
  {
    getNewsAndPromotions {
      news {
        title
        body
        url
        banner
        sticky
        previous
        next
        sportsbook
      }
    }
  }
`;
export const TIME_LIMIT_REFRESH = gql`
  {
    timeLimitRefresh {
      ok
    }
  }
`;

// Graphql confirm rtp popup
export const QUERY_PRAGMATIC_RTP_CONFIRMED = gql`
  {
    pragmaticRTPConfirmed {
      ok
    }
  }
`;

export const QUERY_CHECK_LIMIT_INCREASE = gql`
  query Query($initialAmount: Float!, $limitType: String!) {
    checkLimitIncrease(initialAmount: $initialAmount, limitType: $limitType) {
      increase
      resetTime
    }
  }
`;

export const QUERY_GET_DATA_LINK = gql`
  query Query($nickName: String!) {
    getDataLink(nickName: $nickName) {
      url
      qrCode
    }
  }
`;
