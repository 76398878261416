import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { AccountDescription } from "../styledComponents/AccountPage";
import {
  StyledButton,
  StyledInput,
  StyledSelect,
} from "../styledComponents/CommonStyledComponents";
import {
  IncreaseLimit,
  RealityCheckWrapper,
  StyledCooldownExplanation,
  StyledCooldownExplanationSmall,
  StyledLimitSelect,
  StyledLimitWrapper,
} from "../styledComponents/StyledLimits";

const LossLimit = ({
  limitsData,
  generalMaxLimit,
  skinCurrency,
  lossLimits,
  hasLimits,
  handleSubmit,
  handleChange,
  resetLimits,
  isUk,
  realityCheck,
  player,
  skin,
}) => {
  const { t, i18n } = useTranslation("");
  // const { Country } = player;
  const { limitsLocked } = player;
  //const [maxValue, setMaxLimit] = useState(generalMaxLimit);
  const { lossValue, lossDate } = limitsData;
  const localizedFormat = `${
    i18n.language || window.localStorage.i18nextLng || "nl"
  }`;

  const [hasIncrease /*setHasIncrease*/] = useState(false);
  const [increaseTime /*setIncreaseTime*/] = useState("");
  const [blockChange, setBlockChange] = useState(false);
  //CSS variables
  let plcConfig = skin === "plc" ? true : false;

  useEffect(() => {
    function hasLimit() {
      /* if (lossLimits[0].increaseAt !== '') {
        setHasIncrease(true);
        setIncreaseTime(lossLimits[0].increaseAt);
      } */
      if (lossLimits[0].expiresAt !== "" && lossLimits[0].status === "active") {
        // setMaxLimit(lossValue);
        setBlockChange(true);
      }
    }
    lossLimits.length > 0 && hasLimit();
  }, [lossLimits]);

  return (
    <>
      {(skin === "fun" ||
        skin === "cac" ||
        skin === "hyc" ||
        skin === "yak") && (
        <AccountDescription
          expand="true"
          description
          dangerouslySetInnerHTML={{
            __html: t("lable_loss_limit_description"),
          }}
        ></AccountDescription>
      )}
      <StyledLimitWrapper>
        <StyledLimitSelect>
          <label>{t("label_choose_limit", "Period")}</label>
          <StyledSelect
            onChange={(e) => handleChange(e)}
            value={lossDate}
            name="lossDate"
            //disabled={lossLimits.length > 0}
          >
            <option value="daily">{t("label_daily", "Daily*")}</option>
            <option value="weekly">{t("label_weekly", "Weekly*")}</option>
            <option value="monthly">{t("label_monthly", "Monthly*")}</option>
          </StyledSelect>
        </StyledLimitSelect>
        <StyledLimitSelect>
          <label>
            {t("label_amount", "Amount")} ({skinCurrency})
          </label>
          <div className="input-group">
            <StyledInput
              limit
              type="number"
              name="lossValue"
              min="1"
              value={lossValue}
              onChange={(e) => handleChange(e)}
              max={generalMaxLimit}
              step="1"
              disabled={hasIncrease || (isUk && limitsLocked)}
            />
            <div className="input-group-append">
              <span className="input-group-text">{skinCurrency}</span>
            </div>
          </div>
        </StyledLimitSelect>
        {hasLimits && isUk ? (
          <StyledButton
            pub={skin === "pub"}
            limit
            onClick={() => resetLimits()}
            disabled={isUk && limitsLocked}
          >
            {t("label_reset", "Reset")}
          </StyledButton>
        ) : (
          <StyledButton
            pub={skin === "pub"}
            limit
            disabled={hasIncrease || (isUk && limitsLocked)}
            onClick={() => handleSubmit("loss")}
          >
            {t("label_save", "Save")}
          </StyledButton>
        )}
        {blockChange && (
          <StyledCooldownExplanation cooldownprop>
            <StyledCooldownExplanationSmall>
              {t("cooldown_explanation")}{" "}
              {new Date(lossLimits[0].expiresAt).toLocaleString(
                localizedFormat
              )}
            </StyledCooldownExplanationSmall>
          </StyledCooldownExplanation>
        )}
        {skin === "rac" && (
          <RealityCheckWrapper>
            {realityCheck !== -1 && (
              <p>
                {t("label_reality_check")} {Math.floor(realityCheck / 60)}{" "}
                {t("label_reality_check_minutes")}
              </p>
            )}
          </RealityCheckWrapper>
        )}
      </StyledLimitWrapper>
      {skin !== "rac" && skin !== "fun" && (
        <AccountDescription
          expand="true"
          description
          dangerouslySetInnerHTML={{ __html: t("message_limit_details") }}
        ></AccountDescription>
      )}
      {skin === "rac" && (
        <IncreaseLimit>
          <p>
            <Trans i18nKey="message_lossLimit_description"></Trans>
          </p>
        </IncreaseLimit>
      )}
      {skin === "rac" && (
        <IncreaseLimit>
          <Trans i18nKey="message_limit_details"></Trans>
        </IncreaseLimit>
      )}
    </>
  );
};

export default LossLimit;
