import React, { useEffect, useState, useContext } from "react";
import { ThemeContext } from "styled-components";
import { useHistory, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { useScrollPosition } from "common/src/helpers/useScrollPosition";
import { useTranslation } from "react-i18next";
import { ReactComponent as MainLogoPLC } from "../../../plc/src/data/icon-component/logo.svg";
import { ReactComponent as LogoMobilePLC } from "../../../plc/src/data/icon-component/polder_logo_mobile.svg";
import { ReactComponent as MainLogoABC } from "../../../abc/src/data/icon-component/abc-logo.svg";
import { ReactComponent as LogoMobileABC } from "../../../abc/src/data/icon-component/abc-logo-mobile.svg";
import { ReactComponent as MainLogoPUB } from "../../../pub/src/data/icon-component/logo.svg";
import { ReactComponent as LogoMobilePUB } from "../../../pub/src/data/icon-component/logo.svg";
import { ReactComponent as LogoMobileRAC } from "../../../rac/src/data/icon-component/rac-logo-mobile.svg";
import { ReactComponent as LogoRAC } from "../../../rac/src/data/icon-component/logo.svg";
import { ReactComponent as MainLogoNBC } from "../../../nbc/src/data/icon-component/nbc-logo.svg";
import { ReactComponent as MobileLogoNBC } from "../../../nbc/src/data/icon-component/nbc-logo-mobile.svg";
import { ReactComponent as MainLogoBNK } from "../../../bnk/src/data/icon-component/logo.svg";
import { ReactComponent as MobileLogoBNK } from "../../../bnk/src/data/icon-component/logo-mobile.svg";
import { ReactComponent as MainLogoYET } from "../../../yet/src/data/icon-component/logo.svg";
import { ReactComponent as MobileLogoYET } from "../../../yet/src/data/icon-component/logo-mobile.svg";
import { ReactComponent as MainLogoFUN } from "../../../fun/src/data/icon-component/logo.svg";
import { ReactComponent as MobileLogoFUN } from "../../../fun/src/data/icon-component/logo-mobile.svg";
import { ReactComponent as MainLogoCAC } from "../../../cac/src/data/icon-component/logo.svg";
import { ReactComponent as MainLogoHYC } from "../../../hyc/src/data/icon-component/logo.svg";
import { ReactComponent as MainLogoYAK } from "../../../yak/src/data/icon-component/logo.svg";
import { ReactComponent as MainLogoQBC } from "../../../qbc/src/data/icon-component/logo.svg";

import { isMobile, isMobileOnly } from "react-device-detect";
import { handleOrientationChange } from "common/src/helpers/isPortrait";
import { LogoWrapper } from "../styledComponents/CommonStyledComponents";
import Cookies from "js-cookie";

const Logo = ({ isSWEIp, classOnScroll, setOpen, defaultLang, player }) => {
  const { i18n } = useTranslation("");
  const svgOnScroll = classOnScroll ? "" : "svgchanger";
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [isOnLending, setIsOnLending] = useState(false);
  const themeContext = useContext(ThemeContext);
  /* const [isPortrait, setIsPortrait] = useState(false); */
  const baseUrl = `/${
    i18n.language || window.localStorage.i18nextLng || defaultLang
  }`;
  const indexPage =
    useLocation().pathname.split("/")[2] ===
    (themeContext.skin === "rac" && player ? "games" : "index")
      ? true
      : false;
  const history = useHistory();
  const topMarginForSWE = isSWEIp && hideOnScroll ? "margin-top" : "";

  // index.js
  window.onscroll = function () {
    themeContext.skin === "abc" && scrollRotate();
  };

  const scrollRotate = () => {
    let logo = document.querySelector(".spin");
    if (logo !== null)
      logo.style.transform = `rotate(${window.pageYOffset * 0.1}deg)`;
  };

  const scrollToTop = () => {
    setOpen(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useScrollPosition(
    ({ currPos }) => {
      const isShow = Math.abs(currPos.y) < 150;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  useEffect(() => {
    if (Cookies.get("land") && themeContext.skin === "yet") {
      setIsOnLending(true);
    } else {
      setIsOnLending(false);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("orientationchange", handleOrientationChange);
      return () =>
        window.removeEventListener(
          "orientationchange",
          handleOrientationChange
        );
    }
  }, [handleOrientationChange]);

  let logoClass = isMobile
    ? location.pathname === baseUrl + "/index"
      ? handleOrientationChange()
        ? "logo "
        : "logo-landscape "
      : handleOrientationChange()
      ? "logo-not-landing "
      : "logo-landscape "
    : location.pathname !== baseUrl + "/index"
    ? isOnLending
      ? "logo-not-landing-yet "
      : "logo-not-landing "
    : "logo ";

  const skinLogo = () => {
    let LogoComponent;
    let logoStyle;
    let plcOrPub = themeContext.skin === "plc" || themeContext.skin === "pub";

    // PLC LOGO
    if (themeContext.skin === "plc") {
      if (isMobileOnly) {
        LogoComponent = !svgOnScroll ? MainLogoPLC : LogoMobilePLC;
      } else {
        LogoComponent = MainLogoPLC;
      }
    }
    //ABC LOGO
    if (themeContext.skin === "abc") {
      LogoComponent = isMobileOnly ? LogoMobileABC : MainLogoABC;
    }
    // PUB LOGO
    if (themeContext.skin === "pub") {
      if (isMobileOnly) {
        LogoComponent = !svgOnScroll ? MainLogoPUB : LogoMobilePUB;
      } else {
        LogoComponent = MainLogoPUB;
      }
      /* LogoComponent = isMobileOnly ? LogoMobilePUB : MainLogoPUB; */
    }
    //RAC LOGO
    if (themeContext.skin === "rac") {
      LogoComponent = isMobileOnly ? LogoMobileRAC : LogoRAC;
    }
    //NBC LOGO
    if (themeContext.skin === "nbc") {
      LogoComponent = isMobileOnly ? MobileLogoNBC : MainLogoNBC;
    }
    //BNK LOGO
    if (themeContext.skin === "bnk") {
      LogoComponent = isMobileOnly ? MobileLogoBNK : MainLogoBNK;
    }
    //YET LOGO
    if (themeContext.skin === "yet") {
      LogoComponent = isMobileOnly ? MobileLogoYET : MainLogoYET;
    }
    //FUN LOGO
    if (themeContext.skin === "fun") {
      LogoComponent = isMobileOnly ? MobileLogoFUN : MainLogoFUN;
    }
    //CAC LOGO
    if (themeContext.skin === "cac") {
      LogoComponent = /* isMobileOnly ? MobileLogoFUN : */ MainLogoCAC;
    }
    //HYC LOGO
    if (themeContext.skin === "hyc") {
      LogoComponent = /* isMobileOnly ? MobileLogoFUN : */ MainLogoHYC;
    }
    //YAK LOGO
    if (themeContext.skin === "yak") {
      LogoComponent = /* isMobileOnly ? MobileLogoFUN : */ MainLogoYAK;
    }
    //QBC LOGO
    if (themeContext.skin === "qbc") {
      LogoComponent = /* isMobileOnly ? MobileLogoFUN : */ MainLogoQBC;
    }

    //custom css setter
    if (isMobileOnly) {
      if (plcOrPub && !svgOnScroll) {
        logoStyle = logoClass + topMarginForSWE + svgOnScroll + " spin";
      } else {
        logoStyle = "logo-mobile " + svgOnScroll + " spin";
      }
    } else {
      logoStyle = logoClass + topMarginForSWE + svgOnScroll + " spin";
    }

    return (
      <LogoWrapper
        // player={!player}
        onClick={() =>
          indexPage
            ? scrollToTop()
            : window.location.pathname !== baseUrl + "/maintenance"
            ? history.push(
                baseUrl +
                  (themeContext.skin === "rac" && player ? "/games" : "/index")
              )
            : null
        }
      >
        <LogoComponent className={logoStyle} />
      </LogoWrapper>
    );
  };

  return skinLogo();
};

export default withRouter(Logo);
