import React, { useEffect /*, useRef*/, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentServer } from "common/src/helpers/connectors";
import {
  PENDING_DEPOSIT,
  PENDING_FINISH,
  PNPTS,
  PNPUID,
  SERVER_SETUP,
  SWE_LIMIT_DIALOG,
  THEME_DARK,
} from "common/src/helpers/constants";
import {
  QUERY_CREATE_KYCP,
  QUERY_EDD_SOW_STATUS,
  QUERY_GET_LIMITS,
  QUERY_GET_TIMEOUT,
  QUERY_ME_LOCKS,
} from "common/src/graphql/queries";
import { getToken } from "common/src/helpers/session";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
//import { checkError } from "common/src/helpers/functions";
import ZignsecDialog from "common/src/component/ZignsecDialog";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { useApolloClient } from "@apollo/client";
import { Link } from "react-router-dom";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import {
  PnPFramebox,
  PnPImg,
  PnPOtherPaymentMethods,
  PnPWrapper,
  StyledContinue,
  StyledDepbox,
  StyledHeadline,
  StyledParallax,
  TcppWrapper,
  ZignsecStep,
  StyledIframe,
} from "../styledComponents/StyledSignupLogin";
import {
  StyledButton,
  StyledLink,
} from "../styledComponents/CommonStyledComponents";
import { FullPageOverlay } from "../styledComponents/StyledGame";
import Select from "common/src/component/select.js";
import Input from "common/src/component/input";
import depositEn from "../../../rac/src/data/img/deposit.svg";
import depositSv from "../../../rac/src/data/img/insattning.svg";
// import depositFi from "../../../rac/src/data/img/talletus.svg";
import registerEn from "../../../rac/src/data/img/register.svg";
import continueEn from "../../../rac/src/data/img/continue.svg";
import registerSv from "../../../rac/src/data/img/fortsatt_spela.svg";
// import registerFi from "../../../rac/src/data/img/jatkaa.svg";
import playNowSv from "../../../rac/src/data/img/fortsatt_spela.svg";
import playNowEn from "../../../rac/src/data/img/gotogames-en.svg";
import { BannedDialogWithMessage } from "../modal/BannedDialogWithMessage";
import { NewsButtonWrapper } from "../styledComponents/StyledNews";

const PnP = ({
  pages,
  server,
  player,
  setSpinner,
  depositList,
  currentTheme,
  setPxRegisterStart,
  setPxDepositStart,
  skin,
  paymentsImagePath,
  bannedDepositPage,
  riskyCountry,
  setSweLimitDialog,
}) => {
  const { t, i18n } = useTranslation("");
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const [session] = useState(getToken());
  const apolloClient = useApolloClient();
  // const [select, setSelect] = useState("eur");
  const { currencies } = serverSetup;

  //set default values for pnp deposit
  let defAmount = "10";
  let defCurrency = "eur";
  // let defCurrencyHtml = "€";

  // const inputRef = useRef();
  // const selectRef = useRef();

  const [showBannedDialogWithMessage, setShowBannedDialogWithMessage] =
    useState(false);

  // const [depositCurrencyHtml, setDepositCurrencyHtml] = useState(defCurrencyHtml);
  const [loginRedirect, setLoginRedirect] = useState(false);
  const [registerRedirect, setRegisterRedirect] = useState(false);
  const [gameRedirect, setGameRedirect] = useState(false);
  const [depositRedirect, setDepositRedirect] = useState(false);
  const [supportRedirect, setSupportRedirect] = useState(false);
  const [pnpFinishRedirect, setPnpFinishRedirect] = useState(false);
  const [depositStepIFrameSrcDoc] = useState(null);

  //deposit steps (step 10 is zignsec dialog)
  const [pnpStep, setPnpStep] = useState(1);
  const [pnpStepIFrame, setPnpStepIframe] = useState("");

  const [missingLimitsRedirect, setMissingLimitsRedirect] = useState(false);
  const [hasTimeout, setHasTimeout] = useState(false);

  //zignsec login step
  // const [zignsecStep, setZignsecStep] = useState(false);

  //change php deposit defaults if cntry is:
  let country = "";
  if (player.country) {
    country = player.country;
  } else {
    // console.log("geoip: " + serverSetup.geoIP.isoCode)
    country = serverSetup.geoIP.isoCode.toLowerCase();
  }

  //comment out this when finished
  // if (country === "") {
  //   country = "se";
  // }
  // country = "gb" //test
  if (country === "se") {
    // console.log("sek")
    serverSetup.currencies.forEach((option) => {
      if (option.code === "sek") {
        defAmount = "100";
        defCurrency = option.code;
        // defCurrencyHtml = option.html;
      }
    });
  } else if (country === "fi") {
    serverSetup.currencies.forEach((option) => {
      if (option.code === "eur") {
        defAmount = "10";
        defCurrency = option.code;
        // defCurrencyHtml = option.html;
      }
    });
  } else if (country === "gb") {
    serverSetup.currencies.forEach((option) => {
      if (option.code === "gbp") {
        defAmount = "10";
        defCurrency = option.code;
        // defCurrencyHtml = option.html;
      }
    });
  }

  //this is here so that default amount and currency are appended after logic for default values
  const [depositAmount, setDepositAmount] = useState(defAmount);
  const [depositCurrency, setDepositCurrency] = useState(defCurrency);

  const inputWidth = depositAmount.length * (isMobileOnly ? 27 : 50) + "px";

  let currencyOptions = [];

  currencies.map((option, index) => {
    currencyOptions[index] = { value: option.code, name: option.html };
  });

  useEffect(() => {
    if (/*skin === "rac" && */ session && bannedDepositPage) {
      if (!riskyCountry) {
        sessionStorage.setItem("user_banned_from_page", true);
      } else {
        sessionStorage.setItem("user_from_risky_country", true);
      }
      setSupportRedirect(true);
    }
  }, [session, bannedDepositPage, riskyCountry]);

  useEffect(() => {
    if (session && localStorage.getItem(SWE_LIMIT_DIALOG)) {
      setSweLimitDialog(true);
    }

    // if (
    //   config.skin === "rac" &&
    //   session &&
    //   hasWithdraw &&
    //   player.country !== "fi" &&
    //   player.country !== "se"
    // ) {
    //   setShowWithdrawAlertDialog(true);
    // }
  }, [
    session,
    // hasWithdraw,
    setSweLimitDialog,
    // setShowWithdrawAlertDialog,
    // player.country,
  ]);

  useEffect(() => {
    if (localStorage.getItem(PENDING_DEPOSIT)) {
      setShowBannedDialogWithMessage(true);
      localStorage.removeItem(PENDING_DEPOSIT);
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("startPnpFromHeader") && pnpStep !== 10) {
      sessionStorage.removeItem("startPnpFromHeader");
      pnpContinue();
    }
  }, [sessionStorage.getItem("startPnpFromHeader")]);

  function checkSWEDepositLimit() {
    // console.log("check is set limit for swe");
    // let hasTimelimitDaily = false;
    // let hasTimelimitWeekly = false;
    // let hasTimelimitMonthly = false;
    let hasDepositLimit = false;
    getLimits().then((limits) => {
      limits &&
        limits.forEach((limit) => {
          if (limit.limit === "deposit") {
            hasDepositLimit = true;
          }
          // if (
          //   limit.limitType === "daily_timelimit" &&
          //   limit.initialAmount > 0
          // ) {
          //   hasTimelimitDaily = true;
          // }
          // if (
          //   limit.limitType === "monthly_timelimit" &&
          //   limit.initialAmount > 0
          // ) {
          //   hasTimelimitMonthly = true;
          // }
          // if (
          //   limit.limitType === "weekly_timelimit" &&
          //   limit.initialAmount > 0
          // ) {
          //   hasTimelimitWeekly = true;
          // }
          // console.log(hasDepositLimit + " " + hasTimelimitDaily + " " + hasTimelimitMonthly + " " + hasTimelimitWeekly)
        });
      if (
        !hasDepositLimit /*||
        !hasTimelimitDaily ||
        !hasTimelimitWeekly ||
        !hasTimelimitMonthly */
      ) {
        // console.log("should redirect!");
        localStorage.setItem("sweSetDepositLimit", "show");
        // window.location = `${baseUrl}/my-limits`;
        setMissingLimitsRedirect(true);
      }
    });
  }

  async function getLimits() {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_LIMITS,
    });
    if (loading) return <h3>Loading ...</h3>;
    if (errors) return false;
    return data.getLimits.limits;
  }

  if (session && player.country === "se") {
    checkSWEDepositLimit();
  }

  async function pnpGetTimeout(depositAmount) {
    setSpinner(true);
    const { loading, data, errors } = await apolloClient.query({
      query: QUERY_GET_TIMEOUT,
    });

    if (loading) return <h3>Loading ...</h3>;
    if (errors) {
      if (errors[0].message === "error_timeout_not_exist") {
        setHasTimeout(false);
      } else {
        console.log(errors);
      }
    }

    //setTimeout(data.getTimeout);
    // console.log("timeout: " + JSON.stringify(data))
    if (data.getTimeout !== null) {
      setSpinner(false);
      setHasTimeout(true);
    } else {
      // showPNPDialog();
      await pnpCheckEddSow(depositAmount);
    }
  }

  const pnpCheckEddSow = async (depositAmount) => {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_EDD_SOW_STATUS,
    });

    if (loading) return <h3>Loading ...</h3>;
    if (errors) {
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      setSpinner(false);
      return false;
    }
    // console.log("EDD RESPONSE: " + JSON.stringify(data))
    if (data.eddSowStatus.ok) {
      await pnpKycCheck(depositAmount);
    } else {
      sessionStorage.setItem("user_banned_from_page", "eddsow");
      setSupportRedirect(true);
      //change it to use redirect
      // window.location.replace(`${baseUrl}/support`);
    }
  };

  const pnpKycCheck = async (depositAmount) => {
    if (player.kycpID === "") {
      const { loading, errors } = await apolloClient.query({
        query: QUERY_CREATE_KYCP,
      });

      if (loading) return <h3>Loading ...</h3>;
      if (errors) {
        // toast.error(t(errors[0].message), {
        //   position: "bottom-center",
        // });
        // setShowDepositStartingDialog(false);
        // return false;
      }
      // console.log("KYCP RESPONSE: " + JSON.stringify(data))
      // if(data.createKycp.result) {
      //   experianCheck(depositParameters);
      // } else {
      //   sessionStorage.setItem("user_banned_from_page", "eddsow");
      //   window.location.replace(`${baseUrl}/support`);
      // }
      await pnpDepositsAvailableCheck(depositAmount);
    } else {
      await pnpDepositsAvailableCheck(depositAmount);
    }
  };

  const pnpDepositsAvailableCheck = async (depositAmount) => {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_ME_LOCKS,
    });

    if (loading) return <h3>Loading ...</h3>;
    if (errors) {
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      return false;
    }
    // console.log("DEPOSITS AVAILABLE RESPONSE: " + JSON.stringify(data));
    if (data.me.lockStatus.deposit) {
      sessionStorage.setItem("user_banned_from_page", "eddsow");
      setSupportRedirect(true);
      // window.location.replace(`${baseUrl}/support`);
    } else {
      startPnpDeposit(depositAmount);
    }
  };

  const startPnpDeposit = (depositAmount) => {
    // console.log("start PNP TRUSTLY DEPOSIT: " + depositAmount);
    //tracking pixels
    setPxRegisterStart(true);
    setPxDepositStart(true);

    let parameters = {
      amount: depositAmount,
      depositType: "Trustly",
      paymentProvider: "trustly",
      userLocale: i18n.language,
      ip: serverSetup.geoIP.ipAddress,
      nickName: player.nickName,
      playerCountry: player.country,
      playerCurrency: player.currency,
      playerName: player.firstName,
      playerLastName: player.lastName,
      playerEmail: player.email,
      playerPhone: player.phone,
      domain: window.location.hostname,
      isMobile: isMobile || isTablet ? "true" : "false",
    };

    // console.log("PARAMS: " + JSON.stringify(parameters));
    // console.log("submit form:" + JSON.stringify(data));
    PaymentServer.post("/fhnd/v1/transaction/deposit", parameters)
      .then((res) => {
        // console.log("success: " + JSON.stringify(res));
        if (res.data.response.location === "redirect") {
          //iframe
          setSpinner(false);
          if (isMobile || isTablet) {
            window.location.replace(res.data.response.url);
          }
          setPnpStepIframe(res.data.response.url);
          setPnpStep(2);
          setSpinner(false);
        } else {
          setSpinner(false);
          //normal response (dresponse link)
        }
      })
      .catch((err) => {
        //step back on error
        toast.error(t(err.response.data[0]), {
          position: "bottom-center",
        });
        setSpinner(false);
        return false;
      });
  };

  /* const redirectTo = skin === "plc" ? "index" : "games"; */

  // console.log("currency html: " + depositCurrencyHtml)
  // console.log("currency: " + depositCurrency)

  if (pnpFinishRedirect) {
    localStorage.removeItem(PENDING_FINISH);
    window.location.replace(pages["pnp-finished"]);
  }

  const checkPnpFinishRedirect = () => {
    if (
      session === null &&
      localStorage.getItem(PNPTS) &&
      localStorage.getItem(PNPUID) &&
      localStorage.getItem(PENDING_FINISH)
    ) {
      // console.log("pnp finish redirect!");
      setPnpFinishRedirect(true);
      return true;
    }
    return false;
  };

  const pnpContinue = () => {
    if (country === "se") {
      //continue for pnp eligible, game redirect if logged in, if logged out tbd.
      if (checkPnpFinishRedirect()) {
        return;
      }

      if (session !== null) {
        setGameRedirect(true);
      } else {
        // console.log("start zignsec");
        setPnpStep(10);
      }
    } else if (country === "fi") {
      if (checkPnpFinishRedirect()) {
        return;
      }
      session !== null ? setGameRedirect(true) : setLoginRedirect(true);
    } else {
      //continue for pnp not eligible, game on logged in, register on logged out
      session !== null ? setGameRedirect(true) : setRegisterRedirect(true);
    }
  };

  const startPnp = () => {
    if (country === "se" || country === "fi") {
      //pnp eligible

      if (checkPnpFinishRedirect()) {
        return;
      }

      if (depositAmount < "10") {
        // console.log("empty deposit amount");
      } else {
        if (session !== null) {
          //start trustly deposit for logged in
          // console.log("start trustly");
          if (player.country !== "se" || player.nationalID !== "") {
            pnpGetTimeout(depositAmount);
          }
        } else {
          //start pnp for logged out
          // console.log("deposit amount: " + depositAmount);
          setSpinner(true);
          //tracking pixels
          setPxRegisterStart(true);
          setPxDepositStart(true);

          let uid = uuidv4();
          let ts = Math.floor(Date.now() / 1000);
          let depAmount = parseFloat(depositAmount) * 100;
          depAmount = depAmount.toFixed(0);
          PaymentServer.post("/fhnd/v1/pnp/start", {
            amount: String(depAmount),
            currency: depositCurrency,
            country: country,
            ps_uid: uid,
            ps_ts: ts,
            locale: serverSetup.currentLanguage,
            ip: serverSetup.geoIP.ipAddress,
            aff_code: Cookies.get("affCode" + skin.toUpperCase())
              ? Cookies.get("affCode" + skin.toUpperCase())
              : "",
          })
            .then((res) => {
              setSpinner(false);
              localStorage.setItem(PNPUID, uid);
              localStorage.setItem(PNPTS, ts);
              // console.log("success: " + res.data.response.url);
              if (isMobile || isTablet) {
                window.location.replace(res.data.response.url);
              }
              setPnpStepIframe(res.data.response.url);
              setPnpStep(2);
            })
            .catch((error) => {
              setSpinner(false);
              // console.log("error: " + JSON.stringify(error.response.data));
              // console.log("error count: " + error.length)
              // console.log(error.response)
              toast.error(t(error.response.data["0"], error.data), {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            });
        }
      }
    } else {
      //non-pnp process, logged in redirect to deposit, logged out redirect to login
      session !== null ? setDepositRedirect(true) : setLoginRedirect(true);
    }
  };

  //redirections for pnp cta if player is not pnp eligible (based on ip geo)
  if (loginRedirect) {
    return <Redirect to={pages["login"]}></Redirect>;
  }
  if (registerRedirect) {
    return <Redirect to={pages["register"]}></Redirect>;
  }
  if (gameRedirect) {
    return <Redirect to={pages["games"]}></Redirect>;
  }
  if (depositRedirect) {
    return <Redirect to={pages["deposit"]}></Redirect>;
  }
  if (supportRedirect) {
    return <Redirect to={pages["support"]}></Redirect>;
  }

  if (pnpStep === 3) {
    // return <Redirect to={pages["games"]}></Redirect>;
    window.location.replace(pages["games"]);
  } else if (pnpStep === 4) {
    return <Redirect to={pages["register"]}></Redirect>;
  }

  if (missingLimitsRedirect) {
    return <Redirect to={pages["my-limits"]}></Redirect>;
  }

  if (hasTimeout) {
    sessionStorage.setItem("TIMEOUT_ACTIVE_FLAG", true);
    // window.location = `${baseUrl}/support`;
    setSupportRedirect(true);
  }

  let registerAndLoginBtn;
  if (player) {
    if (i18n.language === "sv") {
      registerAndLoginBtn = playNowSv;
    } else {
      registerAndLoginBtn = playNowEn;
    }
  } else {
    if (i18n.language === "en") registerAndLoginBtn = registerEn;
    if (i18n.language === "sv") registerAndLoginBtn = registerSv;
    if (country === "fi" || (country === "se" && i18n.language === "en"))
      registerAndLoginBtn = continueEn;
  }

  return (
    <>
      <PnPWrapper>
        <StyledParallax data-speed-y="8" zignsec={pnpStep === 10}>
          <StyledHeadline>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "headline_text",
                  "Race Casino is the fastest <br/>Pay N Play Casino."
                ),
              }}
            ></p>
          </StyledHeadline>
        </StyledParallax>

        {pnpStep !== 10 && (
          <StyledDepbox inputWidth={inputWidth}>
            <Select
              name="depositCurrency"
              list={currencyOptions}
              disabled={false}
              value={defCurrency}
              onChange={(e) => {
                setDepositCurrency(e.target.value);
                // var index = e.nativeEvent.target.selectedIndex;
                // setDepositCurrencyHtml(e.nativeEvent.target[index].text);
              }}
              firstOption={""}
            />
            <Input
              name="depositAmount"
              value={depositAmount}
              onChange={(e) =>
                setDepositAmount(
                  e.target.value.replace(/^0+/, "").replace(/[^\d]/, "")
                )
              }
              type="tel"
              errors={null}
              placeholder={true}
              autoFocus={true}
              maxLength={"8"}
            />
            <i></i>
            <div>
              <PnPImg
                className="fganim"
                alt="pnp"
                src={i18n.language === "sv" ? depositSv : depositEn}
                onClick={startPnp}
              />
            </div>
          </StyledDepbox>
        )}
        {pnpStep !== 10 && (
          <StyledContinue>
            <div>
              <p>{t("label_or")}</p>
              <PnPImg alt="" src={registerAndLoginBtn} onClick={pnpContinue} />

              <TcppWrapper>
                {t("tcpp_1", "By proceeding you accept our")}{" "}
                <StyledLink
                  tcpp
                  to={pages["terms-and-conditions"]}
                  dangerouslySetInnerHTML={{
                    __html: t("tcpp_tandc", "T&C"),
                  }}
                ></StyledLink>{" "}
                {t("tcpp_and", "and")}{" "}
                <StyledLink tcpp to={pages["privacy-policy"]}>
                  {t("tcpp_ppolicy", "P. Policy")}
                </StyledLink>
              </TcppWrapper>
            </div>
          </StyledContinue>
        )}
        {!player && pnpStep !== 10 && (
          <NewsButtonWrapper>
            <StyledButton
              btn
              type="button"
              onClick={() => (window.location = pages["games"])}
            >
              {t("label_go_to_games")}
            </StyledButton>
          </NewsButtonWrapper>
        )}
        {pnpStep === 2 && pnpStepIFrame !== "" && (
          <FullPageOverlay>
            <PnPFramebox>
              <StyledIframe
                src={pnpStepIFrame}
                width="600"
                height="600"
                setDepositStep={setPnpStep}
                requestMethod=""
                requestParams="{}"
                srcDoc={depositStepIFrameSrcDoc}
              />
              {/*{!session && (*/}
              {/*  <PnPOtherPaymentMethods>*/}
              {/*    {t("label_choose_other_method")}*/}
              {/*    <div className="methods">*/}
              {/*      {serverSetup.footerLogos.map((value, index) => {*/}
              {/*        return (*/}
              {/*          <Link to={pages["register"]} key={index}>*/}
              {/*            {value.footerImage && (*/}
              {/*              <img*/}
              {/*                alt=""*/}
              {/*                srcSet={*/}
              {/*                  server.cdn +*/}
              {/*                  paymentsImagePath +*/}
              {/*                  "/" +*/}
              {/*                  i18n.language +*/}
              {/*                  "/" +*/}
              {/*                  value.footerImage*/}
              {/*                }*/}
              {/*                height="30"*/}
              {/*              />*/}
              {/*            )}*/}
              {/*          </Link>*/}
              {/*        );*/}
              {/*      })}*/}
              {/*    </div>*/}
              {/*  </PnPOtherPaymentMethods>*/}
              {/*)}*/}
              {/*{session && (*/}
              {/*  <PnPOtherPaymentMethods>*/}
              {/*    {t("label_choose_other_method")}*/}
              {/*    <div className="methods">*/}
              {/*      {depositList &&*/}
              {/*        depositList.map((value, index) => {*/}
              {/*          return (*/}
              {/*            <Link to={pages["deposit"]} key={index}>*/}
              {/*              {value.depositImage && value.depositImageDark && (*/}
              {/*                <img*/}
              {/*                  alt=""*/}
              {/*                  srcSet={*/}
              {/*                    currentTheme === THEME_DARK*/}
              {/*                      ? server.cdn +*/}
              {/*                        paymentsImagePath +*/}
              {/*                        "/" +*/}
              {/*                        i18n.language +*/}
              {/*                        "/" +*/}
              {/*                        value.depositImage*/}
              {/*                      : server.cdn +*/}
              {/*                        paymentsImagePath +*/}
              {/*                        "/" +*/}
              {/*                        i18n.language +*/}
              {/*                        "/" +*/}
              {/*                        value.depositImageDark*/}
              {/*                  }*/}
              {/*                  height="60"*/}
              {/*                />*/}
              {/*              )}*/}
              {/*            </Link>*/}
              {/*          );*/}
              {/*        })}*/}
              {/*    </div>*/}
              {/*  </PnPOtherPaymentMethods>*/}
              {/*)}*/}
            </PnPFramebox>
          </FullPageOverlay>
        )}
        {pnpStep === 10 && (
          <ZignsecStep>
            <ZignsecDialog
              setPnpStep={setPnpStep}
              setSpinner={setSpinner}
              pages={pages}
            ></ZignsecDialog>
          </ZignsecStep>
        )}
      </PnPWrapper>

      <BannedDialogWithMessage
        showModal={showBannedDialogWithMessage}
        handleProceed={() => setShowBannedDialogWithMessage(false)}
        message={t("label_pnp_pending_deposit")}
        skin={skin}
      />
    </>
  );
};

export default PnP;
