import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import {
  QUERY_GET_TRANSACTIONS,
  QUERY_GET_FINANCIAL_TOTALS,
} from "common/src/graphql/queries";
import DepositTransactions from "common/src/component/DepositTransactions";
import WithdrawTransactions from "common/src/component/WithdrawTransactions";
import FinancialTotals from "common/src/component/FinancialTotals";
import { ContentTop } from "common/src/component/ContentTop";
import config from "../config.json";
import { isMobileOnly } from "react-device-detect";
import {
  AccountContainer,
  PageContent,
  PageContentWrapper,
  TabContent,
  TransactionsButtonWrapper,
  TransactionButton,
} from "common/src/styledComponents/AccountPage";

const Transactions = ({
  playerCurrency,
  server,
  pages,
  titles,
  serverSetup,
  skin,
}) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();

  const [depositHistory, setDepositHistory] = useState({});
  const [withdrawHistory, setWithdrawHistory] = useState({});
  const [financialTotal, setFinancialTotal] = useState({});
  const [showAllTransactions, setshowAllTransactions] = useState(false);

  useEffect(() => {
    // console.log(titles)
    document.title = titles["transactions"];
  }, [titles]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    const getFinancialsTotal = async () => {
      const { loading, errors, data } = await apolloClient.query({
        query: QUERY_GET_FINANCIAL_TOTALS,
      });
      if (loading) return <h3>Loading ...</h3>;
      if (errors) return <h1>`Error: ${errors}`</h1>;
      //console.log("total " + data);
      setFinancialTotal(data.getFinancials);
      //winners = recentWinners;
      return null;
    };
    async function getTransactionsHistory(transactionType) {
      const { loading, errors, data } = await apolloClient.query({
        query: QUERY_GET_TRANSACTIONS,
        variables: { transactionType: transactionType },
      });
      if (loading) return <h3>Loading ...</h3>;
      if (errors) return <h1>`Error: ${errors}`</h1>;
      //console.log("transaction " + data.getTransactions.transactions);
      if (transactionType === "deposit") {
        setDepositHistory(data.getTransactions);
      } else {
        setWithdrawHistory(data.getTransactions);
      }
      return null;
    }
    getFinancialsTotal();
    getTransactionsHistory("deposit");
    getTransactionsHistory("withdrawal");
  }, [apolloClient]);

  return (
    <>
      <ContentTop
        serverSetup={serverSetup}
        showProfileMenu={!isMobileOnly}
        pages={pages}
        skin={skin}
        isMyAccount={"label_transactions"}
      />
      <PageContent account="true" nobg="true">
        <PageContentWrapper>
          <AccountContainer>
            <TabContent>
              <>
                <TransactionsButtonWrapper>
                  <Trans i18nKey="label_transaction_description"></Trans>
                  <TransactionButton
                    onClick={() => setshowAllTransactions(!showAllTransactions)}
                  >
                    {!showAllTransactions
                      ? t("label_view_all", "View all")
                      : t("label_show_total", "Show total")}
                  </TransactionButton>
                </TransactionsButtonWrapper>
                {!showAllTransactions && (
                  <FinancialTotals
                    financialTotal={financialTotal}
                    playerCurrency={playerCurrency}
                    server={server}
                    skin={config.skin}
                  />
                )}
                {showAllTransactions && (
                  <>
                    <DepositTransactions
                      playerCurrency={playerCurrency}
                      depositHistory={depositHistory}
                      server={server}
                      skin={config.skin}
                    ></DepositTransactions>
                    <WithdrawTransactions
                      playerCurrency={playerCurrency}
                      withdrawHistory={withdrawHistory}
                      server={server}
                      skin={config.skin}
                    />
                  </>
                )}
              </>
            </TabContent>
          </AccountContainer>
        </PageContentWrapper>
      </PageContent>
    </>
  );
};

export default Transactions;
