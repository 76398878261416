import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { isMobileOnly } from "react-device-detect";
import {
  QUERY_GET_TIMEOUT,
  QUERY_DELETE_TIMEOUT,
} from "common/src/graphql/queries";
import { MUTATION_CREATE_TIMEOUT } from "common/src/graphql/mutations";

import { TimeoutDialog } from "../modal/TimeoutDialog";
import {
  IncreaseLimit,
  RealityCheckWrapper,
  StyledIndefiniteTimeout,
  StyledLimitWrapper,
} from "../styledComponents/StyledLimits";
import {
  StyledButton,
  StyledFormGroup,
  StyledSelect,
} from "../styledComponents/CommonStyledComponents";
import {
  AccountDescription,
  StyledCol,
  StyledTextCenter,
} from "../styledComponents/AccountPage";
import { BonusWrapper } from "../styledComponents/StyledActiveBonus";
import { differenceInHours } from "date-fns";
import SelfExclusion from "./SelfExclusion";
import { StyledMaterialIcon } from "../styledComponents/StyledHeader";
import { useHistory } from "react-router-dom";
import { ThemeSwitcher } from "./ThemeSwitcher";

const Timeout = ({
  server,
  player,
  realityCheck,
  skin,
  setSpinner,
  currentLanguage,
  isSE,
  isNL,
  isUk,
  pages,
}) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();
  const history = useHistory();
  const { Country } = player;

  const [isIndefinite, setIsIndefinite] = useState(false);
  const [hasTimeout, setHasTimeout] = useState(false);
  const [timeout, setTimeout] = useState(null);
  const [showTimeoutDialog, setShowTimeoutDialog] = useState(false);
  const [duration, setDuration] = useState("");
  const [emptyDuration, setEmptyDuration] = useState(false);
  const [showTimeoutExpires, setShowTimeoutExpires] = useState(false);
  const [toogleSelfExcludion, setToogleSelfExcludon] = useState(false);
  const [blockChange, setBlockChange] = useState(false);

  const isSWEIp =
    (server.geoIP.isoCode.toLowerCase() || Country) === "se" ? true : false;

  useEffect(() => {
    async function getTimeout() {
      const { loading, errors, data } = await apolloClient.query({
        query: QUERY_GET_TIMEOUT,
      });

      if (loading) return <h3>Loading ...</h3>;
      if (errors) {
        if (errors[0].message === "error_timeout_not_exist") {
          setHasTimeout(false);
        } else {
          console.log(errors);
        }
      }

      setTimeout(data.getTimeout);

      if (data.getTimeout !== null) {
        setHasTimeout(true);
        setShowTimeoutExpires(true);

        if (data.getTimeout.expiresAt === -1) setIsIndefinite(true);
      }

      //check is less then 24h
      let now = new Date();
      if (
        Math.abs(differenceInHours(now, new Date(data.getTimeout.expiresAt))) <
        24
      ) {
        setHasTimeout(false);
      }
    }
    getTimeout();
  }, [apolloClient]);

  useEffect(() => {
    function hasLimit() {
      if (timeout.expiresAt !== "" && timeout.status === "active") {
        setBlockChange(true);
      }
      //console.log("ffg " + JSON.stringify(timeout.expiresAt))
    }
    timeout !== null && hasLimit();
  }, [timeout]);

  async function resetTimeout() {
    const { loading, errors } = await apolloClient.query({
      query: QUERY_DELETE_TIMEOUT,
    });
    if (loading) return <h3>Loading ...</h3>;
    if (errors)
      return toast.success(t("reset_timeout_message_failed"), {
        position: "bottom-center",
      });
    else
      toast.success(t("reset_timeout_message"), {
        position: "bottom-center",
      });
    setHasTimeout(false);
    window.location.reload(true);
    //history.push({ pathname: pages["my-limits"], tab: 4 });
  }

  function handleChangeDuration(e) {
    setEmptyDuration(false);
    setDuration(e.target.value);
  }

  function confirmTimeout() {
    //console.log(duration.length === 0);
    handleExpend();
    if (duration.length === 0) setEmptyDuration(true);
    else setShowTimeoutDialog(true);
  }

  async function doTimeout() {
    //console.log(duration);
    const { loading, errors } = await apolloClient.mutate({
      mutation: MUTATION_CREATE_TIMEOUT,
      variables: {
        duration: parseFloat(duration),
      },
      errorPolicy: "all",
    });

    if (loading) return "Loading ...";
    if (errors) {
      //console.log(errors);
      //setLoginError(errors);
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      return false;
    } else {
      toast.success(<Trans i18nKey="suc_timeout_message"></Trans>, {
        position: "bottom-center",
      });
      setShowTimeoutDialog(false);
      setBlockChange(true);
      window.location.reload(true);
      //history.push({ pathname: pages["my-limits"], tab: 4 });
      return true;
    }
  }
  const [expandIcon, setExpendIcon] = useState(false);
  //console.log(pages);
  const handleExpend = () => {
    setExpendIcon(!expandIcon);
  };

  return (
    <>
      {(skin === "fun" ||
        skin === "cac" ||
        skin === "hyc" ||
        skin === "yak") && (
        <AccountDescription
          expand="true"
          description
          dangerouslySetInnerHTML={{ __html: t("lable_timeout_description") }}
        >
          {/*  <p>
                Daily - a period of a single day*
                <br />
                Weekly - a period of seven days, from Monday to Sunday*
                <br />
                Monthly - a calendar month, from the first day of the month
                <br />
                till the last day of the month*
                <br />
                <br />
                *) From 00:00 till 23:59 UTC
                <br />
                <br />
                -Any increase in limits will go through a 24 hour cooling period
                before being applied.
                <br />
                <br />
              </p>
              <p>
                -Note that a weekly time limit can't be less than a daily time
                limit and monthly time limit can't be less than a weekly time
                limit.
                <br />
                <br />
                Players residing in the UK have the following default limits
                applied:
                <br />
                Deposit: 100.000
                <br />
                Bet: 100.000.000
                <br />
                Loss: 100.000.000
                <br />
                <br />
                Player residing in Sweden are not allowed to play without a
                deposit limit.
                <br />
                <br />
              </p> */}
        </AccountDescription>
      )}
      {!isIndefinite && (
        <>
          {" "}
          <StyledLimitWrapper selfexcludion={skin !== "rac"}>
            <StyledFormGroup
              margin={skin === "rac" ? "" : "m-0"}
              col={"col-md-8"}
            >
              <label>{t("label_timeout_description")}</label>
              <StyledSelect
                onChange={(e) => handleChangeDuration(e)}
                value={duration}
              >
                <option value="" disabled>
                  {t("label_choose_timeout")}
                </option>
                <option value="1">{t("message_timeout_24_hours")}</option>
                <option value="7">{t("message_timeout_1_week")}</option>
                <option value="30">{t("message_timeout_1_month")}</option>
                <option value="42">{t("message_timeout_6_weeks")}</option>
              </StyledSelect>
            </StyledFormGroup>
            {hasTimeout ? (
              <StyledButton
                pub={skin === "pub"}
                selfexcludion
                onClick={() => resetTimeout()}
              >
                {t("label_reset")}
              </StyledButton>
            ) : (
              <StyledButton
                pub={skin === "pub"}
                selfexcludion
                onClick={() => confirmTimeout()}
                disabled={isUk && blockChange}
              >
                {t("label_submit")}
              </StyledButton>
            )}
            {isIndefinite && skin === "rad" && (
              <StyledIndefiniteTimeout>
                <h3>{t("label_indefinite_timeout")}</h3>
              </StyledIndefiniteTimeout>
            )}
            {skin === "rac" && (
              <RealityCheckWrapper>
                {realityCheck !== -1 && (
                  <p>
                    {t("label_reality_check")} {Math.floor(realityCheck / 60)}{" "}
                    {t("label_reality_check_minutes")}
                  </p>
                )}
              </RealityCheckWrapper>
            )}
          </StyledLimitWrapper>
          <>
            {showTimeoutExpires && (
              <IncreaseLimit>
                {t("label_expires_on")} {timeout.expiresAt}
              </IncreaseLimit>
            )}
            <div className="col-12">
              <AccountDescription
                expand="true"
                dangerouslySetInnerHTML={{
                  __html: t("message_self_exclusion_description"),
                }}
              />
            </div>
            <div
              className={`text-center col-${
                isMobileOnly ? "8" : "12 pb-5"
              } pl-0`}
            >
              <StyledButton
                pub={skin === "pub"}
                selfexcludion
                onClick={() => setToogleSelfExcludon(!toogleSelfExcludion)}
              >
                {t("label_self_exclusion")}
                <StyledMaterialIcon
                  as="span"
                  icon={expandIcon}
                ></StyledMaterialIcon>
              </StyledButton>
            </div>
            {toogleSelfExcludion && (
              <SelfExclusion
                setSpinner={setSpinner}
                isUk={isUk}
                realityCheck={realityCheck}
                serverSetup={server}
                isSE={isSE}
                isNL={isNL}
                skin={skin}
              />
            )}
          </>
          {skin !== "rac" && skin !== "fun" && (
            <AccountDescription
              expand="true"
              description
              dangerouslySetInnerHTML={{ __html: t("message_limit_details") }}
            >
              {/*  <p>
                Daily - a period of a single day*
                <br />
                Weekly - a period of seven days, from Monday to Sunday*
                <br />
                Monthly - a calendar month, from the first day of the month
                <br />
                till the last day of the month*
                <br />
                <br />
                *) From 00:00 till 23:59 UTC
                <br />
                <br />
                -Any increase in limits will go through a 24 hour cooling period
                before being applied.
                <br />
                <br />
              </p>
              <p>
                -Note that a weekly time limit can't be less than a daily time
                limit and monthly time limit can't be less than a weekly time
                limit.
                <br />
                <br />
                Players residing in the UK have the following default limits
                applied:
                <br />
                Deposit: 100.000
                <br />
                Bet: 100.000.000
                <br />
                Loss: 100.000.000
                <br />
                <br />
                Player residing in Sweden are not allowed to play without a
                deposit limit.
                <br />
                <br />
              </p> */}
            </AccountDescription>
          )}
        </>
      )}
      <TimeoutDialog
        showModal={showTimeoutDialog}
        handleCancel={() => setShowTimeoutDialog(false)}
        handleProceed={() => doTimeout()}
        skin={skin}
      />
      {/* {skin === "rac" && (
        <IncreaseLimit
          dangerouslySetInnerHTML={{ __html: t("timeout_text_bottom") }}
        ></IncreaseLimit>
      )} */}
      {/* {skin === "rac" && (
        <IncreaseLimit
          dangerouslySetInnerHTML={{ __html: t("message_timeout_description") }}
        ></IncreaseLimit>
      )} */}
      {isSWEIp && skin === "rac" && (
        <BonusWrapper>
          <StyledCol>
            <h3>{t("label_exclude_from_game_section")}</h3>
            <p>{t("message_exclude_from_game_description")}</p>
          </StyledCol>
        </BonusWrapper>
      )}
    </>
  );
};

export default Timeout;
