import { useEffect, useRef, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { QUERY_ACTIVATE } from "common/src/graphql/queries";
import { useTranslation } from "react-i18next";
import {
  AccountContainer,
  PageContent,
  PageContentWrapper,
  StyledColOffset,
} from "common/src/styledComponents/AccountPage";
import Cookies from "js-cookie";
// import { setToken} from "common/src/helpers/session";

const Activation = ({ pages, setSpinner }) => {
  const apolloClient = useApolloClient();
  const [activation, setActivation] = useState(null); //message for page
  const [activated, setActivated] = useState(false);
  const redirectURL = useRef("");
  const { t } = useTranslation("");
  // console.log(pages"deposit"]);
  let query = new URLSearchParams(useLocation().search);
  const activationCode = query.get("code");

  useEffect(() => {
    const activatePlayer = async () => {
      setSpinner(true);
      const { loading, errors, data } = await apolloClient.query({
        query: QUERY_ACTIVATE,
        variables: {
          code: activationCode,
        },
      });

      if (loading) {
        setActivation("Loading ...");
        return "Loading ...";
      }
      if (errors) {
        setActivation(errors[0].message);
        redirectURL.current = pages["index"];
        setSpinner(false);
        return errors;
      }
      if (data.activate.url !== null) {
        // console.log(data.activate.url);
        // console.log(data.activate.url);
        redirectURL.current = data.activate.url;
        // setToken(data.activation.url);
        // localStorage.setItem("activated", "activated");
        Cookies.set("activated", "activated", { secure: true });
        setActivated(true);
        setActivation("label_activation_completed");
      } else {
        setActivation("label_activation_error");
        redirectURL.current = pages["index"];
      }
      setSpinner(false);
      return null;
    };
    if (redirectURL.current === "") {
      if (!activated) {
        activatePlayer();
      }
    } else {
      setTimeout(() => {
        window.location.replace(redirectURL.current);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectURL.current]);

  // url:%!s(<nil>)?pl=8b256f47-a5ec-4dd0-b6d0-db020eaa7e9b

  return (
    <PageContent>
      <PageContentWrapper>
        <AccountContainer container>
          <StyledColOffset>
            <h3>{t(activation)}</h3>
          </StyledColOffset>
        </AccountContainer>
      </PageContentWrapper>
    </PageContent>
  );
};

export default withRouter(Activation);
