import { useTranslation } from "react-i18next";
import { InvalidFeedback } from "../styledComponents/AccountPage";
import React from "react";

const Input = ({
  label,
  placeholder,
  name,
  groupClass,
  errors,
  type,
  amount,
  id,
  autocomplete,
  maxLength,
  /*focusInput,*/ ...rest
}) => {
  const { t } = useTranslation("");
  const errorClass =
    errors !== null ? "form-control is-invalid" : "form-control";

  return (
    <div className={groupClass}>
      {!placeholder && <label>{t(label)}</label>}
      {amount ? (
        <div className="input-group">
          <input
            {...rest}
            className={errorClass}
            name={name}
            id={id ? id : name}
            placeholder={placeholder ? t(label) : ""}
            type={type}
            required
            autoComplete={autocomplete}
            maxLength={maxLength && maxLength}
            pattern="^(?:\d|[^.e-])+$"
          />
          <div className="input-group-append">
            <span className="input-group-text">€</span>
          </div>
        </div>
      ) : (
        <input
          {...rest}
          className={errorClass}
          name={name}
          id={id ? id : name}
          placeholder={placeholder ? t(label) : ""}
          type={type}
          required
          autoComplete={autocomplete}
          maxLength={maxLength && maxLength}
          pattern="^(?:\d|[^.e-])+$"
        />
      )}
      {errors !== null && (
        <InvalidFeedback
          className="invalid-feedback d-block"
          dangerouslySetInnerHTML={{
            __html: t(errors),
          }}
        ></InvalidFeedback>
      )}
    </div>
  );
};

export default Input;
