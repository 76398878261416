import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link, useLocation } from "react-router-dom";
import "../../../plc/src/data/scss/layout/_grid-container.scss";
import Lobby from "common/src/component/Lobby";
import GamesLoader from "common/src/component/GamesLoader";
import { ContentTop } from "./ContentTop";
import { useTranslation } from "react-i18next";
import {
  AlignCategoryTitle,
  EmptyCategoriy,
  GameCategoryHeader,
  GameCategoryTitle,
  LobbyWrapper,
  ShowAllArrow,
  ShowAllGames,
} from "../styledComponents/StyledGame";
import useGetGameLobby from "common/src/services/useGetGameLobby";
import { GameProvidersInLobby } from "./GameProvidersInLobby";
import { GamesSearch } from "./GamesSearch";
import { ReactComponent as Telescope } from "common/src/assets/icon-component/telescope.svg";
import { isMobileOnly } from "react-device-detect";
import { GamesCategorySearch } from "./GamesCategorySeach";

const GamesCategory = ({
  width,
  serverSetup,
  player,
  skin,
  defaultLang,
  gameImagePath,
  pages,
  vendorsImagePath,
  setSearchResults,
  config,
  setSpinner,
  titles,
}) => {
  const gameCategory = useLocation().pathname.split("/")[2];
  const { t } = useTranslation("");
  const [selectedGameType, setSelectedGameType] = useState(null);
  const [categoryNameForSearch, setCategoryNameForSearch] = useState("");
  const [categoryLabel, setCategoryLabel] = useState("");
  const [scrollOnBack, setScrollOnBack] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredGames, setFilteredGames] = useState([]);

  let playerCountry =
    player && player.country !== undefined
      ? player.country
      : serverSetup.geoIP.isoCode.toLowerCase() !== ""
      ? serverSetup.geoIP.isoCode.toLowerCase()
      : defaultLang;

  let { lobby } = useGetGameLobby(playerCountry, gameCategory, skin);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    if (skin === "rac") {
      if (searchTerm.length > 2) {
        let tempArray = [];
        if (selectedGameType === null) {
          tempArray = lobby.map((el) =>
            el.games.filter((game) =>
              game.name.toLowerCase().includes(searchTerm)
            )
          );
        } else {
          tempArray = selectedGameType.map((el) =>
            el.games.filter((game) =>
              game.name.toLowerCase().includes(searchTerm)
            )
          );
        }
        setFilteredGames([
          {
            categoryName: categoryNameForSearch,
            games: tempArray[0],
          },
        ]);
      } else {
        setFilteredGames([]);
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    // console.log(titles)
    if (gameCategory) {
      if (titles[gameCategory]) {
        document.title = titles[gameCategory];
      } else {
        document.title =
          gameCategory.charAt(0).toUpperCase() +
          gameCategory.slice(1).replace(/-/g, " ");
      }
    }
  }, [titles, gameCategory]);

  useEffect(() => {
    window.onpopstate = () => {
      setScrollOnBack(true);
    };
    useGetGameLobby;
    scrollToTop();
  }, []);

  const getCategoyName = () => {
    let name = lobby.map((el) => el.categoryName);
    return String(name);
  };

  // console.log(lobby);
  useEffect(() => {
    lobby !== null && setCategoryNameForSearch(getCategoyName());
  }, [lobby]);

  useEffect(() => {
    if (scrollOnBack) {
      scrollToTop();
    }
  }, [scrollOnBack]);

  const checkCategoryLabel = (category) => {
    setCategoryLabel(category.categoryName.toLowerCase().replace("-", " "));
  };

  return (
    <>
      <ContentTop
        showProfileMenu={false}
        skin={skin}
        showSearch={true}
        serverSetup={serverSetup}
        gameCategory={gameCategory}
        gameImagePath={gameImagePath}
        pages={pages}
        gameCategoryStyleMobile={true}
        title={
          isMobileOnly
            ? ""
            : categoryNameForSearch &&
              "label_" + categoryNameForSearch.toLowerCase().replace("-", "_")
        }
        gameSearch={skin === "pub"}
      />
      <LobbyWrapper gamesCategory={skin === "rac"}>
        <div>
          {(skin === "abc" ||
            skin === "nbc" ||
            skin === "yet" ||
            skin === "fun" ||
            skin === "yak" ||
            skin === "rac") && (
            <>
              {!isMobileOnly && skin !== "rac" && (
                <GamesSearch
                  serverSetup={serverSetup}
                  gameImagePath={gameImagePath}
                  skin={skin}
                />
              )}
              <GameCategoryHeader provider>
                <GameCategoryTitle category>
                  {t("label_chose_provider")}
                </GameCategoryTitle>
              </GameCategoryHeader>
              <GameProvidersInLobby
                serverSetup={serverSetup}
                vendorsImagePath={vendorsImagePath}
                setSearchResults={setSearchResults}
                setSelectedGameType={setSelectedGameType}
                categoryNameForSearch={categoryNameForSearch}
                lobby={lobby}
                setSpinner={setSpinner}
                setSearchTerm={setSearchTerm}
              />
              {skin === "rac" && (
                <GamesCategorySearch
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                />
              )}
            </>
          )}
          {lobby === null ? (
            <GamesLoader skin={skin} />
          ) : (
            <>
              <GameCategoryHeader>
                {lobby.map(
                  (category) =>
                    category.games.length > 0 && (
                      <GameCategoryTitle category key={category.categoryName}>
                        {t(
                          "label_" +
                            category.categoryName
                              .toLowerCase()
                              .replace("-", "_")
                        )}
                      </GameCategoryTitle>
                    )
                )}

                {skin !== "pub" && (
                  <Link
                    to={`/${serverSetup.currentLanguage}/${
                      skin === "plc" ||
                      skin === "pub" ||
                      skin === "rac" ||
                      skin === "cac" ||
                      skin === "hyc"
                        ? "games"
                        : "index"
                    }`}
                  >
                    <ShowAllGames>
                      {t("label_back_to_lobby")}
                      <ShowAllArrow>&lsaquo;</ShowAllArrow>
                    </ShowAllGames>
                  </Link>
                )}
              </GameCategoryHeader>
              <Lobby
                gamesForCategory={true}
                gameLobby={
                  searchTerm.length > 2 && skin === "rac"
                    ? filteredGames
                    : selectedGameType !== null
                    ? selectedGameType
                    : lobby
                }
                width={width}
                gameImagePath={gameImagePath}
                skin={skin}
                config={config}
                country={playerCountry}
                themes={false}
              />
              {skin === "pub" && (
                <AlignCategoryTitle>
                  <br />
                  <Link
                    to={`/${serverSetup.currentLanguage}/${
                      skin === "plc" || skin === "pub" || skin === "cac"
                        ? "games"
                        : "index"
                    }`}
                  >
                    <ShowAllGames>
                      {t("label_back_to_lobby")}
                      <ShowAllArrow>&lsaquo;</ShowAllArrow>
                    </ShowAllGames>
                  </Link>
                </AlignCategoryTitle>
              )}
              {skin !== "plc" && skin !== "pub" && selectedGameType && (
                <EmptyCategoriy hide={selectedGameType[0].games.length > 0}>
                  <Telescope />
                  <h2> {t("label_unable_to_find", "No results found!")}</h2>
                  <div>
                    {t(
                      "label_unable_to_find_discription",
                      "We are unable to find any games by selected provider"
                    )}
                  </div>
                </EmptyCategoriy>
              )}
              {/* {(skin === "abc" || skin === "nbc") && (
                <>
                  <GameCategoryHeader provider>
                    <GameCategoryTitle category>
                      {t("label_chose_theme", "Chose Theme:")}
                    </GameCategoryTitle>
                  </GameCategoryHeader>
                </>
              )} */}
            </>
          )}
        </div>
      </LobbyWrapper>
    </>
  );
};

export default withRouter(GamesCategory);
