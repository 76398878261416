import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { PaymentServer } from "common/src/helpers/connectors";
import { getToken } from "common/src/helpers/session";
// import { LOGIN_FLAG } from "common/src/helpers/constants";

import {
  SpanCol,
  StyledDresponse,
  StyledDresponseText,
} from "../styledComponents/StyledDepositDialog";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

const Dresponse = ({ match, pages, setPxDeposit, skin }) => {
  const { t } = useTranslation("");
  const {
    params: { depositType, dStatus, dRedirect, transactionID },
  } = match;
  const [navigate, setNavigate] = useState(0);
  const [dresponseText, setDresponseText] = useState("");
  const [interacIssconf, setInteracIssconf] = useState("");
  const [interacBankname, setInteracBankname] = useState("");
  const [interacTxrefid, setInteracTxrefid] = useState("");
  const [session] = useState(getToken());
  /* const redirectTo = skin === 'plc' ? 'index' : 'games'; */

  //TODO: trigger deposit pixel if needed (before session redirect)

  useEffect(() => {
    if (dStatus === "success-finish" || dStatus === "pending-finish") {
      // console.log("set px deposit");
      //tracking pixels
      setPxDeposit(true);
      // localStorage.setItem(LOGIN_FLAG, true);
    }

    // return () => {
    //   console.log("unmount");
    //   setPxDeposit(false);
    // }
  }, [setPxDeposit, dStatus]);

  if (!session) {
    return <Redirect to={pages["index"]}></Redirect>;
  }

  // const baseUrl = "/" + i18n.language;
  //if success finish, auto redirect
  if (dStatus === "success-finish" || dStatus === "pending-finish") {
    //interac has additional text on success step, fetch data for it
    if (depositType === "interac_piq" || depositType === "interac_piq-et") {
      // console.log(transactionID)
      if (transactionID && interacIssconf === "") {
        PaymentServer.post("/fhnd/v1/transaction/deposit/interac", {
          transactionID: transactionID,
        })
          .then((res) => {
            // console.log("success: " + JSON.stringify(res));
            setDresponseText("suc_" + depositType);
            setInteracIssconf(res.data.issconf);
            setInteracBankname(res.data.bankname);
            setInteracTxrefid(res.data.txRefId);
          })
          .catch((error) => {
            console.log("error: " + JSON.stringify(error));
            return false;
          });
      }
    }

    window.top.postMessage(
        JSON.stringify({
          error: false,
          cancelDeposit: "off",
        }),
        window.location.origin
    );

    setTimeout(() => {
      if (depositType === "pnp") {
        setNavigate(4);
      } else {
        setNavigate(3);
      }
    }, 5000);
  }

  if (navigate !== 0) {
    //redirection requested
    if (dRedirect === "self") {
      //if dresponse page is standalone redirect
      if (navigate === 3) {
        return <Redirect to={skin === "rac" ? pages["games"] : pages["index"]}></Redirect>;
      } else if (navigate === 4) {
        return <Redirect to={pages["register"]}></Redirect>;
      } else if (navigate === 1) {
        return <Redirect to={pages["deposit"]}></Redirect>;
      }
    } else if (dRedirect === "opener") {
      console.log("navigate opener: " + navigate);
      window.opener.postMessage(
        JSON.stringify({
          error: false,
          message: navigate,
        }),
        window.location.origin
      );
      window.close();
    } else {
      //parent
      console.log("navigate parent: " + navigate);
      window.top.postMessage(
        JSON.stringify({
          error: false,
          message: navigate,
        }),
        window.location.origin
      );
    }
  }

  const tryAgain = () => {
    setNavigate(1);
  };

  return (
    <>
      {dStatus === "success-finish" && (
        <StyledDresponse status={"green"}>
          <SpanCol>{t("label_dresponse_success", "Success!")}</SpanCol>
          {dresponseText !== "" && (
            <StyledDresponseText
              dangerouslySetInnerHTML={{
                __html: t(dresponseText, {
                  BN: interacBankname,
                  BC: interacIssconf,
                  TID: interacTxrefid,
                }),
              }}
            ></StyledDresponseText>
          )}
        </StyledDresponse>
      )}
      {dStatus === "error-finish" && (
        <StyledDresponse status={"red"}>
          <SpanCol>{t("label_dresponse_error", "Error!")}</SpanCol>
          <StyledButton dresponse type="button" onClick={tryAgain}>
            {t("label_try_again")}
          </StyledButton>
        </StyledDresponse>
      )}
      {dStatus === "pending-finish" && (
        <StyledDresponse status={"yellowgreen"}>
          {t("label_dresponse_pending", "Pending...")}
        </StyledDresponse>
      )}
    </>
  );
};

export default Dresponse;
