import { useEffect, useState } from "react";
import { SERVER_SETUP } from "common/src/helpers/constants";
import { QUERY_GET_GAME_LOBBY } from "common/src/graphql/queries";
import { useApolloClient } from "@apollo/client";

export default function useGetGameLobby(playerCountry, gameCategory, skin) {
  const apolloClient = useApolloClient();
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const [lobby, setLobby] = useState(null);
  const [orderedGameLobby, setOrderedGameLobby] = useState(null);
  const [tournamentGame, setTournamentGame] = useState(null);

  useEffect(() => {
    // Get game lobby from API
    async function getGameLobby() {
      const { data, errors } = await apolloClient.query({
        query: QUERY_GET_GAME_LOBBY,
        fetchPolicy: "cache-first",
        variables: {
          country: playerCountry,
          gameCategory: gameCategory,
        },
      });
      if (errors) return null;
      setLobby(
        data.getGameLobby.lobby.filter(
          (cat) => cat.games.length !== 0 || cat.categoryName === "themes"
        )
      );
      if (data.getGameLobby && serverSetup.gameCategories) {
        let gameLobby = data.getGameLobby;
        let orderedLobby = [];
        serverSetup.gameCategories.forEach((orderedCategory) => {
          gameLobby.lobby.forEach((category) => {
            if (
              orderedCategory.name.toLowerCase().replace(/ /g, "-") ===
              category.categoryName
            ) {
              category = { ...category, title: orderedCategory["title"] };
              if (
                category.games.length > 0 ||
                category.categoryName === "themes"
              ) {
                orderedLobby.push(category);
              }
            }
          });
        });
        if (skin === "rac") {
          if (orderedLobby.length > 0) {
            let category = orderedLobby.filter(
              (cat) => cat.categoryName === "tournament-games"
            );
            setTournamentGame(
              category &&
                category[0] !== undefined &&
                category[0].games.length > 0
                ? category[0].games[0].internalID
                : null
            );
          }
        }
        orderedLobby = { ...gameLobby, lobby: orderedLobby };
        setOrderedGameLobby(orderedLobby);
      }
    }
    getGameLobby();
  }, [playerCountry, gameCategory, skin]);
  return {
    lobby: lobby,
    orderedGameLobby: orderedGameLobby,
    tournamentGame,
  };
}
