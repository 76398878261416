import React, { useEffect, useState } from "react";
import { ReactComponent as Loupe } from "common/src/assets/icon-component/loupe.svg";
import { QUERY_SEARCH_GAMES } from "common/src/graphql/queries";
import {
  browserVersion,
  isMobile,
  isMobileSafari,
  isSafari,
} from "react-device-detect";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import {
  GameSearchWrapper,
  StiledGamesInfo,
  StyledGameSeachLabel,
  StyledListGroup,
  StyledSearchName,
} from "../styledComponents/StyledGamesCategories";
import {
  StyledButton,
  StyledInput,
  StyledLink,
} from "../styledComponents/CommonStyledComponents";

export function GamesSearch({
  serverSetup,
  gameImagePath,
  setHideOnSearch,
  specialProp,
  skin,
}) {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [focus, setFocus] = useState(false);
  const [forcePng, setForcePng] = useState(false);
  const debouncedCallback = useDebouncedCallback(
    (term) => onSearch(term),
    1000,
    []
  );
  const onChange = (e) => {
    const term = e.currentTarget.value;
    setQuery(term);
    debouncedCallback(term);
  };

  useEffect(() => {
    if ((isMobileSafari || isSafari) && parseInt(browserVersion) < 14)
      setForcePng(true);
  }, []);

  // hide winner select on active game search
  setHideOnSearch &&
    useEffect(() => {
      searchResults === null ? setHideOnSearch(false) : setHideOnSearch(true);
    }, [setHideOnSearch, searchResults]);

  const onSearch = async (term) => {
    if (term === "" || term.length < 2) return setSearchResults(null);
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_SEARCH_GAMES,
      variables: {
        query: term,
        device: isMobile ? "mob" : "desk",
        country: "rs",
        themeSearch: "false",
        vendorSearch: "false",
        subVendorSearch: "false",
      },
      //fetchPolicy: 'cache-first',
    });

    if (loading) return <h3>Loading ...</h3>;
    if (errors) return <h1>`Error: ${errors}`</h1>;
    data ? setSearchResults(data.searchGames.games) : setSearchResults(null);

    return null;
  };

  let image_extension = forcePng ? ".png" : ".webp";

  /*   if (isMobileSafari || isSafari) {
    image_extension = ".jpg";
  } */
  const hideSearch = () => {
    if (!focus) {
      setFocus(false);
      setQuery("");
      setSearchResults(null);
    }
  };

  return (
    <GameSearchWrapper>
      <StyledGameSeachLabel htmlFor="search" onBlur={hideSearch}>
        <StyledInput
          bonus
          type="text"
          name="search"
          value={query}
          onChange={onChange}
          placeholder={t("label_find_game")}
        ></StyledInput>
        {query.length >= 1 ? (
          <StyledButton
            searchbutton="true"
            gamesearch="true"
            gamesearchinlobby={specialProp}
            onClick={hideSearch}
          ></StyledButton>
        ) : (
          <Loupe className="icon"></Loupe>
        )}
      </StyledGameSeachLabel>
      {searchResults && (
        <StyledListGroup
          onMouseEnter={() => setFocus(true)}
          onMouseLeave={() => setFocus(false)}
        >
          {/* map */}
          {searchResults.length > 0 &&
            searchResults.map(
              (game) =>
                game.maintenance === false && (
                  <StyledLink
                    key={game.gameID}
                    to={game.gameLink}
                    gamesearch="true"
                  >
                    <img
                      alt=""
                      style={{
                        maxHeight: "100%",
                        marginRight: "15px",
                      }}
                      src={
                        serverSetup.cdn
                          .replace("http:", "")
                          .replace("https:", "") +
                        gameImagePath +
                        (game.banner.match(/\.(webp)/g) !== null &&
                        game.banner.match(/\.(_big_lobby)/g) === null
                          ? game.banner.replace(
                              ".webp",
                              "_big_lobby" + image_extension
                            )
                          : game.banner.match(/\.(jpg)/g) !== null
                          ? game.banner.replace(
                              ".jpg",
                              "_big_lobby" + image_extension
                            )
                          : game.banner.replace(
                              ".png",
                              "_big_lobby" + image_extension
                            ))
                      }
                    />
                    <StiledGamesInfo>
                      {game.subVendor !== "" ? (
                        <span>{game.subVendor}</span>
                      ) : (
                        <span>{game.vendor}</span>
                      )}
                      <StyledSearchName>{game.name}</StyledSearchName>
                    </StiledGamesInfo>
                  </StyledLink>
                )
            )}
          {searchResults && searchResults.length === 0 && (
            <div id="search-no-results">{t("label_no_results")}</div>
          )}
        </StyledListGroup>
      )}
    </GameSearchWrapper>
  );
}
