import { useTranslation } from "react-i18next";
/* import '../data/scss/components/warning.scss'; */
import Logo from "common/src/component/Logo.js";
import { PageContent } from "common/src/styledComponents/AccountPage";
import { SupportLogoWrapper } from "common/src/styledComponents/StyledSupport";
import { StyledMaintenanceWrapp } from "common/src/styledComponents/StyledMaintenance";

const Maintenance = () => {
  const { t } = useTranslation("");

  return (
    <PageContent>
      <StyledMaintenanceWrapp>
        <SupportLogoWrapper>
          <Logo />
        </SupportLogoWrapper>
        <h1
          dangerouslySetInnerHTML={{
            __html: t("label_maintenance_title", "Maintenance in progress"),
          }}
        ></h1>
        <p
          dangerouslySetInnerHTML={{
            __html: t("label_maintenance_content", "We will be back soon"),
          }}
        ></p>
      </StyledMaintenanceWrapp>
    </PageContent>
  );
};

export default Maintenance;
