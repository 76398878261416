import React, { useEffect, useRef } from "react";
// import { Redirect } from "react-router-dom";

const IFrame = ({
  className,
  src,
  srcDoc,
  width,
  height,
  setDepositStep,
  requestMethod,
  requestParams,
}) => {
  useEffect(() => {
    const handler = (event) => {
      // console.log("FRAME EVENT: " + JSON.stringify(event));
      // console.log(typeof event.data)
      // console.log("origin" + event.origin)
      // console.log("origin 2: " + window.location.origin)
      if (event.origin === window.location.origin) {
        // console.log("same origin!");
        let data = null;
        if (typeof event.data !== "undefined") {
          try {
            data = JSON.parse(event.data);
          } catch (e) {
            data = event.data;
          }
          // console.log("DATA: " + JSON.stringify(event.data))
          if (typeof data.message !== "undefined" && typeof data.message !== "object") {
            console.log("set deposit step: " + data.message);
            setDepositStep(data.message);
          }
        }
      }
    };

    window.addEventListener("message", handler);

    // clean up
    return () => window.removeEventListener("message", handler);
  }, [setDepositStep]); // empty array => run only once

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current) {
      // console.log("submit params form");
      formRef.current.submit();
    }
  }, []);
  // console.log("request params: " + JSON.stringify(requestParams.sid));
  // console.log("request params type: " + (typeof requestParams))
  // try {
  //   requestParams = JSON.parse(requestParams);
  // } catch (e) {
  //   console.log("err parsing")
  // }
  return (
    <>
      <iframe
        src={src ? src : "about:blank"}
        className={className}
        width={width}
        height={height}
        name="dframe"
        id="dframe"
        title="dframe"
        srcDoc={srcDoc}
      ></iframe>
      {requestMethod !== "" &&
        requestParams &&
        Object.keys(requestParams).length > 0 && (
          <form
            id="dframeForm"
            ref={formRef}
            method={requestMethod}
            target="dframe"
            action={src}
          >
            {requestParams &&
              Object.keys(requestParams).map((key) => {
                // console.log("key: " + key);
                return (
                  <input
                    type="hidden"
                    name={key}
                    key={key}
                    value={requestParams[key]}
                  ></input>
                );
              })}
          </form>
        )}
    </>
  );
};

export default IFrame;
