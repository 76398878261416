import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledButton,
  StyledInput,
  StyledSelect,
} from "../styledComponents/CommonStyledComponents";
import {
  StyledLimitWrapper,
  StyledLimitSelect,
  IncreaseLimit,
  StyledCooldownExplanation,
  StyledCooldownExplanationSmall,
} from "../styledComponents/StyledLimits";
import { AccountDescription } from "../styledComponents/AccountPage";

const SessionCountLimit = ({
  limitsData,
  generalMaxLimit,
  /*skinCurrency,*/
  loginCountLimit,
  hasLimits,
  handleSubmit,
  handleChange,
  resetLimits,
  isUk,
  realityCheck,
  player,
  skin,
}) => {
  const { t, i18n } = useTranslation("");
  const { limitsLocked } = player;

  const { logincountValue, logincountDate } = limitsData;
  const [hasIncrease, setHasIncrease] = useState(false);
  const [increaseTime, setIncreaseTime] = useState("");
  const [blockChange, setBlockChange] = useState(false);
  const localizedFormat = `${
    i18n.language || window.localStorage.i18nextLng || "nl"
  }`;

  useEffect(() => {
    function hasLimit() {
      if (skin !== "plc" && loginCountLimit[0].increaseAt !== "") {
        setHasIncrease(true);
        setIncreaseTime(loginCountLimit[0].increaseAt);
      }
      if (
        loginCountLimit[0].expiresAt !== "" &&
        loginCountLimit[0].status === "active" &&
        skin === "plc"
      )
        setBlockChange(true);
    }
    loginCountLimit.length > 0 && hasLimit();
  }, [loginCountLimit]);

  return (
    <>
      <StyledLimitWrapper>
        <StyledLimitSelect>
          <label>{t("label_choose_limit", "Period")}</label>
          <StyledSelect
            onChange={(e) => handleChange(e)}
            value={logincountDate}
            name="logincountDate"
            //disabled={loginCountLimit.length > 0}
          >
            <option value="daily">{t("label_daily", "Daily*")}</option>
            <option value="weekly">{t("label_weekly", "Weekly*")}</option>
            <option value="monthly">{t("label_monthly", "Monthly*")}</option>
          </StyledSelect>
        </StyledLimitSelect>
        <StyledLimitSelect>
          <label>{t("label_logincount", "times")}</label>
          <StyledInput
            limit
            type="number"
            value={logincountValue}
            name="logincountValue"
            onChange={(e) => handleChange(e)}
            min="1"
            max={generalMaxLimit}
            step="1"
            disabled={hasIncrease || (isUk && limitsLocked)}
          />
        </StyledLimitSelect>
        {hasLimits && isUk ? (
          <StyledButton
            pub={skin === "pub"}
            limit
            onClick={() => resetLimits()}
            disabled={isUk && limitsLocked}
          >
            {t("label_reset", "Reset")}
          </StyledButton>
        ) : (
          <StyledButton
            pub={skin === "pub"}
            limit
            disabled={hasIncrease || blockChange || (isUk && limitsLocked)}
            onClick={() => handleSubmit("logincount")}
          >
            {t("label_save", "Save")}
          </StyledButton>
        )}
        {blockChange && (
          <StyledCooldownExplanation cooldownprop>
            <StyledCooldownExplanationSmall>
              {t("cooldown_explanation")}{" "}
              {new Date(loginCountLimit[0].expiresAt).toLocaleString(
                localizedFormat
              )}
            </StyledCooldownExplanationSmall>
          </StyledCooldownExplanation>
        )}
      </StyledLimitWrapper>
      <AccountDescription
        description
        dangerouslySetInnerHTML={{ __html: t("message_limit_details") }}
      ></AccountDescription>
      {skin !== "plc" && (
        <div>
          <StyledLimitWrapper>
            {realityCheck !== -1 && (
              <p>
                {t("label_reality_check")} {Math.floor(realityCheck / 60)}{" "}
                {t("label_reality_check_minutes")}
              </p>
            )}
          </StyledLimitWrapper>
          {hasIncrease && (
            <IncreaseLimit>
              <h3>
                {t("label_increased_limit")} {increaseTime}
              </h3>
            </IncreaseLimit>
          )}
          <IncreaseLimit>
            <p>{t("message_logincountLimit_description")}</p>
          </IncreaseLimit>
        </div>
      )}
    </>
  );
};

export default SessionCountLimit;
