import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getToken } from "common/src/helpers/session";
import {
  PaymentMethodsHeader,
  PaymentProvider,
  PaymentProviderWrapper,
  ProviderLogos,
} from "../styledComponents/StyledFooter";
import { ResetButtonWrapper } from "../styledComponents/StyledSignupLogin";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

/* const PaymentMethods = ({ serverSetup }) => { */
const PaymentMethods = ({
  currentTheme,
  pages,
  serverSetup,
  currentLanguage,
  skin,
  paymentsImagePath,
}) => {
  const { t } = useTranslation("");
  const history = useHistory();
  const routeChange = () => {
    let path = getToken() === null ? pages["register"] : pages["deposit"];
    history.push(path);
  };

  return (
    <>
      {(skin === "plc" ||
        skin === "pub" ||
        skin === "cac" ||
        skin === "hyc") && (
        <PaymentMethodsHeader>{t("label_payment_method")}</PaymentMethodsHeader>
      )}
      <PaymentProviderWrapper>
        <PaymentProvider>
          {serverSetup.footerLogos.map((value, index) => {
            let imageName =
              currentTheme === "light"
                ? value.footerImage
                : value.footerImageDark;
            return (
              value.footerImage && (
                <div key={index} className="extra-border">
                  <ProviderLogos
                    alt=""
                    loading="lazy"
                    src={
                      serverSetup.cdn +
                      paymentsImagePath +
                      (skin === "rac" ||
                      skin === "fun" ||
                      skin === "cac" ||
                      skin === "hyc" ||
                      skin === "yak"
                        ? "/"
                        : "/" + currentLanguage + "/") +
                      imageName
                    }
                    height="30"
                  />
                </div>
              )
            );
          })}
        </PaymentProvider>
      </PaymentProviderWrapper>
      {(skin === "plc" ||
        skin === "pub" ||
        skin === "cac" ||
        skin === "hyc") && (
        <ResetButtonWrapper>
          <StyledButton
            btn
            pub={skin === "pub"}
            goldenborder
            onClick={routeChange}
          >
            {t("label_payment_method_btn")}
          </StyledButton>
        </ResetButtonWrapper>
      )}
    </>
  );
};

export default PaymentMethods;
