import { useTranslation } from "react-i18next";
import { CategorySearchWrapper } from "../styledComponents/StyledGame";

export const GamesCategorySearch = ({ setSearchTerm, searchTerm }) => {
  const { t } = useTranslation("");

  return (
    <CategorySearchWrapper>
      <input
        type="text"
        placeholder={t("label_filter_category")}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
      />
    </CategorySearchWrapper>
  );
};
