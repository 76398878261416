import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import NewWindow from "react-new-window";
// import { StyledButton } from "../styledComponents/CommonStyledComponents";
import {
  NewWindowStep,
  NewWindowStepDescription,
} from "../styledComponents/StyledSignupLogin";

const StepNewWindow = ({ src, width, height, setSpinner, setStep, labelText }) => {
  const { t } = useTranslation("");
  useEffect(() => {
      setSpinner(true)
  }, [])
  // const [newWindow, setNewWindow] = useState(false);
  // useEffect(() => {
  //   // console.log('setup handler');
  //   const handler = (event) => {
  //     // console.log("here");
  //     // console.log("FRAME EVENT: " + JSON.stringify(event));
  //     // console.log(typeof event.data)
  //     // console.log("origin" + event.origin)
  //     // console.log("origin 2: " + window.location.origin)
  //     if (event.origin === window.location.origin) {
  //       // console.log("same origin!");
  //       let data = null;
  //       if (typeof event.data !== "undefined") {
  //         try {
  //           data = JSON.parse(event.data);
  //         } catch (e) {
  //           data = event.data;
  //         }
  //         // console.log('DATA: ' + JSON.stringify(event.data));
  //         if (typeof data.message !== "undefined") {
  //           // console.log("set idin step: " + data.message);
  //           setIdinStep(data.message);
  //         }
  //       }
  //     }
  //   };
  //
  //   window.addEventListener("message", handler);
  //
  //   // clean up
  //   return () => window.removeEventListener("message", handler);
  // }, [setIdinStep]); // empty array => run only once
  //
  // const showNewWindow = () => {
  //   setSpinner(true);
  //   setNewWindow(true);
  // };
  //
  const closeNewWindow = () => {
    // setNewWindow(false);
    setStep(0);
    setSpinner(false);
  };

  // if (newWindow) {
    return (
      <>
        <NewWindow
          copyStyles={false}
          name="iwindow"
          url={src}
          center="screen"
          features={{
            width: width,
            height: height,
            menubar: "no",
            toolbar: "no",
            location: "no",
            resizable: "yes",
          }}
          onUnload={closeNewWindow}
        >
          loading...
        </NewWindow>
        <NewWindowStep>
          <NewWindowStepDescription
            dangerouslySetInnerHTML={{
              __html: t(
                  labelText
              ),
            }}
          ></NewWindowStepDescription>
        </NewWindowStep>
      </>
    );
  // }


};

export default StepNewWindow;
