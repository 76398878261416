import React from "react";
import { Link } from "react-router-dom";
import { SewTopBar } from "../styledComponents/StyledHeader";
import sjaelvtest from "../assets/img/sjaelvtest_dark.svg";
import spelgraenser from "../assets/img/spelgraenser_dark.svg";
import spelpaus from  "../assets/img/spelpaus_dark.svg"

export function SweTopBar({ pages }) {
  return (
    <SewTopBar>
      <a href="https://stodlinjen.se/#!/spelberoende-test-pgsi">
        <img
          src={sjaelvtest}
          alt="sjaelvtest_dark.svg"
        ></img>
      </a>
      <Link to={pages["my-limits"]}>
        <img
          src={spelgraenser}
          alt="spelgraenser_dark.svg"
        ></img>
      </Link>
      <a href="https://www.spelpaus.se/?scos=true">
        <img
          src={spelpaus}
          alt="spelpaus_dark.svg"
        ></img>
      </a>
    </SewTopBar>
  );
}
