import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { QUERY_SEARCH_GAMES } from "common/src/graphql/queries";
import { useDebouncedCallback } from "use-debounce";
import { useHistory, Link } from "react-router-dom";
import {
  isMobile,
  isSafari,
  isMobileSafari,
  browserVersion,
} from "react-device-detect";
import {
  SearchListGroup,
  SearchModalBg,
  SearchModalContent,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

const Search = ({
  server,
  onToggleSearchShow,
  hideSearch,
  gameImagePath,
  skin,
}) => {
  const { t } = useTranslation("");
  const history = useHistory();
  const apolloClient = useApolloClient();
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [forcePng, setForcePng] = useState(false);
  const debouncedCallback = useDebouncedCallback(
    (term) => onSearch(term),
    1000,
    []
  );
  const onChange = (e) => {
    const term = e.currentTarget.value;
    setQuery(term);
    debouncedCallback(term);
  };

  useEffect(() => {
    if ((isMobileSafari || isSafari) && parseInt(browserVersion) < 14)
      setForcePng(true);
  }, []);

  let image_extension = forcePng ? ".png" : ".webp";

  /* if (isMobileSafari || isSafari) {
    image_extension = ".jpg";
  } */

  const onSearch = async (term) => {
    if (term === "" || term.length < 2) return setSearchResults(null);
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_SEARCH_GAMES,
      variables: {
        query: term,
        device: isMobile ? "mob" : "desk",
        country: "rs",
        themeSearch: "false",
        vendorSearch: "false",
        subVendorSearch: "false",
      },
      //fetchPolicy: 'cache-first',
    });

    if (loading) return <h3>Loading ...</h3>;
    if (errors) return <h1>`Error: ${errors}`</h1>;
    data ? setSearchResults(data.searchGames.games) : setSearchResults(null);

    return null;
  };

  const clearSearchField = (e) => {
    e.preventDefault();
    setQuery("");
    setSearchResults(null);
    hideSearch();
  };

  useEffect(() => {
    return history.listen(() => {
      //if (location.pathname !== "/en/games") {
      hideSearch();
      //console.log(`You changed the page to: ${location.pathname}`);
      //}
    });
  }, [history, hideSearch]);
  //console.log(searchResults);

  return (
    <SearchModalBg onClick={onToggleSearchShow}>
      <SearchModalContent propsTransform={onToggleSearchShow}>
        <form
          onKeyPress={(event) => {
            if (event.which === 13 /* Enter */) {
              event.preventDefault();
            }
          }}
        >
          {/* <label htmlFor="search">
            {t("label_search_for_a_game", "Search your game")}
          </label> */}
          <input
            autoFocus
            id="searchfield"
            type="text"
            //placeholder={t("label_search_for_a_game", "Search your game")}
            value={query}
            onChange={onChange}
            name="search"
            autoComplete="off"
            placeholder={t("label_find_game", "Search your game")}
          />
          <StyledButton
            searchbutton
            mainsearch
            onClick={(e) => clearSearchField(e)}
          ></StyledButton>
          {searchResults && searchResults.length > 0 && (
            <SearchListGroup>
              {/* map */}
              {searchResults &&
                searchResults.length > 0 &&
                searchResults.map(
                  (game) =>
                    game.maintenance === false && (
                      <Link
                        key={game.gameID}
                        to={game.gameLink}
                        className="list-group-item"
                      >
                        <img
                          alt=""
                          style={{ maxHeight: "100%", marginRight: "15px" }}
                          src={
                            server.cdn
                              .replace("http:", "")
                              .replace("https:", "") +
                            gameImagePath +
                            (game.banner.match(/\.(webp)/g) !== null &&
                            game.banner.match(/\.(_big_lobby)/g) === null
                              ? game.banner.replace(
                                  ".webp",
                                  "_big_lobby" + image_extension
                                )
                              : game.banner.match(/\.(jpg)/g) !== null
                              ? game.banner.replace(
                                  ".jpg",
                                  "_big_lobby" + image_extension
                                )
                              : game.banner.replace(
                                  ".png",
                                  "_big_lobby" + image_extension
                                ))
                          }
                        />
                        {game.name}
                        {game.subVendor !== "" ? (
                          <span className="float-right">{game.subVendor}</span>
                        ) : (
                          <span className="float-right">{game.vendor}</span>
                        )}
                      </Link>
                    )
                )}
              {searchResults && searchResults.length === 0 && (
                <span className="list-group-item">{t("label_no_results")}</span>
              )}
            </SearchListGroup>
          )}
        </form>
      </SearchModalContent>
    </SearchModalBg>
  );
};

export default Search;
