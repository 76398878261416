import { useTranslation } from "react-i18next";
import { getToken } from "common/src/helpers/session";
import "../../../plc/src/data/scss/layout/flex-table.scss";
import React from "react";

const SuccessMessage = ({ pages }) => {
  sessionStorage.setItem("show-limits-reseted", true);

  const { t } = useTranslation("");
  setTimeout(() => {
    if (getToken()) {
      window.location = pages[`deposit`];
    } else {
      window.location = `index`;
    }
  }, 5000);

  return (
    <div className="success-message text-center">
      <h1
        dangerouslySetInnerHTML={{
          __html: t("label_register_success_title"),
        }}
      ></h1>
      <p
        dangerouslySetInnerHTML={{
          __html: t("label_register_success_text"),
        }}
      ></p>
    </div>
  );
};

export default SuccessMessage;
