import axios from 'axios';
import { getToken } from './session';

// console.log("token: " + getToken())
const config =
  getToken() !== null
    ? {
        headers: {
          common: {
            // can be common or any other method
            'X-Http-Auth': getToken(),
          },
        },
      }
    : {};
// console.log(paymentApiURL)

export const PaymentServer = axios.create(config);

// export default axios.create();
