import { useState, useEffect } from "react";
import _ from "lodash";
import {
  THEME,
  LEVEL_OF_PROTECTION_DIALOG,
  COOKIES_CONFIRMATION,
} from "common/src/helpers/constants";

// Convert game name to valid url
export function gameCategoryToRoute(categoryName) {
  let newCategoryName = categoryName.toLowerCase().replace(/ /g, "-");
  return newCategoryName;
}

/**
 * Returns amount in money format with player currency attached
 * @param {*} amount amount that will be converted to money format
 * @param {*} currency player currency from "me" call
 * @param {*} currencySetup currency setup from initial call
 * @param {*} displayShort should it display without decimals, ignoring decimals config in currency setup (default is false)
 */
export function printAmount(amount, currency, currencySetup, displayShort) {
  // console.log("amount: " + amount);
  // console.log("currency: " + currency);
  // console.log("currency setup: " + JSON.stringify(currencySetup));
  // console.log("display short: " + JSON.stringify(displayShort));
  if (typeof displayShort === "undefined") {
    displayShort = false;
  }
  let result = amount / 100;
  // console.log("amnt" + result);
  currencySetup.forEach((curr) => {
    // console.log("currencies: " + JSON.stringify(currencySetup))
    if (curr.code === currency) {
      // console.log("found currency: " + JSON.stringify(curr));
      if (displayShort) {
        result = toMoney(
          result,
          0,
          3,
          curr.separatorThousands === "space"
            ? " "
            : curr.separatorThousands === "none"
            ? ""
            : curr.separatorThousands,
          curr.separatorDecimals === "space"
            ? " "
            : curr.separatorDecimals === "none"
            ? ""
            : curr.separatorDecimals
        );
      } else {
        result = toMoney(
          result,
          curr.decimals,
          3,
          curr.separatorThousands === "space"
            ? " "
            : curr.separatorThousands === "none"
            ? ""
            : curr.separatorThousands,
          curr.separatorDecimals === "space"
            ? " "
            : curr.separatorDecimals === "none"
            ? ""
            : curr.separatorDecimals
        );
      }

      result = curr.separated
        ? curr.positionBefore
          ? curr.html + " " + result
          : result + " " + curr.html
        : curr.positionBefore
        ? curr.html + result
        : result + curr.html;
      // console.log("---------- result: " + result);
    }
  });
  return result;
}

/**
 *
 * @param {*} n length of decimal
 * @param {*} x length of whole part
 * @param {*} s sections delimiter
 * @param {*} c decimal delimiter
 */
function toMoney(amnt, n, x, s, c) {
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
    num = amnt.toFixed(Math.max(2, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    "$&" + (s || ",")
  );
}

export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
}

/**
 * check if error is array or variable and return message (can be asset)
 * @param {*} error error variable
 */
export function checkError(error) {
  if (Array.isArray(error)) {
    return error[0].message;
  }
  return error.message;
}

/* element width and height */
export const useContainerDimensions = (myRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

export function getDiff(obj1, obj2) {
  var result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== "object") {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
      if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
        const value = getDiff(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
}

export function cleanLocalStorage() {
  //ENTER HERE LOCALSTORAGE KEYS that need to be preserved
  let theme = localStorage.getItem(THEME);
  let levelOfProtection = localStorage.getItem(LEVEL_OF_PROTECTION_DIALOG);
  let cookiesConfirmation = localStorage.getItem(COOKIES_CONFIRMATION);
  let i18nextLng = localStorage.getItem("i18nextLng");
  let bonusesAvailable = "";
  let iosPwaPrompt = localStorage.getItem("iosPwaPrompt");
  if (localStorage.getItem("bonuses-available") === "hidden") {
    bonusesAvailable = localStorage.getItem("bonuses-available");
  }
  let rtpUkPopup = localStorage.getItem("rtpUkPopup");

  localStorage.clear();

  //set them again
  localStorage.setItem("iosPwaPrompt", iosPwaPrompt);
  localStorage.setItem(THEME, theme);
  localStorage.setItem(LEVEL_OF_PROTECTION_DIALOG, levelOfProtection);
  localStorage.setItem(COOKIES_CONFIRMATION, cookiesConfirmation);
  localStorage.setItem("i18nextLng", i18nextLng);
  localStorage.setItem("bonuses-available", bonusesAvailable);
  localStorage.setItem("rtpUkPopup", rtpUkPopup);
}

export function underAgeValidate(birthday) {
  // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
  var optimizedBirthday = birthday.replace(/-/g, "/");

  //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
  var myBirthday = new Date(optimizedBirthday);

  // set current day on 01:00:00 hours GMT+0100 (CET)
  var currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";

  // calculate age comparing current date and borthday
  var myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);

  if (myAge < 18) {
    return false;
  } else {
    return true;
  }
}
export function useDate(getDate) {
  var hours = getDate === null ? 0 : new Date(getDate).getHours();
  var minutes = getDate === null ? 0 : new Date(getDate).getMinutes();
  var seconds = getDate === null ? 0 : new Date(getDate).getSeconds();

  let iniTime = `${hours}:${minutes}:${seconds}`;

  const [time, setDate] = useState(iniTime); // Save the current date to be able to trigger an update

  const calculateTime = () => {
    //console.log(seconds);
    // Creates an interval which will update the current data every sec
    if (seconds <= 59) {
      ++seconds;
    }
    if (seconds > 59) {
      seconds = 0;
      if (minutes > 59) {
        minutes = 0;
        ++hours;
      } else {
        ++minutes;
      }
    }
    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      setDate(calculateTime());
    }, 1000);
    return () => {
      clearInterval(myInterval); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, [getDate]);

  return {
    time,
  };
}

//language should be object from server call response
function checkLanguageExist(languages, language) {
  return (
    typeof languages === "object" && languages.some((e) => e.code === language)
  );
}

export function getGeoIPLanguage(geoip, availableLanguages, defaultLanguage) {
  console.log(availableLanguages);
  if (typeof defaultLanguage === "undefined" || defaultLanguage === "") {
    defaultLanguage = "en";
  }
  geoip = "no";
  if (geoip === "de" || geoip === "at" || geoip === "ch") {
    if (checkLanguageExist(availableLanguages, "de")) {
      return "de";
    }
  } else if (geoip === "se") {
    if (checkLanguageExist(availableLanguages, "sv")) {
      return "sv";
    }
  } else if (geoip === "no") {
    if (checkLanguageExist(availableLanguages, "no")) {
      return "no";
    }
  } else if (geoip === "fi") {
    if (checkLanguageExist(availableLanguages, "fi")) {
      return "fi";
    }
  } else if (geoip === "nl") {
    if (checkLanguageExist(availableLanguages, "nl")) {
      return "nl";
    }
  } else if (geoip === "gb" || geoip === "ie") {
    if (checkLanguageExist(availableLanguages, "en")) {
      return "en";
    }
  }

  return defaultLanguage;
}
