import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { addYears } from "date-fns";
import Input from "common/src/component/input";
import Select from "common/src/component/select";
import {
  RegisterStep2DatePickerWrapper,
  RegisterStepWrapper,
} from "../styledComponents/StyledSignupLogin";
import {
  BirthdayWrapper,
  InvalidFeedback,
  StyledDatePicker,
  StyledFormRow,
} from "../styledComponents/AccountPage";
import {
  StyledButton,
  StyledFlexBetween,
  StyledFormGroup,
  StyledSelect,
} from "../styledComponents/CommonStyledComponents";

const RegisterStep2 = ({
  errors,
  touched,
  formData,
  navigation,
  handleChange,
  handleBlur,
  handlePhoneChange,
  handleOnPhoneBlur,
  handleOnDateBlur,
  handleDateChange,
  setTouchedOnRest,
  skin,
  checkForEmptyFields,
}) => {
  const {
    //gender,
    title,
    firstName,
    //middleName,
    lastName,
    address,
    city,
    zipCode,
    dateOfBirth,
    phone,
    country,
  } = formData;
  const { go, pause } = navigation;
  const { t, i18n } = useTranslation("");
  const [focus, setFocus] = useState(false);
  const [currentDate, SetCurrentDate] = useState(new Date());
  const [birthDay, SetBirthDay] = useState("");
  const [birthMonth, SetBirthMonth] = useState("");
  const [birthYear, SetBirthYear] = useState("");
  const [dayOfBirthError, setDayOfBirthError] = useState({});
  //let isValid = false;
  const isUK = country === "gb" ? true : false;
  const nextStep = isUK ? "stepUK" : "stepGDPR";
  const prevStep =
    formData.country !== "se" && formData.currency !== "sek"
      ? "first"
      : "stepIdent";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentLanguage = i18n.language || window.localStorage.i18nextLng;

  const paramsForCheckUK = [
    "firstName",
    "lastName",
    "title",
    "gender",
    /*     "address",
    "city",
    "zipCode", */
    "dateOfBirth",
    "phone",
    /*  "country", */
  ];
  const paramsForCheck = [
    "firstName",
    "lastName",
    "address",
    "city",
    "zipCode",
    "gender",
    "dateOfBirth",
    "phone",
  ];

  /* on next check mandatory fields */
  const checkIsValid = () => {
    if (
      currentDate.getFullYear() >= addYears(new Date(), -100).getFullYear() &&
      currentDate.getTime() <= addYears(new Date(), -18).getTime() &&
      birthMonth &&
      currentDate.getDate() === birthDay
    ) {
      delete dayOfBirthError.year;
      delete dayOfBirthError.month;
      delete dayOfBirthError.day;
      handleDateChange(currentDate);
      if (isUK) {
        setTouchedOnRest(paramsForCheckUK);
        let hasErrors = 0;
        errors &&
          paramsForCheckUK.forEach((p) => {
            for (let key of Object.keys(errors)) {
              if (p === key) {
                hasErrors++;
              }
            }
          });
        if (hasErrors === 0) {
          //double check errors
          let dce = checkForEmptyFields(paramsForCheckUK)
          // console.log(dce.length)
          return dce.length === 0;
        } else {
          return false;
        }
      } else {
        setTouchedOnRest(paramsForCheck);
        let hasErrors = 0;
        errors &&
          paramsForCheck.forEach((p) => {
            for (let key of Object.keys(errors)) {
              if (p === key) {
                hasErrors++;
              }
            }
          });
        if (hasErrors === 0) {
          let dce = checkForEmptyFields(paramsForCheck)
          // console.log(dce.length)
          return dce.length === 0;
        } else {
          return false;
        }
      }
    } else {
      let tempObj = {
        day: "",
        month: "",
        year: "",
      };
      if (birthMonth.length <= 0) {
        tempObj.month = "error_empty_month";
      }
      if (birthYear.toString().length < 4) {
        if (birthYear.toString().length === 0) {
          tempObj.year = "error_empty_year";
        } else {
          tempObj.year = "error_wrong_year";
        }
      } else {
        if (
          currentDate.getFullYear() <= addYears(new Date(), -100).getFullYear()
        ) {
          tempObj.year = "error_wrong_year";
        }
      }
      if (birthDay.toString().length <= 0) {
        tempObj.day = "error_empty_day";
      } else {
        if (currentDate.getDate() !== birthDay) {
          tempObj.day = "error_wrong_day";
        }
        if (currentDate.getTime() >= addYears(new Date(), -18).getTime()) {
          tempObj.day = "error_wrong_day";
        }
      }
      setDayOfBirthError(tempObj);
    }
  };

  /* let titleOptions = [{ name: 'label_mr', value: 'Mr' }]; */
  let genderOptions = [
    { value: "male", name: "label_male" },
    { value: "female", name: "label_female" },
  ];

  const handleBirthdayPickRange = (e) => {
    /* console.log(new Date(currentDate.setDate(3))); */
    const checkisLeapYear = () => {
      if (
        (0 === birthYear % 4 && 0 !== birthYear % 100) ||
        0 === birthYear % 400
      ) {
        if (birthMonth === 2) {
          return 29;
        } else if (
          birthMonth === 4 ||
          birthMonth === 6 ||
          birthMonth === 9 ||
          birthMonth === 11
        ) {
          return 30;
        } else {
          return 31;
        }
      } else {
        if (birthMonth === 2) {
          return 28;
        } else if (
          birthMonth === 4 ||
          birthMonth === 6 ||
          birthMonth === 9 ||
          birthMonth === 11
        ) {
          return 30;
        } else {
          return 31;
        }
      }
    };
    let days = checkisLeapYear();

    if (e.target.name === "day") {
      if (e.target.value > 0) {
        if (e.target.value <= days) {
          SetCurrentDate(new Date(currentDate.setDate(e.target.value)));
          return SetBirthDay(Math.abs(e.target.value));
        } else {
          return null;
        }
      } else {
        return SetBirthDay("");
      }
    } else if (e.target.name === "month") {
      if (e.target.value > 0) {
        if (e.target.value <= 12) {
          SetCurrentDate(new Date(currentDate.setMonth(e.target.value - 1)));
          return SetBirthMonth(Math.abs(e.target.value));
        } else {
          return null;
        }
      } else {
        return SetBirthMonth("");
      }
    } else if (e.target.name === "year") {
      if (e.target.value > 0) {
        if (e.target.value <= addYears(new Date(), -18).getFullYear()) {
          SetCurrentDate(new Date(currentDate.setFullYear(e.target.value)));
          return SetBirthYear(Math.abs(e.target.value));
        } else {
          return null;
        }
      } else {
        return SetBirthYear("");
      }
    }
  };

  const handleOnBlurDate = () => {
    if (
      currentDate.getFullYear() >= addYears(new Date(), -100).getFullYear() &&
      currentDate.getTime() <= addYears(new Date(), -18).getTime()
    ) {
      delete dayOfBirthError.year;
    }
    if (birthMonth) {
      delete dayOfBirthError.month;
    }
    if (currentDate.getDate() === birthDay) {
      delete dayOfBirthError.day;
    }
  };

  return (
    <RegisterStepWrapper>
      {/* <PageheadlinePar dangerouslySetInnerHTML={{ __html: t('label_register_account') }}></PageheadlinePar> */}
      <StyledFormRow>
        <Input
          groupClass="form-group col-md-6 mb-3"
          name="firstName"
          label={"label_first_name"}
          value={firstName}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.firstName && touched.firstName ? errors.firstName : null
          }
          autoFocus={true}
          placeholder={skin !== "rac"}
          required
        />
        <Input
          groupClass="form-group col-md-6 mb-3"
          name="lastName"
          label={"label_last_name"}
          value={lastName}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.lastName && touched.lastName ? errors.lastName : null}
          required
          placeholder={skin !== "rac"}
        />
      </StyledFormRow>
      {!isUK && (
        <StyledFormRow>
          <Input
            groupClass="form-group col-md-6 mb-3"
            name="address"
            label={"label_address"}
            value={address}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.address && touched.address ? errors.address : null}
            placeholder={skin !== "rac"}
          />
          <Input
            groupClass="form-group col-md-6 mb-3"
            name="city"
            label={"label_city"}
            value={city}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.city && touched.city ? errors.city : null}
            placeholder={skin !== "rac"}
          />
        </StyledFormRow>
      )}
      <StyledFormRow>
        {isUK && (
          <StyledFormGroup col={"col-md-6 mb-3"}>
            {skin === "rac" && (
              <label
                dangerouslySetInnerHTML={{ __html: t("label_title") }}
              ></label>
            )}
            <StyledSelect
              formControll
              onChange={(e) => handleChange(e)}
              onBlur={(e) => handleBlur(e)}
              name="title"
              value={title}
            >
              <option value="">
                {t("label_select_title", "Select title")}
              </option>
              <optgroup label="">
                <option
                  value="Mr"
                  dangerouslySetInnerHTML={{ __html: t("label_mr", "Mr") }}
                ></option>
              </optgroup>
              <optgroup label="">
                <option
                  value="Ms"
                  dangerouslySetInnerHTML={{ __html: t("label_ms", "Ms") }}
                ></option>
                <option
                  value="Mrs"
                  dangerouslySetInnerHTML={{ __html: t("label_mrs", "Mrs") }}
                ></option>
                <option
                  value="Miss"
                  dangerouslySetInnerHTML={{ __html: t("label_miss", "Miss") }}
                ></option>
              </optgroup>
            </StyledSelect>
            {errors.title && touched.title && (
                <InvalidFeedback
                    className={'d-block'}
                    dangerouslySetInnerHTML={{ __html: t(errors.title) }}
                ></InvalidFeedback>
            )}
          </StyledFormGroup>
        )}
        {!isUK && (
          <Input
            groupClass="form-group col-md-6 mb-3"
            name="zipCode"
            label={"label_post_code"}
            value={zipCode}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.zipCode && touched.zipCode ? errors.zipCode : null}
            placeholder={skin !== "rac"}
          />
        )}

        <Select
          groupClass="form-group col-md-6 mb-3"
          name="gender"
          list={genderOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          firstOption="label_select_gender"
          errors={errors.gender && touched.gender ? errors.gender : null}
          disabled={false}
          value={""}
          required
          label={skin === "rac" && "label_select_gender"}
        />
      </StyledFormRow>

      <StyledFormRow>
        <div className="form-group col-md-12 mb-12">
          <PhoneInput
            specialLabel={""}
            inputProps={{
              name: "phone",
              required: true,
            }}
            autoFormat={false}
            country={country}
            value={phone}
            containerClass={errors.phone && touched.phone ? "is-invalid" : ""}
            inputClass={errors.phone && touched.phone ? "is-invalid " : ""}
            onChange={(value, country, e, formattedValue) =>
              handlePhoneChange(value, country, e, formattedValue)
            }
            onBlur={(value, country, e, formattedValue) =>
              handleOnPhoneBlur(value, country, e, formattedValue)
            }
            autoComplete={"tel"}
          />
          {errors.phone && touched.phone && (
            <InvalidFeedback
              dangerouslySetInnerHTML={{ __html: t(errors.phone) }}
            ></InvalidFeedback>
          )}
        </div>
      </StyledFormRow>
      <BirthdayWrapper>
        <label>{t("label_day_of_birth")}</label>
      </BirthdayWrapper>
      <StyledFormRow>
        {/* <StyledDatePicker
            maxDate={addYears(new Date(), -18)}
            minDate={addYears(new Date(), -100)}
            value={dateOfBirth === "" ? currentDate : new Date(dateOfBirth)}
            onChange={(date) => handleChangeDate(date)}
            clearIcon={null}
            locale={currentLanguage}
            required={true}
            dayPlaceholder={"dd"}
            monthPlaceholder={"mm"}
            yearPlaceholder={"yyyy"}
          /> */}
        <Input
          groupClass="form-group col-md-4 col-4 mb-3"
          name="year"
          label={"label_yyyy"}
          onChange={handleBirthdayPickRange}
          value={birthYear}
          onBlur={handleOnBlurDate}
          errors={
            dayOfBirthError.year && dayOfBirthError.year
              ? dayOfBirthError.year
              : null
          }
          required
          placeholder={skin !== "rac"}
          type="text"
        />
        <Input
          groupClass="form-group col-md-4 col-4 mb-3"
          name="month"
          label={"label_mm"}
          onChange={handleBirthdayPickRange}
          onBlur={handleOnBlurDate}
          errors={
            dayOfBirthError.month && dayOfBirthError.month
              ? dayOfBirthError.month
              : null
          }
          required
          value={birthMonth}
          placeholder={skin !== "rac"}
          type="text"
        />
        <Input
          groupClass="form-group col-md-4 col-4 mb-3"
          name="day"
          label={"label_dd"}
          onChange={handleBirthdayPickRange}
          onBlur={handleOnBlurDate}
          errors={
            dayOfBirthError.day && dayOfBirthError.day
              ? dayOfBirthError.day
              : null
          }
          required
          placeholder={skin !== "rac"}
          value={birthDay}
          type="text"
        />

        {/* {dayOfBirthError.year && (
          <InvalidFeedback
            dangerouslySetInnerHTML={{ __html: t(dayOfBirthError.year) }}
          ></InvalidFeedback>
        )} */}
      </StyledFormRow>

      <StyledFlexBetween>
        <StyledButton
          fourth
          pub={skin === "pub"}
          colfourth={"col-md-4"}
          type="button"
          onClick={() => go(prevStep)}
          dangerouslySetInnerHTML={{ __html: t("label_go_back", "Previous") }}
        ></StyledButton>
        <StyledButton
          btnCol
          pub={skin === "pub"}
          type="button"
          onClick={() => (checkIsValid() ? go(nextStep) : pause())}
          dangerouslySetInnerHTML={{ __html: t("label_next", "Next") }}
        ></StyledButton>
      </StyledFlexBetween>
    </RegisterStepWrapper>
  );
};

export default RegisterStep2;
