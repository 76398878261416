import styled from "styled-components";
import { Modal } from "reactstrap";
import { isMobileOnly } from "react-device-detect";
import { ReactComponent as CloverSmall } from "../../../plc/src/data/icon-component/clover-small.svg";
import { ReactComponent as MainLogoPLC } from "../../../plc/src/data/icon-component/logo.svg";
import { ReactComponent as MainLogoABC } from "../../../abc/src/data/icon-component/abc-logo.svg";
import { ReactComponent as MainLogoPUB } from "../../../pub/src/data/icon-component/logo.svg";
import { ReactComponent as MainLogoNBC } from "../../../nbc/src/data/icon-component/nbc-logo.svg";
import { ReactComponent as MainLogoRAC } from "../../../rac/src/data/icon-component/logo.svg";
import { ReactComponent as MainLogoYET } from "../../../yet/src/data/icon-component/logo-mobile.svg";
import { ReactComponent as MainLogoFUN } from "../../../fun/src/data/icon-component/logo.svg";
import { ReactComponent as MainLogoCAC } from "../../../cac/src/data/icon-component/logo.svg";
import { ReactComponent as MainLogoHYC } from "../../../hyc/src/data/icon-component/logo.svg";
import { ReactComponent as MainLogoYAK } from "../../../yak/src/data/icon-component/logo.svg";

export const StyledModal = styled(Modal).attrs({
  className: "modal-dialog modal-lg modal-dialog-centered",
})`
  box-shadow: 0 15px 35px hsla(0, 0%, 0.2);
`;

export const StyledModalBody = styled.div.attrs((props) => ({
  className: `modal-body ${props.position ? "text-center" : ""}`,
}))`
  text-align: ${({ queue }) => (queue ? "center" : "")};
  font-weight ${({ queue }) => (queue ? "600" : "")};
  font-size ${({ queue }) => (queue ? "1.1rem" : "")};
  h3 {
    color: ${({ theme }) =>
      theme.skin === "abc"
        ? "#0554b3"
        : theme.skin === "nbc"
        ? "#70328e"
        : theme.skin === "pub"
        ? "#9A875D"
        : theme.skin === "yet"
        ? "#07415f"
        : theme.skin === "fun"
        ? "var(--tabletext)"
        : theme.skin === "cac"
        ? "#1b1b1b"
        : theme.skin === "yak"
        ? "#511186"
        : theme.skin === "rac"
        ? "#240090"
        : "#4d79ff"};
    font-size: 21px;
    font-weight: 500;
  }
  h2 {
    font-weight: 500;
    color: ${({ theme }) =>
      theme.skin === "abc"
        ? "#0554b3"
        : theme.skin === "pub"
        ? "#9A875D"
        : theme.skin === "yet"
        ? "#07415f"
        : theme.skin === "fun"
        ? "var(--tabletext)"
        : theme.skin === "cac"
        ? "#1b1b1b"
        : theme.skin === "yak"
        ? "#511186"
        : theme.skin === "rac"
        ? "#240090"
        : "#4d79ff"};
  }
  ${(props) => {
    if (props.register) {
      return `
      margin: ${
        props.theme.skin === "pub" ||
        props.theme.skin === "cac" ||
        props.theme.skin === "hyc"
          ? "1rem"
          : "1rem !important"
      };
      text-align: center !important;
      `;
    }
  }};
`;

export const StyledModalFooter = styled.div.attrs((props) => ({
  className: `modal-footer ${props.position ? "justify-content-center" : ""}`,
}))`
  button {
    font-size: ${({ theme }) =>
      theme.skin === "rac" ? "1rem !important" : "0.7rem !important"};
    background-image: ${({ theme }) =>
      theme.skin !== "plc" && "none !important"};
  }
`;

export const SearchModalBg = styled.div.attrs({
  className: "show",
})`
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 90;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  transition: all 0.4s ease;
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const SearchModalContent = styled.div`
  box-sizing: border-box;
  transform: ${({ propsTransform }) =>
    propsTransform ? "translateY(0%)" : "translateY(15%)"};
  width: 80%;
  transition: all 1.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  input::placeholder {
    color: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "#fff"};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) =>
      theme.skin === "abc" ? "var(--tabletext)" : "var(--acolor)"};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    font-size: 30px;
  }
  &::-internal-autofill-previewed {
    font-size: 30px !important;
  }
  label {
    color: var(--carouseltext);
    letter-spacing: 2px;
  }
  //provider name show/hide
  .list-group-item {
    /* span {
      display: none; 
    } */
  }

  form {
    & > input[type="text"] {
      width: 100%;
      background-color: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "yet" ||
        theme.skin === "hyc" ||
        theme.skin === "rac"
          ? "var(--bgColorABC)"
          : theme.skin === "pub"
          ? "#1d1d1d"
          : "var(--bgcolor)"};
      border-color: -internal-light-dark(
        rgb(118, 118, 118),
        rgb(133, 133, 133)
      );
      border-bottom: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "yet" ||
        theme.skin === "hyc" ||
        theme.skin === "rac"
          ? "2px solid var(--bgColorABC)"
          : "2px solid var(--bgcolor)"};
      border-top: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "yet" ||
        theme.skin === "hyc" ||
        theme.skin === "rac"
          ? "2px solid var(--bgColorABC)"
          : "2px solid var(--bgcolor)"};
      border-left: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "yet" ||
        theme.skin === "hyc" ||
        theme.skin === "rac"
          ? "2px solid var(--bgColorABC)"
          : "2px solid var(--bgcolor)"};
      border-right: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "yet" ||
        theme.skin === "hyc" ||
        theme.skin === "rac"
          ? "2px solid var(--bgColorABC)"
          : "2px solid var(--bgcolor)"};
      border-radius: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "hyc"
          ? "0.5rem"
          : theme.skin === "rac"
          ? "0"
          : "3rem"};
      padding: 1rem 2rem;
      color: ${({ theme }) =>
        theme.skin === "fun" || theme.skin === "yak" || theme.skin === "rac"
          ? "#fff"
          : "var(--tabletext)"};
      /* font-family: "Catamaran", Fallback, sans-serif; */
      font-weight: 800;
      letter-spacing: 2px;
      font-size: 30px;
      resize: vertical;
      margin: 0;
      margin: 10px 0px;

      &:-webkit-autofill::first-line {
        font-size: 30px;
        font-weight: bold;
        // color: green;
      }

      @media (max-width: 768px) {
        font-size: 16px !important;
        &:-webkit-autofill::first-line {
          font-size: 16px;
        }
      }
    }
  }

  @media (min-width: 576px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      font-size: 16px;
    }
  }
`;

export const SearchListGroup = styled.div.attrs({
  className: "list-group",
})`
  border-bottom-left-radius: 1rem;

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "yet" ||
      theme.skin === "yak" ||
      theme.skin === "rac"
        ? "var(--bgColorABC)"
        : "var(--bgcolor)"};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.skin === "yet" || theme.skin === "fun"
        ? "var(--scrollbarBG)"
        : theme.skin === "yak" || theme.skin === "rac"
        ? "var(--scollbarCac)"
        : ""};
  }

  ${(props) => {
    if (props.theme.skin === "abc") {
      return `
      &::-webkit-scrollbar-thumb {
        background-color: #1f355e;
        border-radius: 0px;
        border: none;
      }   
      `;
    }
  }}
  span {
    display: none;
  }
  /* a {
    height: 1170px;
  } */

  @media (min-width: 576px) {
    span {
      display: inherit;
    }
  }
  @media (max-width: 768px) {
    height: calc(40vh);
  }
`;

export const AlertDanger = styled.div.attrs((props) => ({
  className: props.danger ? "alert alert-danger" : "",
}))``;

export const LookupListItem = styled.div`
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

export const ModalLogoWrapper = styled.div`
  width: 100%;
  height: ${({ theme }) => (theme.skin === "nbc" ? "" : "40px")};
`;

export const ModalLogoPLC = styled(MainLogoPLC)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  z-index: 99;
  fill: #ae0005;
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  top: 2rem;

  @media (max-width: 767px) {
    top: 2rem;
    width: 100px;
  }
`;

export const ModalLogoABC = styled(MainLogoABC)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  z-index: 99;
  fill: #ae0005;
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  top: 2rem;

  @media (max-width: 767px) {
    top: 2rem;
    height: 40px;
  }
`;

export const ModalLogoPUB = styled(MainLogoPUB)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  z-index: 99;
  fill: #ae0005;
  /* cursor: pointer; */
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  top: 0rem;

  @media (max-width: 767px) {
    top: 1rem;
    height: 40px;
  }
`;

export const ModalLogoNBC = styled(MainLogoNBC)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  z-index: 99;
  fill: #ae0005;
  /* cursor: pointer; */
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  top: 0rem;
  background-color: #70328e;

  @media (max-width: 767px) {
    top: 1rem;
    height: 40px;
  }
`;

export const ModalLogoRAC = styled(MainLogoRAC)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  z-index: 99;
  fill: #fff;
  /* cursor: pointer; */
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  top: 2rem;

  @media (max-width: 767px) {
    top: 1rem;
    height: 40px;
  }
`;

export const ModalLogoYET = styled(MainLogoYET)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  z-index: 99;
  fill: #fff;
  /* cursor: pointer; */
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  top: 2rem;

  @media (max-width: 767px) {
    top: 1rem;
    height: 40px;
  }
`;

export const ModalLogoFUN = styled(MainLogoFUN)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  z-index: 99;
  fill: #fff;
  /* cursor: pointer; */
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  top: 2rem;

  @media (max-width: 767px) {
    top: 1rem;
    height: 40px;
  }
`;

export const ModalLogoCAC = styled(MainLogoCAC)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  z-index: 99;
  fill: #fff;
  /* cursor: pointer; */
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  top: 2rem;

  @media (max-width: 767px) {
    top: 1rem;
    height: 40px;
  }
`;

export const ModalLogoHYC = styled(MainLogoHYC)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  z-index: 99;
  fill: #fff;
  /* cursor: pointer; */
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  top: 2rem;

  #Fill-1,
  #Fill-4,
  #Fill-6,
  #Fill-8 {
    fill: silver;
  }

  @media (max-width: 767px) {
    top: 1rem;
    height: 40px;
  }
`;

export const ModalLogoYAK = styled(MainLogoYAK)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  z-index: 99;
  fill: #fff;
  /* cursor: pointer; */
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  top: 2rem;

  .st0 {
    fill: #a687d8;
  }

  @media (max-width: 767px) {
    top: 1rem;
    height: 40px;
  }
`;

export const RegisterCloseButton = styled.div`
  &:after {
    font-size: 20px;
    font-family: "Material Icons";
    content: "close";
    vertical-align: bottom;
  }

  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 5px;
  z-index: 99;
  color: #616060 !important;
  cursor: pointer;
  text-align: center;

  :hover {
    color: #b8b8b8 !important;
  }

  /* @media (max-width: 767px) {
    top: -0.9rem;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    top: -1rem;
  } */
`;

export const LinkWrapper = styled.div`
  a {
    &:hover {
      color: #ae0005 !important;
    }
  }
`;

export const ModalClower = styled(CloverSmall)`
  width: 150px;
  position: absolute;
  left: ${({ bottomright, topright }) => (bottomright || topright ? "" : "0")};
  top: ${({ bottomright, topright }) =>
    bottomright ? "" : topright ? "0" : "0"};
  bottom: ${({ bottomright }) => (bottomright ? "0" : "")};
  right: ${({ bottomright, topright }) =>
    bottomright ? "0" : topright ? "0" : ""};
  fill: #e1f4f3;
  padding: 1rem;
  /* transform: rotate(45deg); */
  transform: ${({ bottomright, topright }) =>
    bottomright ? "rotate(180deg)" : topright ? "rotate(45deg)" : ""};

  @media only screen and (max-width: 767px) {
    width: 100px;
  }
`;
