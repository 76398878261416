import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QUERY_SEARCH_GAMES } from "common/src/graphql/queries";
import { isMobile } from "react-device-detect";
import { useApolloClient } from "@apollo/client";
import {
  GameProviderCol,
  GameProviderExtend,
  GameProviderH3,
  GameProviderWrapper,
} from "../styledComponents/StyledGame";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import { ResetButtonWrapper } from "../styledComponents/StyledSignupLogin";
import { ProviderLogos } from "../styledComponents/StyledFooter";

export const GameProvidersList = ({
  setSearchResults,
  skin,
  serverSetup,
  vendorsImagePath,
  currentTheme,
  accountRoute,
  setSpinner,
  setSelectedCategory,
}) => {
  const { t, i18n } = useTranslation("");
  const apolloClient = useApolloClient();
  const [scrollButton, setScrollButton] = useState(false);
  const baseUrl = "/" + i18n.language + "/index";

  useEffect(() => {
    if (scrollButton) {
      const timer = setTimeout(() => {
        setScrollButton(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [scrollButton]);
  const gamesforProvider = async (term, providerType) => {
    if (skin !== "rac") {
      if (window.location.pathname === baseUrl || accountRoute) {
        setSpinner(true);
        setSelectedCategory(null);
        const { loading, errors, data } = await apolloClient.query({
          query: QUERY_SEARCH_GAMES,
          fetchPolicy: "cache-first",
          variables: {
            query: term,
            device: isMobile ? "mob" : "desk",
            country: "rs",
            themeSearch: "false",
            vendorSearch: providerType === "vendor" ? "true" : "false",
            subVendorSearch: providerType === "subVendor" ? "true" : "false",
          },
          //fetchPolicy: 'cache-first',
        });

        if (loading) return <h3>Loading ...</h3>;
        if (errors) return <h1>`Error: ${errors}`</h1>;

        document.getElementById("scroll-to-category") &&
          document.getElementById("scroll-to-category").scrollIntoView();
        setSearchResults(data.searchGames);
        setSpinner(false);
      }
    }
  };

  const handleScrollButton = (e) => {
    e.preventDefault();
    setScrollButton(true);
    document.getElementById("scroll-to-lobby").scrollIntoView();
  };
  return (
    <>
      {skin !== "rac" && (
        <GameProviderH3
          dangerouslySetInnerHTML={{
            __html: t(
              "label_providerlogo",
              "Select the provider to show all their games"
            ),
          }}
        ></GameProviderH3>
      )}
      <GameProviderWrapper>
        <GameProviderCol>
          <GameProviderExtend test={scrollButton}>
            {serverSetup.footerLogosGames.map((value, index) => {
              let imageName =
                currentTheme === "light"
                  ? value.footerLogo
                  : value.footerLogoDark;
              return (
                <div
                  className="extra-border"
                  key={index}
                  value={value.providerType}
                  onClick={() =>
                    gamesforProvider(
                      value.name.toLowerCase(),
                      value.providerType
                    )
                  }
                >
                  {value.footerLogo && value.footerLogoDark && (
                    <ProviderLogos
                      alt=""
                      loading="lazy"
                      src={serverSetup.cdn + vendorsImagePath + "/" + imageName}
                      height="30"
                    />
                  )}
                </div>
              );
            })}
          </GameProviderExtend>
          {skin === "plc" && (
            <ResetButtonWrapper extrapadding="true">
              <StyledButton
                btn
                spancol={"p-3"}
                onClick={(e) => handleScrollButton(e)}
              >
                {t("label_scroll_to_search", "Search Provider")}
              </StyledButton>
            </ResetButtonWrapper>
          )}
        </GameProviderCol>
      </GameProviderWrapper>
    </>
  );
};
