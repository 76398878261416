import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentServer } from "common/src/helpers/connectors";
import { SERVER_SETUP } from "common/src/helpers/constants";

const IdinRedirect = ({ match, setSpinner }) => {
  const { i18n, t } = useTranslation("");
  const {
    params: { trxid, ec },
  } = match;
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const language = i18n.language;

  useEffect(() => {
    // console.log(trxid + " : " + ec);
    const getStatus = async () => {
      await PaymentServer.post(
        /*'http://localhost:9001'+*/ "/fhnd/v1/idin/status",
        {
          ipAddress: serverSetup.geoIP.ipAddress,
          locale: language,
          TID: trxid,
          EC: ec,
        }
      )
        .then((res) => {
          // console.log("STATUS: " + JSON.stringify(res.data));
          if (res.data.redirect === "newWindow") {
            window.opener.postMessage(
              JSON.stringify({
                error: false,
                message: 3,
              }),
              window.location.origin
            );
          } else if (res.data.redirect === "iframe") {
            window.top.postMessage(
              JSON.stringify({
                error: false,
                message: 3,
              }),
              window.location.origin
            );
          }

          return true;
        })
        .catch((err) => {
          // console.log("STATUS ERROR!" + err.response.data[0]);
          let errMessage = t(err.response.data[0]);
          if (typeof err.response.data[1] !== "undefined") {
            if (err.response.data[1] !== "") {
              errMessage = errMessage + ": " + err.response.data[1];
            }
          }
          window.opener.postMessage(
            JSON.stringify({
              error: errMessage,
              message: 0,
            }),
            window.location.origin
          );
          return false;
        });
    };

    setSpinner(true);

    getStatus()
      .then(() => {
        // setSpinner(false);
        // console.log("GET STATUS THEN" + value);
      })
      .catch(() => {
        // console.log("GET STATUS ERROR");
        // setSpinner(false);
      });
  }, [ec, language, serverSetup.geoIP.ipAddress, setSpinner, t, trxid]);

  return (
    <>
      <div className="idin-redirect message text-center mt-5">
        {t("label_please_wait", "label_please_wait")}
      </div>
    </>
  );
};

export default IdinRedirect;
