import React, { useEffect, useState, useContext, useRef } from "react";
import { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { isMobile, deviceType } from "react-device-detect";
import { CarouselProviderWrapper } from "../styledComponents/StyledGame";
import { ProviderThemes } from "../styledComponents/StyledFooter";
import {
  QUERY_GET_GAME_THEMES,
  QUERY_SEARCH_GAMES,
} from "common/src/graphql/queries";

export const GameThemeProviders = ({
  setSearchResults,
  skin,
  accountRoute,
  serverSetup,
  setThemeSearchTerm,
  setSelectedGameType,
  setSpinner,
  setSelectedCategory,
  setThemeExists,
}) => {
  const { t, i18n } = useTranslation("");
  const apolloClient = useApolloClient();
  const [gameThemes, setGameThemes] = useState([]);
  //const gameThemes = useRef(null);
  const baseUrl = "/" + i18n.language + "/index";
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    const loadGameThemeOptions = async () => {
      const { data } = await apolloClient.query({
        query: QUERY_GET_GAME_THEMES,
        fetchPolicy: "cache-first",
      });
      data && setGameThemes(data.getGameThemes.gameThemes);
    };

    loadGameThemeOptions();
  }, []);

  useEffect(() => {
    if (gameThemes.length > 0) setThemeExists(true);
  }, [gameThemes]);

  const gamesforProvider = async (term) => {
    setThemeSearchTerm(term);
    if (window.location.pathname === baseUrl || accountRoute) {
      setSpinner(true);
      setSelectedCategory(null);
      const { loading, errors, data } = await apolloClient.query({
        query: QUERY_SEARCH_GAMES,
        fetchPolicy: "cache-first",
        variables: {
          query: term,
          device: isMobile ? "mob" : "desk",
          country: "rs",
          themeSearch: "true",
          vendorSearch: "false",
          subVendorSearch: "false",
        },
        //fetchPolicy: 'cache-first',
      });

      if (loading) return <h3>Loading ...</h3>;
      if (errors) return <h1>`Error: ${errors}`</h1>;

      if (skin.skin === "pub") {
        window.scrollTo({
          top: 0,
        });
      } else {
        document.getElementById("scroll-to-category").scrollIntoView();
      }
      setSearchResults(data.searchGames);

      setSelectedGameType &&
        setSelectedGameType([
          {
            games: data.searchGames.games,
            categoryName: term,
          },
        ]);
      setSpinner(false);
    }
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 10,
      slidesToSlide: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
      slidesToSlide: 8,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      <Carousel
        responsive={responsive}
        swipeable={isMobile}
        draggable={false}
        showDots={false}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all 0.5s ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container"
        deviceType={deviceType}
        itemClass="p-2"
      >
        {gameThemes !== null &&
          gameThemes.map((gameTheme) => {
            return (
              <CarouselProviderWrapper key={gameTheme} themes>
                {gameTheme.image && gameTheme.darkImage && (
                  <ProviderThemes
                    themes
                    alt=""
                    src={
                      serverSetup.cdn +
                      skin.gameThemes +
                      (themeContext.current === "light"
                        ? gameTheme.image
                        : gameTheme.darkImage)
                    }
                    height="100px"
                    onClick={() =>
                      gamesforProvider(gameTheme.name.toLowerCase())
                    }
                  />
                )}
              </CarouselProviderWrapper>
            );
          })}
      </Carousel>
    </>
  );
};
