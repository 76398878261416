import React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import {
  StyledModal,
  StyledModalBody,
  StyledModalFooter,
} from "../styledComponents/StyledModals";

export const PleaseReloadPageDialog = ({ showModal, skin }) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      <StyledModalBody register>
        {t("message_please_reload_page")}
      </StyledModalBody>
      <StyledModalFooter position>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => window.location.reload()}
        >
          {t("label_ok")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
