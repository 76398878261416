import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {
  HOOYU_MULTIPLE
} from "../helpers/constants";
import {MUTATION_HOOYU_PENDING} from "common/src/graphql/mutations";
import {useApolloClient} from "@apollo/client";
import { getToken } from "common/src/helpers/session";

const HooyuRedirect = ({setSpinner, pages}) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();
  const [redirect, setRedirect] = useState(false);

  async function hooyuPending(link) {
    //console.log(uid);
    const { loading, errors } = await apolloClient.mutate({
      mutation: MUTATION_HOOYU_PENDING,
      variables: {link : link},
    });

    if (loading) return "Loading ...";
    if (errors) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    // console.log(trxid + " : " + ec);
    setSpinner(true);
    setTimeout(() => {
      // setSpinner(false);
      if(window.self !== window.top) {
        window.parent.postMessage(
          JSON.stringify({
            error: false,
            message: "hooyu-redirect",
          }),
          window.location.origin
        );
      } else {
        setRedirect(true);
      }
    }, 5000);
  }, []);

  if(redirect) {
    let location = pages["index"];
    let link = localStorage.getItem(HOOYU_MULTIPLE);
    if(link && getToken()) {
      //if there are multiple hooyu journeys redirect to verification again and remove key
      localStorage.removeItem(HOOYU_MULTIPLE);
      hooyuPending(link)
        .then((data) => {
          // console.log(data);
          location = pages["my-verification"];
          window.location.replace(location);
        }).catch((err) =>{
          window.location.replace(location);
        })
    } else {
      window.location.replace(location);
    }
  }

  return (
    <>
      <div className="message text-center mt-5">
        {t("label_hooyu_please_wait", "label_hooyu_please_wait")}
      </div>
    </>
  );
};

export default HooyuRedirect;
