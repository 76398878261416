import React, { /*useState,*/ useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/bootstrap.css";
import Input from "common/src/component/input";
import Select from "common/src/component/select";
import {
  OffsetMd4,
  RegisterBSNAndStepWrapper,
  RegisterText,
} from "../styledComponents/StyledSignupLogin";
import { StyledFormRow } from "../styledComponents/AccountPage";
import {
  StyledButton,
  StyledFlexBetween,
} from "../styledComponents/CommonStyledComponents";
/* import { SERVER_SETUP } from 'common/src/helpers/constants';
 */

const RegisterStepLimitsNL = ({
  errors,
  touched,
  formData,
  navigation,
  handleChange,
  handleBlur,
  setTouchedOnRest,
  //setSpinner,
}) => {
  const {
    // /*loginCountDate,*/ loginCountValue,
    depositDate,
    depositValue,
    maxBalansLimit,
    timeLimitDate,
    timeLimitValue,
  } = formData;

  const buttonNext = useRef(null);
  const { go, pause } = navigation;
  const { t } = useTranslation("");

  /* const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP)); */

  const prevStep = "bsnNL";
  const nextStep = "stepGDPR";

  const paramsForCheck = [
    /*'loginCountValue', */ "depositValue",
    "maxBalansLimit",
    "timeLimitValue",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* on next check mandatory fields */
  const checkIsValid = () => {
    setTouchedOnRest(paramsForCheck);
    let hasErrors = 0;
    errors &&
      paramsForCheck.forEach((p) => {
        for (let key of Object.keys(errors)) {
          if (p === key) {
            hasErrors++;
          }
        }
      });
    if (hasErrors === 0) {
      return true;
    } else {
      return false;
    }
  };

  let playSessionOptions = [];
  Array.from(Array(24), (_, i) =>
    playSessionOptions.push({ value: i + 1, name: i + 1 })
  );

  let periodOptions = [
    { value: "daily", name: "label_daily" },
    { value: "weekly", name: "label_weekly" },
    { value: "monthly", name: "label_monthly" },
  ];

  return (
    <OffsetMd4>
      <RegisterText>
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "label_register_limits",
              "In her kadaer zongmet betrekking tot uw spel passen wij een verantwoordspelenbeleid toe. Daarom vragen wij tijdens de registratie uw limieten aan te geven. Lees hierover meer in ons verslavingspreventiebeleid."
            ),
          }}
        ></p>
      </RegisterText>

      <StyledFormRow>
        <Select
          groupClass="form-group col-md-6"
          name="timeLimitDate"
          list={periodOptions}
          label="label_time_limit"
          onChange={handleChange}
          onBlur={handleBlur}
          value={timeLimitDate}
          firstOption=""
          errors={
            errors.timeLimitDate && touched.timeLimitDate
              ? errors.timeLimitDate
              : null
          }
          disabled={false}
          required
        />
        <Input
          groupClass="form-group col-md-6"
          name="timeLimitValue"
          label={"label_time_limit_value"}
          value={timeLimitValue}
          type="number"
          min="1"
          max="720"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.timeLimitValue && touched.timeLimitValue
              ? errors.timeLimitValue
              : null
          }
          autoFocus={true}
          required
          placeholder={false}
        />
      </StyledFormRow>

      {/*<StyledFormRow1>*/}
      {/*  <Select*/}
      {/*    groupClass="form-group col-md-6"*/}
      {/*    name="loginCountDate"*/}
      {/*    list={periodOptions}*/}
      {/*    label="label_session_count_limit"*/}
      {/*    onChange={handleChange}*/}
      {/*    onBlur={handleBlur}*/}
      {/*    firstOption=""*/}
      {/*    errors={errors.loginCountDate && touched.loginCountDate ? errors.loginCountDate : null}*/}
      {/*    disabled={false}*/}
      {/*    required*/}
      {/*  />*/}
      {/*  <Input*/}
      {/*    groupClass="form-group col-md-6"*/}
      {/*    name="loginCountValue"*/}
      {/*    label={'label_session_count_value'}*/}
      {/*    value={loginCountValue}*/}
      {/*    type="number"*/}
      {/*    min="1"*/}
      {/*    onChange={handleChange}*/}
      {/*    onBlur={handleBlur}*/}
      {/*    errors={errors.loginCountValue && touched.loginCountValue ? errors.loginCountValue : null}*/}
      {/*    autoFocus={true}*/}
      {/*    required*/}
      {/*    placeholder={false}*/}
      {/*  />*/}
      {/*</div>*/}
      <StyledFormRow>
        <Select
          groupClass="form-group col-md-6"
          name="depositDate"
          list={periodOptions}
          label="label_deposit_interval"
          onChange={handleChange}
          onBlur={handleBlur}
          firstOption=""
          value={depositDate}
          errors={
            errors.depositDate && touched.depositDate
              ? errors.depositDate
              : null
          }
          disabled={false}
          required
        />
        <Input
          groupClass="form-group col-md-6"
          name="depositValue"
          label={"label_deposit_amount"}
          value={depositValue}
          type="number"
          min="1"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.depositValue && touched.depositValue
              ? errors.depositValue
              : null
          }
          required
          placeholder={false}
          amount={true}
        />
      </StyledFormRow>
      <StyledFormRow>
        <Input
          groupClass="form-group col-md-12 mb-3"
          name="maxBalansLimit"
          label={"label_max_balans_limit"}
          // placeholder={false}
          value={maxBalansLimit}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.maxBalansLimit && touched.maxBalansLimit
              ? errors.maxBalansLimit
              : null
          }
          amount={true}
        />
      </StyledFormRow>

      <StyledFlexBetween mt={"mt-4"}>
        <StyledButton
          fourth
          type="button"
          onClick={() => go(prevStep)}
          dangerouslySetInnerHTML={{ __html: t("label_go_back", "Previous") }}
        ></StyledButton>
        <StyledButton
          btn
          type="button"
          onClick={() => (checkIsValid() ? go(nextStep) : pause())}
          dangerouslySetInnerHTML={{ __html: t("label_next", "Next") }}
          ref={buttonNext}
        ></StyledButton>
      </StyledFlexBetween>
    </OffsetMd4>
  );
};

export default RegisterStepLimitsNL;
