import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const SweSetDepositLimitDialog = ({
  showModal,
  handleProceed,
  skin,
}) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      <StyledModalBody
        register
        dangerouslySetInnerHTML={{
          __html: t("swe_set_deposit_limit_message"),
        }}
      ></StyledModalBody>
      <StyledModalFooter position>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleProceed()}
        >
          {t("label_ok", "Ok")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
