import { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { getToken } from "common/src/helpers/session";
import { useTranslation } from "react-i18next";
/* import useLogout from "../services/useLogout"; */
import useLogout from "common/src/services/useLogout";

const Logout = ({ setSpinner, titles, defaultLang }) => {
  const apolloClient = useApolloClient();
  const { i18n } = useTranslation("");

  const baseUrl = `/${
    i18n.language || window.localStorage.i18nextLng || defaultLang
  }`;

  const logOut = useLogout(setSpinner);

  useEffect(() => {
    document.title = titles["logout"];
  }, [titles]);

  useEffect(() => {
    if (getToken() !== null) {
      logOut;
    } else {
      window.location = baseUrl + "/index";
    }
    apolloClient.cache.reset();
  }, []);

  return null;
};

export default Logout;
