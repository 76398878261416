import React, { useState } from "react";
import { gameCategoryToRoute } from "common/src/helpers/functions";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import {
  MobileFilter,
  StyledFilterButton,
  StyledFilterOption,
} from "../styledComponents/StyledGamesCategories";
import {
  StyledLink,
  StyledSelect,
} from "../styledComponents/CommonStyledComponents";
import { getToken } from "../helpers/session";
import { NoFreePlayUKDialog } from "../modal/NoFreePlayUKDialog";
import { useHistory } from "react-router-dom";

const Categories = ({
  serverSetup,
  selected,
  onClick,
  gameLobby,
  backToAllProviders,
  skin,
  setThemeSearchTerm,
  themeSerachTerm,
  providerSearchFlag,
  setProviderSearchFlag,
  pages,
  player,
}) => {
  const categories = serverSetup.gameCategories;
  const { t } = useTranslation("");
  const [noFreePlay, setnoFreePlay] = useState(false);
  const currentLanguage = serverSetup.currentLanguage;
  const baseUrl = "/" + currentLanguage + "/";
  const isUK = player && player.country === "gb";
  const history = useHistory();
  //console.log(selected && selected.replace("-", " "));
  const categorySelected = (category) =>
    selected && selected.toLowerCase() === category;

  const handleChange = (event) => {
    providerSearchFlag !== undefined && setProviderSearchFlag(false);
    if (event.target.value === "all") {
      if (themeSerachTerm && themeSerachTerm.length !== "")
        setThemeSearchTerm("");
    }
    var unsafeSearchTypeValue = event.target.value;
    //console.log(unsafeSearchTypeValue);
    if (unsafeSearchTypeValue === "all") {
      backToAllProviders();
    } else {
      if (categorySelected(unsafeSearchTypeValue)) onClick(null);
      else
        skin === "rac"
          ? history.push(
              baseUrl + unsafeSearchTypeValue.toLowerCase().replace(/ /g, "-")
            )
          : onClick(unsafeSearchTypeValue);
    }
    document.getElementById("scroll-to-category-onlogin") &&
      document.getElementById("scroll-to-category-onlogin").scrollIntoView();
  };

  const checkEmptyCategory = (gameLobby, categoryClass) => {
    let empty = false;
    let found = false;
    if (gameLobby !== null) {
      // console.log("game lobby present, compare with: " + categoryClass);
      gameLobby.forEach((ctg) => {
        // console.log(ctg.categoryName);
        // console.log(ctg.games.length);
        if (ctg.categoryName === categoryClass) {
          found = true;

          if (ctg.games.length === 0) {
            empty = true;
            // console.log("empty category: " + ctg.categoryName);
          }
        }
      });
    } else {
      found = true; //if game lobby call is not completed, display categories until is
    }
    //if its an empty category (no games) or its not found return true
    return empty || !found;
  };

  const handleCategoryChange = (category) => {
    if (skin === "rac") {
      history.push(baseUrl + category.name.toLowerCase().replace(/ /g, "-"));
    } else {
      onClick(category.name.toLowerCase());
    }
  };
  //Get template
  const template = serverSetup.template;

  return (
    <>
      {isMobileOnly ? (
        <MobileFilter>
          <label htmlFor="filter-select">{t("label_filter", "Filter:")}</label>{" "}
          <StyledSelect
            formControll
            id="filter-select"
            value={selected && selected.replace("-", " ")}
            onChange={(e) => handleChange(e)}
          >
            {(skin === "abc" ||
              skin === "nbc" ||
              skin === "yet" ||
              skin === "fun" ||
              skin === "yak" ||
              ((skin === "cac" || skin === "hyc") && selected === null)) && (
              <StyledFilterOption
                value="all"
                selected={selected === null && !providerSearchFlag}
              >
                {t("label_all_categories", "All")}
              </StyledFilterOption>
            )}
            {(skin === "abc" ||
              skin === "nbc" ||
              skin === "yet" ||
              skin === "fun" ||
              skin === "yak" ||
              skin === "rac" ||
              ((skin === "cac" || skin === "hyc") && selected === null)) &&
              providerSearchFlag && (
                <StyledFilterOption
                  value=""
                  selected={providerSearchFlag}
                ></StyledFilterOption>
              )}
            {providerSearchFlag
              ? null
              : categories &&
                categories.map(
                  (category) =>
                    !checkEmptyCategory(
                      gameLobby,
                      category.name.toLowerCase().replace(/ /g, "-")
                    ) &&
                    category.name !== "game-of-week" &&
                    category.name !== "game-of-month" &&
                    category.name !== "game-of-year" && (
                      <StyledFilterOption
                        opened={
                          categorySelected(category.name) ||
                          selected ===
                            category.name.toLowerCase().replace(/ /g, "-")
                        }
                        value={category.name.toLowerCase()}
                        key={category.title}
                      >
                        {t(`${category.title}`)}
                      </StyledFilterOption>
                    )
                )}
          </StyledSelect>
        </MobileFilter>
      ) : (
        <>
          {(skin === "abc" ||
            skin === "nbc" ||
            skin === "bnk" ||
            skin === "yet" ||
            skin === "fun" ||
            skin === "yak" ||
            ((skin === "cac" || skin === "hyc") && selected === null)) && (
            <StyledFilterButton
              opened={providerSearchFlag ? false : !selected}
              onClick={() => backToAllProviders()}
            >
              {t("label_all_categories", "All Categories")}
            </StyledFilterButton>
          )}

          {providerSearchFlag
            ? null
            : categories.map((category, index) => {
                // console.log(typeof gameLobby);
                // console.log(gameLobby);
                const categoryClass = category.name
                  .toLowerCase()
                  .replace(/ /g, "-");
                // console.log(categoryClass)
                let emptyCat = checkEmptyCategory(gameLobby, categoryClass);
                // console.log("category: " + categoryClass + " empty: " + JSON.stringify(emptyCat));
                return template !== "fourth" && template !== "" ? (
                  <li>
                    <StyledLink
                      hidelink
                      hidden={emptyCat}
                      to={`/${currentLanguage}/${gameCategoryToRoute(
                        category.name
                      )}`}
                    >
                      {(() => {
                        switch (template) {
                          case "first":
                            return (
                              <React.Fragment>
                                <span className={categoryClass}></span>
                                {`${category.name}`}
                              </React.Fragment>
                            );
                          case "second":
                            return `${category.name}`;
                          case "third":
                            return (
                              <React.Fragment>
                                <i className={"icon " + categoryClass}></i>
                                {`${category.name}`}
                              </React.Fragment>
                            );
                          default:
                            return `${category.name}`;
                        }
                      })()}
                    </StyledLink>
                  </li>
                ) : (
                  <React.Fragment key={category.title + index}>
                    {category.isVisible && (
                      <StyledFilterButton
                        opened={
                          categorySelected(category.name) ||
                          selected ===
                            category.name.toLowerCase().replace(/ /g, "-")
                        }
                        display={emptyCat}
                        onClick={() => {
                          handleCategoryChange(category);
                        }}
                      >
                        {t(`${category.title}`, category.name)}
                      </StyledFilterButton>
                    )}
                  </React.Fragment>
                );
              })}
          {(skin === "abc" || skin === "pub") && (
            <NoFreePlayUKDialog
              showModal={noFreePlay}
              handleCancel={() => setnoFreePlay(false)}
              setModal={(e) => setnoFreePlay(e)}
              skin={skin}
            />
          )}
        </>
      )}
    </>
  );
};

export default Categories;
