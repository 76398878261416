import React from "react";
import { useTranslation } from "react-i18next";
/* import { StyledButton } from "../styledComponents/CommonStyledComponents"; */
import {
  AboutHeader,
  AccountHeader,
  DarkLayer,
  ProfileMenuWrapper,
  StyledGamesHeader,
  SupportButtonsWrapper,
  SupportDiv,
  SupportLink,
} from "../styledComponents/StyledContentTop";
import { GamesSearch } from "./GamesSearch";
import Logo from "./Logo";
import ProfileMenu from "./ProfileMenu";
import SupportScriptButton from "common/src/component/SupportScriptButton";
import { isMobileOnly } from "react-device-detect";

export const ContentTop = ({
  serverSetup,
  showProfileMenu,
  pages,
  showSearch,
  setIsOpen,
  skin,
  gameImagePath,
  defaultLang,
  gameCategory,
  title,
  gameCategoryStyleMobile,
  gameSearch,
  isMyAccount,
  sports,
}) => {
  const { i18n, t } = useTranslation("");
  const baseUrl = `/${
    i18n.language || window.localStorage.i18nextLng || defaultLang
  }`;

  let isUk = serverSetup && serverSetup.geoIP.isoCode === "GB";

  const accountRoute =
    pages &&
    (location.pathname === pages["my-account"] ||
      location.pathname === pages["my-profile"] ||
      location.pathname === pages["cashback"] ||
      location.pathname === pages["bonuses"] ||
      location.pathname === pages["change-password"] ||
      location.pathname === pages["transactions"] ||
      location.pathname === pages["casino-history"] ||
      location.pathname === pages["my-limits"] ||
      location.pathname === pages["my-verification"]);

  const buttonData = (skin, button) => {
    if (button === "phone") {
      if (skin === "abc") return `tel:+441223931490`;
      if (skin === "nbc") return `tel:+35620330256`;
      if (skin === "yet") return `tel:+35620330252`;
      if (skin === "pub") return `tel:+441223931493`;
      if (skin === "fun")
        return isUk ? `tel:+441223931485` : `tel:+35620330255`;
      if (skin === "cac") return `tel:+35620330253`;
      if (skin === "hyc") return `tel:+35620330258`;
      if (skin === "yak") return `tel:+35620330257`;
    } else if (button === "phone-data") {
      if (skin === "abc") return `+44 1223 931490`;
      if (skin === "nbc") return `+356 2033 0256`;
      if (skin === "yet") return `+356 2033 0252`;
      if (skin === "pub") return `+44 1223 931493`;
      if (skin === "fun") return isUk ? `+44 1223 931485` : `+356 2033 0255`;
      if (skin === "cac") return `+356 2033 0253`;
      if (skin === "hyc") return `+356 2033 0258`;
      if (skin === "yak") return `+356 2033 0257`;
    } else if (button === "email") {
      if (skin === "abc") return `mailto:support@allbritishcasino.com`;
      if (skin === "nbc") return `mailto:vip@nobonuscasino.com`;
      if (skin === "pub") return `mailto:support@pubcasino.co.uk`;
      if (skin === "yet") return `mailto:support@yeticasino.com`;
      if (skin === "fun") return `mailto:support@funcasino.com`;
      if (skin === "cac") return `mailto:service@casinocasino.com`;
      if (skin === "hyc") return `mailto:support@hypercasino.com`;
      if (skin === "yak") return `mailto:support@yakocasino.com`;
    }
    return null;
  };

  return (
    <StyledGamesHeader
      account={showProfileMenu}
      support={pages && location.pathname === pages["support"]}
      gameCategoryStyleMobile={gameCategoryStyleMobile}
      accountRoute={accountRoute}
      sports={sports}
    >
      {skin === "pub" && location.pathname !== pages["support"] && (
        <DarkLayer />
      )}
      {skin === "plc" && (
        <Logo
          isSWEIp={false}
          setOpen={setIsOpen}
          classOnScroll={true}
          defaultLang={defaultLang}
        />
      )}
      {(skin === "abc" ||
        skin === "nbc" ||
        skin === "bnk" ||
        skin === "yet" ||
        skin === "fun" ||
        skin === "yak" ||
        (accountRoute && skin === "rac")) &&
        (accountRoute && skin !== "yak" ? (
          <AccountHeader>{t(isMyAccount)}</AccountHeader>
        ) : (
          <AboutHeader gameCategory={gameCategory}>
            {t(title)}
            {/*  {location.pathname.substr(4).replace(/-/g, " ")} */}
          </AboutHeader>
        ))}
      {(skin === "abc" ||
        skin === "nbc" ||
        skin === "pub" ||
        skin === "yet" ||
        skin === "fun" ||
        skin === "cac" ||
        skin === "hyc" ||
        skin === "yak") &&
        location.pathname === pages["support"] && (
          <SupportButtonsWrapper>
            <SupportDiv>
              <div>{t("lable_live_support")}</div>
              {/* <StyledButton as="a" supportchat type="button">
              {t("label_chat_support_chat")}
            </StyledButton> */}
              <SupportScriptButton
                skin={skin}
                text={t("support_email_script")}
              />
            </SupportDiv>
            <SupportDiv>
              <div>{t("lable_phone_support")}</div>
              <SupportLink
                phone
                href={buttonData(skin, "phone")}
                target="_blank"
                rel="nofollow"
              >
                {buttonData(skin, "phone-data")}
              </SupportLink>
            </SupportDiv>
            <SupportDiv>
              <div>{t("label_email_support")}</div>
              <SupportLink
                target="_blank"
                rel="nofollow"
                email
                href={buttonData(skin, "email")}
              >
                {t("label_email_support_email")}
              </SupportLink>
            </SupportDiv>
          </SupportButtonsWrapper>
        )}
      <ProfileMenuWrapper
        gameSearch={gameSearch}
        serchLogo={
          location.pathname !== baseUrl + "/games" &&
          location.pathname !== baseUrl + "/" + gameCategory
        }
      >
        {showProfileMenu && (
          <ProfileMenu
            serverSetup={serverSetup}
            profileMenu={true}
            isMobile={true}
            pages={pages}
            showProfileMenu={true}
          />
        )}
        {showSearch &&
          (skin === "plc" ||
            ((skin === "pub" || skin === "hyc" || skin === "cac") &&
              !isMobileOnly)) && (
            <GamesSearch
              serverSetup={serverSetup}
              gameImagePath={gameImagePath}
              skin={skin}
            />
          )}
      </ProfileMenuWrapper>
    </StyledGamesHeader>
  );
};
