import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "common/src/component/input";
import Select from "common/src/component/select.js";
import { SERVER_SETUP } from "common/src/helpers/constants";
import {
  RegisterStepWrapper,
  ResetButtonWrapper,
} from "../styledComponents/StyledSignupLogin";
import { StyledFormRow } from "../styledComponents/AccountPage";
import {
  StyledButton,
  VisibilityIcon,
} from "../styledComponents/CommonStyledComponents";
import { PageheadlinePar } from "../styledComponents/StyledDepositAndWithdrawal";

const RegisterStep1 = ({
  formData,
  navigation,
  errors,
  touched,
  handleChange,
  handleBlur,
  setTouchedOnRest,
  setPxRegisterStart,
  skin,
  config,
}) => {
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));

  const { email, password, confirmPassword, currency, country } = formData;
  const { currencies, countries } = serverSetup;
  const [showPassword, setShowPassword] = useState("password");
  const [showPasswordConfirm, setShowPasswordConfirm] = useState("password");
  const { go, pause } = navigation;
  const { t } = useTranslation("");

  const nextStep =
    currency === "sek" || country === "se" ? "stepIdent" : "second";

  const paramsForCheck = [
    "email",
    "password",
    "confirmPassword",
    "currency",
    "country",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      skin === "abc" ||
      skin === "nbc" ||
      skin === "bnk" ||
      skin === "yet" ||
      skin === "fun" ||
      skin === "cac" ||
      skin === "hyc" ||
      skin === "yak" ||
      skin === "rac"
    ) {
      let focusId = document.getElementById("focus-select");
      focusId && focusId.focus();
    }
  }, []);
  /* on next check mandatory fields */
  const checkIsValid = () => {
    setTouchedOnRest(paramsForCheck);
    //tracking pixels
    setPxRegisterStart(true);
    let hasErrors = 0;
    errors &&
      paramsForCheck.forEach((p) => {
        for (let key of Object.keys(errors)) {
          if (p === key) {
            hasErrors++;
          }
        }
      });
    if (hasErrors === 0) {
      return true;
    } else {
      return false;
    }
  };

  let currencyOptions = [];
  let countryOptions = [];

  currencies.map((option, index) => {
    currencyOptions[index] = { value: option.code, name: option.title };
  });
  countries.map((option, index) => {
    countryOptions[index] = { value: option.code, name: option.name };
  });

  // console.log(errors);

  return (
    <RegisterStepWrapper>
      {skin === "rac" && (
        <PageheadlinePar
          dangerouslySetInnerHTML={{ __html: t("label_register_account") }}
        ></PageheadlinePar>
      )}
      {/* here we need steps */}
      <Input
        groupClass="form-group mb-3"
        name="email"
        label={"label_email"}
        value={email}
        type="text"
        id={"focus-select"}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.email && touched.email ? errors.email : null}
        placeholder={skin !== "plc" && skin !== "rac"}
        required
      />
      {skin !== "plc" && (
        <>
          <Input
            groupClass="form-group mb-3"
            name="password"
            label={"label_password"}
            value={password}
            type={showPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={
              errors.password && touched.password ? errors.password : null
            }
            placeholder="true"
            required
          />
          <VisibilityIcon
            register
            reposition={errors.password && touched.password}
            show={showPassword === "password"}
            onClick={() =>
              showPassword === "password"
                ? setShowPassword("search")
                : setShowPassword("password")
            }
          ></VisibilityIcon>
          <Input
            groupClass="form-group mb-3"
            name="confirmPassword"
            label={"label_confirm_password"}
            value={confirmPassword}
            type={showPasswordConfirm}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={
              errors.confirmPassword && touched.confirmPassword
                ? errors.confirmPassword
                : null
            }
            placeholder="true"
            required
          />
          <VisibilityIcon
            register
            reposition={errors.confirmPassword && touched.confirmPassword}
            show={showPasswordConfirm === "password"}
            onClick={() =>
              showPasswordConfirm === "password"
                ? setShowPasswordConfirm("search")
                : setShowPasswordConfirm("password")
            }
          ></VisibilityIcon>
          {skin === "nbc" ||
          skin === "yet" ||
          skin === "fun" ||
          skin === "cac" ||
          skin === "hyc" ||
          skin === "yak" ||
          skin === "rac" ? (
            <StyledFormRow nbcstepone>
              <Select
                groupClass="form-group col-md-6 mb-3"
                name="currency"
                list={currencyOptions}
                onChange={handleChange}
                onBlur={handleBlur}
                firstOption="label_select_currency"
                errors={
                  errors.currency && touched.currency ? errors.currency : null
                }
                disabled={false}
                required
                value={formData.currency}
              />
              <Select
                groupClass="form-group col-md-6 mb-3"
                name="country"
                list={countryOptions}
                onChange={handleChange}
                onBlur={handleBlur}
                firstOption="label_select_country"
                errors={
                  errors.country && touched.country ? errors.country : null
                }
                disabled={false}
                required
                value={formData.country}
              />
            </StyledFormRow>
          ) : (
            <Select
              groupClass="form-group mb-3"
              name="country"
              list={countryOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              firstOption="label_select_country"
              errors={errors.country && touched.country ? errors.country : null}
              disabled={false}
              value={config.defaultCountry}
              required
            />
          )}
        </>
      )}
      {skin === "plc" && (
        <>
          <StyledFormRow>
            <Input
              groupClass="form-group col-md-6 mb-3"
              name="password"
              label={"label_password"}
              value={password}
              type={showPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                errors.password && touched.password ? errors.password : null
              }
              required
            />
            <VisibilityIcon
              register
              reposition={errors.password && touched.password}
              show={showPassword === "password"}
              onClick={() =>
                showPassword === "password"
                  ? setShowPassword("search")
                  : setShowPassword("password")
              }
            ></VisibilityIcon>
            <Input
              groupClass="form-group col-md-6 mb-3"
              name="confirmPassword"
              label={"label_confirm_password"}
              value={confirmPassword}
              type={confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                errors.confirmPassword && touched.confirmPassword
                  ? errors.confirmPassword
                  : null
              }
              required
            />
            <VisibilityIcon
              register
              reposition={errors.confirmPassword && touched.confirmPassword}
              show={showPasswordConfirm === "password"}
              onClick={() =>
                showPasswordConfirm === "password"
                  ? setShowPasswordConfirm("search")
                  : setShowPasswordConfirm("password")
              }
            ></VisibilityIcon>
          </StyledFormRow>
          <StyledFormRow>
            <Select
              groupClass="form-group col-md-6 mb-3"
              name="currency"
              list={currencyOptions}
              label="label_currency"
              onChange={handleChange}
              onBlur={handleBlur}
              firstOption="label_select_currency"
              errors={
                errors.currency && touched.currency ? errors.currency : null
              }
              disabled={false}
              value={""}
              required
            />
            <Select
              groupClass="form-group col-md-6 mb-3"
              name="country"
              list={countryOptions}
              label="label_country"
              onChange={handleChange}
              onBlur={handleBlur}
              firstOption="label_select_country"
              errors={errors.country && touched.country ? errors.country : null}
              disabled={false}
              required
              value={""}
            />
          </StyledFormRow>
        </>
      )}
      <ResetButtonWrapper>
        <StyledButton
          btnCol
          pub={skin === "pub"}
          type="button"
          onClick={() => (checkIsValid() ? go(nextStep) : pause())}
          dangerouslySetInnerHTML={{ __html: t("label_next", "Next") }}
        ></StyledButton>
      </ResetButtonWrapper>
    </RegisterStepWrapper>
  );
};

export default RegisterStep1;
