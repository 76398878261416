import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { addYears } from "date-fns";
import Input from "common/src/component/input";
import Select from "common/src/component/select";
import { PaymentServer } from "common/src/helpers/connectors";
import { SERVER_SETUP } from "common/src/helpers/constants";
import { isMobileOnly } from "react-device-detect";
import { toast } from "react-toastify";
import {
  OffsetMd4,
  RegisterNlStepWrapper,
  ResetButtonWrapper,
} from "../styledComponents/StyledSignupLogin";
import {
  InvalidFeedback,
  StyledDatePicker,
  StyledFormRow,
} from "../styledComponents/AccountPage";
import {
  StyledButton,
  VisibilityIcon,
} from "../styledComponents/CommonStyledComponents";
import { PageheadlinePar } from "../styledComponents/StyledDepositAndWithdrawal";

const RegisterStep2NL = ({
  errors,
  touched,
  formData,
  navigation,
  handleChange,
  handleBlur,
  handlePhoneChange,
  handleOnPhoneBlur,
  handleOnDateBlur,
  handleDateChange,
  setTouchedOnRest,
  setSpinner,
  ibanError,
  skin,
}) => {
  const {
    gender,
    // title,
    firstName,
    //middleName,
    lastName,
    address,
    city,
    zipCode,
    dateOfBirth,
    phone,
    country,
    // birthPlace,
    inserts,
    buildingNumber,
    password,
    confirmPassword,
    email,
    bankIBAN,
    bankAccountHolder,
  } = formData;
  // formData.country = "nl";
  // const buttonNext = useRef(null);
  const { go, pause } = navigation;
  const { t, i18n } = useTranslation("");
  const language = i18n.language;
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const [resultPlayerData, setResultPlayerData] = useState({});
  const [ibanErrShown, setIbanErrShown] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [currentDate, SetCurrentDate] = useState(addYears(new Date(), -18));
  const [showPassword, setShowPassword] = useState("password");
  const [showPasswordConfirm, setShowPasswordConfirm] = useState("password");
  //let isValid = false;
  // const isUK = country === 'gb' ? true : false;
  // const nextStep = isUK ? 'stepUK' : 'stepGDPR';
  const prevStep = "idin";
  const nextStep = "bsnNL";

  const currentLanguage = i18n.language || window.localStorage.i18nextLng;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getPlayer = async () => {
      await PaymentServer.post(
        /*'http://localhost:9001'+*/ "/fhnd/v1/idin/fetch",
        {
          ipAddress: serverSetup.geoIP.ipAddress,
          locale: language,
        }
      )
        .then((res) => {
          if (typeof res.data.maintenance !== "undefined") {
            //idin maintenance, allow no player data fetched
            setResultPlayerData({
              legalLastName: "",
              country: "nl",
              street: "",
              city: "",
              postalCode: "",
              telephone: "",
              gender: "",
              legalLastNamePrefix: "",
              houseNumber: "",
              dob: "",
            });
            formData.country = "nl";
            formData.gender = "male";
            return true;
          }
          // console.log('PLAYER: ' + JSON.stringify(res.data.player));

          // res.data.player.country = "mt";

          // res.data.player.email = "";
          // res.data.player.legalLastName = "";
          // res.data.player.initials = "";
          // res.data.player.street = "";
          // res.data.player.city = "";
          // res.data.player.postalCode = "";
          // res.data.player.telephone = "";
          // res.data.player.gender = "";
          // res.data.player.legalLastNamePrefix = "";
          // res.data.player.houseNumber = "";
          // res.data.player.dob = "";

          // res.data.player = JSON.parse('{"legalLastName":"Bles","preferredLastName":"Bles","partnerLastName":"","legalLastNamePrefix":"","preferredLastNamePrefix":"","partnerLastNamePrefix":"","initials":"CJB","street":"60, Olympus, Vjal Ragusa","houseNumber":"","postalCode":"","city":"MST2309  MOSTA","country":"MT","dob":"1992-01-10","gender":"male","telephone":"+35699350504","email":"carlobles92@gmail.com"}');
          // res.data.player.email = "spritzer.crassus456@gmail.com";
          // console.log('PLAYER: ' + JSON.stringify(res.data.player));
          formData.email = res.data.player.email;
          formData.country = res.data.player.country.toLowerCase();
          formData.lastName = res.data.player.legalLastName;
          formData.firstName = res.data.player.initials;
          formData.address = res.data.player.street;
          formData.city = res.data.player.city;
          formData.zipCode = res.data.player.postalCode;
          formData.phone = res.data.player.telephone;
          formData.gender = res.data.player.gender;
          formData.inserts = res.data.player.legalLastNamePrefix;

          //check if we should allow empty country?
          // if (res.data.player.country.toLowerCase() !== 'nl') {
          //   //only nl allowed
          //   toast.error(t('err_wrong_country', 'err_wrong_country'));
          //   go(prevStep);
          // }
          formData.buildingNumber = res.data.player.houseNumber;
          formData.dateOfBirth = res.data.player.dob;

          setResultPlayerData(res.data.player);
          return true;
        })
        .catch((error) => {
          if (error.response) {
            toast.error(t("err_not_allowed", "err_not_allowed"));
            go(prevStep);
          }
          return false;
        });
    };

    setSpinner(true);
    getPlayer()
      .then(() => {
        setSpinner(false);
      })
      .catch(() => {
        setSpinner(false);
      });
  }, []);

  const paramsForCheck = [
    "firstName",
    "lastName",
    "address",
    "city",
    "zipCode",
    "gender",
    "dateOfBirth",
    "phone",
    "country",
    // 'birthPlace',
    "inserts",
    "buildingNumber",
    "password",
    "confirmPassword",
    "email",
    "bankIBAN",
    "bankAccountHolder",
  ];
  // console.log("FORM DATA: " + JSON.stringify(formData));

  // console.log("ERRORS: " + JSON.stringify(errors));
  /* on next check mandatory fields */
  const checkIsValid = () => {
    if (!currentDate) errors.dateOfBirth = "err_empty_date_of_birth";

    setTouchedOnRest(paramsForCheck);
    console.log(errors);
    let hasErrors = 0;
    errors &&
      paramsForCheck.forEach((p) => {
        for (let key of Object.keys(errors)) {
          if (p === key) {
            hasErrors++;
          }
        }
      });
    if (hasErrors === 0) {
      return true;
    } else {
      phone.length === 0 ? setPhoneIsValid(true) : setPhoneIsValid(false);
      return false;
    }
  };

  let genderOptions = [
    { value: "male", name: "label_male" },
    { value: "female", name: "label_female" },
  ];
  if (ibanError !== "" && !ibanErrShown) {
    // console.log("set error -------");
    errors.bankIBAN = ibanError;
    setIbanErrShown(true);
  }

  const handleChangeDate = (date) => {
    SetCurrentDate(date);
    handleDateChange(date);
  };

  return (
    <OffsetMd4>
      <RegisterNlStepWrapper>
        <PageheadlinePar
          dangerouslySetInnerHTML={{ __html: t("label_register_2_step") }}
        ></PageheadlinePar>
      </RegisterNlStepWrapper>
      <StyledFormRow>
        <Input
          groupClass="form-group col-md-5"
          name="firstName"
          label={"label_first_name"}
          placeholder={true}
          value={firstName}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.firstName && touched.firstName ? errors.firstName : null
          }
          autoComplete={"given-name"}
          // ref={buttonNext}
          // autoFocus={true}
          required
        />
        <Input
          groupClass={
            "form-group col-md-2" +
            (resultPlayerData.legalLastNamePrefix === "" ? "" : " form-value")
          }
          name="inserts"
          label={"label_inserts"}
          placeholder={true}
          value={inserts}
          type="text"
          // onChange={resultPlayerData.legalLastNamePrefix === "" ? handleChange() : null}
          // onChange={(e) => {resultPlayerData.legalLastNamePrefix === "" ? handleChange(e) : null}}
          {...(resultPlayerData.legalLastNamePrefix === "" && {
            onChange: handleChange,
          })}
          onBlur={handleBlur}
          errors={errors.inserts && touched.inserts ? errors.inserts : null}
          readOnly={resultPlayerData.legalLastNamePrefix === "" ? null : true}
          required
        />
        <Input
          groupClass={
            "form-group col-md-5" +
            (resultPlayerData.legalLastName === "" ? "" : " form-value")
          }
          name="lastName"
          label={"label_last_name"}
          placeholder={true}
          value={lastName}
          type="text"
          autoComplete={"family-name"}
          // onChange={(e) => {(resultPlayerData.legalLastName === "") ? handleChange(e) : null}}
          {...(resultPlayerData.legalLastName === "" && {
            onChange: handleChange,
          })}
          onBlur={handleBlur}
          errors={errors.lastName && touched.lastName ? errors.lastName : null}
          readOnly={resultPlayerData.legalLastName === "" ? null : true}
          required
        />
      </StyledFormRow>

      <StyledFormRow>
        <Select
          groupClass={
            `form-group col-md-6 ${!isMobileOnly ? "mb-0" : ""} ` +
            (resultPlayerData.gender === "" ? "" : " form-value")
          }
          name="gender"
          list={genderOptions}
          // onChange={resultPlayerData.gender === "" ? handleChange() : null}
          // onChange={(e) => {resultPlayerData.gender === "" ? handleChange(e) : null}}
          {...(resultPlayerData.gender === "" && { onChange: handleChange })}
          onBlur={handleBlur}
          defaultValue={gender}
          firstOption="label_select_gender"
          errors={errors.gender && touched.gender ? errors.gender : null}
          readOnly={resultPlayerData.gender === "" ? null : true}
          disabled={resultPlayerData.gender === "" ? null : true}
          required
        />
        <div
          className={
            "form-group col-md-6  register-nl" +
            (resultPlayerData.dob === "" ? "" : " form-value")
          }
        >
          <StyledDatePicker
            maxDate={addYears(new Date(), -18)}
            minDate={addYears(new Date(), -100)}
            value={dateOfBirth === "" ? currentDate : new Date(dateOfBirth)}
            onChange={(date) => handleChangeDate(date)}
            clearIcon={null}
            locale={currentLanguage}
            required={true}
            dayPlaceholder={"dd"}
            monthPlaceholder={"mm"}
            yearPlaceholder={"yyyy"}
          />
          {errors.dateOfBirth && touched.dateOfBirth && (
            <InvalidFeedback
              reg
              dangerouslySetInnerHTML={{ __html: t(errors.dateOfBirth) }}
            ></InvalidFeedback>
          )}
        </div>
      </StyledFormRow>
      {/*<StyledFormRow>*/}
      {/*<Input*/}
      {/*  groupClass="form-group col-md-6 mb-3"*/}
      {/*  name="birthPlace"*/}
      {/*  label={'label_birthplace'}*/}
      {/*  placeholder={true}*/}
      {/*  value={birthPlace}*/}
      {/*  type="text"*/}
      {/*  onChange={handleChange}*/}
      {/*  onBlur={handleBlur}*/}
      {/*  errors={errors.birthPlace && touched.birthPlace ? errors.birthPlace : null}*/}
      {/*/>*/}
      {/*</StyledFormRow>*/}
      <StyledFormRow>
        <Input
          groupClass={
            "form-group col-md-12 mb-3" +
            (resultPlayerData.street === "" ? "" : " form-value")
          }
          name="address"
          label={"label_address"}
          placeholder={true}
          value={address}
          type="text"
          autoComplete={"street-address"}
          // onChange={resultPlayerData.street === "" ? handleChange() : null}
          // onChange={(e) => {resultPlayerData.street === "" ? handleChange(e) : null}}
          {...(resultPlayerData.street === "" && { onChange: handleChange })}
          onBlur={handleBlur}
          errors={errors.address && touched.address ? errors.address : null}
          readOnly={resultPlayerData.street === "" ? null : true}
        />
      </StyledFormRow>
      <StyledFormRow>
        <Input
          groupClass={
            "form-group col-md-12 mb-3" +
            (resultPlayerData.city === "" ? "" : " form-value")
          }
          name="city"
          label={"label_city"}
          placeholder={true}
          value={city}
          type="text"
          // onChange={(e) => {resultPlayerData.city === "" ? handleChange(e) : null}}
          {...(resultPlayerData.city === "" && { onChange: handleChange })}
          onBlur={handleBlur}
          errors={errors.city && touched.city ? errors.city : null}
          readOnly={resultPlayerData.city === "" ? null : true}
          autoComplete={"home city"}
        />
      </StyledFormRow>
      <StyledFormRow>
        <Input
          groupClass={
            "form-group col-md-6 mb-3" +
            (resultPlayerData.houseNumber === "" ? "" : " form-value")
          }
          name="buildingNumber"
          label={"label_house_number"}
          placeholder={true}
          value={buildingNumber}
          type="text"
          // onChange={resultPlayerData.houseNumber === "" ? handleChange() : null}
          // onChange={(e) => {resultPlayerData.houseNumber === "" ? handleChange(e) : null}}
          {...(resultPlayerData.houseNumber === "" && {
            onChange: handleChange,
          })}
          onBlur={handleBlur}
          errors={
            errors.buildingNumber && touched.buildingNumber
              ? errors.buildingNumber
              : null
          }
          readOnly={resultPlayerData.houseNumber === "" ? null : true}
        />
        <Input
          groupClass={
            "form-group col-md-6 mb-3" +
            (resultPlayerData.postalCode === "" ? "" : " form-value")
          }
          name="zipCode"
          label={"label_post_code"}
          placeholder={true}
          value={zipCode}
          type="text"
          // onChange={resultPlayerData.postalCode === "" ? handleChange() : null}
          // onChange={(e) => {resultPlayerData.postalCode === "" ? handleChange(e) : null}}
          {...(resultPlayerData.postalCode === "" && {
            onChange: handleChange,
          })}
          onBlur={handleBlur}
          errors={errors.zipCode && touched.zipCode ? errors.zipCode : null}
          readOnly={resultPlayerData.postalCode === "" ? null : true}
          autoComplete={"postal-code"}
        />
      </StyledFormRow>
      <StyledFormRow>
        <Input
          groupClass={"form-group col-md-12 mb-3"}
          name="email"
          label={"label_email"}
          placeholder={true}
          value={email}
          type="text"
          // onChange={(e) => {resultPlayerData.email === "" ? handleChange(e) : null}}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.email && touched.email ? errors.email : null}
          // readOnly={resultPlayerData.email === "" ? null : true}
          autoFocus={true}
          required
          autoComplete={"email"}
        />
      </StyledFormRow>
      <div className="form-row bank-data">
        <Input
          groupClass={"form-group col-md-12 mb-3"}
          name="bankIBAN"
          label={"label_bank_account"}
          placeholder={true}
          value={bankIBAN}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.bankIBAN && (touched.bankIBAN || ibanError !== "")
              ? errors.bankIBAN
              : null
          }
          required
          autoComplete={"cc-number"}
        />
        <Input
          groupClass={"form-group col-md-12 mb-3"}
          name="bankAccountHolder"
          label={"label_account_holder"}
          placeholder={true}
          value={bankAccountHolder}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.bankAccountHolder && touched.bankAccountHolder
              ? errors.bankAccountHolder
              : null
          }
          required
          autoComplete={"cc-name"}
        />
      </div>
      <StyledFormRow>
        <Input
          groupClass="form-group col-md-6 mb-3"
          name="password"
          label={"label_password"}
          placeholder={true}
          value={password}
          type={showPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.password && touched.password ? errors.password : null}
          autoComplete="new-password"
          required
        />
        <VisibilityIcon
          register
          reposition={errors.password && touched.password}
          show={showPassword === "password"}
          onClick={() =>
            showPassword === "password"
              ? setShowPassword("search")
              : setShowPassword("password")
          }
        ></VisibilityIcon>
        <Input
          groupClass="form-group col-md-6 mb-3"
          name="confirmPassword"
          label={"label_confirm_password"}
          placeholder={true}
          value={confirmPassword}
          type={showPasswordConfirm}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.confirmPassword && touched.confirmPassword
              ? errors.confirmPassword
              : null
          }
          required
        />
        <VisibilityIcon
          register
          reposition={errors.confirmPassword && touched.confirmPassword}
          show={showPasswordConfirm === "password"}
          onClick={() =>
            showPasswordConfirm === "password"
              ? setShowPasswordConfirm("search")
              : setShowPasswordConfirm("password")
          }
        ></VisibilityIcon>
      </StyledFormRow>

      <div
        className={
          "form-row" + (resultPlayerData.telephone === "" ? "" : " form-value")
        }
      >
        <PhoneInput
          inputProps={{
            name: "phone",
            required: true,
            // autoFocus: true,
          }}
          autoFormat={false}
          country={resultPlayerData.telephone === "" ? country : null}
          value={phone}
          containerClass="col-md-12"
          inputClass={errors.phone && touched.phone ? "is-invalid" : ""}
          // onChange={(value, country, e, formattedValue) =>
          //   handlePhoneChange(value, country, e, formattedValue)
          // }
          // onChange={(value, country, e, formattedValue) => {
          //   resultPlayerData.telephone === "" ? handlePhoneChange(value, country, e, formattedValue) : null
          // }}
          {...(resultPlayerData.telephone === "" && {
            onChange: (value, country, e, formattedValue) =>
              handlePhoneChange(value, country, e, formattedValue),
          })}
          onBlur={(value, country, e, formattedValue) =>
            handleOnPhoneBlur(value, country, e, formattedValue)
          }
          disabled={resultPlayerData.telephone === "" ? null : true}
          autoComplete={"tel"}
        />
        {phoneIsValid ? (
          <InvalidFeedback
            reg
            tel
            dangerouslySetInnerHTML={{
              __html: t("err_empty_phone"),
            }}
          ></InvalidFeedback>
        ) : (
          errors.phone &&
          touched.phone && (
            <InvalidFeedback
              reg
              tel
              dangerouslySetInnerHTML={{
                __html: t(errors.phone ? errors.phone : "err_empty_phone"),
              }}
            ></InvalidFeedback>
          )
        )}
      </div>

      <ResetButtonWrapper>
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="btn btn-first col-md-4"*/}
        {/*  onClick={() => go(prevStep)}*/}
        {/*  dangerouslySetInnerHTML={{ __html: t('label_go_back', 'Previous') }}*/}
        {/*></button>*/}
        <StyledButton
          btn
          type="button"
          onClick={() => (checkIsValid() ? go(nextStep) : pause())}
          dangerouslySetInnerHTML={{ __html: t("label_next", "Next") }}
        ></StyledButton>
      </ResetButtonWrapper>
    </OffsetMd4>
  );
};

export default RegisterStep2NL;
