import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import 'react-phone-input-2/lib/bootstrap.css';
import Input from "common/src/component/input";
import Select from "common/src/component/select";
import { isMobile } from "react-device-detect";
import {
  FormRowLeft,
  OffsetMd4,
  RegisterBSNAndStepWrapper,
  RegisterText,
  ResetButtonWrapper,
  StyledCustomCheckboxWrapper,
} from "../styledComponents/StyledSignupLogin";
import {
  StyledButton,
  StyledCustomControlLabel,
  StyledInput,
  StyledSelect,
} from "../styledComponents/CommonStyledComponents";
import {
  InvalidFeedback,
  StyledFormRow,
} from "../styledComponents/AccountPage";

const RegisterBSN = ({
  errors,
  touched,
  formData,
  navigation,
  handleChange,
  // handleDateRangeChange,
  handleBlur,
  setTouchedOnRest,
}) => {
  const {
    BSN,
    birthPlace,
    // kycDocumentType,
    // kycIDNumber,
    // kycDocumentCity,
    // documentValidFrom,
    // documentValidTo,
  } = formData;
  const { go, pause } = navigation;
  const { t } = useTranslation("");

  const nextStep = "limitsNL";
  /* const prevStep = formData.country !== 'se' && formData.currency !== 'sek' ? 'first' : 'stepIdent'; */
  const [haveBSN, setHaveBSN] = useState(true);
  const [focus, setFocus] = useState(false);

  const paramsForCheck = [
    "BSN",
    "birthPlace",
    // "kycDocumentType",
    // "kycIDNumber",
    // "kycDocumentCity",
    // "documentValidFrom",
    // "documentValidTo",
  ];
  // console.log("errors: " + JSON.stringify(errors));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* on next check mandatory fields */
  const checkIsValid = () => {
    setTouchedOnRest(paramsForCheck);
    // console.log("have bsn " + haveBSN);
    if (haveBSN) {
      //check bsn input
      delete errors.birthPlace;
      if (BSN === "") {
        errors.BSN = "err_empty_bsn";
        return false;
      } else {
        delete errors.BSN;
      }
    } else {
      //check birth place input
      delete errors.BSN;
      if (birthPlace === "") {
        errors.birthPlace = "err_empty_birth_place";
        return false;
      } else {
        delete errors.birthPlace;
      }
    }

    // if (kycDocumentType === "" || kycDocumentType === "none") {
    //   errors.kycDocumentType = "err_empty_document_type";
    //   return false;
    // } else {
    //   delete errors.kycDocumentType;
    // }

    // if (kycIDNumber === "") {
    //   errors.kycIDNumber = "err_empty_kyc_id_number";
    //   return false;
    // } else {
    //   delete errors.kycIDNumber;
    // }

    // if (kycDocumentCity === "") {
    //   errors.kycDocumentCity = "err_empty_document_city";
    //   return false;
    // } else {
    //   delete errors.kycDocumentCity;
    // }

    // if (documentValidFrom === "") {
    //   errors.documentValidFrom = "err_empty_document_valid_from";
    //   return false;
    // } else {
    //   delete errors.documentValidFrom;
    // }

    // if (documentValidTo === "") {
    //   errors.documentValidTo = "err_empty_document_valid_to";
    //   return false;
    // } else {
    //   delete errors.documentValidTo;
    // }

    // if (documentValidFrom !== "" && documentValidTo !== "") {
    //   if (!moment(documentValidTo).isAfter(moment(documentValidFrom))) {
    //     errors.documentValidInvalidRange = "err_date_invalid_range";
    //     return false;
    //   } else {
    //     delete errors.documentValidInvalidRange;
    //   }
    // }

    let hasErrors = 0;
    errors &&
      paramsForCheck.forEach((p) => {
        for (let key of Object.keys(errors)) {
          if (p === key) {
            hasErrors++;
          }
        }
      });
    if (hasErrors === 0) {
      return true;
    } else {
      return false;
    }
  };

  /*   const returnYearsBSN = () => {
    let years = [];
    for (let i = moment().year() - 20; i <= moment().year() + 20; i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  }; */
  /*   const renderMonthElementBSN = ({ month, onMonthSelect, onYearSelect }) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <StyledSelect
          formControll
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </StyledSelect>
      </div>
      <div>
        <StyledSelect
          formControll
          value={month.year()}
          onChange={(e) => onYearSelect(month, e.target.value)}
        >
          {returnYearsBSN()}
        </StyledSelect>
      </div>
    </div>
  ); */

  let documentType = [
    // { value: '', name: ''},
    { value: "ID", name: "label_id" },
    { value: "Driving license", name: "label_driving_license" },
    { value: "Passport", name: "label_passport" },
  ];

  return (
    <OffsetMd4>
      <RegisterText>
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "label_register_bsn",
              "Uw Burger Service Nummer (BSN) geruiken wij eenmaldig om CRUKS (Centraal register ultsluten kansspelen) te raadplegen. Wij slaan uw BSN niet op en kunnen deze derhalve niet inzien. Lees meer over hoe wij omgaan met uw BSN in ons privacybeleid",
            ),
          }}
        ></p>
      </RegisterText>
      <FormRowLeft mb={"mb-3"}>
        <StyledCustomCheckboxWrapper>
          <StyledInput
            customControl
            type="checkbox"
            onChange={() => setHaveBSN(!haveBSN)}
            name="haveBSN"
            id="haveBSN"
            checked={haveBSN}
          />
          <StyledCustomControlLabel htmlFor="haveBSN">
            {t("label_have_bsn", "ik heb burger service nummer")}
          </StyledCustomControlLabel>
        </StyledCustomCheckboxWrapper>
      </FormRowLeft>
      {haveBSN && (
        <StyledFormRow extra={"mb-3"}>
          <Input
            groupClass="form-group col-md-12"
            name="BSN"
            label={"label_burger_service_number"}
            placeholder={true}
            value={BSN}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.BSN && touched.BSN ? errors.BSN : null}
            // disabled={haveBSN}
            required
          />
        </StyledFormRow>
      )}
      <StyledFormRow extra={"justify-content-left mb-3"}>
        <StyledCustomCheckboxWrapper>
          <StyledInput
            customControl
            type="checkbox"
            onChange={() => setHaveBSN(!haveBSN)}
            name="dontHaveBSN"
            id="dontHaveBSN"
            checked={!haveBSN}
          />
          <StyledCustomControlLabel htmlFor="haveBSN">
            {t("label_dont_have_bsn", "label_dont_have_bsn")}
          </StyledCustomControlLabel>
        </StyledCustomCheckboxWrapper>
      </StyledFormRow>
      {!haveBSN && (
        <StyledFormRow extra={"mb-5"}>
          <Input
            groupClass="form-group col-md-12"
            name="birthPlace"
            label={"label_birthplace"}
            placeholder={true}
            value={birthPlace}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={
              errors.birthPlace && touched.birthPlace ? errors.birthPlace : null
            }
            // disabled={haveBSN}
            required
          />
        </StyledFormRow>
      )}

      {/*<RegisterBSNAndStepWrapper>*/}
      {/*  <p*/}
      {/*    dangerouslySetInnerHTML={{*/}
      {/*      __html: t(*/}
      {/*        "label_register_document_description",*/}
      {/*        "label_register_document_description"*/}
      {/*      ),*/}
      {/*    }}*/}
      {/*  ></p>*/}
      {/*</RegisterBSNAndStepWrapper>*/}

      {/*<StyledFormRow extra={"mt-5"}>*/}
      {/*  <Select*/}
      {/*    groupClass={"col-md-12 mb-3"}*/}
      {/*    name="kycDocumentType"*/}
      {/*    list={documentType}*/}
      {/*    onChange={handleChange}*/}
      {/*    onBlur={handleBlur}*/}
      {/*    defaultValue={kycDocumentType}*/}
      {/*    firstOption="label_select_document_type"*/}
      {/*    errors={*/}
      {/*      errors.kycDocumentType && touched.kycDocumentType*/}
      {/*        ? errors.kycDocumentType*/}
      {/*        : null*/}
      {/*    }*/}
      {/*    required*/}
      {/*  />*/}
      {/*</StyledFormRow>*/}
      {/*<StyledFormRow>*/}
      {/*  <Input*/}
      {/*    groupClass="form-group col-md-12"*/}
      {/*    name="kycIDNumber"*/}
      {/*    label={"label_id_number"}*/}
      {/*    placeholder={true}*/}
      {/*    value={kycIDNumber}*/}
      {/*    type="number"*/}
      {/*    onChange={handleChange}*/}
      {/*    onBlur={handleBlur}*/}
      {/*    errors={*/}
      {/*      errors.kycIDNumber && touched.kycIDNumber*/}
      {/*        ? errors.kycIDNumber*/}
      {/*        : null*/}
      {/*    }*/}
      {/*    required*/}
      {/*  />*/}
      {/*</StyledFormRow>*/}
      {/*<StyledFormRow>*/}
      {/*  <Input*/}
      {/*    groupClass="form-group col-md-12"*/}
      {/*    name="kycDocumentCity"*/}
      {/*    label={"label_document_city"}*/}
      {/*    placeholder={true}*/}
      {/*    value={kycDocumentCity}*/}
      {/*    type="text"*/}
      {/*    onChange={handleChange}*/}
      {/*    onBlur={handleBlur}*/}
      {/*    errors={*/}
      {/*      errors.kycDocumentCity && touched.kycDocumentCity*/}
      {/*        ? errors.kycDocumentCity*/}
      {/*        : null*/}
      {/*    }*/}
      {/*    required*/}
      {/*  />*/}
      {/*</StyledFormRow>*/}
      {/*<StyledFormRow extra={"register-nl"}>*/}
      {/*  <div className="form-group col-md-12">*/}
      {/*    <DateRangePicker*/}
      {/*      startDate={*/}
      {/*        documentValidFrom === "" ? null : moment(documentValidFrom)*/}
      {/*      }*/}
      {/*      startDateId="documentValidFrom" // PropTypes.string.isRequired,*/}
      {/*      endDate={documentValidTo === "" ? null : moment(documentValidTo)}*/}
      {/*      endDateId="documentValidTo" // PropTypes.string.isRequired,*/}
      {/*      onDatesChange={({ startDate, endDate }) =>*/}
      {/*        handleDateRangeChange(startDate, endDate)*/}
      {/*      }*/}
      {/*      focusedInput={focus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,*/}
      {/*      onFocusChange={(focusedInput) => setFocus(focusedInput)} // PropTypes.func.isRequired,*/}
      {/*      // onClose={(value) => handleOnDateBlur(value)}*/}
      {/*      id="documentValid"*/}
      {/*      noBorder={true}*/}
      {/*      // required={true}*/}
      {/*      orientation={isMobile ? "vertical" : "horizontal"}*/}
      {/*      // initialVisibleMonth={() => moment().add(-10, 'year')}*/}
      {/*      isRTL={false}*/}
      {/*      displayFormat={"DD/MM/YYYY"}*/}
      {/*      startDatePlaceholderText={t(*/}
      {/*        "label_valid_from_placeholder",*/}
      {/*        "Valid From (dd/mm/yyyy)"*/}
      {/*      )}*/}
      {/*      endDatePlaceholderText={t(*/}
      {/*        "label_valid_to_placeholder",*/}
      {/*        "Valid To (dd/mm/yyyy)"*/}
      {/*      )}*/}
      {/*      isOutsideRange={(day) =>*/}
      {/*        day.isAfter(day.isBefore(moment().add(20, "years"))) ||*/}
      {/*        day.isBefore(moment().subtract(20, "years"))*/}
      {/*      }*/}
      {/*      hideKeyboardShortcutsPanel={true}*/}
      {/*      // renderMonthElement={(e) => renderMonthElement(e)}*/}
      {/*      openDirection={"up"}*/}
      {/*      renderMonthElement={(e) => renderMonthElementBSN(e)}*/}
      {/*    />*/}
      {/*    {errors.documentValidFrom && touched.documentValidFrom && (*/}
      {/*      <InvalidFeedback*/}
      {/*        style={{ display: "block" }}*/}
      {/*        dangerouslySetInnerHTML={{ __html: t(errors.documentValidFrom) }}*/}
      {/*      ></InvalidFeedback>*/}
      {/*    )}*/}
      {/*    {errors.documentValidTo && touched.documentValidTo && (*/}
      {/*      <InvalidFeedback*/}
      {/*        style={{ display: "block" }}*/}
      {/*        dangerouslySetInnerHTML={{ __html: t(errors.documentValidTo) }}*/}
      {/*      ></InvalidFeedback>*/}
      {/*    )}*/}
      {/*    {errors.documentValidInvalidRange &&*/}
      {/*      touched.documentValidInvalidRange && (*/}
      {/*        <InvalidFeedback*/}
      {/*          style={{ display: "block" }}*/}
      {/*          dangerouslySetInnerHTML={{*/}
      {/*            __html: t(errors.documentValidInvalidRange),*/}
      {/*          }}*/}
      {/*        ></InvalidFeedback>*/}
      {/*      )}*/}
      {/*  </div>*/}
      {/*</StyledFormRow>*/}

      <ResetButtonWrapper skin={"plc"}>
        {/*   <button
          type="button"
          className="btn btn-first col-md-4"
          onClick={() => go(prevStep)}
          dangerouslySetInnerHTML={{ __html: t('label_go_back', 'Previous') }}
        ></button> */}
        <StyledButton
          btn
          type="button"
          onClick={() => (checkIsValid() ? go(nextStep) : pause())}
          dangerouslySetInnerHTML={{ __html: t("label_next", "Next") }}
        ></StyledButton>
      </ResetButtonWrapper>
    </OffsetMd4>
  );
};

export default RegisterBSN;
