import React from "react";

export function MainBg({ indexPage, skin }) {
  /* bg for RAC */
  let mediumJpg = require(`../../../${skin}/src/data/img/bg_2m.jpg`);
  let largeJpg = require(`../../../${skin}/src/data/img/bg_2.jpg`);
  let mediumWebp = require(`../../../${skin}/src/data/img/bg_2m.webp`);
  let largeWebp = require(`../../../${skin}/src/data/img/bg_2.webp`);
  let cssHasMask = "bgmask";

  if (indexPage) {
    mediumJpg = require(`../../../${skin}/src/data/img/bg1m.jpg`);
    largeJpg = require(`../../../${skin}/src/data/img/bg1.jpg`);
    mediumWebp = require(`../../../${skin}/src/data/img/bg1m.webp`);
    largeWebp = require(`../../../${skin}/src/data/img/bg1.webp`);
    cssHasMask = "";
  }
  return (
    <div id="mainbg">
      <picture>
        <source
          className={cssHasMask}
          srcSet={`${mediumWebp} 1400w, ${largeWebp} 2000w`}
          type="image/webp"
        />
        <img
          alt="race_background"
          className={cssHasMask}
          srcSet={`${mediumJpg} 1400w, ${largeJpg} 2000w`}
        />
      </picture>
    </div>
  );
}
