import React, { useEffect, useState } from "react";
import { ProviderLogos } from "common/src/styledComponents/StyledFooter";
import { ReactComponent as LogoLnL } from "common/src/assets/img/L&L-logo.svg";
import { ReactComponent as Logo18 } from "common/src/assets/img/logo_18.svg";
import { ReactComponent as Cruks } from "common/src/assets/img/plc_cruks_logo_capital_footer.svg";
import { ReactComponent as Betblocker } from "common/src/assets/img/plc__betblocker_logo_footer.svg";
import { ReactComponent as Ksa } from "common/src/assets/img/plc_ksa_logo_footer.svg";
import { ReactComponent as Gambcom } from "common/src/assets/img/logo_gambcom.svg";
import { ReactComponent as Ga } from "common/src/assets/img/plc_logo_ga_footer.svg";
import { ReactComponent as Godaddy } from "common/src/assets/img/logo_godaddy.svg";
import { ReactComponent as Spel } from "common/src/assets/img/logo_spel.svg";
import { ReactComponent as Spelpaus } from "common/src/assets/img/logo_spelpaus.svg";
import { ReactComponent as Gamstop } from "common/src/assets/img/logo_gamstop.svg";
import { ReactComponent as Mga } from "common/src/assets/img/logo_mga.svg";
import { ReactComponent as Gamcare } from "common/src/assets/img/logo_gamcare.svg";
import { ReactComponent as Casinomeister } from "common/src/assets/img/logo_casinomeister.svg";
import { ReactComponent as CasinomeisterBestCasino } from "common/src/assets/img/meister-award-best-casino-group-logo.svg";
import { ReactComponent as CasinomeisterBestNewCasino } from "common/src/assets/img/best-new-casino-logo.svg";
import { ReactComponent as Stondlingen } from "common/src/assets/img/stondlingen-logo.svg";
import { ReactComponent as DummyImg } from "common/src/assets/img/stondlingen-logo.svg";

export const LicensesList = ({ skin }) => {
  const [currentLicencesList, setCurrentLicencesList] = useState([]);

  //All Licences
  const allLicences = [
    {
      name: "landleurope",
      link: "https://www.landleurope.eu",
      component: <LogoLnL />,
    },
    {
      name: "kansspelautoriteit",
      link: "https://www.kansspelautoriteit.nl",
      component: <Ksa />,
    },
    {
      name: "cruksregister",
      link: "http://www.cruksregister.nl",
      component: <Cruks />,
    },
    {
      name: "betblocker",
      link: "https://www.betblocker.org/nl/",
      component: <Betblocker />,
    },
    {
      name: "gamblersanonymous",
      link: "https://www.gamblersanonymous.nl/",
      component: <Ga />,
    },
    {
      name: "mga",
      link: "https://authorisation.mga.org.mt/verification.aspx?lang=EN&company=5394ea22-0083-4f8a-9815-f7650c8145be&details=1",
      component: <Mga />,
    },
    {
      name: "spelinspektionen",
      link: "https://www.spelinspektionen.se/sok-licens/bolag/?holder=2D9B6A909E52D30DA328BC6692920ECC035C204D03D26938C9F3C0C51B88BEE4&l=327257149",
      component: <Spel />,
    },
    {
      name: "gamblingcommission",
      link: "https://www.gamblingcommission.gov.uk/public-register/business/detail/38758",
      component: <Gambcom />,
    },
    {
      name: "spelpaus",
      link: "https://www.spelpaus.se",
      component: <Spelpaus />,
    },
    {
      name: "gamstop",
      link: "https://www.gamstop.co.uk/",
      component: <Gamstop />,
    },
    {
      name: "stodlinjen",
      link: "https://www.stodlinjen.se/#!/sjalvhjalp/rational",
      component: <Stondlingen />,
    },
    { name: "logo18", link: null, component: <Logo18 /> },
    {
      name: "gamcare",
      link: "https://www.gamcare.org.uk/",
      component: <Gamcare />,
    },
    {
      name: "casinomeister",
      link: "https://www.casinomeister.com/casino-awards/year-2018/#bgroup",
      component: <Casinomeister />,
    },
    {
      name: "meister-abc",
      link: "https://www.casinomeister.com/casino-reviews/all-british/",
      component: <CasinomeisterBestCasino />,
    },
    {
      name: "meister-fun",
      link: "https://www.casinomeister.com/casino-awards/year-2018/#bestnew",
      component: <CasinomeisterBestNewCasino />,
    },
    {
      name: "dummy",
      link: null,
      component: <DummyImg />,
    },
    {
      name: "godaddy",
      link: null,
      component: <Godaddy />,
    },
  ];

  //Polder Licences
  const plcLicences = [
    "landleurope",
    "kansspelautoriteit",
    "cruksregister",
    "betblocker",
    "gamblersanonymous",
  ];

  //AllBritish Licences
  const abcLicences = [
    "landleurope",
    "gamblingcommission",
    "godaddy",
    "gamstop",
    "logo18",
    "mga",
    "gamcare",
    "meister-abc",
  ];

  //PubCasino Licences
  const pubLicences = [
    "landleurope",
    "gamblingcommission",
    "godaddy",
    "gamstop",
    "mga",
    "gamcare",
  ];

  //Race Licences
  const racLicences = [
    "mga",
    "spelinspektionen",
    "godaddy",
    "gamblingcommission",
    "gamstop",
    "spelpaus",
    "gamcare",
  ];

  //NoBonus Licences
  const nbcLicences = [
    "landleurope",
    "spelinspektionen",
    "gamblingcommission",
    "godaddy",
    "spelpaus",
    "gamstop",
    "stodlinjen",
    "logo18",
    "mga",
    "gamcare",
    "casinomeister",
  ];

  //Yetti Licences
  const yetLicences = [
    "landleurope",
    "mga",
    "gamblingcommission",
    "spelinspektionen",
    "casinomeister",
    "gamstop",
    "spelpaus",
    "logo18",
    "stodlinjen",
    "gamcare",
    "godaddy",
  ];

  //Yetti Licences
  const funLicences = [
    "landleurope",
    "mga",
    "gamblingcommission",
    "spelinspektionen",
    "casinomeister",
    "meister-fun",
    "gamstop",
    "spelpaus",
    "logo18",
    "stodlinjen",
    "gamcare",
    "godaddy",
  ];

  //Casino Casino Licences
  const cacLicences = [
    "landleurope",
    "mga",
    "gamblingcommission",
    "spelinspektionen",
    "casinomeister",
    "gamstop",
    "spelpaus",
    "stodlinjen",
    "gamcare",
    "godaddy",
  ];

  //Hyper Casino Licences
  const hycLicences = [
    "landleurope",
    "mga",
    "gamblingcommission",
    "spelinspektionen",
    "casinomeister",
    "gamstop",
    "spelpaus",
    "stodlinjen",
    "gamcare",
    "godaddy",
  ];

  //Yako Casino Licences
  const yakLicences = [
    "landleurope",
    "mga",
    "gamblingcommission",
    "spelinspektionen",
    "casinomeister",
    "gamstop",
    "logo18",
    "stodlinjen",
    "gamcare",
    "godaddy",
  ];

  //Qucickbet Casino Licences
  const qbcLicences = [
    "landleurope",
    "mga",
    "gamblingcommission",
    "casinomeister",
    "gamstop",
    "logo18",
    "gamcare",
    "godaddy",
  ];

  const checkLicencesForSkin = (skin) => {
    let currentLicencesList = [];
    let currnetSkin;

    if (skin === "plc") currnetSkin = plcLicences;
    if (skin === "abc") currnetSkin = abcLicences;
    if (skin === "pub") currnetSkin = pubLicences;
    if (skin === "rac") currnetSkin = racLicences;
    if (skin === "nbc") currnetSkin = nbcLicences;
    if (skin === "yet") currnetSkin = yetLicences;
    if (skin === "fun") currnetSkin = funLicences;
    if (skin === "cac") currnetSkin = cacLicences;
    if (skin === "hyc") currnetSkin = hycLicences;
    if (skin === "yak") currnetSkin = yakLicences;
    if (skin === "qbc") currnetSkin = qbcLicences;

    currnetSkin &&
      currnetSkin.map((licenceName) => {
        allLicences.map((licence) => {
          if (licence.name.includes(licenceName))
            currentLicencesList.push(licence);
        });
      });

    setCurrentLicencesList(currentLicencesList);
  };

  useEffect(() => {
    checkLicencesForSkin(skin);
  }, []);

  return currentLicencesList.map((licence) => {
    return (
      <div key={licence.name}>
        <ProviderLogos
          as="a"
          licences
          loading="lazy"
          target="_blank"
          rel="nofollow noopener noreferrer"
          href={licence.link}
        >
          {licence.component}
        </ProviderLogos>
      </div>
    );
  });
};
