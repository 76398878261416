import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {
  SERVER_SETUP,
  SEID,
  SWE_LIMIT_DIALOG,
} from "common/src/helpers/constants";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { PaymentServer } from "common/src/helpers/connectors";
import { setToken } from "common/src/helpers/session";
import { QUERY_SET_TOKEN_GET_NICKNAME } from "common/src/graphql/queries";
import { useApolloClient } from "@apollo/client";
import {
  // InvalidFeedback,
  StyledFormRow,
} from "../styledComponents/AccountPage";
// import { PageheadlinePar } from "../styledComponents/StyledDepositAndWithdrawal";
// import {
//   ZignsecDialogForm,
//   ZignsecFormGroup,
// } from "../styledComponents/StyledZignsecDialog";
// import {
//   StyledButton,
//   StyledFlexBetween,
//   StyledInput,
// } from "../styledComponents/CommonStyledComponents";
import { isMobile } from "react-device-detect";
import config from "hyc/src/config.json";

const ZignsecDialog = ({ setPnpStep, setSpinner, pages }) => {
  const { t } = useTranslation();
  // const baseUrl = "/" + i18n.language;
  const apolloClient = useApolloClient();
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const [token, setTokenZ] = useState("");
  const [order, setOrder] = useState("");
  const [zignsecStep, setZignsecStep] = useState(0);
  const [zignsecIframeSrc, setZignsecIframeSrc] = useState("")
  const {
    // register,
    formState: { errors },
    // handleSubmit,
  } = useForm();
  let timer = null;

  useEffect(() => {
    //load zignsec autostart script
    //for stage: https://test-gateway.zignsec.com/ui/bankidse/autostart.js
    //for live: https://gateway.zignsec.com/ui/bankidse/autostart.js

    const script = document.createElement("script");

    script.src = config.zignsecScript;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const handler = (event) => {
      // console.log("FRAME EVENT: " + JSON.stringify(event));
      // console.log(typeof event.data)
      // console.log("origin" + event.origin)
      // console.log("origin 2: " + window.location.origin)
      if (event.origin === window.location.origin) {
        // console.log("same origin!");
        let data = null;
        if (typeof event.data !== "undefined") {
          try {
            data = JSON.parse(event.data);
          } catch (e) {
            data = event.data;
          }
          // console.log("DATA: " + event.data)
          if (typeof data.message !== "undefined") {
            // console.log(data.message)
            // try {
              let msg = data.message
              if (msg.ltoken && msg.ltoken !== "") {
                setTokenGetPlayer(msg.ltoken, msg.order);
                setSpinner(false);
              } else if(typeof msg.ltoken !== "undefined") {
                //step back error
                setPnpStep(0);
                setSpinner(false);
                setTokenZ("");
                // toast.error(t("err_failed_login"));
                return false;
              }
            // } catch (e) {
            //   console.log(e);
            // }
          } else {
            setSpinner(false);
            setTokenZ("");
            return false;
          }
        }
      }
    };

    window.addEventListener("message", handler);

    // clean up
    return () => window.removeEventListener("message", handler);
  }, []); // empty array => run only once

  //v5 and before
  useEffect(() => {
    setSpinner(true);
    PaymentServer.post("/fhnd/v1/zignsec/check", {
      ip: serverSetup.geoIP.ipAddress,
      isMobile: isMobile,
      locale: serverSetup.currentLanguage,
      lookup: false
    })
        .then((res) => {
          // console.log("success: " + JSON.stringify(res.data));
          if(res.data.version && res.data.version !== "v5") {
            //show zignsec
            setZignsecStep(1);
            setSpinner(false)
          } else if(res.data.redirect && res.data.redirect.length > 0) {
            //show iframe:
            //set iframe src
            setZignsecIframeSrc(res.data.redirect)
            // console.log("set order ", res.data.order)
            // setOrder(res.data.order)
            //set step to show iframe
            setZignsecStep(2);

            setSpinner(false)
          } else {
            setSpinner(false)
          }
        })
        .catch((err) => {
          //step back on error
          // setPnpStep(0);
          // console.log(err)
          toast.error(t(err.response.data[0]));
          setSpinner(false);
          return false
          // return false;
        });
  }, []);

  // console.log(errors);
  const onSubmit = (data) => {
    setSpinner(true);
    // console.log("submit form:" + JSON.stringify(data));
    PaymentServer.post("/fhnd/v1/zignsec/authenticate", {
      ip: serverSetup.geoIP.ipAddress,
      bbid: data.nationalID.trim(),
    })
      .then((res) => {
        // console.log("success: " + JSON.stringify(res));
        if (typeof res.data.ztoken !== "undefined") {
          //register section
          setTokenZ(res.data.ztoken);
          setOrder(res.data.order);
          setSpinner(false);
        } else {
          //step back error
          // setPnpStep(0);
          setSpinner(false);
          setTokenZ("");
          return false;
        }
      })
      .catch((err) => {
        //step back on error
        // setPnpStep(0);
        toast.error(t(err.response.data[0]));
        setSpinner(false);
        setTokenZ("");
        return false;
      });
  };

  const goBackZignsec = () => {
    setPnpStep(0);
  };

  const zignsecCheck = () => {
    // console.log("check status!");
    setSpinner(true);

    setTimeout(() => {
      // console.log("run first check");
      timer = setInterval(() => {
        // console.log("interval check");
        collect();
      }, 5000);
    }, 5000);
  };

  function collect() {
    PaymentServer.post("/fhnd/v1/zignsec/collect", {
      ip: serverSetup.geoIP.ipAddress,
      order: order,
    })
      .then((res) => {
        // console.log("success: " + JSON.stringify(res));
        // console.log("got token: " + res.data)
        if (typeof res.data.ltoken !== "undefined") {
          //register section
          // console.log("return token: " + res.data.ltoken);
          toast.info(t(res.data.message));
          if (res.data.ltoken !== "") {
            // console.log("clear interval");
            clearInterval(timer);
            setTokenGetPlayer(res.data.ltoken);
          }
        } else {
          clearInterval(timer);
          setSpinner(false);
          return;
        }
      })
      .catch((err) => {
        //step back on error
        // setPnpStep(0);
        toast.error(t(err.response.data[0]));
        setSpinner(false);
        clearInterval(timer);
        setTokenZ("");
        return;
      });
  }

  async function setTokenGetPlayer(tokenR, orderId) {
    if(tokenR && tokenR !== "") {
      try {
        const { loading, errors, data } = await apolloClient.query({
          query: QUERY_SET_TOKEN_GET_NICKNAME,
          variables: { token: tokenR },
        });
        if (loading) return "Loading ...";
        if (errors) {
          toast.error(errors[0].message);
          setSpinner(false);
          return "Error ...";
        } else {
          //do login
          // console.log(data.setTokenGetNickname)
          localStorage.setItem(SWE_LIMIT_DIALOG, true);
          setToken(data.setTokenGetNickname.sessionID);
          if(typeof orderId !== 'undefined') {
            localStorage.setItem(SEID, orderId);
          } else {
            // console.log(order)
            localStorage.setItem(SEID, order);
          }
          window.location.replace(pages["deposit"]);
        }
      } catch (ex) {
        console.log(ex);
      }
    }
  }

  return (
      <>
        {/*{zignsecStep === 1 && (*/}
        {/*    <ZignsecDialogForm onSubmit={handleSubmit(onSubmit)}>*/}
        {/*      <PageheadlinePar*/}
        {/*          center*/}
        {/*          dangerouslySetInnerHTML={{*/}
        {/*            __html: t(*/}
        {/*                "label_enter_login_credentials",*/}
        {/*                "Vänligen ange din mailadress och ditt lösenord"*/}
        {/*            ),*/}
        {/*          }}*/}
        {/*      ></PageheadlinePar>*/}
        {/*      <StyledFormRow>*/}
        {/*        <ZignsecFormGroup>*/}
        {/*          <label>{t("label_national_id")}</label>*/}
        {/*          <StyledInput*/}
        {/*              validform*/}
        {/*              errorprop={errors.nationalID}*/}
        {/*              type="text"*/}
        {/*              placeholder="ex. 199911222222"*/}
        {/*              {...register("nationalID", {*/}
        {/*                required: t("err_empty_national_id"),*/}
        {/*                maxLength: {*/}
        {/*                  value: 13,*/}
        {/*                  message: t("err_invalid_length_national_id", "invalid length"),*/}
        {/*                },*/}
        {/*                minLength: {*/}
        {/*                  value: 8,*/}
        {/*                  message: t("err_invalid_length_national_id", "invalid length"),*/}
        {/*                },*/}
        {/*              })}*/}
        {/*          ></StyledInput>*/}
        {/*          <InvalidFeedback>*/}
        {/*            {errors.nationalID && errors.nationalID.message}*/}
        {/*          </InvalidFeedback>*/}
        {/*        </ZignsecFormGroup>*/}
        {/*      </StyledFormRow>*/}
        {/*      <StyledFlexBetween>*/}
        {/*        <StyledButton*/}
        {/*            fourth*/}
        {/*            colfourth={"col-md-4 col-sm-4 col-6"}*/}
        {/*            type="button"*/}
        {/*            onClick={goBackZignsec}*/}
        {/*        >*/}
        {/*          {t("label_go_back")}*/}
        {/*        </StyledButton>*/}
        {/*        {token === "" && (*/}
        {/*            <StyledButton*/}
        {/*                sweVerify*/}
        {/*                btn*/}
        {/*                spancol={"col-md-4 col-sm-4 col-6 start-deposit"}*/}
        {/*                type="submit"*/}
        {/*                name="submit"*/}
        {/*            >*/}
        {/*            </StyledButton>*/}
        {/*        )}*/}
        {/*        {token !== "" && (*/}
        {/*            <StyledButton*/}
        {/*                as="a"*/}
        {/*                btnsecond*/}
        {/*                spancol={"col-md-4 col-sm-4 col-6 button-bank-id"}*/}
        {/*                href={"bankid:///?autostarttoken=" + token + "&redirect=null"}*/}
        {/*                onClick={zignsecCheck}*/}
        {/*            >*/}
        {/*              {t("label_bank_id_app", "Bank-ID app")}*/}
        {/*            </StyledButton>*/}
        {/*        )}*/}
        {/*      </StyledFlexBetween>*/}
        {/*    </ZignsecDialogForm>*/}
        {/*)}*/}
        {zignsecStep === 2 && (
            <StyledFormRow zignsecFrame>
              <iframe
                  id="zignsec-iframe"
                  title="zignsec"
                  src={zignsecIframeSrc}
                  scrolling="no"
                  width={isMobile ? "100vw" : "100%"}
                  height={isMobile ? "100vh" : "100%"}
                  frameBorder="no"
                  style={{ minWidth: "100%", minHeight: "735px", maxWidth: "535px" }}
              ></iframe>
            </StyledFormRow>
        )}
        {/*{zignsecStep === 2 && isMobile && (*/}
        {/*    <StyledFormRow>*/}
        {/*        <ZignsecStepNewWindow*/}
        {/*            src={zignsecIframeSrc}*/}
        {/*            width={"100%"}*/}
        {/*            height={"100%"}*/}
        {/*            setSpinner={setSpinner}*/}
        {/*            setStep={setPnpStep}*/}
        {/*        />*/}
        {/*    </StyledFormRow>*/}
        {/*)}*/}
      </>
  );
};

export default ZignsecDialog;
