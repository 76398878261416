import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentServer } from "common/src/helpers/connectors";
import { toast } from "react-toastify";
import { SERVER_SETUP } from "common/src/helpers/constants";

// import Input from "common/src/component/input";
import { StyledFormRow } from "../styledComponents/AccountPage";
import { RegisterStepWrapper } from "../styledComponents/StyledSignupLogin";
import {
  StyledButton,
  StyledFlexBetween,
} from "../styledComponents/CommonStyledComponents";
// import { PageheadlinePar } from "../styledComponents/StyledDepositAndWithdrawal";
import { isMobile } from "react-device-detect";
import config from "hyc/src/config.json";

const RegisterStepIdent = ({
  errors,
  touched,
  formData,
  navigation,
  handleChange,
  handleBlur,
  //handlePhoneChange,
  //handleOnPhoneBlur,
  setTouchedOnRest,
  setSpinner,
  skin,
  lookup,
}) => {
  const { identID } = formData;
  const { previous, next, pause } = navigation;
  const { t } = useTranslation("");
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const [token, setTokenZ] = useState("");
  const [order, setOrder] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [zignsecStep, setZignsecStep] = useState(0);
  const [zignsecIframeSrc, setZignsecIframeSrc] = useState("")
  let timer = null;
  //let isValid = false;
  // const isUK = country === "gb" ? true : false;
  // const nextStep = (currency === "sek" || country === "se") ? "stepUK" : "stepGDPR";
  const paramsForCheck = ["identID"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //load zignsec autostart script
    //for stage: https://test-gateway.zignsec.com/ui/bankidse/autostart.js
    //for live: https://gateway.zignsec.com/ui/bankidse/autostart.js

    const script = document.createElement("script");

    script.src = config.zignsecScript;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const handler = (event) => {
      // console.log("FRAME EVENT: " + JSON.stringify(event));
      // console.log(typeof event.data)
      // console.log("origin" + event.origin)
      // console.log("origin 2: " + window.location.origin)
      if (event.origin === window.location.origin) {
        // console.log("same origin!");
        let data = null;
        if (typeof event.data !== "undefined") {
          try {
            data = JSON.parse(event.data);
          } catch (e) {
            data = event.data;
          }
          if (typeof data.message !== "undefined") {
            // console.log(data.message)
            // try {
            let msg = data.message
            if (msg.ltoken === "" && msg.register) {
              setAuthenticated(true);
              if (formData.country !== "se") {
                formData.country = "se";
              }
              if (formData.currency !== "sek") {
                formData.currency = "sek";
              }
              next();
            } else if(typeof msg.ltoken !== "undefined") {
              //step back error
              setSpinner(false);
              setTokenZ("");
              toast.error(t("label_error"));
              if(typeof lookup === "undefined" || !lookup) {
                previous();
              } else {
                window.location.replace("/");
              }
              return false;
            }
            // } catch (e) {
            //   console.log(e);
            // }
          } else {
            setSpinner(false);
            setTokenZ("");
            return false;
          }
        }
      }
    };

    window.addEventListener("message", handler);

    // clean up
    return () => window.removeEventListener("message", handler);
  }, []); // empty array => run only once

  //v5 and before
  useEffect(() => {
    setSpinner(true);
    PaymentServer.post("/fhnd/v1/zignsec/check", {
      ip: serverSetup.geoIP.ipAddress,
      isMobile: isMobile,
      locale: serverSetup.currentLanguage,
      lookup: true
    })
        .then((res) => {
          // console.log("success: " + JSON.stringify(res.data));
          if(res.data.version && res.data.version !== "v5") {
            //show zignsec
            setZignsecStep(1);
            setSpinner(false)
          } else if(res.data.redirect && res.data.redirect.length > 0) {
            //show iframe:
            //set iframe src
            setZignsecIframeSrc(res.data.redirect)
            //set step to show iframe
            setZignsecStep(2);

            setSpinner(false)
          } else {
            setSpinner(false)
          }
        })
        .catch((err) => {
          //step back on error
          // setPnpStep(0);
          // console.log(err)
          toast.error(t(err.response.data[0]));
          setSpinner(false);
          return false
          // return false;
        });
  }, []);

  /* on next check mandatory fields */
  const checkIsValid = () => {
    setTouchedOnRest(paramsForCheck);
    let hasErrors = 0;
    errors &&
      paramsForCheck.forEach((p) => {
        for (let key of Object.keys(errors)) {
          if (p === key) {
            hasErrors++;
          }
        }
      });
    if (hasErrors === 0) {
      return true;
    } else {
      return false;
    }
  };

  const zignsecAuth = () => {
    // console.log("submit form:" + JSON.stringify(data));
    // console.log(formData.identID);
    if (formData.identID !== "") {
      setSpinner(true);
      delete errors.identID;
      PaymentServer.post("/fhnd/v1/zignsec/authenticate", {
        ip: serverSetup.geoIP.ipAddress,
        bbid: formData.identID,
      })
        .then((res) => {
          // console.log("success: " + JSON.stringify(res));
          if (typeof res.data.ztoken !== "undefined") {
            //register section
            setTokenZ(res.data.ztoken);
            setOrder(res.data.order);
            setSpinner(false);
          } else {
            //step back error
            // setPnpStep(0);
            setSpinner(false);
            setTokenZ("");
            return false;
          }
        })
        .catch((err) => {
          //step back on error
          // setPnpStep(0);
          toast.error(t(err.response.data[0]));
          setSpinner(false);
          setTokenZ("");
          return false;
        });
    } else {
      errors.identID = "err_empty_national_id";
    }
  };

  const zignsecCheck = () => {
    // console.log("check status!");
    setSpinner(true);

    setTimeout(() => {
      // console.log("run first check");
      timer = setInterval(() => {
        // console.log("interval check");
        collect();
      }, 5000);
    }, 5000);
  };

  function collect() {
    PaymentServer.post("/fhnd/v1/zignsec/collect", {
      ip: serverSetup.geoIP.ipAddress,
      order: order,
      registrationStep: true,
      lookup: typeof lookup !== "undefined" ? lookup : false,
    })
      .then((res) => {
        // console.log("success: " + JSON.stringify(res));
        // console.log("got token: " + res.data)
        if (typeof res.data.ltoken !== "undefined") {
          //register section
          // console.log("return token: " + res.data.ltoken);
          toast.info(t(res.data.message));
          if (res.data.ltoken !== "") {
            // console.log("clear interval");
            setSpinner(false);
            clearInterval(timer);
            // console.log("got natid from zignsec: " + res.data.ltoken);
            // console.log("entered natid: " + formData.identID);
            if (res.data.ltoken === formData.identID) {
              //allow reg
              setAuthenticated(true);
              if (formData.country !== "se") {
                formData.country = "se";
              }
              if (formData.currency !== "sek") {
                formData.currency = "sek";
              }
              next();
            } else {
              toast.error(t("label_error"));
              setTokenZ("");
            }
          }
        } else {
          clearInterval(timer);
          setSpinner(false);
          setTokenZ("");
          toast.error(t("label_error"));
          return;
        }
      })
      .catch((err) => {
        //step back on error
        // setPnpStep(0);
        toast.error(t(err.response.data[0]));
        setSpinner(false);
        clearInterval(timer);
        setTokenZ("");
        return;
      });
  }

  return (
      <>
        {/*{zignsecStep === 1 && (*/}
        {/*  <RegisterStepWrapper>*/}
        {/*    {skin === "rac" && (*/}
        {/*      <PageheadlinePar*/}
        {/*        dangerouslySetInnerHTML={{ __html: t("label_register_account") }}*/}
        {/*      ></PageheadlinePar>*/}
        {/*    )}*/}
        {/*    <StyledFormRow>*/}
        {/*      <Input*/}
        {/*        groupClass="form-group mt-3 mb-3 col-md-12"*/}
        {/*        name="identID"*/}
        {/*        label={"label_national_id"}*/}
        {/*        value={identID}*/}
        {/*        type="text"*/}
        {/*        onChange={handleChange}*/}
        {/*        onBlur={handleBlur}*/}
        {/*        errors={errors.identID && touched.identID ? errors.identID : null}*/}
        {/*        required*/}
        {/*        placeholder={skin !== "rac"}*/}
        {/*      />*/}
        {/*    </StyledFormRow>*/}

        {/*    <StyledFlexBetween>*/}
        {/*      {typeof lookup === "undefined" ||*/}
        {/*        (!lookup && (*/}
        {/*          <StyledButton*/}
        {/*            fourth*/}
        {/*            colfourth={"col-md-4"}*/}
        {/*            type="button"*/}
        {/*            onClick={previous}*/}
        {/*            dangerouslySetInnerHTML={{*/}
        {/*              __html: t("label_go_back", "Previous"),*/}
        {/*            }}*/}
        {/*          ></StyledButton>*/}
        {/*        ))}*/}
        {/*      {authenticated && (*/}
        {/*        <StyledButton*/}
        {/*          btnCol*/}
        {/*          type="button"*/}
        {/*          onClick={() => (checkIsValid() ? next() : pause())}*/}
        {/*          dangerouslySetInnerHTML={{ __html: t("label_next", "Next") }}*/}
        {/*        ></StyledButton>*/}
        {/*      )}*/}
        {/*      {token === "" && !authenticated && (*/}
        {/*        <StyledButton*/}
        {/*          sweVerify*/}
        {/*          btnCol*/}
        {/*          type="button"*/}
        {/*          onClick={zignsecAuth}*/}
        {/*        ></StyledButton>*/}
        {/*      )}*/}
        {/*      {token !== "" && !authenticated && (*/}
        {/*        <StyledButton*/}
        {/*          as="a"*/}
        {/*          regIdin*/}
        {/*          href={"bankid:///?autostarttoken=" + token + "&redirect=null"}*/}
        {/*          onClick={zignsecCheck}*/}
        {/*        >*/}
        {/*          {t("label_bank_id_app", "Bank-ID app")}*/}
        {/*        </StyledButton>*/}
        {/*      )}*/}
        {/*    </StyledFlexBetween>*/}
        {/*  </RegisterStepWrapper>*/}
        {/*)}*/}
        {zignsecStep === 2 && (
          <RegisterStepWrapper>
            <StyledFormRow>
              <iframe
                  id="zignsec-iframe"
                  title="zignsec"
                  src={zignsecIframeSrc}
                  scrolling="no"
                  width={isMobile ? "100vw" : "100%"}
                  height={isMobile ? "100vh" : "100%"}
                  frameBorder="no"
                  style={{ minWidth: "100%", minHeight: isMobile ? "530px" : "735px" }}
              ></iframe>
            </StyledFormRow>
            <StyledFlexBetween>
              {typeof lookup === "undefined" ||
                  (!lookup && (
                      <StyledButton
                          fourth
                          colfourth={"col-md-4"}
                          type="button"
                          onClick={previous}
                          dangerouslySetInnerHTML={{
                            __html: t("label_go_back", "Previous"),
                          }}
                      ></StyledButton>
                  ))}
            </StyledFlexBetween>
          </RegisterStepWrapper>
        )}
      </>
  );
};

export default RegisterStepIdent;
