import styled from "styled-components";
import { ReactComponent as Light } from "../../../plc/src/data/icon-component/light.svg";
import { ReactComponent as LightABC } from "../../../abc/src/data/icon-component/theme_switcher.svg";
import { isMobileOnly, isTablet } from "react-device-detect";
import { is } from "date-fns/locale";

export const StyledLight = styled(Light)`
  cursor: pointer;
  width: 40px;
  fill: var(--icon-bulb);
  margin: 0 12px;
  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const StyledLightABC = styled(LightABC)`
  cursor: pointer;
  width: 40px;
  fill: var(--icon-bulb);
  margin: 0 12px;
  .st0 {
    fill: ${({ theme }) =>
      theme.skin === "rac"
        ? "transparent"
        : theme.current === "dark"
        ? "#14162c"
        : "#E3E6EA"};
  }
  .st1 {
    fill: ${({ theme }) =>
      theme.skin === "rac"
        ? theme.current === "dark"
          ? "#fff"
          : "var(--profileMenu)"
        : "                                                                                                           #1a4294"};
  }
  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const IconBulbHidden = styled.div`
  width: 40px;
  margin: 0 12px;
`;

export const SessionTime = styled.div`
  position: ${({ game, theme }) => {
    if (game) {
      if (
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "qbc"
      ) {
        return `absolute`;
      } else return "";
    } else {
      if (
        // theme.skin === "abc" ||
        theme.skin === "pub" ||
        theme.skin === "nbc" ||
        theme.skin === "yet"
      ) {
        return ``;
      } else return `absolute`;
    }
  }};
  font-weight: ${({ theme }) => theme.skin === "abc" && "600"};
  div {
    width: ${({ theme, isSv }) =>
      theme.skin === "abc" ||
      theme.skin === "qbc" ||
      ((theme.skin === "cac" || theme.skin === "hyc") && !isSv)
        ? (theme.skin === "cac" ||
            theme.skin === "hyc" ||
            theme.skin === "abc" ||
            theme.skin === "qbc") &&
          isMobileOnly
          ? "100%"
          : "130px"
        : theme.skin === "pub" && !isMobileOnly
        ? "130px"
        : ""};
    font-size: ${({ theme }) => (theme.skin === "pub" ? "0.8rem" : "")};
    line-height: ${({ theme }) => (theme.skin === "pub" ? "0.5rem" : "")};
    letter-spacing: ${({ theme }) => (theme.skin === "pub" ? "-0.43px" : "")};
    white-space: nowrap;
    height: ${({ theme }) =>
      theme.skin === "nbc" || theme.skin === "yet"
        ? isMobileOnly
          ? "10px"
          : "16px"
        : ""};
  }
  p {
    color: inherit;
    font-size: ${({ theme }) => (theme.skin === "pub" ? "0.53rem" : "inherit")};
    font-weight: ${({ theme }) => (theme.skin === "abc" ? "600" : "500")};
    text-transform: uppercase;
    letter-spacing: ${({ theme }) => (theme.skin === "pub" ? "-0.29px" : "")};
    line-height: ${({ theme, game }) =>
      (theme.skin === "cac" || theme.skin === "hyc") && game && "0"};
  }
  left: ${({ game, theme }) =>
    game
      ? "50%"
      : theme.skin === "fun"
      ? "250px"
      : theme.skin === "yak" || theme.skin === "abc" || theme.skin === "qbc"
      ? ""
      : theme.skin === "rac"
      ? "190px"
      : "120px"};
  right: ${({ theme, player }) =>
    theme.skin === "yak"
      ? "350px"
      : theme.skin === "abc" || theme.skin === "qbc"
      ? player
        ? "310px"
        : "200px"
      : ""};
  color: ${({ theme }) =>
    theme.skin === "fun"
      ? "var(--smallHeaderABC)"
      : theme.skin === "nbc"
      ? "#977ea2"
      : theme.skin === "abc"
      ? "#93959f"
      : theme.skin === "pub"
      ? "#acacac"
      : "var(--showall)"};
  text-transform: capitalize;
  font-size: ${({ theme }) =>
    (theme.skin === "nbc" ||
      theme.skin === "fun" ||
      (theme.skin === "yet" && isTablet) ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak") &&
    isMobileOnly
      ? "0.5rem"
      : "0.6rem"};
  line-height: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc") && "0"};
  top: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc") && "30px"};
  flex-basis: ${({ theme, game }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "qbc") &&
    !game
      ? "100%"
      : ""};
  width: ${({ theme, game, player }) =>
    theme.skin === "nbc" || theme.skin === "yet"
      ? game
        ? "300px"
        : isMobileOnly && player
        ? "150px"
        : "0"
      : theme.skin === "abc" || theme.skin === "qbc"
      ? isMobileOnly
        ? "100%"
        : ""
      : ""};
  text-align: ${({ theme, game }) =>
    theme.skin === "nbc" || theme.skin === "yet"
      ? game
        ? "center"
        : "right"
      : (theme.skin === "abc" || theme.skin === "qbc") && isMobileOnly
      ? "center"
      : ""};
  padding: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "qbc"
      ? "0.2rem 0"
      : ""};
  transform: ${({ theme, game }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "qbc") &&
    game &&
    "translateX(-50%)"};
  top: ${({ theme, game }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "qbc") &&
    game &&
    "2.5rem"};
  [title]:hover {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    display: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "qbc"
        ? "block"
        : "inherit"};
    right: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "qbc" ? "0" : "20px"};
    left: auto;
    top: ${({ theme }) =>
      theme.skin === "fun" || theme.skin === "yak"
        ? "0"
        : theme.skin === "abc"
        ? "0.4rem"
        : "0.2rem"};
    margin: ${({ theme }) => (theme.skin === "pub" ? "" : "auto")};
    font-size: ${({ theme }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "qbc") &&
      "0.7rem"};
    align-items: ${({ theme }) =>
      (theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak") &&
      isMobileOnly
        ? "baseline"
        : ""};
    div {
      margin-left: ${({ theme }) =>
        theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
          ? "0px"
          : "0.5rem"};
      font-size: ${({ theme }) => theme.skin === "pub" && "0.6rem"};
      width: ${({ theme }) => theme.skin === "pub" && "110px"};
    }
  }
  @media screen and (device-aspect-ratio: 40/71) {
    div {
      margin-left: 0;
    }
  }
  @media (min-width: 1024px) and (max-width: 1366px) {
    transform: ${({ theme, game }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "qbc") &&
      game &&
      "translateX(-80%)"};
    width: ${({ theme, game }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "qbc") &&
      game &&
      "230px"};
  }
  @media (min-width: 1200px) {
    p {
      line-height: ${({ theme }) =>
        (theme.skin === "abc" || theme.skin === "qbc") && "14px"};
    }
  }
`;

export const LanguageSelect = styled.select`
  background-color: transparent;
  outline: none;
  border: none;
  color: #fff;
  font-size: inherit;
  -moz-appearance: ${({ theme }) =>
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak"
      ? ""
      : "none"};
  -webkit-appearance: ${({ theme }) =>
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak"
      ? ""
      : "none"};
  background-color: var(--racProfileMenuCol);
  color: ${({ theme }) =>
    theme.skin === "nbc" || theme.skin === "cac" || theme.skin === "hyc"
      ? "#fff"
      : theme.skin === "rac"
      ? "var(--profileMenu)"
      : "var(--headerbtn)"};
  border-radius: ${({ theme }) =>
    theme.skin === "fun" || theme.skin === "cac" || theme.skin === "yak"
      ? "20px"
      : theme.skin === "hyc"
      ? "10px"
      : theme.skin === "nbc" || theme.skin === "yet"
      ? "0.5rem"
      : "5px"};
  padding: ${({ theme, userInfoProp }) =>
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "rac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak"
      ? (theme.skin === "fun" || theme.skin === "yak") && userInfoProp
        ? "14px"
        : userInfoProp
        ? "10px"
        : "7px"
      : "5px"};
  text-transform: uppercase;
  font-weight: ${({ theme }) =>
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "500"
      : "700"};
  margin-top: ${({ userInfoProp, theme }) =>
    userInfoProp && theme.skin === "nbc" ? "3px" : userInfoProp && "7px"};
  margin: ${({ theme }) => (theme.skin === "rac" ? "2px 5px" : "0 5px")};
  border: ${({ theme }) =>
    theme.skin === "nbc"
      ? "1px solid #977ea2"
      : theme.skin === "cac" || theme.skin === "qbc"
      ? "1px solid #979797"
      : ""};
  cursor: pointer;
  text-align: center;
  transition: background-color 300ms;

  :hover {
    background-color: ${({ theme }) =>
      theme.skin === "fun"
        ? "#ffdba8"
        : theme.skin === "cac"
        ? "#322d2e"
        : theme.skin === "hyc"
        ? "#211b48"
        : theme.skin === "yak"
        ? "#7b38ce"
        : theme.skin === "rac"
        ? "#3300d1"
        : "#21729c"};
  }

  option {
    background-color: ${({ theme }) =>
      theme.skin === "nbc"
        ? "#481a4b"
        : theme.skin === "yak"
        ? "var(--racProfileMenuCol)"
        : theme.skin === "fun"
        ? "#f0d2a7"
        : theme.skin === "cac"
        ? "#1E1D19"
        : theme.skin === "hyc"
        ? "#000"
        : theme.skin === "rac"
        ? "var(--headerbtn)"
        : "#11628d"};
    color: ${({ theme }) =>
      theme.skin === "fun" ? "var(--headerbtn)" : "#fff"};
    text-align: left;
  }

  /* &::-ms-expand {
    display: none;
  } */
`;
