import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
  RegisterCloseButton,
  ModalLogoWrapper,
  ModalLogoABC,
  ModalLogoPUB,
  ModalLogoFUN,
  ModalLogoCAC,
  ModalLogoHYC,
  ModalLogoYAK,
  ModalLogoYET,
  ModalLogoPLC,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const NoFreePlayUKDialog = ({
  showModal,
  setModal,
  handleCancel,
  skin,
}) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      <RegisterCloseButton onClick={() => setModal(false)} />
      <ModalLogoWrapper>
        {skin === "plc" && <ModalLogoPLC />}
        {skin === "abc" && <ModalLogoABC />}
        {skin === "pub" && <ModalLogoPUB />}
        {skin === "yet" && <ModalLogoYET />}
        {skin === "fun" && <ModalLogoFUN />}
        {skin === "cac" && <ModalLogoCAC />}
        {skin === "hyc" && <ModalLogoHYC />}
        {skin === "yak" && <ModalLogoYAK />}
      </ModalLogoWrapper>
      <StyledModalBody register>{t("label_in_order_to_play")}</StyledModalBody>
      <StyledModalFooter position>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleCancel()}
        >
          {t("label_ok")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
