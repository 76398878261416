import styled from "styled-components";
import React from "react";
import { Loader } from "../helpers/loader";

const SpinnerLoaderABC = () => {
  return (
    <FullPageOverlay>
      <Loader />
    </FullPageOverlay>
  );
};

export default SpinnerLoaderABC;

//Styled Components

const FullPageOverlay = styled.div`
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9;
`;
