import React from "react";
import { useTranslation } from "react-i18next";
// import { useApolloClient } from "@apollo/client";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
  LookupListItem,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const UkLookupDialog = ({
  showModal,
  setShowModal,
  allLookupData,
  setLookupData,
  skin,
}) => {
  const { t } = useTranslation("");
  // const apolloClient = useApolloClient();
  // console.log("SHOW MODAL: " + JSON.stringify(showModal))
  // const [showModel, setShowModel] = useState(showModal);
  // console.log("SHOW MODEL: " + JSON.stringify(showModel))

  const handleCancel = () => {
    setShowModal(false);
  };

  const choseAddress = (item) => {
    setLookupData(item);
    setShowModal(false);
  };

  return (
    <StyledModal isOpen={showModal}>
      {allLookupData && Object.keys(allLookupData).length > 0 && (
        <StyledModalBody position>
          <h2
            dangerouslySetInnerHTML={{
              __html: t("label_please_select_address"),
            }}
          ></h2>
          {allLookupData.map((item) => {
            let result = "";
            let comma = "";
            if (item.number !== "") {
              result = result + comma + item.number;
              comma = ", ";
              if (item.street !== " ") {
                result = result + " " + item.street;
              }
            }

            if (item.parish !== "") {
              result = result + comma + item.parish;
              comma = ", ";
            }

            if (item.city !== "") {
              result = result + comma + item.city;
              comma = ", ";
            }

            if (item.abode !== "") {
              result = result + comma + item.abode;
              // comma = ", ";
            }

            return (
              <LookupListItem
                key={item.number}
                onClick={() => choseAddress(item)}
              >
                {result}
              </LookupListItem>
            );
          })}
        </StyledModalBody>
      )}
      <StyledModalFooter position>
        <StyledButton
          dialog
          pub={skin === "pub"}
          onClick={() => handleCancel()}
        >
          {t("label_cancel")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
