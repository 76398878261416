import { useState, useEffect, useRef, lazy, useCallback, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import { Switch, useLocation, useHistory } from "react-router-dom";
import { getToken, destroySession, setToken } from "common/src/helpers/session";
import Route from "./Route";
import { matchPath } from "react-router";
import { useQuery } from "@apollo/client";
import Cookies from "js-cookie";
import { getDiff } from "common/src/helpers/functions";
import {
  QUERY_QUERY_SERVER,
  QUERY_ME,
  QUERY_GET_BALANCE,
  QUERY_GET_ACTIVE_BONUSES,
  QUERY_CANCEL_ALL_BONUSES,
  TIME_LIMIT_REFRESH,
  QUERY_PRAGMATIC_RTP_CONFIRMED,
  QUERY_NETT_LIMIT,
} from "common/src/graphql/queries";
import {
  MUTATION_ACK_RESPONSIBLE_GAMING_THRESHOLD,
  MUTATION_UPDATE_PROFILE,
  MUTATION_LOGIN_AS_PLAYER,
  MUTATION_SET_NETT_DEPOSIT_LIMIT,
} from "common/src/graphql/mutations";
import {
  AFFORDABILITY_FORM,
  SERVER_SETUP,
  LOGIN_FLAG,
  REFRESH_APP_POPUP,
} from "common/src/helpers/constants";
import {
  gameCategoryToRoute,
  cleanLocalStorage,
} from "common/src/helpers/functions";
import configData from "../config.json";
import { isMobile, isMobileOnly, isTablet, getUA } from "react-device-detect";
import ProtectedRoute from "./protectedRoute";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GDPRDialog } from "common/src/modal/GDPRDialog";
import { IdleTimer } from "common/src/helpers/IdleTimer";
import Activation from "../pages/Activation";
//style
import "../data/scss/components/main-bg.scss";
import "react-toastify/dist/ReactToastify.css";

// Import components
import Header from "common/src/component/Header";
import Search from "common/src/component/Search";
import Footer from "common/src/component/Footer";
import CookiesConfirmationDialog from "common/src/component/CookiesConfirmationDialog";
import Dresponse from "common/src/component/Dresponse";
// import Test from "../pages/Test";
import GamesCategory from "common/src/component/GamesCategory";
import { RGV2 } from "common/src/helpers/rgv2";

import { COOKIES_CONFIRMATION, THEME } from "common/src/helpers/constants";
import ChangePassword from "../pages/ChangePassword";
import Cashback from "../pages/Cashback";
import Bonuses from "../pages/Bonuses";
import CasinoHistory from "../pages/CasinoHistory";
import MyLimits from "../pages/MyLimits";
import Verification from "../pages/Verification";
import { TermsAlertDialog } from "common/src/modal/TermsAlertDialog";
import { LogoutDialog } from "common/src/modal/LogoutDialog";
import { RGDialog } from "common/src/modal/RGDialog";
/* import { RealityCheckDialog } from "../modal/RealityCheckDialog"; */
import Landing from "../pages/Landing";
import { PaymentServer } from "common/src/helpers/connectors";
import { WithdrawAlertDialog } from "common/src/modal/WithdrawAlertDialog";
import { SweLimitDialog } from "common/src/modal/SweLimitDialog";
import ExitGame from "../pages/ExitGame";
import TrackingPixels from "common/src/component/TrackingPixels";
import { RegisterNowDialog } from "common/src/modal/RegisterNowDialog";
import IdinRedirect from "common/src/component/IdinRedirect";
import UserInfo from "common/src/component/UserInfo";
import Transactions from "../pages/Transactions";
// import { NlLimitDialog } from "common/src/modal/NlLimitDialog";
/* import { NlLogoutLimitDialog } from "common/src/modal/NlLogoutLimitDialog"; */
import { SessionLimitDialog } from "common/src/modal/SessionLimitDialog";
import { ThemeProvider } from "styled-components";
import Profile from "../pages/Profile";
import { MainBg } from "../components/MainBg";
// Import routes here
import Logout from "common/src/component/Logout";
import Index from "../pages/Index";
import Games from "../pages/Games";
/* import GameWindow from "../pages/GameWindow"; */
import HooyuRedirect from "common/src/component/HooyuRedirect";
import { io } from "socket.io-client";
import { PleaseReloadPageDialog } from "common/src/modal/PleaseReloadPageDialog";
import SpinnerLoaderABC from "../components/SpinnerLoaderABC";
import { BonusAvailableDialog } from "common/src/modal/BonusAvailableDialog";
import Maintenance from "../pages/Maintenance";
import { TimeLimitDialog } from "common/src/modal/TimeLimitDialog";
import MobileNavMenu from "common/src/component/MobileNavMenu";
import { AffordabilityDialog } from "common/src/modal/AffordabilityDialog";
import { FinishAffAndEdd } from "common/src/modal/FinishAffAndEdd";
import { FinishAffAndEddSup } from "common/src/modal/FinishAffAndEddSup";
import { FailingWithdrawalDialog } from "common/src/modal/FailingWithdrawalDialog";
import { RtpUkDialog } from "common/src/modal/RtpUkDialog";
import { SetLimitsDialog } from "common/src/modal/SetLimitsDialog";
import config from "../config.json";
import { QueueDialog } from "common/src/modal/QueueDialog";
import { ConfirmCancelBonusDialog } from "common/src/modal/ConfirmCancelBonusDialog";
import { NettLimitDialog } from "common/src/modal/NettLimitDialog";
import EcospendRedirect from "common/src/component/EcospendRedirect";

const News = lazy(() => import("common/src/component/News"));
const Safety = lazy(() => import("../pages/Safety"));
//const Activation = lazy(() => import('../pages/Activation'));
/* const Games = lazy(() => import("../pages/Games")); */
const GameWindow = lazy(() => import("../pages/GameWindow"));
const Error = lazy(() => import("../pages/Error"));
const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register"));
const Deposit = lazy(() => import("../pages/Deposit"));
const Spain = lazy(() => import("../pages/Spain"));
const Russia = lazy(() => import("../pages/Russia"));

const PnpFinished = lazy(() => import("../pages/PnpFinished"));

//  Import pages lazy
const Support = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Support")), 300);
  });
});
/* const AntiWitwassen = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/AntiWitwassen")), 300);
  });
}); */
const Handelingsbekwaam = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Handelingsbekwaam")), 300);
  });
});
const NewsAndPromotions = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/NewsAndPromotions")), 300);
  });
});
const SportsNews = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/SportsNews")), 300);
  });
});
const PrivacyPolicy = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/PrivacyPolicy")), 300);
  });
});
const ResponsibleGaming = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/ResponsibleGaming")), 300);
  });
});
const Payments = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Payments")), 300);
  });
});
const TermsAndConditions = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/TermsAndConditions")), 300);
  });
});
const SportsTermsAndConditions = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/SportsTermsAndConditions")), 300);
  });
});
const AboutUs = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/AboutUs")), 300);
  });
});
const Faq = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Faq")), 300);
  });
});
const Bonus = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Bonus")), 300);
  });
});
const Tournaments = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Tournaments")), 300);
  });
});
const Jackpots = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Jackpots")), 300);
  });
});
const Complaints = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Complaints")), 300);
  });
});

const LiveCasino = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/LiveCasino")), 300);
  });
});

// Import Constants
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const Withdraw = lazy(() => import("../pages/Withdraw"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const MyAccount = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/MyAccount")), 300);
  });
});

let pages = { index: "/index" };
let titles = {};
let names = {};

const Routes = () => {
  // const history = useHistory();

  let location = useLocation();
  let currency = configData.defaultCurrency;
  const apolloClient = useApolloClient();
  const { t, i18n } = useTranslation("");
  const baseUrl = `/${i18n.language || window.localStorage.i18nextLng || "nl"}`;

  //Player
  const [player, setPlayer] = useState("");
  const [grossResults, setGrossResults] = useState(0);
  const [balance, setBalance] = useState({});
  const [wagering, setWagering] = useState([]);
  const [hasWithdraw, setHasWithdraw] = useState(false);
  const [riskyCountry, setRiskyCountry] = useState(false);
  const [bannedDepositPage, setBannedDepositPage] = useState(false);
  const [bannedWithdrawalPage, setBannedWithdrawalPage] = useState(false);
  const [bannedLiveCasinoPage, setBannedLiveCasinoPage] = useState(false);
  const [verificationRedirect, setVerificationRedirect] = useState(false);
  const [bannedCasinoPage, setBannedCasinoPage] = useState(false);
  const [bannedSportsbookPage, setBannedSportsbookPage] = useState(false);
  const [showGDPRDialog, setShowGDPRDialog] = useState(false);
  /*  const [showRealityCheckDialog, setshowRealityCheckDialog] = useState(false);
  const [rcValue, setRcValue] = useState({}); */
  const [showSessionLimitDialog, setShowSessionLimitDialog] = useState(false);
  const [depositList, setDepositList] = useState([]);
  const [firstDeposit, setFirstDeposit] = useState(null);
  // const [withdrawalList, setWithdrawalList] = useState([]);
  const [showSetLimitsDialog, setShowSetLimitsDialog] = useState(false);
  const [showBonusAvailableDialog, setShowBonusAvailableDialog] =
    useState(false);
  // const [pagesList, setPagesList] = useState({});

  //socket dialog
  const [isOpenRGV, setIsOpenRGV] = useState(false);
  const [RGVPopupData, setRGVPopupData] = useState({});
  const [RGVType, setRGVType] = useState("");
  const [pleaseLogoutDialog, setPleaseLogoutDialog] = useState(false);
  const [termsAlertDialog, setTermsAlertDialog] = useState(false);
  // console.log("BALANCE: " + JSON.stringify(balance))
  const [rtpUkPopup, setRtpUkPopup] = useState(false);

  //pending withdrawal dialog
  const [showWithdrawAlertDialog, setShowWithdrawAlertDialog] = useState(false);

  //failing bank withdrawal dialog
  const [failingBankAlertDialog, setFailingBankAlertDialog] = useState(false);

  //swe limits dialog
  const [sweLimitDialog, setSweLimitDialog] = useState(false);
  //nl limits dialog
  //const [nlLimitDialog, setNlLimitDialog] = useState(false);
  /* const [nlLogoutLimitDialog, setNlLogoutLimitDialog] = useState(false); */

  //affordability limit popup
  const [affordabilityPopup, setAffordabilityPopup] = useState("");
  const [affordabilityPopupLimit, setAffordabilityPopupLimit] = useState(0);

  //mobile gameplay
  const [mobileGameplay, setMobileGameplay] = useState(false);

  //ws
  const [socket, setSocket] = useState(null);
  // let realityTimer = false;
  const [disableSocket, setDisableSocket] = useState(false);

  //spinner
  const [spinner, setSpinner] = useState(false);

  const [pxDeposit, setPxDeposit] = useState(false);
  const [pxRegister, setPxRegister] = useState(false);
  const [pxRegisterStart, setPxRegisterStart] = useState(false);
  const [pxDepositStart, setPxDepositStart] = useState(false);

  // user Expand div
  const [headerUserExpand, setHeaderUserExpand] = useState(false);

  //sidepanel
  const [sidepanel, setSidepanel] = useState(false);

  const [showEndSession, setShowEndSession] = useState(false);

  // toogle profile menu
  const [profileMenu, setProfileMenu] = useState(false);
  const [showRegisterNowDialog, setShowRegisterNowDialog] = useState(false);
  const [showFinishAffAndEdd, setShowFinishAffAndEdd] = useState(false);
  const [showFinishAffAndEddSup, setShowFinishAffAndEddSup] = useState(false);

  const [showSearch, setShowSearch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMyAccount, setisMyAccount] = useState(false);
  const [currentTheme, setTheme] = useState(
    "dark"
    // localStorage.getItem(THEME) || "light"
  );

  const [dashboardState, setDashboardState] = useState({
    categories: null,
    games: null,
  });
  // category
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [providerSearchFlag, setProviderSearchFlag] = useState(false);

  //MobileWallet
  const [expandWallet, setExpandWallet] = useState(false);

  //Cancel active bonus dialog
  const [showConfirmCancelBonusDialog, setShowConfirmCancelBonusDialog] =
    useState(false);

  // Nett Limit dialog
  const [showNettLimitDialog, setShowNettLimitDialog] = useState(false);

  //idle
  //const [showModal, setShowModal] = useState(false);
  const idleTimerRef = useRef(null);
  const idlePlayerTimerRef = useRef(null);

  useEffect(() => {
    console.log("cache reset");
    apolloClient.cache.reset();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      apolloClient.cache.reset();
    }, 600000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  //Queue dialog
  const [showQueueDialog, setShowQueueDialog] = useState(false);

  const history = useHistory();

  /* const redirectTo = configData.skin === "plc" ? "index" : "games"; */

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", idleTimerRef.current.getLastActiveTime());
    destroySession();
    cleanLocalStorage();
    /* setNlLogoutLimitDialog(true); */
    window.location = `/${currentLanguage}/logout`;
  };

  const theme = useMemo(
    () => ({ current: currentTheme, skin: configData.skin }),
    [currentTheme]
  );

  //New App verson
  const [newAppVersion, setNewAppVersion] = useState(
    localStorage.getItem(REFRESH_APP_POPUP)
  );

  useEffect(() => {
    if (localStorage.getItem(REFRESH_APP_POPUP) === "true") {
      setNewAppVersion(true);
    }
  }, [localStorage.getItem(REFRESH_APP_POPUP)]);

  /* useEffect(() => {
    // Load script for SportsBook
    // console.log('load script')
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = config.sportsBookScript;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []); */

  const handlePlayerOnIdle = () => {
    //console.log("user is idle");
    setShowRegisterNowDialog(true);
    localStorage.setItem("RN", true);
  };
  /* const removeRegisterNowDialog = () => {
    //localStorage.setItem("RN", true);
    setShowRegisterNowDialog(false);
    setIsOpen(!isOpen);
  }; */
  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", idleTimerRef.current.getRemainingTime());
  };

  /* const handleOnAction = (e) => {
    console.log('user did something', e);
  };  */

  /*  const handleClose = () => {
    setShowModal(false);
  }; */

  const getBalance = useCallback(async () => {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_BALANCE,
      // fetchPolicy: "cache-first",
    });
    if (loading) setBalance({ loading: true });
    if (
      errors &&
      (errors[0].message === "error_token_not_provided" ||
        errors[0].message === "error_wrong_token" ||
        errors[0].message === "error_invalid_session_token" ||
        errors[0].message === "error_invalid_token")
    ) {
      /* setNlLogoutLimitDialog(true); */
      //destroySession();
      //window.location.replace(baseUrl + `/index`);
      console.log("getBalance token error");
    }
    //console.log("balans " + data.getBalance.withdrawalID);
    else {
      setBalance({ balance: data.getBalance, loading: false });
      setHasWithdraw(
        data.getBalance && (data.getBalance.withdrawalID !== "" ? true : false)
      );
    }
  }, [apolloClient]);

  const getWageringBonus = useCallback(async () => {
    //QUERY_GET_ACTIVE_BONUSES
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_ACTIVE_BONUSES,
    });
    if (loading) return <h3>Loading ...</h3>;
    if (
      errors &&
      (errors[0].message === "error_token_not_provided" ||
        errors[0].message === "error_wrong_token" ||
        errors[0].message === "error_invalid_session_token" ||
        errors[0].message === "error_invalid_token")
    ) {
      /* setNlLogoutLimitDialog(true); */
      console.log("getWageringBonus token error");
      //destroySession();
      //window.location.replace(baseUrl + `/index`);
      return false;
    } else setWagering(data.getActiveBonuses.bonuses);
  }, [apolloClient]);

  const getTimelimitRefresh = useCallback(async () => {
    // eslint-disable-next-line
    const { loading, errors, data } = await apolloClient.query({
      query: TIME_LIMIT_REFRESH,
    });
    if (loading) return "Loading ...";
    if (
      errors &&
      (errors[0].message === "error_daily_limit_reach" ||
        errors[0].message === "error_weekly_limit_reach" ||
        errors[0].message === "error_monthly_limit_reach")
    ) {
      setShowEndSession(true);
      setTimeout(function () {
        console.log("----- time limit reach ------");
        destroySession();
        cleanLocalStorage();
        // localStorage.clear();
        window.location.reload(true);
      }, 3000);
    } else if (
      errors &&
      (errors[0].message === "error_token_not_provided" ||
        errors[0].message === "error_wrong_token" ||
        errors[0].message === "error_invalid_session_token" ||
        errors[0].message === "error_invalid_token")
    ) {
      /* setNlLogoutLimitDialog(true); */
      console.log("getTimelimitRefresh token error");
      setTimeout(function () {
        console.log("----- wrong token ------");
        destroySession();
        cleanLocalStorage();
        // localStorage.clear();
        window.location.reload(true);
      }, 3000);
    }
    //console.log(data);
  }, [apolloClient]);

  useEffect(() => {
    setSidepanel(false);
    /* if (
      player &&
      localStorage.getItem("bonuses-available") !== "hidden" &&
      localStorage.getItem("bonuses-available") !== "temp_hidden"
    ) {
      setShowBonusAvailableDialog(true);
    } */
  }, [location, player]);

  async function getNettLimit() {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_NETT_LIMIT,
    });
    if (loading) {
      setSpinner(true);
    }
    if (errors) {
      setShowNettLimitDialog(true);
    }
    if (data) {
      if (data.getNettDepositLimit.limitType.length === 0) {
        setShowNettLimitDialog(true);
      }
    }
    setSpinner(false);
    return null;
  }

  async function handleNettLimitProceed() {
    //console.log(uid);
    const { loading, errors } = await apolloClient.mutate({
      mutation: MUTATION_SET_NETT_DEPOSIT_LIMIT,
      variables: {
        limitType: "incl",
      },
    });

    if (loading) {
      toast.loading("Please wait...", {
        position: "bottom-center",
      });
      setSpinner(true);
    }
    if (errors) {
      toast.error(errors[0].message, {
        position: "bottom-center",
      });
      return null;
    }
    if (data) {
      toast.success("success", {
        position: "bottom-center",
      });
      setShowNettLimitDialog(false);
    }
    setSpinner(false);
    return true;
  }

  const getPlayer = useCallback(async () => {
    try {
      const { errors, data } = await apolloClient.query({ query: QUERY_ME });

      if (
        errors /* &&
        (errors[0].message === "error_token_not_provided" ||
          errors[0].message === "error_wrong_token" ||
          errors[0].message === "error_invalid_session_token" ||
          errors[0].message === "error_invalid_token") */
      ) {
        //setNlLogoutLimitDialog(true);
        console.log("getPlayer token error");
        destroySession();
        window.location.replace(baseUrl + `/index`);
      } else {
        if (data.me.player.country !== "se") {
          getNettLimit();
        }
        setPlayer(data.me.player);
        setGrossResults(data.me.grossResults);
        setBannedDepositPage(data.me.lockStatus.deposit);
        setBannedWithdrawalPage(data.me.lockStatus.withdrawal);
        setBannedLiveCasinoPage(data.me.lockStatus.liveCasino);
        setBannedCasinoPage(data.me.lockStatus.casino);
        setBannedSportsbookPage(data.me.lockStatus.sportsbook);
        setDepositList(data.me.deposits);
        //used for tracking pixels
        localStorage.setItem("pc", data.me.player.country);

        //setting localstorage variable because of the dresponse page, which comes after deposit is completed..
        //..so if dresponse page is opened after callback first deposit will be false
        if (localStorage.getItem("ftd") === "true") {
          // console.log("set first")
          setFirstDeposit(true);
        } else {
          if (data.me.firstDeposit) {
            localStorage.setItem("ftd", "true");
          }
          // console.log("set second")
          setFirstDeposit(data.me.firstDeposit);
        }
        // setWithdrawalList(data.me.withdrawals);
        if (!data.me.player.gdprAccepted) {
          setShowGDPRDialog(true);
        }
        setRiskyCountry(data.me.player.riskyCountry);
        if (data.me.player.requestAffordability === "active") {
          if (
            localStorage.getItem(LOGIN_FLAG) !== null ||
            location.pathname.indexOf("dresponse") > 0
          ) {
            localStorage.setItem(AFFORDABILITY_FORM, true);
            setVerificationRedirect(true);
          }
        }
        localStorage.removeItem(LOGIN_FLAG); //remove login flag on first me query
      }
    } catch (ex) {
      console.log(ex);
    }
  }, [apolloClient, baseUrl, location.pathname]);

  useEffect(() => {
    if (getToken() !== null) {
      getPlayer();
    }
  }, []);

  useEffect(() => {
    if (player) {
      getBalance();
      getWageringBonus();
    }
  }, [player, getBalance, getWageringBonus]);

  useEffect(() => {
    if (
      player &&
      location.pathname.indexOf("dresponse") < 0 &&
      location.pathname.indexOf("hooyuredirect") < 0
    ) {
      const newSocket = io(configData.wsPath, {
        extraHeaders: {
          sessionid: player.sessionID,
          nickname: player.nickName,
        },
      });

      function forceLogout() {
        setPleaseLogoutDialog(true);
        setTimeout(function () {
          console.log("----- redirect force logout socket ------");
          destroySession();
          cleanLocalStorage();
          // localStorage.clear();
          window.location.replace(baseUrl + `/index`);
        }, 3000);
      }
      function refreshPlayerData(/*session_id*/) {
        getPlayer();
      }
      /* const handleLogout = () => {
        //setShowModal(false);
        window.location = baseUrl + `/logout`;
      }; */

      const messageListener = (data) => {
        console.log("socket index " + JSON.stringify(data));

        if (data.allow !== undefined && !data.allow) {
          //session-limit
          setShowSessionLimitDialog(true);
        }

        if (typeof data.action !== "undefined") {
          let action = data.action;
          switch (action) {
            case "disable-window":
              setDisableSocket(true);
              newSocket.disconnect();
              break;
            case "force-logout":
              /* try {
                let msg = JSON.stringify(data);
                PaymentServer.post(
                  "/fhnd/v1/slog/info", {
                    id: player.nickName,
                    sid: player.sessionID,
                    message: msg
                  }
                )
              } catch (e) {

              } */
              if (
                typeof player.sessionID !== "undefined" &&
                player.sessionID === data.session_id
              ) {
                forceLogout();
              }
              break;
            case "refresh-balance":
              console.log("refresh-balance " + JSON.stringify(data));
              getBalance();
              getWageringBonus();
              break;
            case "refresh-deposits":
              //refreshDeposits(data.country, data.currency);
              break;
            case "refresh-withdrawals":
              //refreshWithdrawals(data.country, data.currency);
              break;
            case "online-checker":
              //ne radi nista ali za testiranje poziva moze da posluzi
              //console.log(data.session_id);
              break;
            case "refresh-data":
              if (
                typeof player.sessionID !== "undefined" &&
                player.sessionID === data.session_id
              ) {
                refreshPlayerData(data.session_id);
              }
              break;
            case "responsible-gaming-threshold":
              /* try {
                let msg = JSON.stringify(data);
                PaymentServer.post(
                  "/fhnd/v1/slog/info", {
                    id: player.nickName,
                    sid: player.sessionID,
                    message: msg
                  }
                )
              } catch (e) {

              } */
              if (player.nickName === data.nick_name) {
                console.log("RESPONSIBLE GAMING THRESHOLD: " + data.type);
                const { popupMessage, popupBtn, popupBtn1 } = RGV2(data.type);
                setRGVType(data.type);
                setRGVPopupData({
                  popupMessage: popupMessage,
                  popupBtn: popupBtn,
                  popupBtn1: popupBtn1,
                });
                // localStorage.setItem("redirect-on-deposit", "dont");
                // setIsOpenRGV(true);
              }
              break;
            case "end-session":
              console.log("-------- time limit! ---------");
              /* try {
                let msg = JSON.stringify(data);
                PaymentServer.post(
                  "/fhnd/v1/slog/info", {
                    id: player.nickName,
                    sid: player.sessionID,
                    message: msg
                  }
                )
              } catch (e) {

              } */
              if (
                typeof player.sessionID !== "undefined" &&
                player.sessionID === data.session_id
              ) {
                setShowEndSession(true);
              }
              break;
            case "affordability-form":
              console.log("---- redirect to affordability form----");
              localStorage.setItem(AFFORDABILITY_FORM, true);
              setVerificationRedirect(true); //try it this way
              // window.location.replace(pages["my-verification"]);
              break;
            default:
              break;
          }
        }
      };

      setSocket(newSocket);
      newSocket.on("message", messageListener);
      newSocket.on("connect", () => {
        console.log("socket connect");
        newSocket.emit("disable:close");
      });
      /*   newSocket.on("disconnect", () => {
        console.log("socket disconnected");
      }); */
      newSocket.on("timeout:thick", () => {
        //console.log("tik");
        getTimelimitRefresh();
      });
      return () => newSocket.close();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloClient, baseUrl, player]);

  useEffect(() => {
    const logInPlayerWithToken = async (sessionId) => {
      const { loading, errors, data } = await apolloClient.mutate({
        mutation: MUTATION_LOGIN_AS_PLAYER,
        variables: { sessionID: sessionId },
        errorPolicy: "all",
      });

      if (loading) return "Loading ...";
      if (errors) {
        toast.error(errors[0].message, {
          position: "bottom-center",
        });
        return false;
      }
      return data;
    };
    if (Cookies.get("plCode")) {
      logInPlayerWithToken(Cookies.get("plCode")).then((data) => {
        // console.log(data);
        data && setToken(data.loginAsPlayer.token);
        //console.log("logged in with token" + getToken());
        Cookies.remove("plCode");
        // getPlayer();
        //console.log(data.loginAsPlayer.token);
        // console.log(baseUrl + `/mijn-storten`);
        // console.log(configData.skin);
        // console.log(Cookies.get("activated"));
        // if (configData.skin === "plc" && Cookies.get("activated")) {
        //   console.log("redirect after activation");
        //   window.location.replace(baseUrl + `/register`);
        // } else {
        //   console.log("redirect after backoffice login");
        window.location.replace(pages["index"]);
        // }
      });
    }

    if (player) {
      //uk accepted
      async function pragmaticRTP() {
        const { loading, error } = await apolloClient.query({
          query: QUERY_PRAGMATIC_RTP_CONFIRMED,
        });
        if (loading) return "Loading ...";
        if (error) console.log(error);
      }

      if (player.tc === false && player.country === "gb") {
        setTermsAlertDialog(true);
      }
      /* if (player.country === "gb") {
        if (player.pragmaticRTPNotification === false) {
          if (localStorage.getItem("rtpUkPopup") === "false") {
            setRtpUkPopup(false);
            pragmaticRTP();
          } else {
            localStorage.setItem("rtpUkPopup", true);
            setRtpUkPopup(true);
          }
        }
      } */
    }
  }, [apolloClient, baseUrl, currency, player]);

  useEffect(() => {
    //console.log(location.pathname);
    if (
      location.pathname === pages["my-account"] ||
      location.pathname === pages["my-profile"] ||
      location.pathname === pages["cashback"] ||
      location.pathname === pages["bonuses"] ||
      location.pathname === pages["change-password"] ||
      location.pathname === pages["transactions"] ||
      location.pathname === pages["casino-history"] ||
      location.pathname === pages["my-limits"] ||
      location.pathname === pages["my-verification"]
    ) {
      setisMyAccount(true);
    } else {
      setProfileMenu(false);
      setisMyAccount(false);
    }
  }, [location]);

  //Theme state
  if (localStorage.getItem(THEME) === null) {
    //set DEFAULT theme here!
    localStorage.setItem(THEME, "dark");
  }

  //console.log('THEME: ' + currentTheme);

  //Window height
  const getHeight = () =>
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  // Window height
  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  //Height on window resize
  function useCurrentHeight() {
    let [height, setHeight] = useState(getHeight());

    useEffect(() => {
      let timeout = null;
      const resizeListener = () => {
        clearTimeout(timeout);
        timeout = window.setTimeout(() => setHeight(getHeight()), 150);
      };
      window.addEventListener("resize", resizeListener);

      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }, []);

    return height;
  }
  const pageHeight = useCurrentHeight();

  //Width on window resize
  function useCurrentWidth() {
    let [width, setWidth] = useState(getWidth());

    useEffect(() => {
      let timeout = null;
      const resizeListener = () => {
        clearTimeout(timeout);
        timeout = window.setTimeout(() => setWidth(getWidth()), 150);
      };
      window.addEventListener("resize", resizeListener);

      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }, []);

    return width;
  }
  const pageWidth = useCurrentWidth();

  //mouseflow script
  /* useEffect(() => {
    if (configData.env && configData.env === "production") {
      window._mfq = window._mfq || [];
      (function () {
        var mf = document.createElement("script");
        mf.type = "text/javascript";
        mf.defer = true;
        mf.src =
          "//cdn.mouseflow.com/projects/368afd1d-683c-44eb-9816-4129bcb4decc.js";
        document.getElementsByTagName("head")[0].appendChild(mf);
      })();
    }
  }, []); */
  //mouseflow script on location change
  /* useEffect(() => {
    return history.listen((location) => {
      if (configData.env && configData.env === "production") {
        window._mfq.push(["newPageView", location.pathname]);
      }
    });
  }, [history]); */

  // useEffect(() => {
  //   console.log("use effect")
  //   if (sessionStorage.getItem(AFFORDABILITY_DIALOG)) {
  //     //popup for affordability
  //     setAffordabilityPopup(sessionStorage.getItem(AFFORDABILITY_DIALOG));
  //     sessionStorage.removeItem(AFFORDABILITY_DIALOG);
  //   }
  // }, []);

  // Send graphql to collect server setup
  const { loading, error, data, refetch } = useQuery(QUERY_QUERY_SERVER, {
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first", // Used for subsequent executions
  });
  let serverData;
  const reassign = () => {
    refetch();
  };

  if (loading) return <SpinnerLoaderABC />;
  /* if (IsError) return <div>{`Error: ${error}`}</div>; */
  if (error) {
    console.log(error);
    //calls refetch func on error
    reassign();
    return <SpinnerLoaderABC />;
  }
  if (data) {
    serverData = data.server;
  }

  //console.log(serverData);
  // Prepare route parameters
  serverData && sessionStorage.setObject(SERVER_SETUP, serverData);
  // console.log("-------------- current language: " + data.server.currentLanguage);
  let currentLanguage = serverData && serverData.currentLanguage;
  if (serverData && !localStorage.getItem("pc")) {
    if (serverData.geoIP.isoCode === "") {
      localStorage.setItem("pc", "mt");
    } else {
      localStorage.setItem("pc", serverData.geoIP.isoCode.toLowerCase());
    }
  }

  data &&
    data.server.pages.forEach((page) => {
      if (page.isEnabled) {
        //localized links
        pages[page.name] = "/" + currentLanguage + "/" + page.url;
        //page titles
        titles[page.name] = page.title;
        //page names for each url
        names["/" + currentLanguage + "/" + page.url] = page.name;
      }
    });

  // console.log(location.pathname);
  // console.log(location.pathname.indexOf("aff="))

  let geoip = serverData ? serverData.geoIP.isoCode.toLowerCase() : "";
  let language = configData.defaultLang;

  if (configData.skin === "rac") {
    if (geoip === "de" || geoip === "at" || geoip === "ch") {
      language = "de";
    } else if (geoip === "se") {
      language = "sv";
    } else if (geoip === "no") {
      language = "no";
    } else if (geoip === "fi") {
      language = "fi";
    }
  }

  let pth = location.pathname;
  if (pth === `/` || pth === ``) {
    // console.log("index without language");
    window.location.replace("/" + language + `/index`);
  } else {
    const urlParams = new URLSearchParams(location.search);
    // console.log("aff: " + urlParams.get("aff"));
    if (urlParams.get("aff") !== null) {
      //save cookie
      Cookies.set(
        "affCode" + configData.skin.toUpperCase(),
        urlParams.get("aff"),
        { secure: true }
      );
      // console.log(Cookies.get('affCodeRAC'))
      window.location.replace(pth);
    } else if (urlParams.get("pl") !== null) {
      Cookies.set("plCode", urlParams.get("pl"), { secure: true });
      console.log("set cooke");
      window.location.replace(pth);
    } else if (urlParams.get("token") !== null) {
      Cookies.set("resetToken", urlParams.get("token"), { secure: true });
      window.location.replace(pth);
    }
  }

  function pageExists(page) {
    return typeof pages[page] !== "undefined";
  }

  if (player) currency = player.currency;

  function silentLogout() {
    destroySession();
    window.location.replace(baseUrl + `/index`);
  }

  const confirmRgv2 = async () => {
    //console.log(RGVType);
    const { loading, errors } = await apolloClient.mutate({
      mutation: MUTATION_ACK_RESPONSIBLE_GAMING_THRESHOLD,
      variables: { thresholdType: RGVType },
      errorPolicy: "all",
    });
    if (loading) return "Loading ...";
    if (errors) {
      toast.error(errors[0].message, {
        position: "bottom-center",
      });
      return false;
    } else localStorage.removeItem("redirect-on-deposit");
    if (location.pathname === pages["deposit"]) {
      window.location = `/${currentLanguage}/index`;
    }
    setIsOpenRGV(false);
    return true;
  };

  // Cookies confimed
  const cookiesConfirmed = localStorage.getItem(COOKIES_CONFIRMATION);
  // console.log("Cookies confirmed: " + cookiesConfirmed)

  const toggleSearchShow = (event) => {
    event.preventDefault();
    //console.log('ovo ' + JSON.stringify(event));
    if (event.target === event.currentTarget) setShowSearch(!showSearch);
  };

  const hideSearch = () => {
    setShowSearch(false);
  };

  const acceptUkTerms = async () => {
    //MUTATION_UPDATE_PROFILE
    const { loading, errors } = await apolloClient.mutate({
      mutation: MUTATION_UPDATE_PROFILE,
      variables: { tc: true },
      errorPolicy: "all",
    });

    if (loading) return "Loading ...";
    if (errors) {
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      return false;
    }
    window.location.reload(false);
  };

  const cancelActiveBonuses = async () => {
    //QUERY_CANCEL_ALL_BONUSES
    const { loading, errors } = await apolloClient.query({
      query: QUERY_CANCEL_ALL_BONUSES,
    });
    if (loading) return <h3>Loading ...</h3>;
    if (errors) {
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      return false;
    } else window.location.reload(false);
  };

  const gameCategories =
    data &&
    data.server.gameCategories.map((value) => {
      return `/${currentLanguage}/${gameCategoryToRoute(value.name)}/:gameId`;
    });

  const inGame =
    matchPath(location.pathname, gameCategories) &&
    matchPath(location.pathname, gameCategories).isExact
      ? true
      : false;

  const hideInMobGames =
    inGame &&
    isMobile &&
    location.pathname !== `/${currentLanguage}/sports-book/sports`
      ? false
      : true;

  const cancelWithdrawal = () => {
    if (player.country.toLowerCase() !== "gb") {
      setSpinner(true);
      PaymentServer.delete(
        "/fhnd/v1/transaction/withdrawal/" + balance.balance.withdrawalID
      )
        .then((/*res*/) => {
          // setShowDepositStartingDialog(false);
          // console.log("success: " + JSON.stringify(res));
          // toast.success("success");
          setSpinner(false);
          // console.log("refresh balance!")
          toast.success("success", {
            position: "bottom-center",
          });
          setShowWithdrawAlertDialog(false);
          getBalance();
          return true;
        })
        .catch((error) => {
          // setShowDepositStartingDialog(false);
          //step back on error
          toast.error(t(error[0]), {
            position: "bottom-center",
          });
          setSpinner(false);
          return false;
        });
    }
  };

  const handleGDPRProceed = async (e) => {
    //console.log(e);
    const onlyChanged = getDiff(
      {
        gdpr: player.gdprAccepted,
        receiveNews: player.receiveNews,
        receiveSMS: player.receiveSMS,
      },
      e
    );
    //console.log(onlyChanged);
    const { loading, errors } = await apolloClient.mutate({
      mutation: MUTATION_UPDATE_PROFILE,
      variables: onlyChanged,
      errorPolicy: "all",
    });

    if (loading) return "Loading ...";
    if (errors) {
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      return false;
    } else window.location.reload(false);
  };

  const mobileGame = (e) => {
    setMobileGameplay(e);
    //console.log("jes mobilni " + e);
  };
  let indexExpand = !isTablet ? "index-expand" : "index-expand-tablet";

  /*   const cancelNlLimitDialog = () => {
    if (localStorage.getItem(NL_LIMIT_DIALOG)) {
      localStorage.removeItem(NL_LIMIT_DIALOG);
    }
    setNlLimitDialog(false);
  }; */

  const accountRoute =
    pages &&
    (location.pathname === pages["my-account"] ||
      location.pathname === pages["my-profile"] ||
      location.pathname === pages["cashback"] ||
      location.pathname === pages["bonuses"] ||
      location.pathname === pages["change-password"] ||
      location.pathname === pages["transactions"] ||
      location.pathname === pages["casino-history"] ||
      location.pathname === pages["my-limits"] ||
      location.pathname === pages["my-verification"] ||
      location.pathname === pages["register"] ||
      location.pathname === pages["reset-password"] ||
      location.pathname === pages["forgot-password"] ||
      location.pathname === pages["deposit"] ||
      location.pathname === pages["withdrawal"] ||
      location.pathname === pages["login"]);

  const setSearchResults = (e) => {
    //console.log(e.games.length);
    var gamesByCategory = [];

    gamesByCategory = e.games.reduce((r, a) => {
      r[a.gameType.toLowerCase().replace(" ", "-")] = [
        ...(r[a.gameType.toLowerCase().replace(" ", "-")] || []),
        a,
      ];
      return r;
    }, {});

    let gamesWithCategory = [];

    serverData &&
      serverData.gameCategories.length !== 0 &&
      serverData.gameCategories.forEach((cat /*index*/) => {
        cat.isVisible &&
          gamesWithCategory.push({
            categoryName: cat.name.toLowerCase().replace(" ", "-"),
          });
      });

    gamesWithCategory.map(
      (cat, index) =>
        //console.log(cat.categoryName);
        (gamesWithCategory[index] = {
          ...gamesWithCategory[index],
          games: gamesByCategory[cat.categoryName] || [],
        })
    );
    setDashboardState({
      ...dashboardState,
      // dodaj na listu učitanih igara, u responseu je razlika
      games: gamesWithCategory.length !== 0 ? gamesWithCategory : null,
    });
    //console.log(gamesWithCategory.length !== 0 && gamesWithCategory);

    let firstCategory = gamesWithCategory.filter(
      (category) => category.games.length !== 0
    );
    let firstCategoryName =
      firstCategory.length !== 0 && firstCategory[0]["categoryName"];
    //console.log(firstCategoryName);

    // gameThemeProvider to default
    /* if (configData.skin === "plc" || configData.skin === "pub") {
      setSelectedCategory(firstCategoryName);
    } */
    if (location.pathname === `/${currentLanguage}/index` || accountRoute) {
      setProviderSearchFlag(true);
    }
  };

  const bonusRedirect = () => {
    localStorage.setItem("bonuses-available", "temp_hidden");
    window.location = pages["bonuses"];
  };

  const hideAllways = () => {
    localStorage.setItem("bonuses-available", "hidden");
    setShowBonusAvailableDialog(false);
  };

  const handleTimeLimitProceed = () => {
    setShowEndSession(false);
    window.location = `/` + currentLanguage + `/logout`;
  };

  const handleFinishAffAndEdd = () => {
    if (document.getElementById("scroll-to-aff-from")) {
      document.getElementById("scroll-to-aff-from").scrollIntoView();
    }
    setShowFinishAffAndEdd(false);
  };

  if (verificationRedirect) {
    if (
      typeof pages["my-verification"] !== "undefined" &&
      location.pathname !== pages["my-verification"]
    ) {
      console.log("index redirect verification");
      window.location = pages["my-verification"];
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {spinner && <SpinnerLoaderABC />}
      <ToastContainer />
      {getToken() !== null && !inGame && (
        <>
          <IdleTimer
            ref={idleTimerRef}
            //15miutes kill
            timeout={900000}
            //crossTab
            onActive={(e) => handleOnActive(e)}
            //onAction={(e) => handleOnAction(e)}
            onIdle={(e) => handleOnIdle(e)}
          ></IdleTimer>
        </>
      )}
      {getToken() === null &&
        location.pathname === pages["index"] &&
        serverData.geoIP.isoCode !== "SE" &&
        serverData.geoIP.isoCode !== "FI" &&
        localStorage.getItem("RN") === null && (
          <IdleTimer
            ref={idlePlayerTimerRef}
            onIdle={(e) => handlePlayerOnIdle(e)}
            timeout={20000}
            stopOnIdle={true}
          />
        )}
      {location.pathname.indexOf("dresponse") > 0 && (
        <Switch>
          <Route
            exact
            path={`/${currentLanguage}/dresponse/:depositType/:dStatus/:dRedirect`}
            render={(props) => (
              <Dresponse
                {...props}
                pages={pages}
                setPxDeposit={setPxDeposit}
                skin={configData.skin}
              />
            )}
          />
        </Switch>
      )}
      {location.pathname.indexOf("dresponse") > 0 && (
        <Switch>
          <Route
            exact
            path={`/${currentLanguage}/dresponse/:depositType/:dStatus/:dRedirect/:transactionID`}
            render={(props) => (
              <Dresponse
                {...props}
                pages={pages}
                setPxDeposit={setPxDeposit}
                skin={configData.skin}
              />
            )}
          />
        </Switch>
      )}
      {location.pathname.indexOf("idinredirect") > 0 && (
        <Switch>
          <Route
            exact
            path={`/${currentLanguage}/idinredirect/:trxid/:ec`}
            // component={IdinRedirect}
            render={(props) => (
              <IdinRedirect {...props} pages={pages} setSpinner={setSpinner} />
            )}
          />
        </Switch>
      )}
      {location.pathname.indexOf("hooyuredirect") > 0 && (
        <Switch>
          <Route
            exact
            render={(props) => (
              <HooyuRedirect {...props} setSpinner={setSpinner} pages={pages} />
            )}
          />
        </Switch>
      )}
      {location.pathname.indexOf("ecospendredirect") > 0 && (
          <Switch>
            <Route
                exact
                path={`/${currentLanguage}/ecospendredirect/:ecStatus/:ecDataLinkID/:ecConsentID/:ecBankRererenceID`}
                render={(props) => (
                    <EcospendRedirect {...props} />
                )}
            />
          </Switch>
      )}
      {getToken() !== null && serverData && (
        <div
          className={
            headerUserExpand
              ? isMyAccount
                ? indexExpand + " account"
                : indexExpand
              : "hidden"
          }
        >
          <UserInfo
            isMobile={isMobile}
            player={player}
            headerUserExpand={headerUserExpand}
            balance={balance.balance}
            serverSetup={serverData}
            pages={pages}
            profileMenu={profileMenu}
            setProfileMenu={setProfileMenu}
            isMyAccount={isMyAccount}
            cancelWithdrawal={cancelWithdrawal}
            /* setNlLogoutLimitDialog={setNlLogoutLimitDialog} */
            skin={configData.skin}
          />
        </div>
      )}
      {serverData &&
        isMobileOnly &&
        (location.pathname === `/${currentLanguage}/sports-book/sports`
          ? true
          : !inGame) &&
        location.pathname !== `/${currentLanguage}/maintenance` &&
        location.pathname.indexOf("dresponse") < 0 &&
        location.pathname.indexOf("idinredirect") < 0 &&
        location.pathname.indexOf("hooyuredirect") < 0 &&
        location.pathname.indexOf("ecospendredirect") < 0 && (
          <MobileNavMenu
            pages={pages}
            player={player}
            onToggleSearchShow={toggleSearchShow}
            balance={balance.balance}
            serverSetup={serverData}
            hideSearch={hideSearch}
            showSearch={showSearch}
            skin={configData.skin}
            currentTheme={currentTheme}
            setTheme={setTheme}
            expandWallet={expandWallet}
            setExpandWallet={setExpandWallet}
          />
        )}
      {serverData &&
        location.pathname !== `/${currentLanguage}/spain` &&
        location.pathname !== `/${currentLanguage}/russia` &&
        location.pathname !== `/${currentLanguage}/maintenance` &&
        location.pathname.indexOf("dresponse") < 0 &&
        location.pathname.indexOf("idinredirect") < 0 &&
        location.pathname.indexOf("hooyuredirect") < 0 &&
        location.pathname.indexOf("ecospendredirect") < 0 &&
        hideInMobGames && (
          <Header
            pageHeight={pageHeight}
            currentTheme={currentTheme}
            setTheme={setTheme}
            serverSetup={serverData}
            player={player}
            balance={balance.balance}
            wagering={wagering}
            cancelActiveBonuses={cancelActiveBonuses}
            cancelWithdrawal={cancelWithdrawal}
            pages={pages}
            inGame={inGame}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            headerUserExpand={headerUserExpand}
            setHeaderUserExpand={setHeaderUserExpand}
            isMobile={isMobile}
            profileMenu={profileMenu}
            sidepanel={sidepanel}
            isMyAccount={isMyAccount}
            gameCategories={gameCategories}
            skin={configData.skin}
            defaultLang={configData.defaultLang}
            expandWallet={expandWallet}
            setExpandWallet={setExpandWallet}
          />
        )}
      {location.pathname !== pages["games"] && configData.skin === "rac" && (
        <MainBg
          skin={configData.skin}
          indexPage={location.pathname === `/${currentLanguage}/index`}
        />
      )}
      {showSearch && (
        <Search
          server={serverData}
          onToggleSearchShow={toggleSearchShow}
          //onSearch={onSearch}
          hideSearch={hideSearch}
          gameImagePath={configData.gameImagePath}
        />
      )}
      {location.pathname !== `/${currentLanguage}/spain` &&
        location.pathname !== `/${currentLanguage}/russia` &&
        location.pathname !== `/${currentLanguage}/maintenance` &&
        location.pathname.indexOf("dresponse") < 0 &&
        location.pathname.indexOf("idinredirect") < 0 &&
        location.pathname.indexOf("hooyuredirect") < 0 &&
        location.pathname.indexOf("ecospendredirect") < 0 && (
          <main
            className={
              mobileGameplay
                ? location.pathname === `/${currentLanguage}/sports-book/sports`
                  ? ""
                  : "mob"
                : ""
            }
            id="smooth"
          >
            <Switch>
              {/* Global routes */}
              <Route
                exact
                path={`/${currentLanguage}/index`}
                // component={Index}
                render={(props) => (
                  <Index
                    {...props}
                    indexPage={true}
                    currentTheme={currentTheme}
                    player={player}
                    bannedDepositPage={bannedDepositPage}
                    hasWithdraw={hasWithdraw}
                    grossResults={grossResults}
                    server={serverData}
                    playerCurrency={player.currency}
                    balance={balance.balance}
                    wagering={wagering}
                    setSpinner={setSpinner}
                    cancelActiveBonuses={cancelActiveBonuses}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    depositList={depositList}
                    setSweLimitDialog={setSweLimitDialog}
                    setShowWithdrawAlertDialog={setShowWithdrawAlertDialog}
                    pages={pages}
                    titles={titles}
                    setPxRegisterStart={setPxRegisterStart}
                    setPxDepositStart={setPxDepositStart}
                    onToggleSearchShow={toggleSearchShow}
                    headerUserExpand={headerUserExpand}
                    profileMenu={profileMenu}
                    setProfileMenu={setProfileMenu}
                    setSidepanel={setSidepanel}
                    width={pageWidth}
                    setIsOpen={setIsOpen}
                    dashboardState={dashboardState}
                    setDashboardState={setDashboardState}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    setSearchResults={setSearchResults}
                    accountRoute={accountRoute}
                    setProviderSearchFlag={setProviderSearchFlag}
                    providerSearchFlag={providerSearchFlag}
                  />
                )}
              />
              {pageExists("games") && (
                <Route
                  exact
                  path={`${pages["games"]}`}
                  render={(props) => (
                    <Games
                      {...props}
                      server={serverData}
                      player={player}
                      //searchResults={searchResults}
                      currency={player.currency}
                      onToggleSearchShow={toggleSearchShow}
                      setSweLimitDialog={setSweLimitDialog}
                      titles={titles}
                      width={pageWidth}
                      skin={configData.skin}
                      pages={pages}
                    />
                  )}
                ></Route>
              )}
              {/* Static routes for text pages */}
              {pageExists("about-us") && (
                <Route
                  exact
                  path={`${pages["about-us"]}`}
                  // component={AboutUs}
                  render={(props) => (
                    <AboutUs
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}
              {/*  {pageExists("anti-witwassen") && (
                <Route
                  exact
                  path={`${pages["anti-witwassen"]}`}
                  render={(props) => (
                    <AntiWitwassen
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )} */}
              {pageExists("handelingsbekwaam") && (
                <Route
                  exact
                  path={`${pages["handelingsbekwaam"]}`}
                  render={(props) => (
                    <Handelingsbekwaam
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}
              {pageExists("support") && (
                <Route
                  exact
                  path={`${pages["support"]}`}
                  // component={Support}
                  render={(props) => (
                    <Support
                      {...props}
                      pageWidth={pageWidth}
                      bannedCasinoPage={bannedCasinoPage}
                      bannedSportsbookPage={bannedSportsbookPage}
                      bannedLiveCasinoPage={bannedLiveCasinoPage}
                      riskyCountry={riskyCountry}
                      pages={pages}
                      titles={titles}
                      setShowFinishAffAndEddSup={setShowFinishAffAndEddSup}
                      playerCountry={player.country}
                    />
                  )}
                ></Route>
              )}
              {pageExists("news-and-promotions") && (
                <Route
                  exact
                  path={`${pages["news-and-promotions"]}`}
                  render={(props) => (
                    <NewsAndPromotions
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      server={serverData}
                      isMobile={isMobile}
                      newsImagePath={configData.newsImagePath}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}

              {pageExists("news-and-promotions") && (
                <Route
                  exact
                  path={`${pages["news-and-promotions"]}/:newsPage`}
                  render={(props) => (
                    <News
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      server={serverData}
                      newsImagePath={configData.newsImagePath}
                      player={player}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}
              {pageExists("sports-news") && (
                <Route
                  exact
                  path={`${pages["sports-news"]}`}
                  render={(props) => (
                    <SportsNews
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      server={serverData}
                      isMobile={isMobile}
                      newsImagePath={configData.newsImagePath}
                      skin={configData.skin}
                      player={player}
                    />
                  )}
                ></Route>
              )}
              {pageExists("sports-news") && (
                <Route
                  exact
                  path={`${pages["sports-news"]}/:newsPage`}
                  render={(props) => (
                    <News
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      server={serverData}
                      newsImagePath={configData.newsImagePath}
                      player={player}
                      skin={configData.skin}
                      sports={true}
                    />
                  )}
                ></Route>
              )}
              {pageExists("privacy-policy") && (
                <Route
                  exact
                  path={`${pages["privacy-policy"]}`}
                  // component={PrivacyPolicy}
                  render={(props) => (
                    <PrivacyPolicy
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      isMobile={isMobile}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}
              {pageExists("responsible-gaming") && (
                <Route
                  exact
                  path={`${pages["responsible-gaming"]}`}
                  // component={ResponsibleGaming}
                  render={(props) => (
                    <ResponsibleGaming
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}
              {pageExists("payments") && (
                <Route
                  exact
                  path={`${pages["payments"]}`}
                  // component={ResponsibleGaming}
                  render={(props) => (
                    <Payments
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}
              {pageExists("terms-and-conditions") && (
                <Route
                  exact
                  path={`${pages["terms-and-conditions"]}`}
                  // component={TermsAndConditions}
                  render={(props) => (
                    <TermsAndConditions
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      server={serverData}
                      player={player}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}
              {pageExists("sports-terms") && (
                <Route
                  exact
                  path={`${pages["sports-terms"]}`}
                  // component={TermsAndConditions}
                  render={(props) => (
                    <SportsTermsAndConditions
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      server={serverData}
                      player={player}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}
              {pageExists("safety") && (
                <Route
                  exact
                  path={`${pages["safety"]}`}
                  // component={Safety}
                  render={(props) => (
                    <Safety
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                    />
                  )}
                ></Route>
              )}
              {pageExists("faq") && (
                <Route
                  exact
                  path={`${pages["faq"]}`}
                  // component={Faq}
                  render={(props) => (
                    <Faq
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}
              {pageExists("bonus") && (
                <Route
                  exact
                  path={`${pages["bonus"]}`}
                  // component={Bonus}
                  render={(props) => (
                    <Bonus
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      skin={configData.skin}
                      player={player}
                      serverSetup={serverData}
                    />
                  )}
                ></Route>
              )}
              {pageExists("tournaments") && (
                <Route
                  exact
                  path={`${pages["tournaments"]}`}
                  pages={pages}
                  // component={Bonus}
                  render={(props) => (
                    <Tournaments
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      configData={configData}
                      player={player}
                      serverSetup={serverData}
                      setSpinner={setSpinner}
                    />
                  )}
                ></Route>
              )}
              {pageExists("jackpots") && (
                <Route
                  exact
                  path={`${pages["jackpots"]}`}
                  // component={Bonus}
                  render={(props) => (
                    <Jackpots
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}
              {pageExists("complaints") && (
                <Route
                  exact
                  path={`${pages["complaints"]}`}
                  pages={pages}
                  // component={Complaints}
                  render={(props) => (
                    <Complaints
                      {...props}
                      pageWidth={pageWidth}
                      pages={pages}
                      titles={titles}
                      serverSetup={serverData}
                      setIsOpen={setIsOpen}
                      skin={configData.skin}
                    />
                  )}
                ></Route>
              )}
              {pageExists("pnp-finished") && (
                <Route
                  exact
                  path={`/${currentLanguage}/pnp-finished`}
                  // component={PNP}
                  render={(props) => (
                    <PnpFinished
                      {...props}
                      pageWidth={pageWidth}
                      setSpinner={setSpinner}
                      pages={pages}
                      titles={titles}
                      serverSetup={serverData}
                      setPxDeposit={setPxDeposit}
                      setPxRegister={setPxRegister}
                    />
                  )}
                ></Route>
              )}
              {pageExists("livecasino") && (
                <Route
                  exact
                  path={`${pages["livecasino"]}`}
                  pages={pages}
                  // component={Bonus}
                  render={(props) => (
                    <LiveCasino
                      serverSetup={serverData}
                      pages={pages}
                      setSearchResults={setSearchResults}
                      player={player}
                      skin={configData.skin}
                      defaultLang={configData.defaultLang}
                      gameImagePath={configData.gameImagePath}
                      onToggleSearchShow={toggleSearchShow}
                      titles={titles}
                    />
                  )}
                ></Route>
              )}
              {/* Export game categories routes*/}
              {serverData &&
                serverData.gameCategories.map((value /*index*/) => {
                  return (
                    <Route
                      exact
                      path={`/${currentLanguage}/${gameCategoryToRoute(
                        value.name
                      )}`}
                      key={`${gameCategoryToRoute(value.name)}`}
                      render={(props) => (
                        <GamesCategory
                          {...props}
                          width={pageWidth}
                          serverSetup={serverData}
                          player={player}
                          skin={configData.skin}
                          defaultLang={configData.defaultLang}
                          gameImagePath={configData.gameImagePath}
                          pages={pages}
                          vendorsImagePath={configData.vendorsImagePath}
                          setSearchResults={setSearchResults}
                          config={configData}
                          setSpinner={setSpinner}
                          titles={titles}
                        />
                      )}
                    />
                  );
                })}

              {/* Export game categories with game name*/}
              {serverData &&
                serverData.gameCategories.map((value /*index*/) => {
                  return (
                    <Route
                      exact
                      path={`/${currentLanguage}/${gameCategoryToRoute(
                        value.name
                      )}/:gameId`}
                      render={(props) => (
                        <GameWindow
                          {...props}
                          server={serverData}
                          currentLanguage={currentLanguage}
                          currentTheme={currentTheme}
                          pageWidth={pageWidth}
                          pageHeight={pageHeight}
                          currency={currency}
                          bannedCasinoPage={bannedCasinoPage}
                          bannedSportsbookPage={bannedSportsbookPage}
                          bannedLiveCasinoPage={bannedLiveCasinoPage}
                          bannedDepositPage={bannedDepositPage}
                          riskyCountry={riskyCountry}
                          player={player}
                          socket={socket}
                          onToggleSearchShow={toggleSearchShow}
                          balance={balance.balance}
                          wagering={wagering}
                          //searchResults={searchResults}
                          hasWithdraw={hasWithdraw}
                          cancelWithdrawal={cancelWithdrawal}
                          setSpinner={setSpinner}
                          depositList={depositList}
                          setShowWithdrawAlertDialog={
                            setShowWithdrawAlertDialog
                          }
                          setSweLimitDialog={setSweLimitDialog}
                          //setNlLimitDialog={setNlLimitDialog}
                          getBalance={() => getBalance()}
                          getWageringBonus={() => getWageringBonus()}
                          key={window.location.pathname}
                          mobileGame={(e) => mobileGame(e)}
                          pages={pages}
                          setPxDepositStart={setPxDepositStart}
                          sidepanel={sidepanel}
                          setSidepanel={setSidepanel}
                          grossResults={grossResults}
                          firstDeposit={firstDeposit}
                          titles={titles}
                        />
                      )}
                      key={`${gameCategoryToRoute(value.name)}`}
                    />
                  );
                })}

              {pageExists("register") && (
                <Route
                  exact
                  path={`${pages["register"]}`}
                  // component={Register}
                  render={(props) => (
                    <Register
                      {...props}
                      serverSetup={serverData}
                      pages={pages}
                      setSpinner={setSpinner}
                      titles={titles}
                      setPxRegister={setPxRegister}
                      setPxRegisterStart={setPxRegisterStart}
                      player={player}
                    />
                  )}
                />
              )}

              <Route
                exact
                path={`/${currentLanguage}/exit-game`}
                render={(props) => <ExitGame {...props} pages={pages} />}
              />

              {pageExists("login") && (
                <Route
                  exact
                  path={`${pages["login"]}`}
                  // component={Login}
                  render={(props) => (
                    <Login
                      {...props}
                      setSpinner={setSpinner}
                      serverSetup={serverData}
                      pages={pages}
                      titles={titles}
                      setShowQueueDialog={setShowQueueDialog}
                    />
                  )}
                />
              )}

              {pageExists("forgot-password") && (
                <Route
                  exactTrigger
                  path={`${pages["forgot-password"]}`}
                  // component={ForgotPassword}
                  render={(props) => (
                    <ForgotPassword
                      {...props}
                      titles={titles}
                      setSpinner={setSpinner}
                      serverSetup={serverData}
                      pages={pages}
                    />
                  )}
                ></Route>
              )}
              {pageExists("reset-password") && (
                <Route
                  exactTrigger
                  path={`${pages["reset-password"]}`}
                  render={(props) => (
                    <ResetPassword
                      {...props}
                      titles={titles}
                      pages={pages}
                      setSpinner={setSpinner}
                    />
                  )}
                ></Route>
              )}
              <Route
                exact
                path={`/${currentLanguage}/logout`}
                // component={Logout}
                render={(props) => (
                  <Logout
                    {...props}
                    setSpinner={setSpinner}
                    titles={titles}
                    player={player}
                    //setNlLimitDialog={setNlLimitDialog}
                    defaultLang={configData.defaultLang}
                  />
                )}
              />
              {/*<Route exact path={`/${currentLanguage}/activation`} component={Activation} />*/}
              <Route
                exact
                path={`/${currentLanguage}/activation`}
                // component={Activation}
                render={(props) => (
                  <Activation
                    {...props}
                    pages={pages}
                    setSpinner={setSpinner}
                  />
                )}
              />

              {pageExists("deposit") && (
                <ProtectedRoute
                  exact
                  path={`${pages["deposit"]}`}
                  pages={pages}
                  // component={Deposit}
                  render={(props) => (
                    <Deposit
                      {...props}
                      currentTheme={currentTheme}
                      server={serverData}
                      bannedDepositPage={bannedDepositPage}
                      hasWithdraw={hasWithdraw}
                      cancelWithdrawal={cancelWithdrawal}
                      player={player}
                      riskyCountry={riskyCountry}
                      preselectedDeposit={""}
                      setSpinner={setSpinner}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      userAgent={getUA}
                      inGame={false}
                      depositList={depositList}
                      setShowWithdrawAlertDialog={setShowWithdrawAlertDialog}
                      setSweLimitDialog={setSweLimitDialog}
                      //setNlLimitDialog={setNlLimitDialog}
                      pages={pages}
                      titles={titles}
                      setPxDepositStart={setPxDepositStart}
                      firstDeposit={firstDeposit}
                      setShowBonusAvailableDialog={setShowBonusAvailableDialog}
                      setShowSetLimitsDialog={setShowSetLimitsDialog}
                      getNettLimit={getNettLimit}
                    />
                  )}
                />
              )}

              {pageExists("withdrawal") && (
                <ProtectedRoute
                  exact
                  path={`${pages["withdrawal"]}`}
                  pages={pages}
                  // component={Deposit}
                  render={(props) => (
                    <Withdraw
                      {...props}
                      currentTheme={currentTheme}
                      server={serverData}
                      bannedWithdrawalPage={bannedWithdrawalPage}
                      hasWithdraw={hasWithdraw}
                      cancelWithdrawal={cancelWithdrawal}
                      player={player}
                      preselectedDeposit={""}
                      setSpinner={setSpinner}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      // withdrawalList={withdrawalList}
                      playerBalance={balance.balance}
                      setShowWithdrawAlertDialog={setShowWithdrawAlertDialog}
                      pages={pages}
                      titles={titles}
                      setFailingBankAlertDialog={setFailingBankAlertDialog}
                      hasBonus={wagering}
                      setShowConfirmCancelBonusDialog={
                        setShowConfirmCancelBonusDialog
                      }
                    />
                  )}
                />
              )}

              {pageExists("my-profile") && (
                <ProtectedRoute
                  exact
                  path={`${pages["my-profile"]}`}
                  pages={pages}
                  render={(props) => (
                    <Profile
                      {...props}
                      currentTheme={currentTheme}
                      server={serverData}
                      player={player}
                      pages={pages}
                      titles={titles}
                      setIsOpen={setIsOpen}
                      skin={configData.skin}
                    />
                  )}
                />
              )}

              {pageExists("change-password") && (
                <ProtectedRoute
                  exact
                  path={`${pages["change-password"]}`}
                  pages={pages}
                  render={(props) => (
                    <ChangePassword
                      {...props}
                      currentTheme={currentTheme}
                      server={serverData}
                      pages={pages}
                      titles={titles}
                      player={player}
                      skin={configData.skin}
                    />
                  )}
                />
              )}

              {pageExists("cashback") && (
                <ProtectedRoute
                  exact
                  path={`${pages["cashback"]}`}
                  pages={pages}
                  render={(props) => (
                    <Cashback
                      {...props}
                      currentTheme={currentTheme}
                      server={serverData}
                      playerCurrency={player.currency}
                      balance={balance.balance}
                      height={pageHeight}
                      width={pageWidth}
                      pages={pages}
                      titles={titles}
                      player={player}
                      skin={configData.skin}
                      setSpinner={setSpinner}
                    />
                  )}
                />
              )}

              {pageExists("bonuses") && (
                <ProtectedRoute
                  exact
                  path={`${pages["bonuses"]}`}
                  pages={pages}
                  render={(props) => (
                    <Bonuses
                      {...props}
                      currentTheme={currentTheme}
                      server={serverData}
                      playerCurrency={player.currency}
                      pages={pages}
                      titles={titles}
                      player={player}
                      skin={configData.skin}
                    />
                  )}
                />
              )}

              {pageExists("casino-history") && (
                <ProtectedRoute
                  exact
                  path={`${pages["casino-history"]}`}
                  pages={pages}
                  render={(props) => (
                    <CasinoHistory
                      {...props}
                      currentTheme={currentTheme}
                      server={serverData}
                      playerCurrency={player.currency}
                      pages={pages}
                      titles={titles}
                      player={player}
                      skin={configData.skin}
                    />
                  )}
                />
              )}
              {pageExists("my-account") && (
                <ProtectedRoute
                  exact
                  path={`${pages["my-account"]}`}
                  pages={pages}
                  render={(props) => (
                    <MyAccount
                      {...props}
                      currentTheme={currentTheme}
                      server={serverData}
                      playerCurrency={player.currency}
                      pages={pages}
                      titles={titles}
                      player={player}
                      skin={configData.skin}
                    />
                  )}
                />
              )}
              {pageExists("transactions") && (
                <ProtectedRoute
                  exact
                  path={`${pages["transactions"]}`}
                  pages={pages}
                  render={(props) => (
                    <Transactions
                      {...props}
                      currentTheme={currentTheme}
                      server={serverData}
                      playerCurrency={player.currency}
                      pages={pages}
                      titles={titles}
                      player={player}
                      skin={configData.skin}
                    />
                  )}
                />
              )}

              {pageExists("my-limits") && (
                <ProtectedRoute
                  exact
                  path={`${pages["my-limits"]}`}
                  pages={pages}
                  render={(props) => (
                    <MyLimits
                      {...props}
                      currentTheme={currentTheme}
                      server={serverData}
                      player={player}
                      pages={pages}
                      titles={titles}
                      currentLanguage={currentLanguage}
                      setSpinner={setSpinner}
                      skin={configData.skin}
                    />
                  )}
                />
              )}

              {pageExists("my-verification") && (
                <ProtectedRoute
                  exact
                  path={`${pages["my-verification"]}`}
                  pages={pages}
                  render={(props) => (
                    <Verification
                      {...props}
                      currentTheme={currentTheme}
                      serverSetup={serverData}
                      player={player}
                      pages={pages}
                      titles={titles}
                      skin={configData.skin}
                      setSpinner={setSpinner}
                      setAffordabilityPopupLimit={setAffordabilityPopupLimit}
                      setAffordabilityPopup={setAffordabilityPopup}
                      setShowFinishAffAndEdd={setShowFinishAffAndEdd}
                    />
                  )}
                />
              )}

              {/* Error routes */}
              <Route
                exact
                path={`/${currentLanguage}/error`}
                component={Error}
              />
              {/*<Route*/}
              {/*  exact*/}
              {/*  path={`/${currentLanguage}/test`}*/}
              {/*  component={Test}*/}
              {/*/>*/}
              {serverData && (
                <Route
                  path="*"
                  pages={pages}
                  render={(props) => (
                    <Landing
                      {...props}
                      currentTheme={currentTheme}
                      server={serverData}
                      player={player}
                      pages={pages}
                      titles={titles}
                      setSpinner={setSpinner}
                      setPxRegisterStart={setPxRegisterStart}
                      setPxRegister={setPxRegister}
                    />
                  )}
                />
              )}
            </Switch>

            {accountRoute && (
              <Index
                indexPage={false}
                currentTheme={currentTheme}
                player={player}
                bannedDepositPage={bannedDepositPage}
                hasWithdraw={hasWithdraw}
                grossResults={grossResults}
                server={serverData}
                playerCurrency={player.currency}
                balance={balance.balance}
                wagering={wagering}
                setSpinner={setSpinner}
                cancelActiveBonuses={cancelActiveBonuses}
                isMobile={isMobile}
                isTablet={isTablet}
                depositList={depositList}
                setSweLimitDialog={setSweLimitDialog}
                setShowWithdrawAlertDialog={setShowWithdrawAlertDialog}
                pages={pages}
                titles={titles}
                setPxRegisterStart={setPxRegisterStart}
                setPxDepositStart={setPxDepositStart}
                onToggleSearchShow={toggleSearchShow}
                headerUserExpand={headerUserExpand}
                profileMenu={profileMenu}
                setProfileMenu={setProfileMenu}
                setSidepanel={setSidepanel}
                width={pageWidth}
                setIsOpen={setIsOpen}
                dashboardState={dashboardState}
                setDashboardState={setDashboardState}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setSearchResults={setSearchResults}
                accountRoute={accountRoute}
                setProviderSearchFlag={setProviderSearchFlag}
                providerSearchFlag={providerSearchFlag}
              />
            )}

            {/* footer */}
            {serverData &&
              !(location.pathname === pages["logout"]) &&
              hideInMobGames && (
                <Footer
                  currentTheme={currentTheme}
                  setTheme={setTheme}
                  serverSetup={serverData}
                  pages={pages}
                  gameCategories={gameCategories}
                  skin={configData.skin}
                  paymentsImagePath={configData.paymentsImagePath}
                  vendorsImagePath={configData.vendorsImagePath}
                  defaultLang={configData.defaultLang}
                  setSearchResults={setSearchResults}
                  accountRoute={accountRoute}
                  player={player}
                  setSpinner={setSpinner}
                  setSelectedCategory={setSelectedCategory}
                />
              )}

            <RGDialog
              showModal={isOpenRGV}
              RGVPopupData={RGVPopupData}
              currentLanguage={currentLanguage}
              handleProceed={() => confirmRgv2()}
              pages={pages}
            />

            <LogoutDialog
              showModal={pleaseLogoutDialog}
              handleProceed={() => silentLogout()}
              skin={configData.skin}
            />
            <RtpUkDialog
              showModal={rtpUkPopup}
              setRtpUkPopup={setRtpUkPopup}
              skin={configData.skin}
            />

            {(location.pathname !== pages["support"] ||
              location.pathname !== pages["terms-and-conditions"]) && (
              <TermsAlertDialog
                showModal={termsAlertDialog}
                handleProceed={() => acceptUkTerms()}
                currentLanguage={currentLanguage}
              />
            )}
          </main>
        )}
      {/*end of spain/russia block*/}
      {location.pathname === `/${currentLanguage}/spain` && <Spain />}
      {location.pathname === `/${currentLanguage}/russia` && <Russia />}
      {location.pathname === `/${currentLanguage}/maintenance` && (
        <Maintenance />
      )}
      {cookiesConfirmed === null && (
        <CookiesConfirmationDialog serverSetup={serverData} pages={pages} />
      )}
      {showWithdrawAlertDialog && (
        <WithdrawAlertDialog
          player={player}
          showModal={showWithdrawAlertDialog}
          handleProceed={cancelWithdrawal}
          handleCancel={() => {
            window.location = pages["index"];
          }}
          type={
            location.pathname === pages["withdrawal"] ? "withdraw" : "deposit"
          }
        />
      )}
      {sweLimitDialog && (
        <SweLimitDialog
          showModal={sweLimitDialog}
          setSweLimitDialog={setSweLimitDialog}
          server={serverData}
          grossResults={grossResults}
          player={player}
        />
      )}
      {/*  {nlLimitDialog && (
        <NlLimitDialog
          showModal={nlLimitDialog}
          server={serverData}
          grossResults={grossResults}
          player={player}
          balance={balance.balance}
          pages={pages}
          location={location}
          handleProceed={() => cancelNlLimitDialog()}
        />
      )} */}
      {serverData && affordabilityPopup !== "" && (
        <AffordabilityDialog
          showModal={affordabilityPopup}
          server={serverData}
          grossResults={grossResults}
          player={player}
          balance={balance.balance}
          pages={pages}
          location={location}
          affordabilityPopupLimit={affordabilityPopupLimit}
          popupVersion={affordabilityPopup}
          handleCancel={() => {
            setAffordabilityPopup("");
            // if(location.pathname !== pages["index"]) {
            window.location = pages["index"];
            // }
          }}
          handleSupport={() => {
            setAffordabilityPopup("");
            window.location = pages["support"];
          }}
        />
      )}
      {/* {nlLogoutLimitDialog && (
        <NlLogoutLimitDialog
          showModal={nlLogoutLimitDialog}
          server={serverData}
          grossResults={grossResults}
          player={player}
          handleProceed={() => (window.location = `/${currentLanguage}/logout`)}
        />
      )} */}
      {serverData && (
        <TrackingPixels
          player={player}
          names={names}
          location={location}
          serverSetup={serverData}
          ftd={false}
          pixelConversion="page-view"
          skin={configData.skin}
        />
      )}
      {pxDeposit && (
        <TrackingPixels
          player={player}
          names={names}
          location={location}
          serverSetup={serverData}
          ftd={firstDeposit}
          pixelConversion="deposit"
          callback={setPxDeposit}
          skin={configData.skin}
        />
      )}
      {pxDepositStart && (
        <TrackingPixels
          player={player}
          names={names}
          location={location}
          serverSetup={serverData}
          ftd={false}
          pixelConversion="deposit-start"
          callback={setPxDepositStart}
          skin={configData.skin}
        />
      )}
      {pxRegister && (
        <TrackingPixels
          player={player}
          names={names}
          location={location}
          serverSetup={serverData}
          ftd={false}
          pixelConversion="register"
          callback={setPxRegister}
          skin={configData.skin}
        />
      )}
      {pxRegisterStart && (
        <TrackingPixels
          player={player}
          names={names}
          location={location}
          serverSetup={serverData}
          ftd={false}
          pixelConversion="register-start"
          callback={setPxRegisterStart}
          skin={configData.skin}
        />
      )}

      <RegisterNowDialog
        showModal={showRegisterNowDialog}
        setModal={setShowRegisterNowDialog}
        handleProceed={() => {
          window.location = pages["register"];
        }}
        handleCancel={() => silentLogout()}
        skin={configData.skin}
      />
      <NettLimitDialog
        showModal={showNettLimitDialog}
        handleProceed={() => {
          handleNettLimitProceed();
        }}
        handleCancel={() => {
          window.location = `/${currentLanguage}/logout`;
        }}
      />
      <FinishAffAndEdd
        showModal={showFinishAffAndEdd}
        handleProceed={handleFinishAffAndEdd}
        playerCountry={player.country}
        skin={configData.skin}
      />
      <FinishAffAndEddSup
        showModal={showFinishAffAndEddSup}
        handleProceed={() => {
          window.location = pages["my-verification"];
        }}
      />
      <GDPRDialog
        showModal={showGDPRDialog}
        handleProceed={(e) => handleGDPRProceed(e)}
        // handleLogout={handleLogout}
      />
      <SessionLimitDialog
        showModal={showSessionLimitDialog}
        handleCancel={() =>
          (window.location = "/" + currentLanguage + "/index")
        }
      />
      <PleaseReloadPageDialog showModal={disableSocket} />
      <FailingWithdrawalDialog
        showModal={failingBankAlertDialog}
        handleProceed={() => setFailingBankAlertDialog(false)}
      />
      <TimeLimitDialog
        showModal={showEndSession}
        handleProceed={() => handleTimeLimitProceed()}
      />
      <BonusAvailableDialog
        showModal={showBonusAvailableDialog}
        handleProceed={bonusRedirect}
        handleCancel={hideAllways}
      />
      <SetLimitsDialog
        showModal={showSetLimitsDialog}
        handleProceed={() => (window.location = pages["my-limits"])}
        handleCancel={() => setShowSetLimitsDialog(false)}
      />
      <QueueDialog
        showModal={showQueueDialog}
        handleCancel={() =>
          (window.location = "/" + currentLanguage + "/index")
        }
      />
      <ConfirmCancelBonusDialog
        showModal={showConfirmCancelBonusDialog}
        handleProceed={cancelActiveBonuses}
        handleCancel={() => setShowConfirmCancelBonusDialog(false)}
        skin={configData.skin}
      />
    </ThemeProvider>
  );
};

export default Routes;
