import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PaymentServer } from "common/src/helpers/connectors";
import { toast } from "react-toastify";
import { UkLookupDialog } from "../modal/UkLookupDialog";
import Input from "common/src/component/input";
import { StyledFormRow } from "../styledComponents/AccountPage";
import {
  LookupStepShow,
  LookupStepShowFlex,
  RegisterStepWrapper,
  ResisterStepUkAdress,
} from "../styledComponents/StyledSignupLogin";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import { PageheadlinePar } from "../styledComponents/StyledDepositAndWithdrawal";

const RegisterStepUK = ({
  errors,
  touched,
  formData,
  navigation,
  handleChange,
  handleBlur,
  setSpinner,
  setTouchedOnRest,
  skin,
}) => {
  const { zipCode, address, city, state, buildingName, buildingNumber, abode } =
    formData;

  const textInput = useRef(null);
  const [lookupStep, setLookupStep] = useState(false);
  const [allLookupData, setAllLookupData] = useState([]);
  const [showLookupDialog, setShowLookupDialog] = useState(false);
  // console.log(setAllLookupData.length)
  const { previous, go, pause } = navigation;
  const { t } = useTranslation("");
  //let isValid = false;
  // console.log(errors);
  // console.log(touched);

  let paramsForCheck = [
    "zipCode",
    "address",
    "city",
    "abode",
    "buildingName",
    "buildingNumber",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* useEffect(() => {
    //on modal show/hide, focus on last input on form in order to trigger onblur after input was left
    textInput.current.focus();
  }, [lookupStep]); */

  /* on next check mandatory fields */
  const checkIsValid = () => {
    if (city.length > 0) delete errors.city;
    if (address.length > 0) delete errors.address;
    if (
      abode.length > 0 ||
      buildingName.length > 0 ||
      buildingNumber.length > 0
    )
      delete errors.abode;
    if (
      buildingName.length > 0 ||
      abode.length > 0 ||
      buildingNumber.length > 0
    )
      delete errors.buildingName;
    if (
      buildingNumber.length > 0 ||
      abode.length > 0 ||
      buildingName.length > 0
    )
      delete errors.buildingNumber;
    if (state.length > 0) delete errors.state;
    if (zipCode.length > 0) delete errors.zipCode;
    setTouchedOnRest(paramsForCheck);
    let hasErrors = 0;
    errors &&
      paramsForCheck.forEach((p) => {
        for (let key of Object.keys(errors)) {
          if (p === key) {
            hasErrors++;
          }
        }
      });
    if (hasErrors === 0) {
      return true;
    } else {
      return false;
    }
  };

  const showLookupStep = () => {
    setLookupStep(true);
  };

  const setLookupData = (data) => {
    // console.log("data: " + JSON.stringify(data));
    setLookupStep(true);
    formData.abode = data.abode;
    formData.city = data.city;
    formData.address = data.street;
    formData.buildingName = data["building-name"];
    formData.buildingNumber = data.number;
    formData.state = data.county;
    formData.zipCode = data.postcode;
    // textInput.current.focus();
  };

  const doLookup = () => {
    if (
      formData.zipCode !== "" &&
      formData.zipCode.length < 10 &&
      formData.zipCode.length >= 6
    ) {
      // console.log("do lookup!: " + formData.zipCode);
      setSpinner(true);
      PaymentServer.post("/fhnd/v1/postcode/lookup", {
        postcode: formData.zipCode,
      })
        .then((res) => {
          setSpinner(false);
          // console.log("result: " + JSON.stringify(res.data))
          setAllLookupData(res.data);
          setShowLookupDialog(true);
        })
        .catch((err) => {
          // console.log("ERROR: " + JSON.stringify(error))
          //step back on error
          toast.error(t(err.response.data[0]), {
            position: "bottom-center",
          });
          setSpinner(false);
          return false;
        });
    } else {
      //show error
    }
  };

  return (
    <>
      <RegisterStepWrapper>
        {skin === "rac" && (
          <PageheadlinePar
            dangerouslySetInnerHTML={{ __html: t("label_register_account") }}
          ></PageheadlinePar>
        )}
        <StyledFormRow>
          <Input
            groupClass="form-group col-md-12 mb-3"
            name="zipCode"
            label={"label_post_code"}
            value={zipCode}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            // ref={textInput}
            placeholder={skin !== "rac"}
            autoFocus={true}
            errors={errors.zipCode && touched.zipCode ? errors.zipCode : null}
          />
          <ResisterStepUkAdress lookupStep={lookupStep}>
            <StyledButton
              pub={skin === "pub"}
              third
              colthird={skin === "rac" ? "col-md-4" : ""}
              onClick={showLookupStep}
            >
              {" "}
              {t("label_submit_address_manually")}
            </StyledButton>
            <StyledButton btnCol pub={skin === "pub"} onClick={doLookup}>
              {t("label_lookup")}
            </StyledButton>
          </ResisterStepUkAdress>
        </StyledFormRow>
        <LookupStepShow lookupStep={lookupStep}>
          <Input
            groupClass="form-group col-md-6 mb-3"
            label={"label_current_street"}
            name="address"
            value={address}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={skin !== "rac"}
            errors={errors.address && touched.address ? errors.address : null}
          />
          <Input
            groupClass="form-group col-md-6 mb-3"
            label={"label_city"}
            name="city"
            value={city}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.city && touched.city ? errors.city : null}
            placeholder={skin !== "rac"}
          />
        </LookupStepShow>
        <Input
          groupClass={lookupStep ? "form-group mb-3" : "hidden"}
          label={"label_county"}
          name="state"
          value={state}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={null}
          placeholder={skin !== "rac"}
        />
        <Input
          groupClass={lookupStep ? "form-group required mb-3" : "hidden"}
          label={"label_current_building_name"}
          name="buildingName"
          value={buildingName}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.buildingName && touched.buildingName
              ? errors.buildingName
              : null
          }
          placeholder={skin !== "rac"}
        />
        <LookupStepShow lookupStep={lookupStep}>
          <Input
            groupClass="form-group required col-md-6 mb-3"
            label={"label_current_house_number"}
            name="buildingNumber"
            value={buildingNumber}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={
              errors.buildingNumber && touched.buildingNumber
                ? errors.buildingNumber
                : null
            }
            min={0}
            placeholder={skin !== "rac"}
          />
          <Input
            groupClass="form-group required col-md-6 mb-3"
            label={"label_current_adobe"}
            name="abode"
            value={abode}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.abode && touched.abode ? errors.abode : null}
            placeholder={skin !== "rac" && t("label_current_adobe")}
            /* autoFocus={true} */
          />
          {/* <div className="form-group required col-md-6 mb-3">
            <input
              className="form-control"
              label={"label_current_adobe"}
              name="abode"
              value={abode}
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.abode && touched.abode ? errors.abode : null}
              placeholder={t("label_current_adobe")}
              ref={textInput}
              // id={"focus-select"}
            />
          </div> */}
        </LookupStepShow>
        {/* <LookupStepShow lookupStep={lookupStep}>
          <h3>{t("label_one_of_the_three")}</h3>
        </LookupStepShow> */}
        <LookupStepShowFlex lookupStep={lookupStep}>
          <StyledButton
            pub={skin === "pub"}
            fourth
            colfourth={"col-md-4"}
            type="button"
            onClick={previous}
          >
            {t("label_go_back", "Previous")}
          </StyledButton>
          <StyledButton
            pub={skin === "pub"}
            btnCol
            type="button"
            onClick={() => (checkIsValid() ? go("stepGDPR") : pause())}
          >
            {t("label_next", "Next")}
          </StyledButton>
        </LookupStepShowFlex>
      </RegisterStepWrapper>

      <UkLookupDialog
        showModal={showLookupDialog}
        setShowModal={setShowLookupDialog}
        allLookupData={allLookupData}
        setLookupData={setLookupData}
        skin={skin}
      />
    </>
  );
};

export default RegisterStepUK;
