import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

// Main route wrapper responsible to handle App routing
export default function RouteWrapper({ component: Component, server: Server, ...rest }) {
  // Do some additional checks, like session validation, etc.
  // console.log("Server:", Server);
  // console.log("...rest", rest);

  // Render route
  return <Route {...rest} component={Component} server={Server} />;
}

// Define property types
RouteWrapper.propTypes = {
  //component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  server: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

// Default property setup
RouteWrapper.defaultProps = {
  server: {},
};
