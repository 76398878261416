import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const RGDialog = ({
  showModal,
  handleProceed,
  RGVPopupData,
  pages,
  skin,
}) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      <StyledModalBody register>
        <p
          dangerouslySetInnerHTML={{ __html: t(RGVPopupData.popupMessage) }}
        ></p>
      </StyledModalBody>
      <StyledModalFooter>
        <StyledButton btnBold onClick={() => handleProceed()}>
          {t(RGVPopupData.popupBtn)}
        </StyledButton>
        {RGVPopupData.popupBtn1 !== "" && (
          <StyledButton
            btnBold
            pub={skin === "pub"}
            onClick={() => (window.location = pages["support"])}
          >
            {t(RGVPopupData.popupBtn1)}
          </StyledButton>
        )}
      </StyledModalFooter>
    </StyledModal>
  );
};
