import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { browserVersion, isMobileSafari, isSafari } from "react-device-detect";
import {
  StyledPlayLiveTop,
  PlayLiveWrapper,
  PlayLiveLeftWrapper,
  PlayLiveImgLeft,
  PlayLiveRightWrapper,
  StyledClowerSmall,
  PlayLiveExtend,
  PlayLiveH2,
  PlayLiveDescription,
  StyledClowerBig,
  StyledPlayLiveBottom,
  PlayLivePUBImg,
  PlayLivePUBImgSafari,
  PlayLiveCACImg,
  PlayLiveHYCImg,
} from "../styledComponents/StyledIndex";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import bubleImg from "../../../plc/src/data/img/buble-img.png";

const PlayLive = ({ pages, skin }) => {
  const { t } = useTranslation("");
  const [forcePng, setForcePng] = useState(false);

  useEffect(() => {
    if ((isMobileSafari || isSafari) && parseInt(browserVersion) < 14)
      setForcePng(true);
  }, []);

  return (
    <>
      {skin === "plc" && <StyledPlayLiveTop />}
      <PlayLiveWrapper>
        {skin === "pub" &&
          (forcePng ? <PlayLivePUBImgSafari /> : <PlayLivePUBImg />)}
        {(skin === "plc" || skin === "cac" || skin === "hyc") && (
          <PlayLiveLeftWrapper>
            {skin === "plc" && <PlayLiveImgLeft src={bubleImg} />}
            {skin === "cac" && <PlayLiveCACImg />}
            {skin === "hyc" && <PlayLiveHYCImg />}
          </PlayLiveLeftWrapper>
        )}
        <PlayLiveRightWrapper>
          {skin === "plc" && <StyledClowerSmall />}
          <PlayLiveExtend>
            <PlayLiveH2>{t("label_play_live_header")}</PlayLiveH2>
            <PlayLiveDescription>
              {t("label_play_live_par")}
            </PlayLiveDescription>
            <StyledButton
              btn
              pub={skin === "pub"}
              onClick={() => (window.location = pages["live-casino"])}
            >
              {t("label_play_live")}
            </StyledButton>
          </PlayLiveExtend>
          {skin === "plc" && <StyledClowerBig />}
        </PlayLiveRightWrapper>
      </PlayLiveWrapper>
      {skin === "plc" && <StyledPlayLiveBottom />}
    </>
  );
};

export default PlayLive;
