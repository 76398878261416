import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import Pagination from "../components/Pagination";
// import { paginate } from "common/src/helpers/functions";
import { paginate, printAmount } from "common/src/helpers/functions";
import {
  DepositTransactionWrapper,
  GameHistoryWrapper,
  StyledTableCell,
  StyledTableContent,
  StyledTableHeading,
  StyledTableRow,
  TableHeader,
  TransactionNotFound,
} from "../styledComponents/AccountPage";
import { StyledFelxTableRow } from "../styledComponents/CommonStyledComponents";
import {
  SpanColMd,
  StyledDWTotal,
} from "../styledComponents/StyledDepositDialog";
import Pagination from "common/src/component/Pagination";

const DepositTransactions = ({
  depositHistory,
  playerCurrency,
  server,
  skin,
}) => {
  const { t } = useTranslation("");
  let pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  let onlyFilteredTrans;

  if (depositHistory.transactions !== undefined) {
    onlyFilteredTrans = depositHistory.transactions.filter(
      (tansaction) => tansaction.transactionType === "deposit"
    );
  }

  const filterTransactionsPart = paginate(
    onlyFilteredTrans,
    currentPage,
    pageSize
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <DepositTransactionWrapper>
      <GameHistoryWrapper>
        <TableHeader col={"col-md-4"}>{t("label_date", "Data")}</TableHeader>
        <TableHeader col={"col-md-4"}>
          {t("label_transaction_type", "Type")}
        </TableHeader>
        <TableHeader col={"col-md-2"}>
          {t("label_status", "Status")}
        </TableHeader>
        <TableHeader col={"col-md-2"}>
          {t("label_amount", "Amount")}
        </TableHeader>
      </GameHistoryWrapper>
      {depositHistory.transactions !== undefined &&
        filterTransactionsPart.map((tansaction, index) => (
          <StyledTableRow key={index}>
            <StyledTableCell cell={"topic-cell"} col={"col-md-4"}>
              <StyledTableContent>{tansaction.completedAt}</StyledTableContent>
            </StyledTableCell>
            <StyledTableCell cell={"type-cell"} col={"col-md-4"}>
              <StyledTableHeading>
                {(t("label_transaction_type"), "Type")}
              </StyledTableHeading>
              <StyledTableContent>
                {tansaction.transactionType}
              </StyledTableContent>
            </StyledTableCell>
            <StyledTableCell cell={"status-cell"} col={"col-md-2"}>
              <StyledTableHeading>{t("label_status")}</StyledTableHeading>
              {skin === "plc" && (
                <SpanColMd>{t("label_" + tansaction.status)}</SpanColMd>
              )}
              {skin !== "plc" && (
                <StyledTableContent>
                  {t("label_" + tansaction.status)}
                </StyledTableContent>
              )}
            </StyledTableCell>
            {/* <Pagination
              itemsCount={depositHistory.transactions}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange()}
            /> */}

            <StyledTableCell cell={"amount-cell"} col={"col-md-2"}>
              <StyledTableHeading>{t("label_amount")}</StyledTableHeading>
              <StyledTableContent>
                {printAmount(
                  tansaction.amount,
                  playerCurrency,
                  server.currencies,
                  true
                )}
              </StyledTableContent>
            </StyledTableCell>
          </StyledTableRow>
        ))}

      {(depositHistory.transactions === undefined ||
        depositHistory.transactions.length === 0) && (
        <TransactionNotFound
          dangerouslySetInnerHTML={{
            __html: t("account_transactions_not_found"),
          }}
        ></TransactionNotFound>
      )}

      {skin !== "plc" && (
        <StyledFelxTableRow total>
          <StyledDWTotal col={"col-md-10 col-sm-6"}>
            <StyledTableContent description>
              {t("label_deposit_total")}
            </StyledTableContent>
          </StyledDWTotal>
          <StyledDWTotal col={"total-cell col-md-2 col-sm-6"}>
            <StyledTableContent>
              {printAmount(
                depositHistory.depositAmount,
                playerCurrency,
                server.currencies,
                true
              )}
            </StyledTableContent>
          </StyledDWTotal>
        </StyledFelxTableRow>
      )}
      {onlyFilteredTrans && (
        <Pagination
          itemsCount={onlyFilteredTrans.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={(e) => handlePageChange(e)}
        />
      )}
    </DepositTransactionWrapper>
  );
};

export default DepositTransactions;
