import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { withRouter } from "react-router";
import { MUTATION_CHANGE_PASSWORD } from "common/src/graphql/mutations";
import { toast } from "react-toastify";
import { ContentTop } from "common/src/component/ContentTop";
import { SERVER_SETUP } from "common/src/helpers/constants";
import { isMobileOnly } from "react-device-detect";
import {
  AccountContainer,
  ChangePasswordWrapper,
  PageContent,
  PageContentWrapper,
  ChangeInputWrapper,
  ChangePassButton,
  InvalidFeedback,
} from "common/src/styledComponents/AccountPage";
import {
  StyledInput,
  VisibilityIcon,
} from "common/src/styledComponents/CommonStyledComponents";

const ChangePassword = ({ pages, titles, skin }) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();
  const [error, setError] = useState({});

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const [disabledButton, setDisabledButon] = useState(true);
  const didMountRef = useRef(false);
  const [showPassword, setShowPassword] = useState("password");
  const [showPasswordNew, setShowPasswordNew] = useState("password");
  const [showPasswordCp, setShowPasswordCp] = useState("password");

  useEffect(() => {
    if (didMountRef.current) {
      let upperCase = new RegExp("[A-Z]");
      let numbers = new RegExp("[0-9]");

      if (oldPassword === "") {
        error.oldPassword = "err_empty_password";
      } else delete error.oldPassword;
      if (newPassword === "") {
        error.newPassword = "err_empty_new_password";
      } else if (newPassword.length < 8) {
        error.newPassword = "err_invalid_minimum_password";
      } else if (
        newPassword.length < 8 ||
        !newPassword.match(upperCase) ||
        !newPassword.match(numbers)
      ) {
        error.newPassword = "err_not_strong_password";
      } else delete error.newPassword;
      if (cPassword === "") {
        error.cPassword = "err_empty_confirm_password";
      } else if (newPassword !== cPassword) {
        error.cPassword = "err_not_match_password";
      } else delete error.cPassword;
      setError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldPassword, newPassword, cPassword]);

  useEffect(() => {
    // console.log(titles)
    document.title = titles["change-password"];
  }, [titles]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    if (
      oldPassword.length !== 0 &&
      newPassword.length >= 8 &&
      cPassword.length >= 8 &&
      newPassword === cPassword &&
      Object.keys(error)
    ) {
      setDisabledButon(false);
    } else {
      setDisabledButon(true);
    }
  }, [newPassword, cPassword, error, oldPassword]);

  async function changePassword() {
    const { loading, errors } = await apolloClient.mutate({
      mutation: MUTATION_CHANGE_PASSWORD,
      variables: {
        oldPassword: oldPassword,
        newPassword: newPassword,
        cNewPassword: cPassword,
      },
    });

    if (loading) return "Loading ...";
    if (errors) {
      //console.log(errors);
      //setLoginError(errors);
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      return false;
    } else
      toast.success(t("update_password_success"), {
        position: "bottom-center",
      });

    return true;
  }

  return (
    <>
      <ContentTop
        serverSetup={serverSetup}
        showProfileMenu={!isMobileOnly}
        pages={pages}
        skin={skin}
        isMyAccount={"label_new_password"}
      />
      <PageContent account="true" nobg="true">
        <PageContentWrapper>
          <AccountContainer mt>
            <ChangePasswordWrapper>
              {/* <MyAccountNav pages={pages} playerData={player} /> */}

              {/*  <p>{t("label_change_password_description")}</p> */}
              <ChangeInputWrapper>
                <StyledInput
                  cp
                  error={error.oldPassword}
                  type={showPassword}
                  name="oldPassword"
                  value={oldPassword}
                  placeholder={t("label_cp_password")}
                  onChange={(e) => setOldPassword(e.target.value.trim())}
                />
                {error.oldPassword && (
                  <InvalidFeedback>{t(error.oldPassword)}</InvalidFeedback>
                )}
                <VisibilityIcon
                  reposition={error.oldPassword}
                  show={showPassword === "password"}
                  onClick={() =>
                    showPassword === "password"
                      ? setShowPassword("search")
                      : setShowPassword("password")
                  }
                ></VisibilityIcon>
              </ChangeInputWrapper>
              <ChangeInputWrapper>
                <StyledInput
                  cp
                  error={error.newPassword}
                  type={showPasswordNew}
                  name="newPassword"
                  value={newPassword}
                  placeholder={t("label_new_password")}
                  onChange={(e) => setNewPassword(e.target.value.trim())}
                  onBlur={() => (didMountRef.current = true)}
                />
                {error.newPassword && (
                  <InvalidFeedback>{t(error.newPassword)}</InvalidFeedback>
                )}
                <VisibilityIcon
                  reposition={error.newPassword}
                  show={showPasswordNew === "password"}
                  onClick={() =>
                    showPasswordNew === "password"
                      ? setShowPasswordNew("search")
                      : setShowPasswordNew("password")
                  }
                ></VisibilityIcon>
              </ChangeInputWrapper>
              <ChangeInputWrapper>
                <StyledInput
                  cp
                  error={error.cPassword}
                  type={showPasswordCp}
                  name="cPassword"
                  value={cPassword}
                  placeholder={t("label_confirm_new_password")}
                  onChange={(e) => setCPassword(e.target.value.trim())}
                  onBlur={() => (didMountRef.current = true)}
                />
                {error.cPassword && (
                  <InvalidFeedback>{t(error.cPassword)}</InvalidFeedback>
                )}
                <VisibilityIcon
                  reposition={error.cPassword}
                  show={showPasswordCp === "password"}
                  onClick={() =>
                    showPasswordCp === "password"
                      ? setShowPasswordCp("search")
                      : setShowPasswordCp("password")
                  }
                ></VisibilityIcon>
              </ChangeInputWrapper>
              <ChangeInputWrapper button>
                <ChangePassButton
                  type="submit"
                  disabled={disabledButton}
                  onClick={() => changePassword()}
                >
                  {t("label_submit")}
                </ChangePassButton>
              </ChangeInputWrapper>
            </ChangePasswordWrapper>
          </AccountContainer>
        </PageContentWrapper>
      </PageContent>
    </>
  );
};

export default withRouter(ChangePassword);
