import React from "react";
import { useTranslation } from "react-i18next";
import {
  PaymentProviderWrapper,
  RacFooterTextTop,
  RacLogo18,
} from "../styledComponents/StyledFooter";
import racTrystlylineImg from "../../../rac/src/data/img/logo_trustly.svg";
import { StyledFader } from "../styledComponents/StyledIndex";

const RacTrastlyline = () => {
  const { t } = useTranslation("");

  return (
    <StyledFader>
      <PaymentProviderWrapper ractrystly="true">
        <img alt="" src={racTrystlylineImg} height="50px" />
        <RacFooterTextTop>
          {t("footer-text-top", "The fastest Pay N Play Casino is here")}
        </RacFooterTextTop>
        <RacLogo18 />
      </PaymentProviderWrapper>
    </StyledFader>
  );
};

export default RacTrastlyline;
