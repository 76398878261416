import {
  DescriptionWrapper,
  InnderDescriptionWrapper,
} from "common/src/styledComponents/StyledSlider";
import { Logo18 } from "common/src/styledComponents/StyledFooter";
import { ReactComponent as Logo18Svg } from "common/src/assets/img/logo_18.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";

const MainDescription = ({ pages, skin }) => {
  const { t } = useTranslation("");
  let indexLocation = pages && location.pathname === pages["index"];
  return (
    <DescriptionWrapper maindescription={skin ? false : indexLocation}>
      <InnderDescriptionWrapper>
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              "label_welcome_bonus" /* ,
              `Welcome bonus for a njew players only. Maximum bonus is 100% up to e100.
              Min deposit is e20. No max cash out. Wagering is 35x bonus. Maximumbet
              while playing with a bonus is e5. <br>Eligibility is restriced for a
              suspected abuse. Cashback is cash with no restrictions. Skrill and
              Neteller deposits excluded. Cashbach applies to deposits where no bonus
              is included,</br>` */
            ),
          }}
        ></div>
        {skin !== "cac" && skin !== "hyc" && skin !== "abc" && (
          <Logo18 maindescription={skin ? false : indexLocation}>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  "label_footer-text-top"
                  /* `Gambling can be addictive, <br>play responsibily.</br>` */
                ),
              }}
            ></div>
            <Logo18Svg
              height={
                isMobileOnly ? "30px" : (skin = "pub" ? "21.81px" : "40px")
              }
              id="scroll-to-category"
            />
          </Logo18>
        )}
        {skin === "abc" && (
          <div>
            <Logo18Svg height="30px" id="scroll-to-category" />
          </div>
        )}
      </InnderDescriptionWrapper>
    </DescriptionWrapper>
  );
};

export default MainDescription;
