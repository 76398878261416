import styled from "styled-components";
import { isMobile, isTablet, isMobileOnly } from "react-device-detect";

export const DepositWithdrawalWrapper = styled.div`
  max-width: ${({ ingamedep }) => (ingamedep && isMobile ? "90vw" : "1100px")};
  width: 100%;
  /* min-height: ${({ ingamedep }) =>
    ingamedep ? "" : "calc(70vh - 170px)"}; */
  /*  padding: ${({ theme, ingamedep }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? (theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "yak") &&
        isMobileOnly
        ? "0 0.5rem"
        : "0"
      : ingamedep
      ? "0px 0 0 0"
      : theme.skin === "rac"
      ? "0 0 50px 0"
      : (theme.skin === "pub" ||
          theme.skin === "cac" ||
          theme.skin === "hyc") &&
        isMobileOnly
      ? "8rem 0 2rem 0"
      : "20px 0 0 0"}; */
  padding: ${({ ingamedep }) =>
    ingamedep || isMobileOnly ? "0" : "100px 0 0 0"};
  padding-top: ${({ ingamedep, theme }) =>
    ingamedep && theme.skin !== "rac" && "20px"};
  box-sizing: border-box;
  /* margin: ${({ theme, ingamedep }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak" ||
    theme.skin === "rac" ||
    (theme.skin === "pub" && isMobile) ||
    ((theme.skin === "cac" || theme.skin === "hyc") && !isMobile)
      ? ingamedep
        ? "15px 12px 0 12px"
        : "9rem 0 0 0"
      : theme.skin === "pub" && !isMobile
      ? "8rem 0 0 0"
      : ""}; */
  margin: auto;

  .col2,
  .col3,
  .col4 {
    height: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .col5 {
    width: 150px;
    transition: all 0.2s ease;
    pointer-events: auto;
    cursor: pointer;

    //&:hover {
    //  transform: scale(1.1);
    //}
  }

  .col1 {
    width: ${({ theme }) =>
      theme.skin === "rac"
        ? "145px"
        : theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak"
        ? "80px"
        : "100px"};
    height: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "rac" || theme.skin === "nbc"
        ? "100px"
        : theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak"
        ? "80px"
        : ""};
  }
  button {
    width: ${({ theme }) => theme.skin === "abc" && "145px"};
  }

  @media (max-width: 880px) {
    .col2,
    .col3,
    .col4 {
      font-size: 12px;
      line-height: 14px;
    }
    .col1 {
      width: 80px;
      height: 80px;
    }

    .col3 {
      visibility: hidden;
      width: 0px;
    }

    .col5 {
      font-size: 1rem;
      width: 120px;
    }
    button {
      font-size: 0.7rem !important;
      width: ${({ ingamedep }) =>
        ingamedep ? "85px !important" : "100px !important"};
    }
  }

  @media (max-width: 580px) {
    margin: ${({ theme, ingamedep }) =>
      theme.skin === "abc" ||
      theme.skin === "yet" ||
      theme.skin === "nbc" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? ingamedep
          ? "auto"
          : "9rem 0 5rem 0"
        : ""};
    .col2 {
      visibility: hidden;
      width: 0px;
    }
  }
`;

export const DepositWithdrawalList = styled.div`
  min-height: ${({ theme, ingamedep }) =>
    theme.skin === "abc" ||
    theme.skin === "rac" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? ingamedep
        ? ""
        : "50vh"
      : "70vh"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? isMobileOnly
        ? "0rem auto"
        : "5rem auto"
      : (theme.skin === "pub" ||
          theme.skin === "cac" ||
          theme.skin === "hyc") &&
        isMobileOnly
      ? "8rem auto"
      : "0 auto !important"};

  button {
    ${(props) => {
      if (props.theme.skin === "pub") {
        return `
        border: 2px solid #fff;
        border-radius: 0.3rem;
        :hover {
          border-color: #9A875D;
        }
        `;
      }
    }}
    &.btn-wd-dialog.unavailable,
    &.btn-deposit-dialog.unavailable {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        border-color: #fff;
      }
    }
    //text-transform: uppercase;
  }
  @media (max-width: 768px) {
    min-height: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "35vh"
        : ""};
  }
`;

export const DepositAndWithdrawalRow = styled.div.attrs({
  className: isMobile ? "col-12" : "col-12",
})`
  background: ${({ theme, dialogOpened }) =>
    theme.skin === "abc"
      ? "var(--profileHeaderBgABC)"
      : theme.skin === "rac"
      ? "#5400ff2e"
      : theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak"
      ? dialogOpened
        ? ""
        : theme.skin === "fun" || theme.skin === "yak"
        ? "var(--racProfileMenuCol)"
        : "#fff"
      : ""};
  border-radius: ${({ theme }) =>
    theme.skin === "yet"
      ? "1rem"
      : theme.skin === "fun" || theme.skin === "yak"
      ? "40px"
      : ""};
  border-top: ${({ theme }) =>
    theme.skin === "plc" && "1px solid var(--depositBorder)"};
  width: 100%;
  min-height: 70px;
  margin: ${({ theme }) =>
    theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
      ? "1rem 0"
      : isMobileOnly
      ? "3% 0 0 0"
      : "0.7% 0 0 0"};
  display: flex;
  justify-content: space-between;
  //border-radius: 8px;
  transition: all 0.2s ease;
  pointer-events: auto;
  cursor: pointer;
  align-items: center;
  opacity: ${({ testing }) => (testing ? "0.3" : "")};
  //padding: 0;
  border-bottom: ${({ theme }) => theme.skin === "pub" && "2px solid"};

  &:first-child {
    border-top: none;
  }

  //&:hover {
  //  background: rgba(84, 0, 255, 0.15);
  //}

  & > p {
    color: ${({ theme }) =>
      theme.skin === "yet" ? "#07415f" : "var(--tabletext)"};
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    margin-left: ${({ theme }) =>
      (theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
      !isMobileOnly &&
      "1rem"};
    max-height: 70px;
  }
  button {
    margin-right: ${({ theme }) =>
      (theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
      !isMobileOnly &&
      "1rem"};
    background-color: ${({ theme }) =>
      theme.skin === "yet" ? "#07415f" : theme.skin === "fun" ? "#fa2c70" : ""};
    color: ${({ theme }) =>
      (theme.skin === "yet" || theme.skin === "fun") && "#fff"};
  }
  @media (max-width: 767px) {
    /* height: 20vh !important; */
    /* margin: ${({ theme }) => theme.skin === "pub" && "0.5rem"}; */
  }
`;

export const DepositWithdrawalText = styled.p.attrs({
  className: "mt-5 col-12",
})`
  color: ${({ theme }) =>
    theme.skin === "abc"
      ? "var(--smallHeaderABC)"
      : theme.skin === "fun" || theme.skin === "yak"
      ? "#fff"
      : "var(--tabletext)"};
  opacity: ${({ theme }) =>
    (theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc") &&
    "0.4"};
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
`;

export const FrameBox = styled.div`
  // height: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  align-content: center;

  iframe {
    border: none;
    width: 100%;
    max-width: 1130px;
    /* min-height: 600px; */
    background-color: #fff;
    border-radius: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "20px"};
    border: 2px solid #fff;
  }
  /* @media (max-width: 580px) {
    iframe {
      min-height: 400px;
      height: ${({ theme }) =>
    theme.skin === "fun" || theme.skin === "yak"
      ? "calc(100vh - 125px)"
      : "85vh"};
      margin-top: ${({ theme }) =>
    theme.skin === "fun" || theme.skin === "yak" ? "70px" : "15vh"};
      margin-bottom: ${({ theme }) =>
    (theme.skin === "fun" || theme.skin === "yak") && "55px"};
    }
  } */
`;

export const PageheadlinePar = styled.p`
  color: var(--acolor);
  font-weight: ${({ theme }) => (theme.skin === "rac" ? "500" : "600")};
  letter-spacing: 0.5px;
  font-size: 22px;
  text-align: ${({ center }) => (center ? "center" : "")};
  padding-top: ${({ theme, dep }) => theme.skin === "abc" && "50px"};

  /* ${(props) => {
    if (props.dep) {
      return `
        position: absolute;
        top: 2.5rem;
        left: 50%;
        z-index: 1;
        transform: translateX(-50%);
        @media (max-width: 1024px) {
          top: ${isTablet ? "5.5rem" : "7rem"};
        }
      `;
    }
  }} */

  h3 {
    color: ${({ theme }) =>
      theme.skin === "abc" ? "var(--tabletext)" : "var(--acolor)"};
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "18px"
        : "22px"};
    text-align: ${({ center }) => (center ? "center" : "")};
  }
  @media (max-width: 580px) {
    width: 100%;
    font-size: ${({ theme }) => (theme.skin === "rac" ? "24px" : "16px")};
    line-height: ${({ theme }) => (theme.skin === "rac" ? "50px" : "21px")};
  }
`;

export const StyledWithdrawalDialog = styled.form.attrs({
  className: "col-lg-8 col-md-10 col-12 offset-md-1 offset-lg-2",
})`
  margin-top: ${({ bankDialog }) => (bankDialog ? "10vh" : "")};
  margin-bottom: ${({ theme, bankDialog }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak"
      ? bankDialog
        ? "5rem"
        : ""
      : ""};

  button {
    //text-transform: uppercase;
    font-size: ${({ theme }) =>
      theme.skin === "rac"
        ? "0.875rem !important"
        : theme.skin === "pub"
        ? "0.8rem !important"
        : "20px !important"};
    height: auto;
    padding-left: ${({ theme }) => (theme.skin === "rac" ? "" : "0px")};
    padding-right: ${({ theme }) => (theme.skin === "rac" ? "" : "0px")};
    padding-bottom: ${({ theme }) => (theme.skin === "rac" ? "" : "0px")};
    padding-top: ${({ theme }) => (theme.skin === "rac" ? "" : "0px")};
    line-height: ${({ theme }) => (theme.skin === "rac" ? "" : "37px")};
    border-radius: ${({ theme }) =>
      theme.skin === "fun" || theme.skin === "cac" || theme.skin === "yak"
        ? "20px"
        : theme.skin === "hyc"
        ? "10px"
        : "6px"};
    font-weight: ${({ theme }) => (theme.skin === "pub" ? "400" : "800")};
    // padding-top: 5px;
    // padding-bottom: 5px;

    &.button-first {
      background-size: cover;
    }
  }
  .form-group {
    label {
      color: var(--tabletext) !important;
      min-height: 15px;
      font-size: 10px;
      margin-bottom: 0.1rem;
    }
  }
  legend {
    color: var(--tabletext) !important;
    min-height: 15px;
    font-size: 15px;
    margin: ${({ theme }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak") &&
      "0.5rem 0"};
  }

  input,
  select {
    color: var(--inputtext);
    font-weight: 800;

    &::placeholder {
      font-weight: 400;
      text-transform: capitalize;
      font-size: 0.85rem;
    }
  }
  .sort {
    margin-left: 0px;
    margin-right: 0px;
  }
  @media (max-width: 450px) {
    button {
      font-size: ${({ theme }) =>
        theme.skin === "rac" ? "0.7rem !important" : "14px !important"};
    }
  }

  @media (max-width: 320px) {
    button {
      font-size: 12px !important;
    }
  }
  @media (min-width: 576px) {
    button {
      font-size: 26px;
      padding-top: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? "0"
          : "0.75rem"};
      padding-bottom: ${({ theme }) =>
        theme.skin === "abc" ? "0" : "0.75rem"};

      &.button-first {
        background-size: 100% 100%;
      }
    }
    .form-group {
      label {
        font-size: 12px;
      }
    }
  }
  @media (min-width: 1200px) {
    button {
      height: ${({ theme }) =>
        theme.skin !== "plc" && theme.skin !== "bnk"
          ? "45px"
          : theme.skin === "rac"
          ? ""
          : "70px"};
    }
  }
  @media (min-width: 992px) {
    button {
      font-size: ${({ theme }) =>
        theme.skin !== "plc" && theme.skin !== "rac"
          ? "0.875rem !important"
          : "26px"};
      padding-top: ${({ theme }) =>
        theme.skin !== "plc" && theme.skin !== "bnk" ? "0" : "0.75rem"};
      padding-bottom: ${({ theme }) =>
        theme.skin !== "plc" && theme.skin !== "bnk" ? "0" : "0.75rem"};
    }
  }
`;

export const WithdrawalRow = styled.div`
  background: none;
  cursor: default;

  img {
    // margin-left: -20px;
    //transform: scale(1.4);
    margin-right: 15px;
    margin-left: -5px;
  }

  &:hover {
    background: none;
  }

  .col2,
  .col3,
  .col4 {
    color: var(--tabletext);
    height: 100%;
    padding: 0;
    margin: 0;
    display: block;
    text-align: left;
    align-items: center;
    height: 20%;
    flex: 1;
    justify-content: flex-start;
    flex: 0;
    visibility: visible;
    width: 100%;
    font-size: 10px;
  }

  .col3 {
    visibility: visible;
  }
`;

export const WithdrawalImg = styled.img.attrs({
  className: "col-lg-2 col-sm-4 col-4",
})`
  padding: 0 !important;
`;

export const StyledColWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 576px) {
    .col2,
    .col3,
    .col4 {
      font-size: 12px;
    }
  }

  @media (min-width: 992px) {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .col2,
    .col3,
    .col4 {
      display: flex;
      text-align: initial;
    }

    .col3 {
      justify-content: center;
      flex: 1;
    }

    .col4 {
      justify-content: flex-end;
      flex: 1;
      height: 40%;
      border-bottom: 1px solid var(--depositBorder);
    }

    .col1,
    .col2,
    .col3 {
      border-bottom: 1px solid var(--depositBorder);
      height: 40%;
      flex: 1;
    }
  }
`;

export const WithdrawalFormGroup = styled.div.attrs((props) => ({
  className: `form-group col-12 text-left ${props.extraCol && props.extraCol} ${
    props.theme.skin === "abc" && "mb-3"
  }`,
}))``;

export const StyledWithdrawalDescription = styled.div.attrs({
  className: "mt-4 col-12 text-center",
})`
  color: var(--tabletext);
`;

export const CloseIframeWrapper = styled.div.attrs({
  className: "",
})`
  width: 100%;
  max-width: 1130px;
  position: relative;

  button {
    color: #908e8e;
    top: 0;
    ::after {
      font-size: 2rem;
    }
    @media (max-width: 580px) {
      top: 0px;
    }
    :hover {
      color: #b8b4b4;
    }
  }
`;
