import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
  ModalClower,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const LockUkDialog = ({
  showModal,
  handleProceed,
  handleCancel,
  skin,
}) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      {skin === "plc" && <ModalClower topright />}
      <StyledModalBody
        register
        dangerouslySetInnerHTML={{
          __html: t("message_want_lock_uk"),
        }}
      ></StyledModalBody>
      <StyledModalFooter>
        <StyledButton
          dialog
          pub={skin === "pub"}
          onClick={() => handleCancel()}
        >
          {t("label_no")}
        </StyledButton>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleProceed()}
        >
          {t("label_yes")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
