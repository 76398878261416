export const RGV2 = (type) => {
  let popupMessage = '';
  let popupBtn = '';
  let popupBtn1 = '';

  let session_keyword = 'threshold-session-';
  let threshold40k_keyword = 'threshold-40k-session-';
  let threshold80k_keyword = 'threshold-80k-session-';
  let covid_keyword = 'threshold-covid-session-';

  if (type.indexOf(session_keyword) > -1) {
    //1st dialog
    localStorage.setItem('redirect-on-deposit', 'dont');
    popupMessage = 'message_rg1_threshold';
    popupBtn = 'label_rg1_threshold_confirm';
    localStorage.setItem('display_responsible_gaming_popup', 'display-popup');
    //setIsOpen(true);
  } else if (type.indexOf(threshold40k_keyword) > -1) {
    //5th dialog
    localStorage.setItem('redirect-on-deposit', 'dont');
    popupMessage = 'message_rg4_threshold';
    popupBtn = 'label_rg4_threshold_confirm';
  } else if (type.indexOf(threshold80k_keyword) > -1) {
    //6th dialog

    popupMessage = 'message_rg5_threshold';
    popupBtn = 'label_rg5_threshold_confirm';
  } else if (type.indexOf(covid_keyword) > -1) {
    //6th dialog
    localStorage.setItem('redirect-on-deposit', 'dont');
    popupMessage = 'message_rg_covid_threshold';
    popupBtn = 'label_ok';
    popupBtn1 = 'label_covid_contact_support';
  } else {
    switch (type) {
      case 'threshold-kyc':
        //2nd dialog
        localStorage.setItem('redirect-on-deposit', 'dont');
        popupMessage = 'message_kyc_threshold';
        popupBtn = 'label_kyc_threshold_play';
        break;
      case 'threshold-limits-advice':
        //3rd dialog
        localStorage.setItem('redirect-on-deposit', 'dont');
        popupMessage = 'message_rg2_threshold';
        popupBtn = 'label_rg2_threshold_confirm';
        break;
      case 'threshold-sow':
        //4th dialog
        localStorage.setItem('redirect-on-deposit', 'dont');
        popupMessage = 'message_rg3_threshold';
        popupBtn = 'label_rg3_threshold_confirm';
        break;
      default:
        break;
    }
  }

  return { popupMessage, popupBtn, popupBtn1 };
};
