import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AllBonuses from "common/src/component/AllBonuses";
import AvailableBonus from "common/src/component/AvailableBonus";
import ActiveBonus from "common/src/component/ActiveBonus";
import PreviousBonuses from "common/src/component/PreviousBonuses";
import { useApolloClient } from "@apollo/client";
import {
  QUERY_GET_PLAYER_BONUSES,
  QUERY_GET_AVAILABLE_BONUSES,
} from "common/src/graphql/queries";
import BonusCode from "common/src/component/BonusCode";
import { SERVER_SETUP } from "common/src/helpers/constants";

import { ContentTop } from "common/src/component/ContentTop";
import config from "../config.json";
import { isMobileOnly } from "react-device-detect";
import {
  AccountContainer,
  AccountDescription,
  PageContent,
  PageContentWrapper,
  StyledTab,
  StyledTabList,
  StyledTabs,
  StyledTabPanel,
  TextBottom,
} from "common/src/styledComponents/AccountPage";
import { TabContent } from "reactstrap";
import { FooterButton } from "common/src/styledComponents/StyledFooter";
import { StyledMaterialIcon } from "common/src/styledComponents/StyledHeader";

const Bonuses = ({ playerCurrency, server, pages, titles, skin }) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();

  const [allBonuses, setAllBonuses] = useState({});
  const [activeBonuses, setAtiveBonuses] = useState({});
  const [claimedBonuses, setClaimedBonuses] = useState({});
  const [availableBonuses, setAvailableBonuses] = useState({});
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const [expand, setExpand] = useState(false);

  const handleExpend = (e) => {
    e.preventDefault();
    setExpand(!expand);
  };

  useEffect(() => {
    // console.log(titles)
    document.title = titles["bonuses"];
  }, [titles]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    async function getAllBonuses() {
      const { loading, errors, data } = await apolloClient.query({
        query: QUERY_GET_PLAYER_BONUSES,
      });
      if (loading) return <h3>Loading ...</h3>;
      if (errors) return <h1>`Error: ${errors}`</h1>;
      //console.log("bonuses " + data.getPlayerBonuses.bonuses);
      setAllBonuses(data.getPlayerBonuses);
      let activeBonuses =
        data.getPlayerBonuses.bonuses !== 0
          ? data.getPlayerBonuses.bonuses.filter(
              (bonus) => bonus.status === "active"
            )
          : {};
      setAtiveBonuses(activeBonuses);
      let claimedBonuses =
        data.getPlayerBonuses.bonuses !== 0
          ? data.getPlayerBonuses.bonuses.filter(
              (bonus) => bonus.status !== "active"
            )
          : {};
      setClaimedBonuses(claimedBonuses);
      return null;
    }

    async function getAvailableBonuses() {
      const { loading, errors, data } = await apolloClient.query({
        query: QUERY_GET_AVAILABLE_BONUSES,
      });
      if (loading) return <h3>Loading ...</h3>;
      if (errors) return <h1>`Error: ${errors}`</h1>;
      //console.log("available bonuses " + data.getAvailableBonuses.bonuses);
      setAvailableBonuses(data.getAvailableBonuses);

      return null;
    }

    getAllBonuses();
    getAvailableBonuses();
  }, [apolloClient]);

  return (
    <>
      <ContentTop
        serverSetup={serverSetup}
        showProfileMenu={!isMobileOnly}
        pages={pages}
        skin={skin}
        isMyAccount={"label_bonuses"}
      />
      <PageContent account="true" nobg="true">
        <PageContentWrapper>
          <AccountContainer>
            <TabContent>
              <AccountDescription expand={expand}>
                <h5
                  dangerouslySetInnerHTML={{
                    __html: t("label_bonuses_description"),
                  }}
                ></h5>
              </AccountDescription>
              <FooterButton onClick={handleExpend}>
                <StyledMaterialIcon
                  as="span"
                  icon={expand}
                ></StyledMaterialIcon>
              </FooterButton>
              <StyledTabs defaultIndex={2} selectedTabClassName="active">
                <StyledTabList>
                  <StyledTab>{t("label_all", "All")}</StyledTab>
                  <StyledTab>{t("label_active_bonus")}</StyledTab>
                  <StyledTab>{t("label_available_bonus")}</StyledTab>
                  <StyledTab>{t("label_previous_bonuses")}</StyledTab>
                  {config.skin !== "plc" && (
                    <StyledTab>{t("label_bonus_code")}</StyledTab>
                  )}
                </StyledTabList>
                <StyledTabPanel>
                  <AllBonuses
                    allBonuses={allBonuses}
                    availableBonuses={availableBonuses}
                    playerCurrency={playerCurrency}
                    server={server}
                  ></AllBonuses>
                </StyledTabPanel>
                <StyledTabPanel>
                  <ActiveBonus
                    activeBonuses={activeBonuses}
                    playerCurrency={playerCurrency}
                    server={server}
                    pages={pages}
                    bonusImagePath={config.imagesPath}
                    skin={config.skin}
                  ></ActiveBonus>
                </StyledTabPanel>
                <StyledTabPanel>
                  <AvailableBonus
                    availableBonuses={availableBonuses}
                    playerCurrency={playerCurrency}
                    server={server}
                    pages={pages}
                    bonusImagePath={config.imagesPath}
                    skin={config.skin}
                  />
                </StyledTabPanel>
                <StyledTabPanel>
                  <PreviousBonuses
                    claimedBonuses={claimedBonuses}
                    playerCurrency={playerCurrency}
                    server={server}
                    skin={config.skin}
                    bonusImagePath={config.imagesPath}
                  />
                </StyledTabPanel>
                {config.skin !== "plc" && (
                  <StyledTabPanel>
                    <BonusCode></BonusCode>
                  </StyledTabPanel>
                )}
              </StyledTabs>
              {skin === "rac" && (
                <TextBottom
                  dangerouslySetInnerHTML={{
                    __html: t("label_bonuses_description_bottom"),
                  }}
                ></TextBottom>
              )}
            </TabContent>
          </AccountContainer>
        </PageContentWrapper>
      </PageContent>
    </>
  );
};

export default Bonuses;
