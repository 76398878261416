import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PaymentServer } from "common/src/helpers/connectors";
import { toast } from "react-toastify";
import Select from "common/src/component/select";
import { SERVER_SETUP } from "common/src/helpers/constants";

import IdinStepNewWindow from "common/src/component/IdinStepNewWindow";
import { StyledFormRow } from "../styledComponents/AccountPage";
import {
  IdinStepTwo,
  LogoIdinImg,
  RegisterBSNAndStepWrapper,
  RegisterStepWrapper,
  ResetPageheadlinePar,
  StepidinFormGroup,
  TitlePagesHeader,
} from "../styledComponents/StyledSignupLogin";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import idinLogo from "common/src/assets/img/idin_logo.png";

const RegisterStepIdin = ({
  errors,
  touched,
  formData,
  navigation,
  handleChange,
  handleBlur,
  setSpinner,
  setTouchedOnRest,
}) => {
  const { idinBank } = formData;

  const { go, pause } = navigation;
  const nextStep = "secondNL";
  const { t, i18n } = useTranslation("");
  const language = i18n.language;
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  const [bankOptions, setBankOptions] = useState([]);
  const [idinIframeSrc, setIdinIframeSrc] = useState("");
  const [idinStep, setIdinStep] = useState(0);
  let paramsForCheck = ["idinBank"];
  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight / 1.5;

  if (window.innerWidth >= 800) {
    windowWidth = 800;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const handler = (event) => {
      // console.log("FRAME EVENT: " + JSON.stringify(event));
      // console.log(typeof event.data)
      // console.log("origin" + event.origin)
      // console.log("origin 2: " + window.location.origin)
      if (event.origin === window.location.origin) {
        // console.log("same origin!");
        let data = null;
        if (typeof event.data !== "undefined") {
          try {
            data = JSON.parse(event.data);
          } catch (e) {
            data = event.data;
          }
          // console.log("DATA: " + JSON.stringify(event.data))
          if (typeof data.message !== "undefined") {
            setIdinStep(data.message);
            if (data.message === 3) {
              go(nextStep);
            } else {
              toast.error(t(data.error, data.error));
            }
          }
        }
      }
    };

    window.addEventListener("message", handler);

    // clean up
    return () => window.removeEventListener("message", handler);
  }, [setIdinStep]); // empty array => run only once

  useEffect(() => {
    const getBanks = async () => {
      setSpinner(true);
      PaymentServer.post(
        /*'http://localhost:9001'+*/ "/fhnd/v1/idin/get-banks",
        {
          ipAddress: serverSetup.geoIP.ipAddress,
          locale: language,
        }
      )
        .then((res) => {
          // console.log("BANKS: " + JSON.stringify(res.data.banks));
          if (typeof res.data.maintenance !== "undefined") {
            //idin maintenance, proceed to step 2
            go(nextStep);
            return true;
          }
          let bankArray = [];
          Object.keys(res.data.banks).forEach((key) => {
            let bank = {
              value: res.data.banks[key].IssuerID,
              name: res.data.banks[key].IssuerName,
            };
            bankArray.push(bank);
          });
          setSpinner(false);
          setBankOptions(bankArray);
          return true;
        })
        .catch((err) => {
          setSpinner(false);
          let errMessage = t(err.response.data[0]);
          if (typeof err.response.data[1] !== "undefined") {
            if (err.response.data[1] !== "") {
              errMessage = errMessage + ": " + err.response.data[1];
            }
          }
          toast.error(errMessage);
          return false;
        });
    };

    setSpinner(true);
    getBanks()
      .then(() => {
        setSpinner(false);
      })
      .catch(() => {
        setSpinner(false);
      });
  }, []);

  /* on next check mandatory fields */
  const checkIsValid = () => {
    setTouchedOnRest(paramsForCheck);
    let hasErrors = 0;
    errors &&
      paramsForCheck.forEach((p) => {
        for (let key of Object.keys(errors)) {
          if (p === key) {
            hasErrors++;
          }
        }
      });
    if (hasErrors === 0) {
      return true;
    } else {
      return false;
    }
  };

  const authenticate = (bankId) => {
    // console.log("--------- authenticate --------" + bankId);
    setSpinner(true);
    if (typeof bankId !== "undefined") {
      PaymentServer.post(
        /*'http://localhost:9001' + */ "/fhnd/v1/idin/authenticate",
        {
          ipAddress: serverSetup.geoIP.ipAddress,
          bankID: bankId,
          locale: language,
        }
      )
        .then((res) => {
          // console.log("BANKS: " + JSON.stringify(res.data.banks));
          setSpinner(false);
          setIdinIframeSrc(res.data.authUrl);
          if (res.data.redirect === "newWindow") {
            setIdinStep(2);
          } else if (res.data.redirect === "iframe") {
            setIdinStep(1);
          }
          return true;
        })
        .catch((err) => {
          setSpinner(false);
          let errMessage = t(err.response.data[0]);
          if (typeof err.response.data[1] !== "undefined") {
            if (err.response.data[1] !== "") {
              errMessage = errMessage + ": " + err.response.data[1];
            }
          }
          toast.error(errMessage);
          return false;
        });
    } else {
      // alert('choose bank');
      setSpinner(false);
      toast.error(t("error_choose_bank", "error_choose_bank"));
    }

    if (idinStep === 2) {
      // console.log("go to the next step");
      go(nextStep);
    }
  };

  return (
    <>
      <RegisterStepWrapper idinstep={!idinStep === 0}>
        <RegisterBSNAndStepWrapper stepone>
          <TitlePagesHeader
            dangerouslySetInnerHTML={{
              __html: t("label_register_title", "Register"),
            }}
          ></TitlePagesHeader>
          {idinStep !== 2 && (
            <ResetPageheadlinePar
              dangerouslySetInnerHTML={{
                __html: t(
                  "label_register_idin",
                  "We verifieren uw identiteit en leeftijd via Idin."
                ),
              }}
            ></ResetPageheadlinePar>
          )}
        </RegisterBSNAndStepWrapper>
        {idinStep === 0 && (
          <StyledFormRow>
            <Select
              groupClass="form-group col-md-8 offset-md-2 mb-3"
              name="idinBank"
              list={bankOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              firstOption={t("label_select_idin_bank")}
              errors={
                errors.idinBank && touched.idinBank ? errors.idinBank : null
              }
              disabled={false}
              required
            />
            <StepidinFormGroup>
              <StyledButton
                as="div"
                gdpr
                spancol={"col-md-12"}
                // style={{ fontSize: '13px', textTransform: 'capitalize' }}
                // dangerouslySetInnerHTML={{ __html: t('label_idin_authenticate') }}
                onClick={() =>
                  checkIsValid() ? authenticate(idinBank) : pause()
                }
              >
                {t("label_idin_authenticate", "authenticate")}{" "}
                <LogoIdinImg alt="idin_logo.png" src={idinLogo} />
              </StyledButton>
            </StepidinFormGroup>
          </StyledFormRow>
        )}
        {idinStep === 1 && (
          <StyledFormRow>
            <iframe
              id="idin-iframe"
              title="idin"
              src={idinIframeSrc}
              scrolling="no"
              width="100%"
              height="100%"
              frameBorder="no"
              style={{ minWidth: "100%", minHeight: "100%" }}
            ></iframe>
          </StyledFormRow>
        )}
        {idinStep === 2 && (
          <StyledFormRow>
            <IdinStepTwo>
              <IdinStepNewWindow
                src={idinIframeSrc}
                width={windowWidth}
                height={windowHeight}
                setSpinner={setSpinner}
                setIdinStep={setIdinStep}
              />
            </IdinStepTwo>
          </StyledFormRow>
        )}
      </RegisterStepWrapper>
    </>
  );
};

export default RegisterStepIdin;
