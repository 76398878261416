import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AccountDescription } from "../styledComponents/AccountPage";
import {
  StyledButton,
  StyledSelect,
} from "../styledComponents/CommonStyledComponents";
import {
  StyledSectionTimeLimit,
  StyledLimitWrapper,
  StyledTimeLimit,
  StyledCooldownExplanationSmall,
  RealityCheckWrapper,
  IncreaseLimit,
} from "../styledComponents/StyledLimits";

const DailyTimeLimit = ({
  limitsData,
  timeLimit,
  handleSubmit,
  handleChange,
  isUk,
  realityCheck,
  player,
  skin,
}) => {
  const { t, i18n } = useTranslation("");
  const { limitsLocked } = player;

  const { dailyValue, weeklyValue, monthlyValue } = limitsData;

  const [maxDailyValue, setMaxDailyValue] = useState(24);
  const [maxWeeklyValue, setMaxWeeklyLimit] = useState(167);
  const [maxMonthlyValue, setMaxMonthlyLimit] = useState(720);

  const [hasIncrease /*setHasIncrease*/] = useState(false);
  const [increaseTime /*setIncreaseTime*/] = useState("");
  const [blockChange, setBlockChange] = useState(false);
  const localizedFormat = `${
    i18n.language || window.localStorage.i18nextLng || "nl"
  }`;

  //console.log(timeLimit.filter((limit) => limit.period === 'daily'));
  let daily = timeLimit.filter((limit) => limit.period === "daily")[0];
  let weekly = timeLimit.filter((limit) => limit.period === "weekly")[0];
  let monthly = timeLimit.filter((limit) => limit.period === "monthly")[0];
  //CSS variables
  let plcConfig = skin === "plc" ? true : false;

  useEffect(() => {
    function hasLimit() {
      /*  if (timeLimit[0].increaseAt !== '') {
        setHasIncrease(true);
        setIncreaseTime(timeLimit[0].increaseAt);
      }  */
      if (
        daily &&
        daily.expiresAt !== "" &&
        daily.status === "active" &&
        skin === "plc"
      ) {
        setMaxDailyValue(dailyValue);
      }
      if (
        weekly &&
        weekly.expiresAt !== "" &&
        weekly.status === "active" &&
        skin === "plc"
      ) {
        //console.log("imali te ");
        setMaxWeeklyLimit(weeklyValue);
      }
      if (
        monthly &&
        monthly.expiresAt !== "" &&
        monthly.status === "active" &&
        skin === "plc"
      )
        setMaxMonthlyLimit(monthlyValue);
      //setBlockChange(true);
    }
    timeLimit.length > 0 && hasLimit();
  }, [timeLimit]);

  return (
    <>
      {(skin === "fun" ||
        skin === "cac" ||
        skin === "hyc" ||
        skin === "yak") && (
        <AccountDescription
          timelimit
          expand={true}
          dangerouslySetInnerHTML={{
            __html: t("lable_daily_time_limit_description"),
          }}
        >
          {/*  <p>
        Daily - a period of a single day*
        <br />
        Weekly - a period of seven days, from Monday to Sunday*
        <br />
        Monthly - a calendar month, from the first day of the month
        <br />
        till the last day of the month*
        <br />
        <br />
        *) From 00:00 till 23:59 UTC
        <br />
        <br />
        -Any increase in limits will go through a 24 hour cooling period
        before being applied.
        <br />
        <br />
      </p>
      <p>
        -Note that a weekly time limit can't be less than a daily time
        limit and monthly time limit can't be less than a weekly time
        limit.
        <br />
        <br />
        Players residing in the UK have the following default limits
        applied:
        <br />
        Deposit: 100.000
        <br />
        Bet: 100.000.000
        <br />
        Loss: 100.000.000
        <br />
        <br />
        Player residing in Sweden are not allowed to play without a
        deposit limit.
        <br />
        <br />
      </p> */}
        </AccountDescription>
      )}
      <StyledLimitWrapper limit timelimit timelimitAbc>
        <StyledTimeLimit>
          <label>
            {t("label_choose_daily_limit")} {t("label_hours")}
          </label>
          <StyledSelect
            onChange={(e) => handleChange(e)}
            name="dailyValue"
            value={dailyValue ? dailyValue : ""}
            disabled={isUk && limitsLocked}
          >
            <option value="" disabled selected hidden>
              -
            </option>
            {Array.from(Array(24), (_, i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </StyledSelect>
          {daily && daily.expiresAt !== "" && daily.status === "active" && (
            <StyledCooldownExplanationSmall>
              {t("cooldown_explanation")}{" "}
              {new Date(daily.expiresAt).toLocaleString(localizedFormat)}
            </StyledCooldownExplanationSmall>
          )}
        </StyledTimeLimit>
        <StyledTimeLimit>
          <label>
            {t("label_choose_weekly_limit")} {t("label_hours")}
          </label>
          <StyledSelect
            onChange={(e) => handleChange(e)}
            value={weeklyValue ? weeklyValue : ""}
            name="weeklyValue"
            disabled={isUk && limitsLocked}
          >
            <option value="" disabled selected hidden>
              -
            </option>
            {Array.from(Array(167), (_, i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            )).slice(24, 167)}
          </StyledSelect>
          {weekly && weekly.expiresAt !== "" && weekly.status === "active" && (
            <StyledCooldownExplanationSmall>
              {t("cooldown_explanation")}{" "}
              {new Date(weekly.expiresAt).toLocaleString(localizedFormat)}
            </StyledCooldownExplanationSmall>
          )}
        </StyledTimeLimit>
        <StyledTimeLimit>
          <label>
            {t("label_choose_monthly_limit")} {t("label_hours")}
          </label>
          <StyledSelect
            onChange={(e) => handleChange(e)}
            value={monthlyValue ? monthlyValue : ""}
            name="monthlyValue"
            disabled={isUk && limitsLocked}
          >
            <option value="" disabled selected hidden>
              -
            </option>
            {Array.from(Array(720), (_, i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            )).slice(167, 720)}
          </StyledSelect>
          {monthly &&
            monthly.expiresAt !== "" &&
            monthly.status === "active" && (
              <StyledCooldownExplanationSmall>
                {t("cooldown_explanation")}{" "}
                {new Date(monthly.expiresAt).toLocaleString(localizedFormat)}
              </StyledCooldownExplanationSmall>
            )}
        </StyledTimeLimit>
        <StyledSectionTimeLimit
          padding={plcConfig ? "pb-5" : ""}
          border={plcConfig ? "border-bottom" : "border-0"}
        >
          <StyledButton
            pub={skin === "pub"}
            limit
            type="submit"
            disabled={hasIncrease || (isUk && limitsLocked)}
            onClick={() => handleSubmit("time")}
          >
            {t("label_save", "Save")}
          </StyledButton>
        </StyledSectionTimeLimit>
        {skin === "rac" && (
          <RealityCheckWrapper extrapadding>
            {realityCheck !== -1 && (
              <p>
                {t("label_reality_check")} {Math.floor(realityCheck / 60)}{" "}
                {t("label_reality_check_minutes")}
              </p>
            )}
          </RealityCheckWrapper>
        )}
      </StyledLimitWrapper>
      {hasIncrease && (
        <StyledSectionTimeLimit padding={"p-3"} border={"border-0"}>
          <h3>
            {t("label_increased_limit")} {increaseTime}
          </h3>
        </StyledSectionTimeLimit>
      )}
      {skin !== "rac" && skin !== "fun" && (
        <AccountDescription
          timelimit
          expand={true}
          dangerouslySetInnerHTML={{
            __html: t("message_timelimitLimit_description"),
          }}
        >
          {/*  <p>
        Daily - a period of a single day*
        <br />
        Weekly - a period of seven days, from Monday to Sunday*
        <br />
        Monthly - a calendar month, from the first day of the month
        <br />
        till the last day of the month*
        <br />
        <br />
        *) From 00:00 till 23:59 UTC
        <br />
        <br />
        -Any increase in limits will go through a 24 hour cooling period
        before being applied.
        <br />
        <br />
      </p>
      <p>
        -Note that a weekly time limit can't be less than a daily time
        limit and monthly time limit can't be less than a weekly time
        limit.
        <br />
        <br />
        Players residing in the UK have the following default limits
        applied:
        <br />
        Deposit: 100.000
        <br />
        Bet: 100.000.000
        <br />
        Loss: 100.000.000
        <br />
        <br />
        Player residing in Sweden are not allowed to play without a
        deposit limit.
        <br />
        <br />
      </p> */}
        </AccountDescription>
      )}
      {skin === "rac" && (
        <IncreaseLimit>
          <p>{t("message_timelimitLimit_description")}</p>
        </IncreaseLimit>
      )}
    </>
  );
};

export default DailyTimeLimit;
