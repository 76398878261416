import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { COOKIES_CONFIRMATION } from "common/src/helpers/constants";
import {
  CookieButtons,
  CookieConfirmationDialog,
  CookieConfirmationWrapper,
  CookieMessage,
} from "../styledComponents/StyledCookieConfirmation";

const CookiesConfirmationDialog = ({ pages }) => {
  const { t } = useTranslation();

  const [showDialog, setShowDialog] = useState(
    localStorage.getItem(COOKIES_CONFIRMATION) === null ? false : true
  );
  const acceptCookie = (e) => {
    e.preventDefault();
    localStorage.setItem(COOKIES_CONFIRMATION, "hidden");
    setShowDialog(true);
  };
  return (
    <CookieConfirmationWrapper hidden={showDialog}>
      <CookieConfirmationDialog>
        <CookieMessage>
          <div
            dangerouslySetInnerHTML={{
              __html: t("cookies_notice", { link: pages["privacy-policy"] }),
            }}
          />
        </CookieMessage>
        <CookieButtons>
          <button onClick={acceptCookie}>{t("label_ok")}</button>
        </CookieButtons>
      </CookieConfirmationDialog>
    </CookieConfirmationWrapper>
  );
};

export default CookiesConfirmationDialog;
