import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  RegisterStepWrapper,
  RegisterBSNAndStepWrapper,
} from "../styledComponents/StyledSignupLogin";
import { StyledFormRow } from "../styledComponents/AccountPage";
import {
  StyledButton,
  StyledFlexCenter,
} from "../styledComponents/CommonStyledComponents";
import { PageheadlinePar } from "../styledComponents/StyledDepositAndWithdrawal";

import HooyuIFrame from "../component/HooyuIFrame";
import { QUERY_HOOYU_REQUEST_LINK } from "common/src/graphql/queries";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

const RegisterStepVerify = ({ player, pages, setSpinner }) => {
  const apolloClient = useApolloClient();
  const { t } = useTranslation("");
  const [step, setStep] = useState(0); // 0 - text, 1 - hooyu iframe, 2 - success redirect?
  const [kycIframeSrc, setkycIframeSrc] = useState("");

  async function startKYC() {
    setSpinner(true);
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_HOOYU_REQUEST_LINK,
    });
    if (loading) return <h3>Loading ...</h3>;
    if (errors) {
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      setSpinner(false);
      return false;
    }
    setSpinner(false);
    setkycIframeSrc(data.getHooyuRequestLink.link);
    setStep(1);
    return true;
  }

  return (
    <RegisterStepWrapper>
      {step === 0 && (
        <RegisterBSNAndStepWrapper stepone>
          <PageheadlinePar
            as="h3"
            dangerouslySetInnerHTML={{
              __html: t("label_kyc_description", {
                firstname: player.firstName,
              }),
            }}
          ></PageheadlinePar>
        </RegisterBSNAndStepWrapper>
      )}
      {step === 0 && (
        <StyledFlexCenter>
          <StyledButton banktransfer onClick={() => startKYC()}>
            {t("label_lets_start", "label_lets_start")}
          </StyledButton>
        </StyledFlexCenter>
      )}

      {step === 1 && (
        <StyledFormRow>
          <HooyuIFrame
            src={kycIframeSrc}
            className=""
            width="100%"
            height="1000px"
            pages={pages}
          />
        </StyledFormRow>
      )}
    </RegisterStepWrapper>
  );
};

export default RegisterStepVerify;
