import React, { useEffect, useRef } from "react";
import {HOOYU_MULTIPLE} from "../helpers/constants";
import {MUTATION_HOOYU_PENDING} from "../graphql/mutations";
// import { Redirect } from "react-router-dom";
import {useApolloClient} from "@apollo/client";
import { getToken } from "common/src/helpers/session";

const HooyuIFrame = ({ className, src, width, height, pages }) => {
  const apolloClient = useApolloClient();
  const frameRef = useRef(null);

  async function hooyuPending(link) {
    //console.log(uid);
    const { loading, errors } = await apolloClient.mutate({
      mutation: MUTATION_HOOYU_PENDING,
      variables: {link : link},
    });

    if (loading) return "Loading ...";
    if (errors) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    const handler = (event) => {
      console.log("HOOYU FRAME EVENT: " + JSON.stringify(event.data));

      let data = event.data;
      if (frameRef.current && data.height) {
        console.log("HOOYU FRAME SIZE!");
        frameRef.current.height = data.height;
        frameRef.current.width = data.width;
      }

      let selfie = data.selfie;
      if (selfie != null) {
        if (selfie) {
          /** user is about to capture selfie: hide header, footer, and other elements so iframe can fill the whole screen **/
          console.log("HOOYU SELFIE");
          window.scrollTo(0, document.body.scrollHeight + 50);
        } else {
          /** user finished selfie capture, you can unhide header, footer, etc... **/
        }
      }

      if (data.modal != null && data.modal) {
        setTimeout(() => {
          if (frameRef.current) {
            console.log("HOOYU MODAL");
            window.scrollTo(0, frameRef.current.offsetTop);
          }
        }, 100);
      }

      if (event.origin === window.location.origin) {
        let dt = null;
        if (typeof event.data !== "undefined") {
          try {
            dt = JSON.parse(event.data);
          } catch (e) {
            dt = event.data;
          }
          if (typeof dt["message"] !== "undefined") {
            if (dt["message"] === "hooyu-redirect") {
              console.log("HOOYU REDIRECT");

              let location = pages["index"];
              let link = localStorage.getItem(HOOYU_MULTIPLE);
              if(link && getToken()) {
                //if there are multiple hooyu journeys redirect to verification again and remove key
                localStorage.removeItem(HOOYU_MULTIPLE);
                hooyuPending(link)
                  .then((data) => {
                    // console.log(data);
                    location = pages["my-verification"];
                    window.parent.location.replace(location);
                  }).catch((err) =>{
                  window.parent.location.replace(location);
                })
              } else {
                window.parent.location.replace(location);
              }
            }
          }
        }
      }

      // let data = null;
      // if (typeof event.data !== "undefined") {
      //   try {
      //     data = JSON.parse(event.data);
      //   } catch (e) {
      //     data = event.data;
      //   }
      //   // console.log("DATA: " + JSON.stringify(event.data))
      //   if (typeof data.message !== "undefined") {
      //     console.log("set deposit step: " + data.message);
      //     // setDepositStep(data.message);
      //   }
      // }
    };

    window.addEventListener("message", handler);

    // clean up
    return () => window.removeEventListener("message", handler);
  }, []); // empty array => run only once

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current) {
      // console.log("submit params form");
      formRef.current.submit();
    }
  }, []);
  // console.log("request params: " + JSON.stringify(requestParams.sid));
  // console.log("request params type: " + (typeof requestParams))
  // try {
  //   requestParams = JSON.parse(requestParams);
  // } catch (e) {
  //   console.log("err parsing")
  // }
  return (
    <>
      <iframe
        src={src ? src : "about:blank"}
        className={className}
        width={width}
        height={height}
        name="hooyuIframe"
        id="hooyuIframe"
        title="hooyuIframe"
        allow="geolocation; camera"
        ref={frameRef}
        frameBorder={0}
        scrolling="no"
      ></iframe>
    </>
  );
};

export default HooyuIFrame;
