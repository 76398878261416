import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { MUTATION_SELF_EXCLUDE } from "common/src/graphql/mutations";
import { LockDialog } from "common/src/modal/LockDialog";
import { LockUkDialog } from "common/src/modal/LockUkDialog";
import {
  QUERY_GET_BALANCE,
  QUERY_GET_TRANSACTIONS,
  QUERY_WITHDRAWAL_LIST,
} from "common/src/graphql/queries";
import { destroySession } from "common/src/helpers/session";
import { PaymentServer } from "common/src/helpers/connectors";
import { CruksSEDialog } from "../modal/CruksSEDialog";
import { cleanLocalStorage } from "common/src/helpers/functions";
import {
  IncreaseLimit,
  MessageLock,
  RealityCheckWrapper,
  StyledLimitWrapper,
} from "../styledComponents/StyledLimits";
import {
  StyledButton,
  StyledFormGroup,
  StyledSelect,
} from "../styledComponents/CommonStyledComponents";
import {
  AccountDescription,
  StyledTextCenter,
} from "../styledComponents/AccountPage";

const SelfExclusion = ({
  setSpinner,
  isUk,
  realityCheck,
  serverSetup,
  isSE,
  isNL,
  skin,
}) => {
  const { i18n, t } = useTranslation("");
  const apolloClient = useApolloClient();
  const baseUrl = `/${i18n.language || window.localStorage.i18nextLng || "nl"}`;

  const [showLockDialog, setShowLockDialog] = useState(false);
  const [showLockUkDialog, setShowLockUkDialog] = useState(false);
  const [showCruksLink, setShowCruksLink] = useState(false);
  const [period, setPeriod] = useState("");
  const [emptyPeriod, setEmptyPeriod] = useState(false);

  function handleLockPeriod(e) {
    setEmptyPeriod(false);
    setPeriod(e.target.value);
  }

  function showConfirmLock() {
    if (period.length === 0) setEmptyPeriod(true);
    else {
      setShowLockDialog(true);
    }
  }

  async function lockAccount() {
    //MUTATION_SELF_EXCLUDE
    setSpinner(true);
    const { loading, errors } = await apolloClient.mutate({
      mutation: MUTATION_SELF_EXCLUDE,
      variables: {
        duration: parseFloat(period),
      },
      errorPolicy: "all",
    });

    if (loading) {
      destroySession();
      cleanLocalStorage();
    }
    if (errors) {
      setSpinner(false);
      //console.log(errors);
      //setLoginError(errors);
      setShowLockDialog(false);
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      return false;
    } else {
      if (isUk) {
        await processRefund();
      } else {
        setSpinner(false);
        toast.success(<Trans i18nKey="suc_lock_message"></Trans>, {
          position: "bottom-center",
        });
        setShowLockDialog(false);
        if (skin === "plc") {
          setShowCruksLink(true);
          destroySession();
          cleanLocalStorage();
        } else {
          window.location = `${baseUrl}/logout`;
        }
        return true;
      }
    }
    // setShowLockDialog(false);
    // window.location = `/${i18n.language}/logout`;
    // return true;
  }

  async function processRefund() {
    getDepositsHistory()
      .then((transactions) => {
        // console.log("OLD CARDS: " + JSON.stringify(res.data));
        // console.log("tr: " + JSON.stringify(transactions));
        getPlayerBalance()
          .then((balance) => {
            console.log("balance: " + JSON.stringify(balance));
            getWithdrawals()
              .then((withdrawals) => {
                // console.log("withdrawals: " + JSON.stringify(withdrawals));
                PaymentServer.post("/fhnd/v1/refund/process", {
                  transactions: transactions,
                  amount: balance,
                  withdrawals: withdrawals,
                  ip: serverSetup.geoIP.ipAddress,
                  userLocale: i18n.language,
                })
                  .then(() => {
                    toast.success(<Trans i18nKey="suc_lock_message"></Trans>, {
                      position: "bottom-center",
                    });
                    setShowLockDialog(false);
                    setSpinner(false);
                    window.location = `/${i18n.language}/logout`;
                    return true;
                  })
                  .catch(() => {
                    toast.success(<Trans i18nKey="suc_lock_message"></Trans>, {
                      position: "bottom-center",
                    });
                    setShowLockDialog(false);
                    setSpinner(false);
                    window.location = `/${i18n.language}/logout`;
                    return false;
                  });
              })
              .catch((error) => {
                console.log("error withdrawals: " + error);
              });
          })
          .catch((error) => {
            console.log("error transaction: " + error);
          });
      })
      .catch((error) => {
        console.log("error balance: " + error);
      });
  }

  async function getDepositsHistory() {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_TRANSACTIONS,
      variables: { transactionType: "deposit" },
    });
    if (loading) return <h3>Loading ...</h3>;
    if (errors) {
      return <h1>`Error: ${errors}`</h1>;
    } else {
      //data.getTransactions.transactions
      return data.getTransactions.transactions;
    }
  }

  async function getPlayerBalance() {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_BALANCE,
      // fetchPolicy: "cache-first",
    });
    if (loading) return <h3>Loading ...</h3>;
    if (
      errors &&
      (errors[0].message === "error_token_not_provided" ||
        errors[0].message === "error_wrong_token" ||
        errors[0].message === "error_invalid_session_token")
    ) {
      destroySession();
      window.location.replace(`/index`);
    } else {
      // console.log("balance: " + JSON.stringify(data));
      return data.getBalance.real;
    }
  }

  async function getWithdrawals() {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_WITHDRAWAL_LIST,
    });
    if (loading) return <h3>Loading ...</h3>;
    if (errors) {
      return false;
    } else {
      //data.getTransactions.transactions
      return data.getAvailableWithdrawalTypes.availableWithdrawalTypes;
    }
  }

  function lockGameStop() {
    setShowLockDialog(false);
    setShowLockUkDialog(true);
  }

  return (
    <>
      <StyledLimitWrapper selfexcludion={skin !== "rac"}>
        {(isUk || skin === "pub") && (
          <>
            <StyledFormGroup
              col={"col-md-8"}
              margin={skin === "rac" ? "" : "m-0"}
            >
              <label>{t("label_choose_self_exclusion_description")}</label>
              <StyledSelect onChange={(e) => handleLockPeriod(e)}>
                <option value="" disabled selected>
                  {t("label_choose_self_exclusion")}
                </option>
                <option value="180">{t("message_lock_6_months")}</option>
                <option value="365">{t("message_lock_1_year")}</option>
                <option value="730">{t("message_lock_2_years")}</option>
                <option value="1825">{t("message_lock_5_years")}</option>
              </StyledSelect>
            </StyledFormGroup>
            <StyledButton
              pub={skin === "pub"}
              selfexcludion
              onClick={() => showConfirmLock()}
            >
              {t("label_save", "Save")}
            </StyledButton>
          </>
        )}
        {isSE && skin !== "fun" && (
          <>
            <StyledLimitWrapper>
              <label>{t("label_choose_self_exclusion")}</label>
              <StyledSelect
                emptyPeriod={"is-invalid"}
                onChange={(e) => handleLockPeriod(e)}
                value={period}
              >
                <option value="" disabled selected>
                  {t("label_choose_self_exclusion")}
                </option>
                <option value="180">{t("message_lock_6_months")}</option>
                <option value="365">{t("message_lock_1_year")}</option>
                <option value="730">{t("message_lock_2_years")}</option>
                <option value="-1">{t("message_lock_indefenetly")}</option>
              </StyledSelect>
            </StyledLimitWrapper>
            <StyledButton
              pub={skin === "pub"}
              selfexcludion
              onClick={() => showConfirmLock()}
            >
              {t("label_save", "Save")}
            </StyledButton>
          </>
        )}
        {isSE && skin === "fun" && (
          <>
            <StyledLimitWrapper>
              <label>{t("label_choose_self_exclusion")}</label>
              <StyledSelect
                emptyPeriod={"is-invalid"}
                onChange={(e) => handleLockPeriod(e)}
                value={period}
              >
                <option value="" disabled selected>
                  {t("label_choose_self_exclusion")}
                </option>
                <option value="180">{t("message_lock_6_months")}</option>
                <option value="365">{t("message_lock_1_year")}</option>
                <option value="730">{t("message_lock_2_years")}</option>
                <option value="-1">{t("message_lock_indefenetly")}</option>
              </StyledSelect>
            </StyledLimitWrapper>
            <StyledButton
              pub={skin === "pub"}
              selfexcludion
              onClick={() => showConfirmLock()}
            >
              {t("label_save", "Save")}
            </StyledButton>
          </>
        )}
        {isNL && (
          <>
            <StyledFormGroup margin={"m-0"} col={"col-md-8"}>
              <label>{t("label_choose_self_exclusion")}</label>
              <StyledSelect
                onChange={(e) => handleLockPeriod(e)}
                value={period}
              >
                <option value="" disabled selected>
                  {t("label_choose_self_exclusion")}
                </option>
                <option value="90">{t("message_lock_months")}</option>
                <option value="180">{t("message_lock_6_months")}</option>
                <option value="365">{t("message_lock_1_year")}</option>
                <option value="-1">{t("message_lock_indefenetly")}</option>
              </StyledSelect>
            </StyledFormGroup>
            <StyledButton
              pub={skin === "pub"}
              selfexcludion
              onClick={() => showConfirmLock()}
            >
              {t("label_save", "Save")}
            </StyledButton>
          </>
        )}
        {!isUk && !isSE && !isNL && skin !== "pub" && skin !== "fun" && (
          <>
            <StyledFormGroup margin={"m-0"} col={"col-md-8"}>
              <label>{t("label_choose_self_exclusion")}</label>
              <StyledSelect
                onChange={(e) => handleLockPeriod(e)}
                //value=""
              >
                <option value="" disabled selected>
                  {t("label_choose_self_exclusion")}
                </option>
                <option value="7">{t("message_lock_days")}</option>
                <option value="30">{t("message_lock_month")}</option>
                <option value="90">{t("message_lock_months")}</option>
                <option value="-1">{t("message_lock_indefenetly")}</option>
              </StyledSelect>
            </StyledFormGroup>
            <StyledButton
              pub={skin === "pub"}
              selfexcludion
              onClick={() => showConfirmLock()}
            >
              {t("label_save", "Save")}
            </StyledButton>
          </>
        )}
        {!isUk && !isSE && !isNL && skin === "fun" && (
          <>
            <StyledFormGroup margin={"m-0"} col={"col-md-8"}>
              <label>{t("label_choose_self_exclusion")}</label>
              <StyledSelect
                onChange={(e) => handleLockPeriod(e)}
                //value=""
              >
                <option value="" disabled selected>
                  {t("label_choose_self_exclusion")}
                </option>
                <option value="90">{t("message_lock_months")}</option>
                <option value="180">{t("message_lock_6_months")}</option>
                <option value="365">{t("message_lock_1_year")}</option>
                <option value="1095">{t("message_lock_3_years")}</option>
                <option value="-1">{t("message_lock_indefenetly")}</option>
              </StyledSelect>
            </StyledFormGroup>
            <StyledButton
              pub={skin === "pub"}
              selfexcludion
              onClick={() => showConfirmLock()}
            >
              {t("label_save", "Save")}
            </StyledButton>
          </>
        )}
        <LockUkDialog
          showModal={showLockUkDialog}
          handleCancel={() => setShowLockUkDialog(false)}
          handleProceed={() => lockAccount()}
          skin={skin}
        />

        <LockDialog
          showModal={showLockDialog}
          handleCancel={() => setShowLockDialog(false)}
          handleProceed={() => (isUk ? lockGameStop() : lockAccount())}
          skin={skin}
        />

        <CruksSEDialog
          showModal={showCruksLink}
          handleProceed={() => (window.location = `${baseUrl}/index`)}
          skin={skin}
        />
        {skin === "rac" && (
          <RealityCheckWrapper>
            {realityCheck !== -1 && (
              <p>
                {t("label_reality_check")} {Math.floor(realityCheck / 60)}{" "}
                {t("label_reality_check_minutes")}
              </p>
            )}
          </RealityCheckWrapper>
        )}
      </StyledLimitWrapper>

      {(skin === "plc" || skin === "pub") && skin !== "fun" && (
        <AccountDescription
          expand="true"
          description
          dangerouslySetInnerHTML={{ __html: t("message_limit_details") }}
        ></AccountDescription>
      )}
      {skin === "rac" && (
        <IncreaseLimit>
          <p>{t("message_lock_description")}</p>
        </IncreaseLimit>
      )}
    </>
  );
};

export default SelfExclusion;
