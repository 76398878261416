import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const SessionLimitDialog = ({ showModal, handleCancel, skin }) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      <StyledModalBody register>
        <span
          dangerouslySetInnerHTML={{
            __html: t("session_time_message"),
          }}
        ></span>
      </StyledModalBody>

      <StyledModalFooter position>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleCancel()}
        >
          {t("label_ok", "Ok")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
