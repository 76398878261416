import React, { useEffect /* useRef */ } from "react";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import { useTranslation } from "react-i18next";

const SupportScriptButton = ({ skin }) => {
  const { i18n } = useTranslation("");
  /* const myDivElement = useRef(null); */
  let currentLanguage = i18n.translator.language;

  let skinScript;

  if (skin === "abc") skinScript = "b7f78db3";
  if (skin === "nbc") skinScript = "15gyp8ls";
  if (skin === "pub") skinScript = "tqqs89h2";
  if (skin === "yet") skinScript = "a6d6cd08";
  if (skin === "fun") skinScript = "fdff6eff";
  if (skin === "cac") skinScript = "4f68c1dd";
  if (skin === "hyc")
    skinScript = currentLanguage === "sv" ? "e0e4d70d" : "4ad8dc20";
  if (skin === "yak") skinScript = "vl7cq76m";
  if (skin === "rac")
    skinScript = currentLanguage === "sv" ? "pqa224sr" : "myc0gr2n";

  useEffect(() => {
    let scriptUrl = "https://landleurope.ladesk.com/scripts/track.js";
    let node = document.createElement("script");
    node.src = scriptUrl;
    node.id = "la_x2s6df8d";
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    node.onload = function (e) {
      LiveAgent.createButton(skinScript, document.getElementById("start-chat"));
    };
    document.getElementsByTagName("head")[0].appendChild(node);
    //var span = myDivElement.current;
    /* const script = document.createElement("script");
    script.type = "text/javascript";
    script.text = `(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.async=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,'https://landleurope.ladesk.com/scripts/track.js',function(e){ var targetElem = document.getElementById("start-chat");LiveAgent.createButton('b7f78db3', targetElem); });`;
    script.async = true;
    span.appendChild(script);*/

    return () => {
      document.getElementsByTagName("head")[0].removeChild(node);
      // span.removeChild(script);
    };
  }, []);

  return (
    <StyledButton as="span" supportchat type="button">
      <span id="start-chat"></span>
    </StyledButton>
  );
};

export default SupportScriptButton;
