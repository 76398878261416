import { useTranslation } from "react-i18next";
import { paginate, printAmount } from "common/src/helpers/functions";
import {
  GameHistoryWrapper,
  StyledTableCell,
  StyledTableContent,
  StyledTableHeading,
  TableHeader,
  TransactionNotFound,
} from "../styledComponents/AccountPage.js";
import { BonusWrapper } from "../styledComponents/StyledActiveBonus";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import Pagination from "common/src/component/Pagination";

const AllBonuses = ({
  allBonuses,
  availableBonuses,
  playerCurrency,
  server,
}) => {
  const { t } = useTranslation("");
  let pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const allBonusesPart = paginate(allBonuses.bonuses, currentPage, pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <GameHistoryWrapper>
        <TableHeader
          col={"col-md-5"}
          dangerouslySetInnerHTML={{ __html: t("label_name") }}
        ></TableHeader>
        <TableHeader
          col={"col-md-3"}
          dangerouslySetInnerHTML={{ __html: t("label_valid_through") }}
        ></TableHeader>
        <TableHeader
          col={"col-md-2"}
          dangerouslySetInnerHTML={{ __html: t("label_amount") }}
        ></TableHeader>
        <TableHeader
          col={"col-md-2"}
          dangerouslySetInnerHTML={{ __html: t("label_status") }}
        ></TableHeader>
      </GameHistoryWrapper>

      {allBonuses.bonuses !== undefined &&
        allBonusesPart.map((bonus, index) => (
          <BonusWrapper key={index}>
            <StyledTableCell cell={"col-md-5"} padding={"topic-cell"}>
              <StyledTableContent
                left
                dangerouslySetInnerHTML={{
                  __html: t("label_bonus_" + bonus.bonusType),
                }}
              ></StyledTableContent>
            </StyledTableCell>
            <StyledTableCell cell={"col-md-3"} padding={"date-cell"}>
              <StyledTableHeading>
                {t("label_valid_through")}
              </StyledTableHeading>
              <StyledTableContent right={isMobile}>
                {bonus.expiresAt}
              </StyledTableContent>
            </StyledTableCell>
            <StyledTableCell cell={"col-md-2"} padding={"amount-cell"}>
              <StyledTableHeading>{t("label_amount")}</StyledTableHeading>
              <StyledTableContent right={isMobile}>
                {bonus.instance === "free-spins"
                  ? bonus.balance
                  : bonus.balance &&
                    printAmount(
                      bonus.balance,
                      playerCurrency,
                      server.currencies,
                      true
                    )}
              </StyledTableContent>
            </StyledTableCell>
            <StyledTableCell cell={"col-md-2"} padding={"status-cell"}>
              <StyledTableHeading>{t("label_status")}</StyledTableHeading>
              <StyledTableContent right>
                {t("label_" + bonus.status)}
              </StyledTableContent>
            </StyledTableCell>
          </BonusWrapper>
        ))}

      {/* {availableBonuses.bonuses !== undefined &&
        availableBonuses.bonuses.map((bonus, index) => (
          <div className="flex-table-row" key={index}>
            <div className="flex-table-cell topic-cell col-md-12 col-lg-5">
              <div
                className="flex-table-cell-content"
                dangerouslySetInnerHTML={{ __html: t(bonus.bonusDescription) }}
              ></div>
            </div>
            <div className="flex-table-cell date-cell col-md-12 col-lg-3">
              <StyledTableHeading>
                {t("label_valid_through")}
              </div>
              <div className="flex-table-cell-content">{bonus.expiresAt}</div>
            </div>
            <div className="flex-table-cell amount-cell col-md-12 col-lg-2">
              <StyledTableHeading>{t("label_amount")}</div>
              <div className="flex-table-cell-content">
                {bonus.bonusType === "free-spins"
                  ? t("lable_free-spins", "FREE-SPINS")
                  : bonus.balance &&
                    printAmount(
                      bonus.balance,
                      playerCurrency,
                      server.currencies,
                      true
                    )}
              </div>
            </div>
            <div className="flex-table-cell status-cell col-md-12 col-lg-2">
              <StyledTableHeading>{t("label_status")}</div>
              <div className="flex-table-cell-content">{bonus.status}</div>
            </div>
          </div>
        ))} */}
      {allBonuses.bonuses &&
        allBonuses.bonuses.length === 0 &&
        availableBonuses.bonuses &&
        availableBonuses.bonuses.length === 0 && (
          <TransactionNotFound
            dangerouslySetInnerHTML={{ __html: t("bonuses_not_found") }}
          ></TransactionNotFound>
        )}
      {allBonuses.bonuses && (
        <Pagination
          itemsCount={allBonuses.bonuses.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={(e) => handlePageChange(e)}
        />
      )}
    </>
  );
};

export default AllBonuses;
