import React from "react";
import { useTranslation } from "react-i18next";
import { InvalidFeedback } from "../styledComponents/AccountPage";
import { StyledSelect } from "../styledComponents/CommonStyledComponents";

const Select = ({
  name,
  label,
  errors,
  groupClass,
  list,
  firstOption,
  disabled,
  value,
  ...rest
}) => {
  const errorClass = errors ? "form-control is-invalid" : "form-control";
  const { t } = useTranslation("");
  const options = [...list];
  const firstOptionName =
    (firstOption.startsWith("label_") ? t(firstOption) : firstOption) !== ""
      ? firstOption.startsWith("label_")
        ? t(firstOption)
        : firstOption
      : "";
  //console.log(value);
  const defaultValue = value !== "" ? value : "none";

  return (
    <div className={groupClass}>
      {label && <label>{t(label)}</label>}
      <select
        disabled={disabled}
        defaultValue={defaultValue}
        className={errorClass}
        name={name}
        {...rest}
      >
        {firstOptionName !== "" && (
          <option value="none" disabled>
            {firstOptionName}
          </option>
        )}
        {options.map((value, index) => (
          <option key={`currency-${index}`} value={value["value"]}>
            {value["name"].startsWith("label_")
              ? t(value["name"], "")
              : value["name"]}
          </option>
        ))}
      </select>
      {errors && <div className="invalid-feedback">{t(errors, errors)}</div>}
    </div>
  );
};

export default Select;
