import { useApolloClient } from "@apollo/client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { QUERY_SERVER_TIME } from "../graphql/queries";
// import { timeFunction } from "../helpers/functions";
import { SessionTime } from "../styledComponents/StyledTimeAndBulb";
import { isDesktop, isMobileOnly } from "react-device-detect";
/* import QuickGameCategories from "./QuickGameCategories"; */

const Time = ({ player, isInGame, skin, server }) => {
  const [currentLoginTime, setCurrentLoginTime] = useState();
  const [loginCounter, setLoginCounter] = useState();
  const { t, i18n } = useTranslation("");
  const timeDifference = useRef(false);
  const apolloClient = useApolloClient();

  // Focus window
  let tabHidden = false;
  if (document.hidden) {
    tabHidden = true;
  } else {
    tabHidden = false;
  }

  let inter = useRef(false);
  let currSt = useRef(0);
  // Time in Holland for Time component
  const [localTime, setLocalTime] = useState();
  const [currentServerTime, setCurentServerTime] = useState(0);

  // TIME AND FOCUS OPERATIONS
  async function getServerTime() {
    const { data } = await apolloClient.query({
      query: QUERY_SERVER_TIME,
    });
    return data.serverTime.serverTime;
  }

  useEffect(() => {
    if (tabHidden) {
      getServerTime().then((res) => {
        setCurentServerTime(new Date(res.replace(/ /g, "T")));
      });
    }
  }, [tabHidden]);

  useEffect(() => {
    getServerTime().then((res) => {
      setCurentServerTime(new Date(res.replace(/ /g, "T")));
    });
  }, []);

  let noServerTime = new Date().toISOString();
  const serverTime = currentServerTime
    ? new Date(currentServerTime)
    : new Date(noServerTime);

  /* console.log(serverTime);
  console.log(currentServerTime); */
  // Setting serverTime to localStorige
  if (!localStorage.getItem("serverTime")) {
    localStorage.setItem("serverTime", serverTime);
  }

  // Setting currentServerTime for the first time
  if (currentServerTime === 0) {
    setCurentServerTime(serverTime);
    currSt.current = serverTime.getTime();
  } else {
    currSt.current = new Date(currentServerTime).getTime();
  }

  useEffect(() => {
    timeDifference.current =
      localTime &&
      (Math.abs(new Date().getTime() - localTime.getTime()) < 45000
        ? false
        : true);
    /*  localTime &&
      console.log(Math.abs(new Date().getTime() - localTime.getTime()) < 45000); */
  }, [localTime]);

  // Convert UTC time to LocalDate format
  const convertUTCDateToLocalDate = useCallback((date) => {
    let newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();

    newDate.setHours(hours - offset);
    return newDate;
  }, []);

  // Interval for setting time
  if (!inter.current) {
    setInterval(() => {
      let tmp = new Date(currSt.current);
      currSt.current = tmp.getTime() + 1000;
      setCurentServerTime(new Date(currSt.current));
      setLocalTime(convertUTCDateToLocalDate(tmp));
      localStorage.setItem("serverTime", tmp);
    }, 1000);
  }

  inter.current = true;

  //let { localTime, currentServerTime } = timeFunction(server);

  const calculateLoginTime = new Date(localTime - currentLoginTime);
  let localizedFormat = `${
    i18n.language || window.localStorage.i18nextLng || "nl"
  }`;
  localizedFormat === "en" ? (localizedFormat = "uk") : localizedFormat;

  let currentDate = new Date();
  let utcDate = new Date(currentDate.toUTCString().slice(0, -4));

  useEffect(() => {
    setLoginCounter(
      timeDifference.current
        ? new Date(
            calculateLoginTime.getTime() +
              calculateLoginTime.getTimezoneOffset() * 60 * 1000
          )
        : new Date(
            new Date(currentDate - currentLoginTime).getTime() +
              new Date(currentDate - currentLoginTime).getTimezoneOffset() *
                60 *
                1000
          )
    );
  }, [currentServerTime]);

  useEffect(() => {
    player &&
      setCurrentLoginTime(
        convertUTCDateToLocalDate(
          new Date(player.currentLoginTime.replace(/ /g, "T"))
        )
      );
  }, [player, convertUTCDateToLocalDate]);
  /* 
  console.log(
    "local " +
      localTime +
      ", server time " +
      currentServerTime +
      ", UTC " +
      utcDate +
      " " +
      timeDifference.current,
  ); */
  return (
    <>
      {skin === "plc" ? (
        <SessionTime game={isInGame} player={player}>
          {isInGame && (
            <div title={t("label_server_time", "server time:")}>
              {t("label_server_time", "server time:")}{" "}
              {currentServerTime && timeDifference.current
                ? currentServerTime
                    .toLocaleString(localizedFormat)
                    .replace(/-/g, "/")
                : utcDate.toLocaleString(localizedFormat).replace(/-/g, "/")}
            </div>
          )}
          <div title={t("label_time", "local time:")}>
            {isInGame && t("label_time", "local time:")}{" "}
            {localTime && timeDifference.current
              ? localTime.toLocaleString(localizedFormat).replace(/-/g, "/")
              : new Date().toLocaleString(localizedFormat).replace(/-/g, "/")}
          </div>
          {player && !isInGame && (
            <div title={t("label_login_time", "login time:")}>
              {currentLoginTime &&
                currentLoginTime
                  .toLocaleString(localizedFormat)
                  .replace(/-/g, "/")}
            </div>
          )}
          {player && isInGame && (
            <div title={t("label_total_login_time", "total login time:")}>
              {t("label_total_login_time", "total login time:")}{" "}
              {loginCounter &&
                loginCounter
                  .toLocaleTimeString(localizedFormat)
                  .replace(/-/g, "/")}
            </div>
          )}
        </SessionTime>
      ) : (
        <SessionTime
          game={isInGame}
          player={player}
          isSv={player.country === "se"}
        >
          <div>
            {(skin === "nbc" || skin === "yet") && isMobileOnly ? null : (
              <p>{t("label_server_time", "Casino Time (UTC): ")} </p>
            )}
            {currentServerTime && timeDifference.current
              ? currentServerTime
                  .toLocaleString(localizedFormat)
                  .replace(/-/g, "/")
                  .replace(/,/g, ".")
              : utcDate
                  .toLocaleString(localizedFormat)
                  .replace(/-/g, "/")
                  .replace(/,/g, ".") + `${isMobileOnly ? " UTC" : ""}`}
          </div>
          {/* {skin === "pub" && isDesktop && (
            <QuickGameCategories currentLanguage={i18n.language} games={true} />
          )} */}
          {player && player.country === "se" && (
            <div>
              <p>{t("label_last_login")}</p>
              {new Date(player.lastLogin)
                .toLocaleString(localizedFormat)
                .replace(/-/g, "/")
                .replace(/,/g, ".")}
            </div>
          )}
        </SessionTime>
      )}
    </>
  );
};

export default Time;
