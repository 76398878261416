import styled from "styled-components";
import {
  isMobile,
  isMobileOnly,
  isTablet,
  isDesktop,
} from "react-device-detect";
import { handleOrientationChange } from "common/src/helpers/isPortrait";
import { Link } from "react-router-dom";

const orientation = () => {
  if ((isTablet, isMobile, isDesktop)) {
    window.addEventListener("orientationchange", handleOrientationChange);
    return () =>
      window.removeEventListener("orientationchange", handleOrientationChange);
  }
};
// Styled Components
export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 5;
  pointer-events: none;
  transition: all 0.5s ease-in-out;

  @media (max-width: 1024px) {
    height: ${({ theme, player }) =>
      // theme.skin === "abc" ||
      theme.skin === "rac" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak"
        ? isMobileOnly
          ? "75px"
          : "90px"
        : theme.skin === "abc"
        ? isMobileOnly
          ? "110px"
          : "90px"
        : theme.skin === "pub"
        ? isMobileOnly && (player ? "80px" : "110px")
        : "80px"};
  }
  ${(props) => {
    if (props.hideOnScroll) {
      return `
            background-color: ${
              props.theme.skin === "abc"
                ? "var(--bgHederABC)"
                : "var(--bgcolor)"
            };
            height: 100px;
            transform: translateY(-100px);
            opacity: 0;
            `;
    } else {
      if (isTablet && !orientation()) {
        return `
            background-color: ${
              props.theme.skin === "abc"
                ? "var(--bgHederABC)"
                : "var(--bgcolor)"
            };
            height: ${
              props.theme.skin === "abc" ||
              props.theme.skin === "nbc" ||
              props.theme.skin === "yet" ||
              props.theme.skin === "fun" ||
              props.theme.skin === "yak"
                ? "100px"
                : "80px"
            };
            transform: translateY(0px);
            opacity: 1;
                }    

            `;
      } else {
        return `
            background-color: ${
              props.theme.skin === "abc" ||
              props.theme.skin === "fun" ||
              props.theme.skin === "yak"
                ? "var(--bgHederABC)"
                : props.theme.skin === "pub"
                ? "#0c0c0cc7"
                : props.theme.skin === "cac" || props.theme.skin === "hyc"
                ? "#1b1b1b6e"
                : "var(--bgcolor)"
            };
            height: 100px;
            transform: translateY(0px);
            opacity: 1;
            backdrop-filter: ${
              (props.theme.skin === "fun" ||
                props.theme.skin === "cac" ||
                props.theme.skin === "hyc" ||
                props.theme.skin === "yak") &&
              "blur(5px)"
            };
            `;
      }
    }
  }}
`;

export const StyledHeader = styled.div.attrs((props) => ({
  className:
    // props.theme.skin === "abc" ||
    props.theme.skin === "nbc" ||
    props.theme.skin === "yet" ||
    props.theme.skin === "fun" ||
    props.theme.skin === "yak"
      ? "d-flex justify-content-between"
      : "d-flex justify-content-between align-items-center",
}))`
  position: ${({ headerUserExpand }) =>
    headerUserExpand ? "absolute" : "fixed"};
  top: ${({ headerUserExpand, notAccountRoute, theme, isSWEIp }) => {
    if (isMobile && notAccountRoute) {
      if (!isTablet) {
        if (headerUserExpand) {
          if (theme.skin === "pub") {
            return "450px";
          } else {
            return "500px";
          }
        } else {
          return "0";
        }
      } else {
        if (headerUserExpand) {
          return "350px";
        } else {
          return "0";
        }
      }
    } else {
      if (headerUserExpand) {
        if (theme.skin === "pub") {
          return "100px";
        } else {
          return "150px";
        }
      } else {
        return "0";
      }
    }
  }};
  left: 0;
  right: 0;
  z-index: ${({ headerUserExpand }) => (headerUserExpand ? "99" : "5")};
  height: ${isMobile ? "80px" : "150px"};
  height: 100px;
  padding: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "20px 50px"
      : "0 50px"};
  align-items: ${({ theme }) =>
    (theme.skin === "fun" || theme.skin === "yak") && "center"};
  .btn {
    color: ${({ theme, hideOnScroll }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? ""
        : theme.skin === "rac" && !hideOnScroll
        ? "var(--scrollfill)"
        : theme.skin === "rac" && hideOnScroll
        ? "#fff"
        : "var(--showall)"};
  }

  @media (max-width: 1024px) {
    display: inline;
    height: ${({ theme, player }) =>
      theme.skin === "pub" && isMobileOnly && player
        ? "40px"
        : isMobileOnly &&
          (theme.skin === "nbc" ||
            theme.skin === "yet" ||
            theme.skin === "fun" ||
            theme.skin === "yak")
        ? "70px"
        : "90px"};
    padding: ${({ theme }) =>
      theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
        ? "0 10px"
        : "0 20px"};
    padding-top: ${({ theme }) => theme.skin === "abc" && "20px"};

    a {
      display: inline;
      font-size: ${({ theme }) => (theme.skin === "pub" ? "0.7rem" : "0.8rem")};
      padding: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? "0.25rem 0"
          : "0 0.25rem"};
    }
  }
`;
export const LinkToHome = styled.div`
  ${() => {
    if (isMobile) {
      ("");
    } else {
      return `
      position: absolute;
      top: 0.8rem;
      left: 44%;
      width: 210px;
      height: 80px;
      cursor: pointer;
      `;
    }
  }}
`;

export const LinkForDeposit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  .btn {
    min-height: 60px;
  }
  .btn-first {
    text-transform: uppercase;
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 900;
  }
`;

export const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${({ theme }) =>
    (theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak") &&
    isMobile
      ? "10px"
      : isMobileOnly
      ? "5px"
      : ""};
  flex-direction: ${({ theme, player }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak") &&
    isMobileOnly &&
    player &&
    "row-reverse"};

  .btn {
    padding: ${({ theme }) =>
      theme.skin === "pub" ? "10px 10px 8px 10px" : "10px"};
    margin-left: ${({ theme }) =>
      theme.skin === "pub" && isMobileOnly && "0px"};
  }

  svg {
    align-items: bottom;
  }
  @media (max-width: 768px) {
    /* align-items: stretch; */
    .btn {
      padding: 10px;
      min-width: ${({ theme }) => theme.skin === "rac" && isMobile && "80px"};

      @media screen and (device-aspect-ratio: 40/71) {
        width: ${({ theme }) =>
          theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "yak"
            ? "65px"
            : ""};
        font-size: ${({ theme }) =>
          theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "yak"
            ? "0.6rem"
            : ""};
      }
    }
  }

  @media (max-width: 400px) {
    /* align-items: stretch; */
    .btn {
      padding: ${({ theme }) =>
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "yak"
          ? "10px 5px"
          : ""};
      /* width: ${({ theme }) =>
        theme.skin === "yet" || theme.skin === "fun" || theme.skin === "cac"
          ? "60px"
          : ""}; */
    }
  }
`;

export const StyledHeaderLink = styled(Link).attrs((props) => ({
  className: props.register
    ? props.theme.skin === "rac"
      ? "btn btn-register"
      : "btn"
    : props.deposit
    ? props.theme.skin === "rac"
      ? "btn btn-deposit"
      : "btn"
    : "btn ml-3",
}))`
  font-family: ${({ theme }) => {
    if (theme.skin === "pub") return "Ancho";
  }};
  font-weight: ${({ theme }) => theme.skin === "pub" && "500"};
  top: ${({ checklocation }) => (checklocation ? "" : "52px")};
  right: ${({ checklocation }) => (checklocation ? "" : "120px")};
  display: ${({ checklocation }) => (checklocation ? "none" : "")};
  padding: ${({ login }) => (login ? "0rem 0.50rem" : "")};
  margin-left: 10px;
  color: ${({ prime, theme, deposit, login, register }) => {
    if (prime) {
      if (
        theme.skin !== "rac" &&
        theme.skin !== "yet" &&
        theme.skin !== "hyc" &&
        theme.skin !== "yak"
      )
        return theme.skin === "abc" && register
          ? "#0d1343 !important"
          : "#fff !important";
      if (theme.skin === "yet") return "#07415F";
      if (theme.skin === "hyc") return "#0d1343 !important";
    } else {
      if (login) {
        if (theme.skin === "yet") return "#fff";
      } else if (deposit) {
        if (theme.skin === "nbc") return "";
        if (theme.skin === "bnk") return "";
        if (theme.skin === "yet") return "#07415F";
      } else {
        return "";
      }
    }
  }};
  /* prime && theme.skin === "nbc"
      ? "#fff"
      : prime && theme.skin !== "rac"
      ? "#fff !important"
      : theme.skin === "nbc" && deposit
      ? ""
      : theme.skin === "bnk" && deposit
      ? "#fff !important"
      : ""}; */
  min-width: ${({ login, prime, theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak") &&
    (prime || login ? (isMobileOnly ? "60px" : "80px") : "")};
  max-width: ${({ login, prime, theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak") &&
    (prime || login ? "110px" : "")};
  background-color: ${({ prime, theme, login, deposit, register }) => {
    if (prime) {
      if (theme.skin === "abc") return register ? "white" : "#CF1F2C";
      if (theme.skin === "plc") return "#ae0005";
      if (theme.skin === "nbc") return "#e007ae";
      if (theme.skin === "hyc") return "#fff";
      /* if (theme.skin === "yet") return "#07415F"; */
    } else {
      if (theme.skin === "abc" && login) {
        //ABC login background color
        return "transparent";
      } else if (theme.skin === "nbc") {
        //NBC login background color
        if (login) {
          return "#fff";
        }
        if (deposit) {
          return `#fff`;
        }
      } else if (theme.skin === "yet") {
        //YET login background color
        if (login) {
          return "#07415F";
        }
        if (deposit) {
          return `#fff`;
        }
      } else if (theme.skin === "fun") {
        //FUN login background color
        if (login) {
          return "#fa2c70";
        }
        if (deposit) {
          return `#fa2c70`;
        }
      } else if (theme.skin === "hyc") {
        //HYC login background color
        if (login) {
          return "transparent";
        }
        if (deposit) {
          return ``;
        }
      } else if (theme.skin === "yak") {
        //YAK login background color
        /* if (login) {
          return "transparent";
        } */
        if (deposit) {
          return `#fff`;
        }
      } else {
        return "";
      }
    }
  }};
  background: ${({ theme, register, deposit, login }) => {
    if (theme.skin === "qbc" && (register || (deposit && !login))) {
      return "linear-gradient(90deg,rgba(253, 175, 30, 1) 0%,rgba(253, 137, 24, 1) 100%)";
    }
  }};
  border: ${({ theme, prime, login, deposit }) => {
    if (prime) {
      if (theme.skin === "pub") return "2px solid #fff";
      if (theme.skin === "cac" || theme.skin === "qbc")
        return "1px solid #979797";
    }
    if (login) {
      if (theme.skin === "cac" || theme.skin === "qbc")
        return "1px solid #979797";
      if (theme.skin === "abc") return "1px solid white";
    }
    if (deposit) {
      if (theme.skin === "hyc") return "1px solid #fff";
    }
  }};
  text-transform: ${({ theme, login }) => {
    if (login) {
      if (login) {
        if (theme.skin === "abc") return "uppercase";
      }
    }
  }};

  :hover {
    background: ${({ theme, register, deposit, login }) => {
      if ((theme.skin === "qbc" && register) || (deposit && !login)) {
        return "linear-gradient(90deg, rgba(253, 175, 30, 1) 0%,rgba(253, 137, 24, 0.8554273760285365) 100%)";
      }
    }};
    background-color: ${({ prime, theme, login, deposit }) => {
      if (prime) {
        if (theme.skin === "abc") return "#ae0005";
        if (theme.skin === "plc") return "#CF1F2C";
        if (theme.skin === "nbc") return "a790b1";
        if (theme.skin === "yak") return "#e9ac1a";
      } else {
        if (theme.skin === "abc" && login) {
          //ABC login background color hover
          return "#0661cf";
          // return "rgba(255, 255, 255, 0.5)";
        } else if (theme.skin === "nbc" && login) {
          //NBC login background color hover
          return "#350e46";
        } else if (theme.skin === "fun") {
          //FUN login background color hover
          if (login || deposit) return "#ee1c61";
        } else if (theme.skin === "qbc") return "#322d2e";
        else {
          return "";
        }
      }
    }};
    color: ${({ prime, theme, login, deposit, register }) => {
      if (theme.skin === "abc" && register) {
        return "white !important";
      }
    }};
    border: ${({ theme, prime, login }) => {
      if (prime) {
        if (theme.skin === "pub") return "2px solid #9A875D";
      }
      if (login) {
        if (theme.skin === "abc") return "1px solid transparent";
      }
    }};
  }

  @media screen and (device-aspect-ratio: 40/71) {
    width: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "80px"
        : ""};
  }
`;

export const UserInfoExpandWrapper = styled.div`
  .btn {
    font-family: ${({ theme }) => theme.skin === "pub" && "Montserrat"};
    font-weight: ${({ theme }) => theme.skin === "pub" && "800"};
    background-color: ${({ theme }) => (theme.skin === "abc" ? "#206BC8" : "")};
    margin: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "bnk" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "0 5px"
        : ""};
  }
  hr {
    display: ${isMobile && "none"};
    margin-top: 3rem;
    width: calc(100% - 10px);
  }

  ${(props) => {
    if (props.headerUserExpand) {
      if (isTablet && !orientation()) {
        return `
          padding: 0 20px;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0px;
          text-align: center;
          a,
          span {
            float: none !important;
            color: ${
              props.theme.skin === "abc"
                ? "#fff"
                : props.theme.skin === "nbc"
                ? "#1f0529"
                : "var(--headerbtn)"
            };
            position:${
              props.theme.skin === "rac" ||
              props.theme.skin === "nbc" ||
              props.theme.skin === "yet" ||
              props.theme.skin === "fun" ||
              props.theme.skin === "yak"
                ? ""
                : "relative"
            };
            float: right;
            bottom: ${props.theme.skin === "abc" ? "0" : "40px"};
            z-index: 11111;
            

          }
          .bnt-logout{
            bottom: 15px !important;

          }
          @media screen and (max-width: 1024px) {
            padding: 0px;

            a {
              float: none;
              /* height: 110px; */
              font-size: 0.8rem;
              bottom: 90px;
              color: ${props.theme.skin === "abc" ? "#fff" : "var(--acolor)"};
              background-color:${props.theme.skin === "nbc" && "#70328e"};
            }
          }
          @media screen and (orientation: landscape) {
            .bnt-logout{
            bottom: 15px !important;
            position: absolute !important;
            right: 3rem;
            @media screen and (max-width: 1024px) {
            right: 1.5rem;

            }

          }
          }
        `;
      } else {
        return `

        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0px;
        padding: 0 50px;
        text-align: center;
       
        a,
        span {
          margin: ${
            (props.theme.skin === "cac" ||
              props.theme.skin === "hyc" ||
              props.theme.skin === "qbc") &&
            !isMobileOnly &&
            "0 5px"
          };
          
          color: ${
            props.theme.skin === "abc" ||
            props.theme.skin === "bnk" ||
            props.theme.skin === "fun" ||
            props.theme.skin === "yak"
              ? "#fff"
              : "var(--headerbtn)"
          };
          position: relative;
          float: right;
          z-index: 11111;
          span {
            border: ${
              (props.theme.skin === "cac" || props.theme.skin === "qbc") &&
              "1px solid #424141"
            };
            background-color: ${
              props.theme.skin === "nbc"
                ? "#350e46"
                : props.theme.skin === "yet"
                ? "#186289"
                : props.theme.skin === "fun"
                ? "#13aec3"
                : props.theme.skin === "yak"
                ? "#7D41CD"
                : ""
            };

          }
          a {
            border: ${
              (props.theme.skin === "cac" || props.theme.skin === "qbc") &&
              "1px solid #424141"
            };
            background-color: ${
              props.theme.skin === "nbc"
                ? "#350e46"
                : props.theme.skin === "yet"
                ? "#186289"
                : props.theme.skin === "fun"
                ? "#13aec3"
                : props.theme.skin === "yak"
                ? "#7D41CD"
                : ""
            };
          }
        }
        .bnt-logout{
            right: 0px !important;
            bottom: 20px !important;

          }
        @media screen and (max-width: 1024px) {
          padding: 0px;
          
          a, span {
            position: ${
              (props.theme.skin === "abc" ||
                props.theme.skin === "nbc" ||
                props.theme.skin === "yet" ||
                props.theme.skin === "fun" ||
                props.theme.skin === "yak") &&
              isTablet
                ? "static"
                : ""
            };
          }
          a {
            float: none;
            height: ${
              props.theme.skin === "abc" ||
              props.theme.skin === "nbc" ||
              props.theme.skin === "yet" ||
              props.theme.skin === "fun" ||
              props.theme.skin === "yak"
                ? ""
                : "110px"
            };
            font-size: 0.8rem;
            bottom: 70px;
            color: ${
              props.theme.skin === "abc" || props.theme.skin === "nbc"
                ? "#fff"
                : "var(--acolor)"
            };
          }
          .bnt-logout{
            position: ${isMobileOnly ? "" : "absolute !important;"};
            right:  ${isMobileOnly ? "0px !important" : "10px !important"};
            bottom:  ${isMobileOnly ? "0px !important" : "20px !important"}; 

          }
        }
        `;
      }
    } else {
      return `
      display: none;
      `;
    }
  }}
`;

export const UserInfoBalance = styled.div.attrs((props) => ({
  className: props.widrawal ? "actions" : "",
}))`
  font-size: ${({ theme }) => (theme.skin === "pub" ? "0.867" : "13px")};
  padding: 5px 0;
  margin-top: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak") &&
    isMobile
      ? ""
      : isMobile
      ? "0px"
      : theme.skin === "pub" && !isMobile
      ? "20px"
      : "50px"};
  &.actions {
    margin-top: 6px;
    margin-bottom: -45px;

    .btn-first {
      height: 30px;
      width: 90px;
      padding: 0;
      font-size: 10px;
    }
  }

  div {
    justify-content: center;
    display: ${isMobileOnly ? "flex" : "inline"};
    margin: ${isMobileOnly && "auto"};
    padding: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "cac" ||
      theme.skin === "hyc"
        ? "10px 20px"
        : "15px 20px"};
    /* color: var(--headerbtn); */
    color: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "fun" ||
      theme.skin === "yak" ||
      theme.skin === "rac"
        ? "var(--balanceABC)"
        : "var(--headerbtn)"};
    font-weight: ${({ theme }) =>
      theme.skin !== "plc" && theme.skin !== "pub" && "700"};
    width: ${isMobileOnly && "90%"};
  }

  @media screen and (max-width: 767px) {
    div {
      padding: 7px !important;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    position: ${({ mobileNav }) => (mobileNav ? "" : "relative")};
    top: 340px;
    padding: ${({ mobileNav }) => mobileNav && "0 0 10px 0"};

    div {
      display: flex;
      color: ${({ theme }) =>
        theme.skin === "abc" ? "var(--balanceABC)" : "var(--acolor)"};
      padding: 10px;
      margin: 0 auto;
      width: ${({ theme }) => (isTablet ? "500px" : "90%")};
    }
    @media screen and (orientation: landscape) {
      top: ${({ theme }) =>
        theme.skin === "abc" || theme.skin === "nbc" ? "230px" : "240px"};
      div {
        width: ${({ theme }) =>
          (theme.skin === "pub" ||
            theme.skin === "cac" ||
            theme.skin === "hyc") &&
          isMobileOnly
            ? "80%"
            : "560px"};
      }
    }
  }
`;

export const UserInfoBalanceWrapp = styled.div`
  background-color: ${({ theme }) =>
    theme.skin === "nbc"
      ? "#12061a"
      : theme.skin === "pub"
      ? "transparent"
      : "var(--customInputColor)"};
  padding: 60px;
  border-radius: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "rac"
      ? "0.5rem"
      : theme.skin === "pub"
      ? "0"
      : theme.skin === "fun" || theme.skin === "yak"
      ? "20px"
      : theme.skin === "hyc"
      ? "10px"
      : "30px"};
  border: ${({ theme }) =>
    theme.skin === "rac" && "1px solid var(--inputbordercolorABC)"};
  border-bottom: ${({ theme }) =>
    theme.skin === "pub" && isMobileOnly && "2px solid #2f2f2f"};
  div {
    color: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "#fff"};
    justify-content: ${({ theme }) =>
      (theme.skin === "pub" || theme.skin === "cac") &&
      isMobileOnly &&
      "center"};
  }
  text-transform: capitalize;
  //word-spacing: 12px;

  :first-child {
    font-weight: ${({ theme }) => theme.skin === "pub" && "400"};
  }

  @media screen and (max-width: 1024px) {
    padding: 0;
    word-spacing: 0;
  }
`;

export const UserInfoBalanceTable = styled(UserInfoBalance)`
  ${(props) => {
    if (isTablet) {
      if (props.locationfortablet) {
        return `
          top: 10px !important;
          width: 100%;
          font-size: 0.8rem;
          position: relative;
            top: ${
              props.theme.skin === "abc"
                ? "40px !important"
                : props.theme.skin === "rac"
                ? "5px !important"
                : props.theme.skin === "nbc" ||
                  props.theme.skin === "yet" ||
                  props.theme.skin === "fun" ||
                  props.theme.skin === "yak"
                ? "100px !important"
                : "20px !important"
            };
          

          div {
            color: var(--acolor);
            padding: 10px;
          }

          @media screen and (max-width: 1024px) {
            display: ${
              props.theme.skin === "abc" ||
              props.theme.skin === "nbc" ||
              props.theme.skin === "yet" ||
              props.theme.skin === "fun" ||
              props.theme.skin === "yak"
                ? ""
                : props.theme.skin === "rac"
                ? ""
                : "inline !important"
            };
            margin-top: 0;
            top: ${
              props.theme.skin === "abc"
                ? "30px !important"
                : props.theme.skin === "rac"
                ? "5px !important"
                : props.theme.skin === "nbc" ||
                  props.theme.skin === "yet" ||
                  props.theme.skin === "fun" ||
                  props.theme.skin === "yak"
                ? "100px !important"
                : "50px !important"
            };
            left: 0 !important;


            div {
              display: inline !important;
            }
          }
        `;
      } else {
        return `
        width: 100%;
        font-size: 0.8rem;
        position: relative;
        /* balance tablet problem */
        top: ${
          props.theme.skin === "rac"
            ? "310px"
            : props.theme.skin === "nbc" ||
              props.theme.skin === "yet" ||
              props.theme.skin === "fun" ||
              props.theme.skin === "yak"
            ? "365px"
            : "240px"
        };

        div {
          color: var(--acolor);
          padding: 10px;
          display: inline;
          
        }
        
        @media screen and (orientation: landscape) {
          top: ${
            props.theme.skin === "rac"
              ? "300px !important"
              : props.theme.skin === "nbc" ||
                props.theme.skin === "yet" ||
                props.theme.skin === "fun" ||
                props.theme.skin === "yak"
              ? "365px !important"
              : "280px !important"
          };
        }
        @media screen and (max-width: 1024px) {
          margin-top: 0;
          @media screen and (orientation: landscape) {
          top: ${
            props.theme.skin === "abc"
              ? "240px !important"
              : props.theme.skin === "rac"
              ? "300px !important"
              : props.theme.skin === "nbc" ||
                props.theme.skin === "yet" ||
                props.theme.skin === "fun" ||
                props.theme.skin === "yak"
              ? "365px !important"
              : "240px !important"
          };
        };
        }
        `;
      }
    } else {
      if (props.locationfortablet) {
        return `
        @media screen and (max-width: 1024px) {
            top: ${
              props.theme.skin === "abc" ||
              props.theme.skin === "nbc" ||
              props.theme.skin === "yet" ||
              props.theme.skin === "fun" ||
              props.theme.skin === "yak"
                ? "0"
                : "35px"
            };
          }
        `;
      } else {
        return ``;
      }
    }
  }}
`;

export const StyledButtonOpened = styled.div.attrs((props) => ({
  className: props.btnOpen ? "" : "btn",
}))`
  ${(props) => {
    if (props.btnOpen) {
      return `
        height: 100px;
        display: inline-block;
        color: #212529;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        border: ${
          props.theme.skin === "cac" || props.theme.skin === "qbc"
            ? "1px solid #424141"
            : "border: 0 solid transparent"
        };
        line-height: 1.5;
        border-radius: 0.25rem;
        padding: 10px 30px 12px;
        font-size: 0.875rem;
        width: 200px;
        position: relative;
        float: left;
        text-align: left;
        bottom: 40px;
        -webkit-box-shadow: 0px 0px 10px -2px ${
          props.theme.skin === "fun" || props.theme.skin === "yak"
            ? "var(--profileMenu)"
            : "var(--headerbg)"
        };
        -moz-box-shadow: 0px 0px 10px -2px ${
          props.theme.skin === "fun" || props.theme.skin === "yak"
            ? "var(--profileMenu)"
            : "var(--headerbg)"
        };
        box-shadow: 0px 0px 10px -2px ${
          props.theme.skin === "fun" || props.theme.skin === "yak"
            ? "var(--profileMenu)"
            : "var(--headerbg)"
        });
        border-top-left-radius: ${
          props.theme.skin === "abc" ||
          props.theme.skin === "nbc" ||
          props.theme.skin === "yet"
            ? "0.5rem"
            : props.theme.skin === "pub" || props.theme.skin === "rac"
            ? "0.3rem"
            : props.theme.skin === "fun" ||
              props.theme.skin === "cac" ||
              props.theme.skin === "yak"
            ? "20px"
            : props.theme.skin === "hyc"
            ? "10px"
            : "15px"
        };
        border-top-right-radius: ${
          props.theme.skin === "abc" ||
          props.theme.skin === "nbc" ||
          props.theme.skin === "yet" ||
          props.theme.skin === "hyc"
            ? "0.5rem"
            : props.theme.skin === "pub" || props.theme.skin === "rac"
            ? "0.3rem"
            : props.theme.skin === "fun" ||
              props.theme.skin === "cac" ||
              props.theme.skin === "yak"
            ? "20px"
            : props.theme.skin === "hyc"
            ? "10px"
            : "15px"
        };15px;
        color: ${
          props.theme.skin === "abc" ||
          props.theme.skin === "nbc" ||
          props.theme.skin === "bnk" ||
          props.theme.skin === "fun" ||
          props.theme.skin === "yak"
            ? "#fff"
            : "var(--headerbtn)"
        };
        font-weight: 700;
        background-color: ${
          props.theme.skin === "abc" || props.theme.skin === "bnk"
            ? "#206BC8"
            : "var(--profileMenu)"
        };
    `;
    } else {
      return `
        justify-content: ${props.theme.skin === "rac" && "left"};
        font-size: 0.875rem;
        text-align: left !important;
        padding: ${
          props.theme.skin === "pub" || props.theme.skin === "rac"
            ? "10px !important"
            : "12px 30px 12px !important"
        };
        border: ${
          (props.theme.skin === "cac" || props.theme.skin === "qbc") &&
          "1px solid #424141"
        };
        color: ${
          props.theme.skin === "abc" ||
          props.theme.skin === "bnk" ||
          props.theme.skin === "fun" ||
          props.theme.skin === "yak"
            ? "#fff"
            : props.theme.skin === "rac"
            ? "var(--racProfileMenuCol)"
            : "var(--acolor)"
        };
        background-color: ${
          props.theme.skin === "nbc"
            ? "#350e46"
            : props.theme.skin === "yet"
            ? "#186289"
            : props.theme.skin === "fun"
            ? "#13aec3"
            : props.theme.skin === "yak"
            ? "#7D41CD"
            : ""
        };
        width: ${
          props.theme.skin === "abc" ||
          props.theme.skin === "nbc" ||
          props.theme.skin === "yet" ||
          props.theme.skin === "fun" ||
          props.theme.skin === "yak" ||
          props.theme.skin === "rac"
            ? "120px"
            : "200px"
        };

        position: relative;
        float: left;
        text-align: left;
        bottom: 40px;
        flex-wrap: nowrap;
      `;
    }
  }}
`;

export const StyledMaterialIcon = styled.div`
  ${(props) => {
    if (props.icon) {
      return `
      &:after {
        margin-left: 7px;
        font-family: 'Material Icons';
        content: 'expand_less';
        vertical-align: bottom;
      }
      `;
    } else if (props.mobileType) {
      return ``;
    } else if (props.goback) {
      return `
      &:after {
        margin-left: 7px;
        font-family: 'Material Icons';
        content: 'arrow_back_ios';
        vertical-align: bottom;
      }
      `;
    } else {
      return `
      &:after {
        margin-left: 7px;
        font-family: 'Material Icons';
        content: 'expand_more';
        vertical-align: bottom;
      }
      `;
    }
  }}
`;

export const ProfileLinks = styled(Link).attrs({
  className: "btn",
})`
  ${(props) => {
    if (
      props.theme.skin === "plc" ||
      props.theme.skin === "pub" ||
      props.theme.skin === "cac" ||
      props.theme.skin === "hyc"
    ) {
      if (props.tablet) {
        return `
        display: none !important`;
      } else {
        return `
          @media screen and (max-width: 1024px) {
            display: none !important;
          }
        `;
      }
    } else if (
      props.theme.skin === "abc" ||
      props.theme.skin === "nbc" ||
      props.theme.skin === "yet" ||
      props.theme.skin === "fun" ||
      props.theme.skin === "yak"
    ) {
      return `
      @media screen and (max-width: 1024px) {
        top: ${
          isTablet
            ? props.locationfortablet
              ? "55px !important"
              : "-15px !important"
            : "10px !important"
        };
        float: none !important;
            a {
              height: 20px;
            }
          }
      }
      @media screen and (orientation: landscape) {
        top: ${isTablet && props.locationfortablet ? "55px !important" : ""}
      }
        `;
    }
  }}
`;

export const StyledBalanceWrapper = styled.div`
  text-align: ${({ theme }) => theme.skin === "rac" && "right"};
  z-index: 1;
  top: ${({ expand }) => (expand ? "" : " 63px")};
  right: 150px;
  color: ${({ theme, hideOnScroll, racInGame }) =>
    theme.skin === "abc" || theme.skin === "fun" || theme.skin === "yak"
      ? "#fff"
      : theme.skin === "rac" && !hideOnScroll
      ? "var(--scrollfill)"
      : theme.skin === "rac" && hideOnScroll
      ? racInGame
        ? "var(--footerlinks)"
        : "var(--scrollfill)"
      : "var(--showall)"};
  word-spacing: 20px;
  font-weight: 800;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  margin: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc" || theme.skin === "bnk"
      ? "0 1rem"
      : ""};
  background-color: ${({ theme }) =>
    theme.skin === "abc"
      ? "#0554B3"
      : theme.skin === "nbc"
      ? "#e007ae"
      : theme.skin === "yet"
      ? "#07415F"
      : theme.skin === "fun"
      ? "#1e8ea6"
      : theme.skin === "yak"
      ? "var(--racProfileMenuCol)"
      : ""};
  padding: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? "10px 20px"
      : ""};
  border-radius: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc" || theme.skin === "yet"
      ? "0.5rem"
      : theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "yak" ||
        theme.skin === "qbc"
      ? "20px"
      : theme.skin === "hyc"
      ? "10px"
      : ""};
  margin-right: ${({ theme }) =>
    (theme.skin === "pub" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "qbc") &&
    "10px"};
  border: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc" || theme.skin === "qbc") &&
    "1px solid #979797"};
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 926px) {
    top: ${({ expand }) => (expand ? "" : "20px")};
    font-size: 0.8rem;
    right: 80px;
    margin: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "nbc" ? "0  0 0 1rem" : ""};
    i {
      font-size: 19px;
    }
  }

  @media screen and (max-height: 600px) {
    font-size: ${({ theme }) => theme.skin === "rac" && "0.7rem"};
  }
  @media (min-width: 926px) and (max-width: 1024px) {
    margin-top: -2px;
    margin: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "nbc" ? "0  0 0 1rem" : ""};
    top: ${({ expand }) => (expand ? "100px" : " 130px")};
  }

  @media screen and (device-aspect-ratio: 40/71) {
    margin: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "nbc" ? "0 0 0 0.5rem" : ""};
    padding: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "nbc" ? "10px 12px" : ""};
  }
`;

export const IndexExpand = styled.div``;

export const SewTopBar = styled.div.attrs({
  className: "align-self-center ml-auto",
})`
  position: absolute;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s;

  //left: 50%;
  //transform: translate(-50%, 0);
  top: 0;
  z-index: 100;
  img {
    background-color: #00000033;
    height: 20px;
    margin-left: 15px;
  }
`;

export const ProfileRightWrapper = styled.span`
  bottom: 40px;
`;
