import { AccountContainer } from "common/src/styledComponents/AccountPage.js";
import {
  LoaderCol,
  LoaderRow,
  RotateLogo,
} from "common/src/styledComponents/StyledIndex.js";
import { ReactComponent as Logo } from "../data/icon-component/abc-logo.svg";

export function Loader() {
  return (
    <AccountContainer vh>
      <LoaderRow>
        <LoaderCol>
          <RotateLogo>
            <Logo />
          </RotateLogo>
        </LoaderCol>
      </LoaderRow>
    </AccountContainer>
  );
}
