import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { paginate, printAmount } from "common/src/helpers/functions";
import {
  GameHistoryWrapper,
  StyledTableCell,
  StyledTableContent,
  StyledTableHeading,
  TableHeader,
  TransactionNotFound,
} from "../styledComponents/AccountPage";
import { StyledFelxTableRow } from "../styledComponents/CommonStyledComponents";
import { StyledDWTotal } from "../styledComponents/StyledDepositDialog";
import Pagination from "common/src/component/Pagination";

const WithdrawTransactions = ({
  withdrawHistory,
  playerCurrency,
  server,
  skin,
}) => {
  const { t } = useTranslation("");
  let pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  let onlyFilteredTrans;

  if (withdrawHistory.transactions !== undefined) {
    onlyFilteredTrans = withdrawHistory.transactions.filter(
      (tansaction) => tansaction.transactionType === "withdrawal"
    );
  }

  const filterTransactionsPart = paginate(
    onlyFilteredTrans,
    currentPage,
    pageSize
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  console.log(onlyFilteredTrans);

  return (
    skin !== "plc" && (
      <>
        <GameHistoryWrapper>
          <TableHeader col={"col-4"}>{t("label_date", "Data")}</TableHeader>
          <TableHeader col={"col-4"}>
            {t("label_transaction_type", "Type")}
          </TableHeader>
          <TableHeader col={"col-2"}>{t("label_status", "Status")}</TableHeader>
          <TableHeader col={"col-2"}>{t("label_amount", "Amount")}</TableHeader>
        </GameHistoryWrapper>
        {(withdrawHistory.transactions === undefined ||
          withdrawHistory.transactions.length === 0) && (
          <TransactionNotFound>
            {t("account_transactions_not_found")}
          </TransactionNotFound>
        )}
        {withdrawHistory.transactions !== undefined &&
          filterTransactionsPart.map((tansaction) => (
            <StyledFelxTableRow key={tansaction.completedAt}>
              <StyledTableCell cell={"topic-cell"} col={"col-md-4"}>
                <StyledTableContent>
                  {tansaction.completedAt}
                </StyledTableContent>
              </StyledTableCell>
              <StyledTableCell cell={"type-cell"} col={"col-md-4"}>
                <StyledTableHeading>
                  {t("label_transaction_type")}
                </StyledTableHeading>
                <StyledTableContent>
                  {tansaction.transactionType}
                </StyledTableContent>
              </StyledTableCell>
              <StyledTableCell cell={"status-cell"} col={"col-md-2"}>
                <StyledTableHeading>{t("label_status")}</StyledTableHeading>
                <StyledTableContent>
                  {t("label_" + tansaction.status)}
                </StyledTableContent>
              </StyledTableCell>
              <StyledTableCell cell={"amount-cell"} col={"col-md-2"}>
                <StyledTableHeading>{t("label_amount")}</StyledTableHeading>

                <StyledTableContent>
                  {printAmount(
                    tansaction.amount,
                    playerCurrency,
                    server.currencies,
                    true
                  )}
                </StyledTableContent>
              </StyledTableCell>
            </StyledFelxTableRow>
          ))}
        <StyledFelxTableRow total>
          <StyledDWTotal col={"col-md-10 col-sm-6"}>
            <StyledTableContent description>
              {t("label_withdrawal_total")}
            </StyledTableContent>
          </StyledDWTotal>
          <StyledDWTotal col={"total-cell col-md-2 col-sm-6"}>
            <StyledTableContent>
              {printAmount(
                withdrawHistory.withdrawalAmount,
                playerCurrency,
                server.currencies,
                true
              )}
            </StyledTableContent>
          </StyledDWTotal>
        </StyledFelxTableRow>
        {onlyFilteredTrans && (
          <Pagination
            itemsCount={onlyFilteredTrans.length}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={(e) => handlePageChange(e)}
          />
        )}
      </>
    )
  );
};

export default WithdrawTransactions;
