import { isMobile } from "react-device-detect";
import styled from "styled-components";

export const StyledDepositDialog = styled.form.attrs({
  className: "col-lg-8 col-md-10 col-12 offset-md-1 offset-lg-2 mb-5 mt-3",
})`
  ${(props) => {
    if (props.theme.skin === "rac" && props.racDeposit) {
      return `
      margin-right: 0;
      margin-left: 0;
      max-width: 100%;
      padding-right: 0;
      padding-left: 0;
      `;
    }
  }}

  /* margin-right: 0;
    margin-left: 0;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0; */
  button {
    /* font-size: 20px !important;
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    line-height: 37px;
    border-radius: 6px;
    font-weight: 800; */

    &.button-first {
      background-size: cover;
    }

    input,
    select {
      color: var(--inputtext);
      font-weight: 800;

      &::placeholder {
        font-weight: 400;
        text-transform: capitalize;
        font-size: 0.85rem;
      }
    }
    @media (max-width: 350px) {
      button {
        font-size: 16px;
      }
    }
    @media (min-width: 576px) {
      button {
        font-size: 26px;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        &.button-first {
          background-size: 100% 100%;
        }
      }
    }

    @media (min-width: 992px) {
      button {
        font-size: 26px;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }
    }
  }
`;

export const DepositRow = styled.div`
  background: none;
  cursor: default;

  ${(props) => {
    if (props.theme.skin === "rac") {
      return `
        width: 100%;
        margin: ${isMobile && props.inGame ? "0 0 10px 0" : ".7% 0 0"};
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        transition: all .2s ease;
        pointer-events: auto;
        cursor: pointer;
      
      `;
    }
  }}

  img {
    // margin-left: -20px;
    //transform: scale(1.4);
    margin-right: 15px;
    margin-left: -5px;
  }

  &:hover {
    background: none;
  }

  .col2,
  .col3,
  .col4 {
    color: var(--tabletext);
    height: 100%;
    padding: 0;
    margin: 0;
    display: block;
    text-align: left;
    align-items: center;
    height: 20%;
    flex: 1;
    justify-content: flex-start;
    flex: 0;
    visibility: visible;
    width: 100%;
    font-size: 10px;
  }

  .col3 {
    visibility: visible;
  }

  @media (min-width: 992px) {
    .col2,
    .col3,
    .col4 {
      display: flex;
      text-align: initial;
    }

    .col3 {
      justify-content: center;
      flex: 1;
    }

    .col4 {
      justify-content: flex-end;
      flex: 1;
      height: 40%;
      border-bottom: ${({ theme }) =>
        theme.skin === "plc" && "2px solid var(--depositBorder)"};
    }

    .col1,
    .col2,
    .col3 {
      border-bottom: ${({ theme }) =>
        theme.skin === "plc" && "1px solid var(--depositBorder)"};
      height: 40%;
      flex: 1;
    }
  }
`;

export const StyledDepositImage = styled.img.attrs({
  className: "col-lg-2 col-sm-4 col-4 p-0",
})`
  width: auto;
  height: auto;
  transform: scale(0.9);
`;

export const DepositColWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: ${({ theme }) =>
    theme.skin === "plc" && "1px solid var(--depositBorder)"};

  .col2,
  .col3,
  .col4 {
    color: var(--tabletext);
    height: 100%;
    padding: 0;
    margin: 0;
    display: block;
    text-align: left;
    align-items: center;
    height: 20%;
    flex: 1;
    justify-content: flex-start;
    flex: 0;
    visibility: visible;
    width: 100%;
    font-size: 10px;
  }

  @media (min-width: 992px) {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .col2,
    .col3,
    .col4 {
      display: flex;
      text-align: initial;
    }

    .col3 {
      justify-content: center;
      flex: 1;
    }

    .col4 {
      justify-content: flex-end;
      flex: 1;
      height: 40%;
      border-bottom: 1px solid var(--depositBorder);
    }

    .col1,
    .col2,
    .col3 {
      border-bottom: 1px solid var(--depositBorder);
      height: 40%;
      flex: 1;
    }
  }

  @media (min-width: 576px) {
    .col2,
    .col3,
    .col4 {
      font-size: 12px;
    }
  }
`;

export const StyledColPar = styled.div.attrs((props) => ({
  className: `${props.col} col-lg-4 col-12`,
}))`
  color: ${({ theme }) =>
    theme.skin === "rac" ? "var(--labelcolor)" : "var(--acolor)"};
  /* border-bottom: 1px solid var(--depositBorder); */
`;

export const DepositType = styled.div.attrs((props) => ({
  className: props.typeofdata
    ? "form-group mb-3 col-lg-2 col-4 text-left"
    : "form-group mb-3 col-12 text-left",
}))`
  .form-group {
    label {
      color: var(--tabletext) !important;
      min-height: 15px;
      font-size: 10px;
      margin-bottom: 0.1rem;
    }
  }

  @media (min-width: 576px) {
    .form-group {
      label {
        font-size: 12px;
      }
    }
  }
`;

export const DepositChoseAccount = styled.div.attrs({
  className: "form-group mb-3 col-lg-8 col-sm-8 col-8 text-left",
})``;

export const DepositNewAccount = styled.div.attrs({
  className: "form-group mb-3 col-lg-4 col-sm-4 col-4 text-left",
})`
  .btn {
    padding: 0;
    margin: 0;
    height: calc(1.5em + 1.5rem + 10px);
    width: 100%!important;
    background-color: #ae0005;
    color: #fff;
    border-radius: 0.5rem;
  }
`;

export const DepositSavedAccount = styled.div.attrs({
  className: "form-group mb-3 col-lg-12 col-sm-12 col-12 text-left",
})`
  .btn {
    padding: 0;
    margin: 0;
    height: calc(1.5em + 1.5rem - 4px);
    width: 100%!important;
    background-color: #ae0005;
    color: #fff;
    border-radius: 0.5rem;
  }
`;

export const DepositCardNumber = styled.div.attrs({
  className: "form-group mb-3 col-lg-4 col-8 text-left",
})``;

export const DepositNotVisibleAccount = styled.div.attrs({
  className: "form-group mb-3 col-lg-4 col-8 text-left",
})``;

export const DepositExpiryDate = styled.div.attrs({
  className: "form-group mb-3 col-lg-2 col-sm-4 col-6 text-left",
})``;

export const DepositCCVCode = styled.div.attrs((props) => ({
  className: props.savedAccountsVisible
    ? "form-group mb-3 col-lg-12 col-sm-12 col-12 text-left"
    : "form-group mb-3 col-lg-2 col-sm-4 col-12 text-left",
}))``;

export const DepositName = styled.div.attrs({
  className: "form-group mb-3 col-md-6 col-12 text-left",
})``;

export const StyledBonusCode = styled.div.attrs({
  className: "form-group mb-3 col-12 text-left",
})``;

export const StyledBankName = styled.div.attrs({
  className: "form-group mb-3 col-12 text-left",
})``;

export const StyledBankTransfer = styled.div.attrs({
  className: "form-group mb-4 col-12 text-left container",
})`
  color: var(--tabletext) !important;
  .column-right {
    font-weight: 700;
  }
  .column-left {
    text-transform: capitalize;
  }
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const StyledBonusRow = styled.div.attrs({
  className: "row mt-3 mb-3",
})``;

export const ColumnLeftAndRight = styled.div.attrs({
  className: "col-md-6 col-sm-12",
})`
  text-transform: ${({ left }) => (left ? "capitalize" : "")};
  font-weight: ${({ right }) => (right ? "700" : "")};
`;

export const DepositNewStep = styled.div`
  color: var(--tabletext);

  .btn {
    margin-top: 3vh;
  }
`;

export const SpanCol = styled.span.attrs({
  className: "col-sm-12",
})`
  display: block;
`;

export const SpanColMd = styled.span.attrs({
  className: "col-md-2",
})``;

export const StyledContent = styled.div`
  font-weight: 700;
`;

export const StyledDWTotal = styled.span.attrs((props) => ({
  className: `flex-table-cell ${props.col}`,
}))`
  ${(props) => {
    if (props.theme.skin === "plc") {
      return `
            font-size: 1.1rem;
            line-height: 1.6;
            color: var(--acolor);
            font-weight: 700;
            text-align: justify;

            .flex-table-cell-content {
                font-weight: 500;
            }
            @media (max-width: 767px) {
                font-size: 1rem;
                line-height: 1.5;
                font-weight: 600;
                flex-wrap: wrap;
            }
            `;
    }
  }};
`;

export const StyledDresponse = styled.div`
  position: absolute;
  font-size: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: ${({ status }) => status};
  button {
    margin: ${({ theme }) => theme.skin === "rac" && "auto"};
  }
`;

export const StyledDresponseText = styled.div`
  color: #4674c8;
  font-size: 15px;
  margin-top: 4vh;
`;

export const StyledDepositDesciption = styled.div.attrs({
  className: "my-4 col-12 text-center",
})`
  //old browsers and IE support
  word-wrap: break-word;
  // newer versions
  overflow-wrap: break-word;
  color: var(--tabletext);
`;
