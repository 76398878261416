import React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import {
  StyledModal,
  StyledModalBody,
  StyledModalFooter,
} from "../styledComponents/StyledModals";
// import { QUERY_GET_LIMITS } from "common/src/graphql/queries";
import { printAmount } from "common/src/helpers/functions";
// import { useApolloClient } from "@apollo/client";
// import { getToken } from "common/src/helpers/session";

export const AffordabilityDialog = ({
  showModal,
  handleCancel,
  handleSupport,
  skin,
  server,
  player,
  affordabilityPopupLimit,
  popupVersion,
}) => {
  const { t } = useTranslation("");
  // const [allLimits, setAllLimits] = useState([]);
  // const [depositLimit, setDepositLimit] = useState([]);
  // const apolloClient = useApolloClient();
  const { currency } = player;

  // useEffect(() => {
  //   async function getLimits() {
  //     const { loading, errors, data } = await apolloClient.query({
  //       query: QUERY_GET_LIMITS,
  //     });
  //     if (loading) return <h3>Loading ...</h3>;
  //     if (errors) return false;
  //     setAllLimits(data.getLimits.limits);
  //     return true;
  //   }
  //
  //   if (allLimits.length === 0) {
  //     getToken() !== null && getLimits();
  //   }
  //   allLimits.map((limit) => {
  //     if (limit.limitType === "monthly_deposit" && limit.status === "active") {
  //       setDepositLimit(limit.initialAmount);
  //     }
  //   });
  // }, [allLimits, player, apolloClient, server.currencies]);

  return (
    <StyledModal isOpen={showModal}>
      <StyledModalBody>
        <h2
          dangerouslySetInnerHTML={{
            __html: t("label_affordability_dialog_title", {
              firstname: player.firstName,
            }),
          }}
        ></h2>{" "}
        {popupVersion === "1" && (
          <span
            dangerouslySetInnerHTML={{
              __html: t("label_affordability_dialog_deposit_limit", {
                limit: printAmount(
                  affordabilityPopupLimit,
                  player && currency,
                  server.currencies,
                  false
                ),
              }),
            }}
          ></span>
        )}
        {popupVersion === "2" && (
          <span
            dangerouslySetInnerHTML={{
              __html: t("label_affordability_dialog_deposit_limit_lowered", {
                limit: printAmount(
                  affordabilityPopupLimit,
                  player && currency,
                  server.currencies,
                  false
                ),
              }),
            }}
          ></span>
        )}
      </StyledModalBody>
      <StyledModalFooter position>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleCancel()}
        >
          {t("label_go_to_games")}
        </StyledButton>
        {popupVersion === "2" && (
          <StyledButton
            banktransfer
            pub={skin === "pub"}
            onClick={() => handleSupport()}
          >
            {t("label_contact_support")}
          </StyledButton>
        )}
      </StyledModalFooter>
    </StyledModal>
  );
};
