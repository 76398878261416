import React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import {
  StyledModal,
  StyledModalBody,
  StyledModalFooter,
} from "../styledComponents/StyledModals";

export const FailingWithdrawalDialog = ({ showModal, handleProceed }) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      <StyledModalBody register>
        {t("label_bank_failing_message")}
      </StyledModalBody>
      <StyledModalFooter position>
        <StyledButton banktransfer onClick={handleProceed}>
          {t("label_ok")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
