import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import {
  QUERY_GET_CASHBACK,
  QUERY_REDEEM_CASHBACK,
} from "common/src/graphql/queries";
import { printAmount } from "common/src/helpers/functions";
import { differenceInMilliseconds } from "date-fns";
import { toast } from "react-toastify";
// import Modal from "react-modal";
import { CashbackDialog } from "common/src/modal/CashbackDialog";
import { SERVER_SETUP } from "common/src/helpers/constants";
import { ContentTop } from "common/src/component/ContentTop";
import config from "../config.json";
import { isMobileOnly } from "react-device-detect";
import {
  PageContent,
  AccountContainer,
  PageContentWrapper,
  TabContent,
  StyledClock,
  StyledTextCenter,
  CashbackCountdown,
  StyledClockWrapper,
  DepositAndCashbackWrapper,
  TotalDepositWrapper,
  DepositAndCashback,
  TotalCashbackWrapper,
  RedeemCashback,
  CashbackButton,
  TextBottom,
  AccountDescription,
  BackgroundWrapperCashback,
} from "common/src/styledComponents/AccountPage";
import { StyledRow } from "common/src/styledComponents/StyledActiveBonus";
import { FooterButton } from "common/src/styledComponents/StyledFooter";
import { StyledMaterialIcon } from "common/src/styledComponents/StyledHeader";

let clicked = false;

const Cashback = ({
  width,
  playerCurrency,
  server,
  balance,
  pages,
  titles,
  player,
  setSpinner,
  skin,
}) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();
  const { geoIP } = server;
  const { isCashbackAbuser, country } = player;
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));
  /* const redirectTo = config.skin === "plc" ? "index" : "games"; */

  const isSWEIp =
    geoIP.isoCode.toLowerCase() === "se" || country === "se" ? true : false;

  if (isCashbackAbuser || isSWEIp) {
    window.location = pages["index"];
  }

  const [coverText, setCoverText] = useState(true);
  const [cashback, setCashback] = useState({});
  const [cashbackDialog, setCashbackDialog] = useState(false);
  const [difference, setDifference] = useState(0);
  const [expand, setExpand] = useState(false);

  const handleExpend = (e) => {
    e.preventDefault();
    setExpand(!expand);
  };

  useEffect(() => {
    // console.log(titles)
    document.title = titles["cashback"];
  }, [titles]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  }, []);
  let multi = 1;

  switch (playerCurrency) {
    case "nok":
    case "sek":
    case "zar":
      multi = 10;
      break;
    case "inr":
      multi = 100;
      break;
    default:
      multi = 1;
      break;
  }

  const textBottomRef = useRef(null);

  function errorChecker() {
    let error = false;
    //console.log(countdown());
    if (difference > 0) {
      toast.error(t("err_cashback_time"), {
        position: "bottom-center",
      });
      error = true;
    } else if (cashback.amount <= 0) {
      toast.error(t("err_cashback_not_accumulated"), {
        position: "bottom-center",
      });
      error = true;
    } else if (balance.pendingWithdrawal !== 0) {
      toast.error(t("err_cashback_pending_withdrawal"), {
        position: "bottom-center",
      });
      error = true;
    } else if (balance.real / 100 > 10 * multi) {
      toast.error(t("err_cashback_balance"), {
        position: "bottom-center",
      });
      error = true;
    }
    return error;
  }

  function cashbackRedeem() {
    if (!errorChecker()) setCashbackDialog(true);
  }

  async function getCashbackStatus() {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_CASHBACK,
    });
    if (loading) return <h3>Loading ...</h3>;
    if (errors) return <h1>`Error: ${errors}`</h1>;

    setCashback(data.getCashback);
    return null;
  }

  function msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }

  async function claimCashback() {
    setSpinner(true);
    if (!errorChecker() && !clicked) {
      clicked = true;
      const { loading, errors } = await apolloClient.query({
        query: QUERY_REDEEM_CASHBACK,
      });
      if (loading) return <h3>Loading ...</h3>;
      setCashbackDialog(false);
      if (errors) {
        toast.error(t(errors[0].message), {
          position: "bottom-center",
        });
        clicked = false;
      } else {
        toast.success(t("label_cashback_success_message"), {
          position: "bottom-center",
        });
        getCashbackStatus();
        window.location.reload();
      }
    }
    setSpinner(false);
  }

  useEffect(() => {
    getCashbackStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function countdown() {
      const now = new Date();
      let utcMonth = now.getUTCMonth() + 1;
      if (utcMonth < 10) {
        utcMonth = "0" + utcMonth.toString();
      }
      let utcDay = now.getUTCDate();
      if (utcDay < 10) {
        utcDay = "0" + utcDay.toString();
      }
      let utcHours = now.getUTCHours();
      if (utcHours < 10) {
        utcHours = "0" + utcHours.toString();
      }
      let utcMinutes = now.getUTCMinutes();
      if (utcMinutes < 10) {
        utcMinutes = "0" + utcMinutes.toString();
      }
      let utcSeconds = now.getUTCSeconds();
      if (utcSeconds < 10) {
        utcSeconds = "0" + utcSeconds.toString();
      }
      let now_utc =
        now.getUTCFullYear() +
        "-" +
        utcMonth +
        "-" +
        utcDay +
        "T" +
        utcHours +
        ":" +
        utcMinutes +
        ":" +
        utcSeconds;

      let df = differenceInMilliseconds(
        new Date(cashback.expiresAt.replace(/ /g, "T")),
        new Date(now_utc)
      );
      // console.log(df);
      // let difference = format(new Date(df), "hh:MM:ss");
      // console.log(difference);
      let diff = df <= 0 ? 0 : msToTime(df);
      //console.log(difference);
      setDifference(diff);
    }
    if (cashback.amount > 0) {
      const id = setInterval(() => {
        countdown();
      }, 1000);
      return () => clearInterval(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloClient, cashback.amount, cashback.expiresAt]);

  function toggleText() {
    if (coverText) {
      /*  let containerHeight = textBottomRef.current.scrollHeight; */
      /*  setMaxHeight(containerHeight); */
    } else {
      /*  setMaxHeight(200); */
    }
    setCoverText(!coverText);
  }

  /* const open = !coverText ? " open" : ""; */
  /* const [maxHeight, setMaxHeight] = useState(200); */
  /*  const fontSize = width > 1024 ? (width - 700) / 14 : width / 10; */

  return (
    <>
      <ContentTop
        serverSetup={serverSetup}
        showProfileMenu={!isMobileOnly}
        pages={pages}
        skin={skin}
        isMyAccount={"label_cashback"}
      />
      <PageContent account="true" cashback="true" nobg="true">
        <PageContentWrapper>
          <AccountContainer>
            <AccountDescription expand={expand} cashback>
              <h5>
                {t("label_cashback_description")}
                {/* "Welcome to your personal cashback page. For your convenience we
                keep track of the cashback you have accumulated so far. After
                your first deposit at No Bonus Casino the clock will count down
                24 hours. <br />
                After the 24 hours period has expired you will be able to
                activate the accumulated cashback. That is not mandatory, you
                can activate your cashback at any time after the 24 hours. This
                means you could claim your cashback once a week or once a month.
                The clock resets after you have activated your cashback." */}
              </h5>
            </AccountDescription>
            <FooterButton onClick={handleExpend}>
              <StyledMaterialIcon as="span" icon={expand}></StyledMaterialIcon>
            </FooterButton>
            <TabContent spanSize>
              <BackgroundWrapperCashback>
                <CashbackCountdown>
                  <StyledTextCenter>
                    <StyledClock>
                      <div>
                        {t("label_cashback_countdown_to_claim", "Time left:")}
                      </div>
                      <StyledClockWrapper style={{ fontSize: "5em" }}>
                        {difference === 0 ? "00:00:00" : difference}
                      </StyledClockWrapper>
                    </StyledClock>
                    <DepositAndCashbackWrapper>
                      <TotalDepositWrapper>
                        {t("label_cashback_total_deposit", "Total deposit")}
                        <DepositAndCashback>
                          {printAmount(
                            cashback && cashback.totalAmount
                              ? cashback.totalAmount
                              : 0,
                            playerCurrency,
                            server.currencies,
                            true
                          )
                            .toString()
                            .replace(/["."]+/g, ",")}
                        </DepositAndCashback>
                      </TotalDepositWrapper>
                      <TotalCashbackWrapper>
                        {t(
                          "label_cashback_total_cashback",
                          "Total cashback Accumulated"
                        )}

                        <DepositAndCashback>
                          {printAmount(
                            cashback && cashback.amount ? cashback.amount : 0,
                            playerCurrency,
                            server.currencies,
                            true
                          )
                            .toString()
                            .replace(/["."]+/g, ",")}
                        </DepositAndCashback>
                      </TotalCashbackWrapper>
                    </DepositAndCashbackWrapper>
                    {config.skin === "rac" && (
                      <StyledRow mt>
                        <RedeemCashback>
                          {t(
                            "label_cashback_redeem_cashback",
                            "I want my cashback, activate now!"
                          )}
                        </RedeemCashback>
                      </StyledRow>
                    )}
                    <CashbackButton
                      onClick={() => cashbackRedeem()}
                      disabled={
                        difference !== 0
                          ? true
                          : balance &&
                            (cashback.amount === undefined ||
                              balance.real / 100 > 10 * multi)
                          ? true
                          : false
                      }
                    >
                      {t("label_cashback_button_redeem", "activate cashback")}
                    </CashbackButton>
                  </StyledTextCenter>
                </CashbackCountdown>
              </BackgroundWrapperCashback>
              <TextBottom
                open
                /* style={{ maxHeight: maxHeight, overflow: 'hidden' }} */
                ref={textBottomRef}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("text_cashback_terms"),
                  }}
                />
              </TextBottom>
            </TabContent>
          </AccountContainer>
        </PageContentWrapper>
        <div /*className="text-expand "*/ onClick={() => toggleText()}></div>
        <CashbackDialog
          showModal={cashbackDialog}
          handleCancel={() => setCashbackDialog(false)}
          handleProceed={() => claimCashback()}
        />
      </PageContent>
    </>
  );
};

export default Cashback;
