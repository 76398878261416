import { AUTH_TOKEN } from "common/src/helpers/constants";

// Get player authentication token
export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN) || null;
};

// Set authentication token
export const setToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token);
};

// Destroy player session
export const destroySession = () => {
  localStorage.removeItem(AUTH_TOKEN);
};
