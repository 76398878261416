import Slider from "react-slick";
import styled from "styled-components";
import { isMobileOnly, isTablet, isMobile } from "react-device-detect";
import divider from "../../../pub/src/data/icons/divider.svg";

export const MainBgWrapper = styled.div`
  transition: transform 0.2s ease;
  width: 100%;
  position: relative;
  top: 0;
  height: 100%;
  opacity: ${({ theme }) => theme.current === "light" && "0.7"};
  source {
    z-index: -2;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
`;

export const Promoslider = styled.div`
  min-height: 100px;
  width: 100%;
  text-align: center;
`;

export const StyledSlider = styled(Slider)`
  width: 100%;
  top: 0;
`;

export const StyledSlide = styled.div`
  display: block !important;
  .details {
    color: var(--carouseltext);
    font-size: 12px;
    float: left;
  }
  img {
    padding-top: ${({ theme }) =>
      (theme.skin === "abc" || theme.skin === "nbc") && isTablet
        ? "1rem"
        : isMobileOnly
        ? theme.skin === "plc"
          ? "40px"
          : ""
        : "0 !important"};
    background-color: ${({ theme }) =>
      theme.skin === "plc" && "var(--headerbg)"};
  }
  .svgbutton {
    float: right;
    padding-bottom: 12px;
  }
`;

export const StyledSlideControlls = styled.div`
  width: 100%;
  display: ${({ theme }) =>
    theme.skin === "nbc" && isMobileOnly ? "none" : "flex"};
  justify-content: ${({ theme }) =>
    theme.skin === "nbc" ||
    ((theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
      !isMobileOnly)
      ? "left"
      : "center"};
  align-items: center;
  z-index: 4;
  position: ${({ theme }) =>
    theme.skin === "pub" ||
    theme.skin === "nbc" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    ((theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
      !isMobileOnly)
      ? "absolute"
      : "relative"};
  top: ${({ theme }) =>
    theme.skin === "nbc" ||
    ((theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
      !isMobileOnly)
      ? "30%"
      : theme.skin === "pub" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "abc"
      ? ""
      : "-100px"};
  bottom: ${({ theme }) =>
    theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
      ? "2vw"
      : ""};
  background-color: ${({ theme }) =>
    theme.skin === "yet" && isMobileOnly
      ? "var(--bgcolor)"
      : (theme.skin === "fun" || theme.skin === "yak") && isMobileOnly
      ? "var(--mainDescriptionABC)"
      : ""};

  & > div {
    width: 1600px;
    padding: ${({ theme }) =>
      (theme.skin === "nbc" ||
        ((theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "yak") &&
          !isMobileOnly)) &&
      !isTablet
        ? "0 100px"
        : theme.skin === "nbc" ||
          ((theme.skin === "yet" ||
            theme.skin === "fun" ||
            theme.skin === "yak") &&
            !isMobileOnly)
        ? "0px 140px 0 20px"
        : "0 40px"};
    display: flex;
    justify-content: flex-end;
    align-items: ${({ theme }) =>
      theme.skin === "nbc" ||
      ((theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
        !isMobileOnly)
        ? "left"
        : "center"};
    text-align: right;
  }
  a {
    color: ${({ theme }) => theme.skin === "yet" && "#07415F"};
    width: ${({ theme }) =>
      // theme.skin === "abc" ||
      theme.skin === "nbc" ||
      ((theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
        !isMobileOnly)
        ? "170px"
        : ""};
  }
  @media (max-width: 767px) {
    top: ${({ theme }) =>
      theme.skin === "nbc"
        ? "100px"
        : theme.skin === "pub" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "abc"
        ? ""
        : ""};
    position: ${({ theme }) =>
      theme.skin === "nbc"
        ? "absolute"
        : theme.skin === "pub" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "abc"
        ? ""
        : "static"};
    bottom: ${({ theme }) =>
      theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
        ? "3.5rem"
        : "inherit"};
    & > div {
      padding: ${({ theme }) =>
        theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak"
          ? "10px 20px"
          : "0 5px"};
      margin-top: ${({ theme }) => theme.skin === "plc" && "-60px"};
    }
  }
`;

export const WelcomeTextWrapper = styled.div.attrs({
  className: "d-flex flex-column col-md-8 col-sm-12",
})`
  cursor: ${({ theme }) =>
    (theme.skin === "nbc" ||
      ((theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
        !isMobileOnly)) &&
    "pointer"};
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  padding-top: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc" ? "160px" : ""};
  background-color: ${({ theme }) =>
    (theme.skin === "abc" || theme.skin === "nbc") && "var(--landingBgColor)"};

  -webkit-mask-image: ${({ theme }) =>
    theme.skin === "rac" && "linear-gradient(black, black, transparent)"};
  mask-image: ${({ theme }) =>
    theme.skin === "rac" && "linear-gradient(black, black, transparent)"};

  @media (max-width: 1024px) {
    padding-top: ${({ theme }) =>
      theme.skin === "nbc" ? "75px" : theme.skin === "abc" ? "120px" : ""};
  }
`;

export const StyledJoinCasino = styled.h1`
  color: var(--joinCasinoABC);
  text-align: left;
  font-weight: 600;
  padding: 0 10px 0px 10px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    line-height: 0;
    padding: 0 10px;
  }
`;

export const StyledSlideControllDetails = styled.div`
  font-size: ${({ theme }) =>
    // theme.skin === "abc" ||
    theme.skin === "nbc" ||
    ((theme.skin === "yet" || theme.skin === "fun") && !isMobileOnly)
      ? "3.5vw"
      : theme.skin === "cac" || theme.skin === "hyc" || theme.skin === "yak"
      ? "1.5vw"
      : theme.skin === "abc"
      ? "1vw"
      : "3em"};
  font-weight: ${({ theme }) =>
    theme.skin === "fun" || theme.skin === "hyc"
      ? "600"
      : theme.skin === "abc"
      ? "500"
      : "800"};
  line-height: ${({ theme }) =>
    theme.skin === "yet" || theme.skin === "fun"
      ? "1"
      : theme.skin === "abc"
      ? "0.8"
      : "1.3"};
  color: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak"
      ? "var(--welcomBonusFont)"
      : "var(--tabletext)"};
  text-align: ${({ theme }) =>
    // theme.skin === "abc" ||
    theme.skin === "nbc" ||
    ((theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
      !isMobileOnly)
      ? "left"
      : "center"};
  padding: ${({ theme }) =>
    // theme.skin === "abc" ||
    theme.skin === "nbc" || theme.skin === "yet" || theme.skin === "fun"
      ? "10px 0"
      : "0 10px 30px 10px"};
  padding-bottom: ${({ theme }) => theme.skin === "abc" && "50px"};

  font-family: ${({ theme }) =>
    theme.skin === "fun"
      ? "MorningBreeze"
      : theme.skin === "abc"
      ? "Geologica"
      : "inherit"};
  text-transform: ${({ theme }) => theme.skin === "abc" && "uppercase"};
  div {
    font-size: ${({ theme }) =>
      !isMobileOnly &&
      (theme.skin === "yet"
        ? "2vw"
        : theme.skin === "fun"
        ? "6vw"
        : theme.skin === "cac" || theme.skin === "hyc" || theme.skin === "yak"
        ? "3vw"
        : theme.skin === "abc"
        ? "2.7vw"
        : "")};
    color: ${({ theme }) =>
      (theme.skin === "fun" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "abc" ||
        theme.skin === "yak") &&
      "#fff"};
    text-shadow: ${({ theme }) => theme.skin === "fun" && "2px 2px #1e8ea6"};
    font-weight: ${({ theme }) => theme.skin === "abc" && "700"};
    &:last-child {
      font-family: ${({ theme }) =>
        theme.skin === "fun" && "RavagerSansRegular"};
      font-weight: ${({ theme }) =>
        (theme.skin === "fun" || theme.skin === "yak") && "400"};
    }
    small {
      color: #f4ef00;
      font-size: inherit;
    }
  }

  @media (max-width: 767px) {
    font-size: ${({ theme }) =>
      theme.skin === "nbc"
        ? "1.6rem"
        : theme.skin === "fun"
        ? "2.6em"
        : theme.skin === "abc"
        ? "0.8em"
        : "1.6em"};
    padding: ${({ theme }) => (theme.skin === "nbc" ? "10px" : "0 0 10px 0")};
    padding-bottom: ${({ theme }) => theme.skin === "abc" && "20px"};
    line-height: ${({ theme }) =>
      theme.skin === "nbc" ? "1" : theme.skin === "abc" ? "0.9" : "1.3"};
    width: ${({ theme }) => (theme.skin === "nbc" ? "180px" : "100%")};
    font-weight: ${({ theme }) => theme.skin === "abc" && "500"};
    div {
      font-size: ${({ theme }) => theme.skin === "abc" && "2.5em"};
      font-weight: ${({ theme }) => theme.skin === "abc" && "700"};
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: ${({ theme }) => (theme.skin === "nbc" ? "2.5rem" : "2rem")};
  }
`;

export const StyledSliderControll = styled.div`
  width: 100%;
  height: 20px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  & > div {
    width: 1600px;
    padding: 0 40px;
    button {
      float: left;
      background: none;
      border: none;
      z-index: 1100;
      transform: translateY(-26px);
      cursor: pointer;
      &.prev {
        padding-right: 10px;
      }
    }
  }
`;

export const InnderDescriptionWrapper = styled.div`
  max-width: 1600px;
  height: ${({ theme, maindescription }) =>
    (theme.skin === "nbc" ||
      theme.skin === "bnk" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak") &&
    isMobileOnly &&
    maindescription
      ? "75px"
      : "100%"};

  display: flex;
  margin: 0 auto;
  justify-content: ${({ theme }) =>
    theme.skin === "cac" || theme.skin === "hyc" || theme.skin === "abc"
      ? "center"
      : "space-between"};
  align-items: center;
  color: ${({ theme }) =>
    theme.skin === "pub"
      ? "#9A875D"
      : theme.skin === "cac" || theme.skin === "hyc"
      ? "#fff"
      : "#96a0b5"};
  padding: ${({ maindescription }) =>
    maindescription && isTablet ? "20px" : "10px"};
  flex-direction: ${({ theme }) =>
    (theme.skin === "pub" || theme.skin === "abc") && !isTablet
      ? isMobile
        ? "column-reverse"
        : theme.skin === "abc"
        ? ""
        : "row-reverse"
      : ""};
  margin: ${({ theme }) =>
    theme.skin === "pub" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "abc"
      ? "auto"
      : ""};
  font-size: 0.8rem;

  @media screen and (orientation: landscape) {
    flex-direction: ${({ theme }) =>
      (theme.skin === "pub" ||
        theme.skin === "cac" ||
        theme.skin === "hyc" ||
        theme.skin === "abc") &&
      isMobileOnly &&
      "row-reverse"};
    @media (max-width: 768px) {
      flex-direction: ${({ theme }) =>
        (theme.skin === "pub" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "abc") &&
        isMobileOnly &&
        "column-reverse"};
      align-items: ${({ theme }) =>
        (theme.skin === "pub" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "abc") &&
        "baseline"};
    }
  }

  .st0,
  .st4 {
    fill: ${({ theme }) =>
      theme.skin === "pub"
        ? "#9A875D"
        : theme.skin === "nbc"
        ? "#a790b1"
        : theme.skin === "yet"
        ? "#e2e2e2"
        : theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "yak" ||
          theme.skin === "qbc"
        ? "#fff"
        : "#96a0b5"};
  }
  div {
    font-size: ${isMobile ? "0.7rem" : "0.75rem"};
    color: ${({ theme }) =>
      theme.skin === "pub"
        ? "#9A875D"
        : theme.skin === "nbc"
        ? "#c1a5cd"
        : theme.skin === "yet"
        ? "#e2e2e2"
        : theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "yak" ||
          theme.skin === "qbc"
        ? "#fff"
        : theme.skin === "fun"
        ? "#283d6f"
        : "#7688b1"};
    vertical-align: super;
    padding: ${({ theme }) =>
      theme.skin === "abc" && isMobileOnly && "0 25px 0 0"};
    padding-top: ${({ maindescription, theme }) =>
      !maindescription && theme.skin === "nbc" ? "10px" : ""};
    &:nth-child(2) {
      @media (max-width: 768px) {
        position: ${({ theme }) => theme.skin === "abc" && "absolute"};
        padding-right: ${({ theme }) => theme.skin === "abc" && "5px"};

        padding-top: ${({ maindescription, theme }) =>
          (!maindescription &&
            (theme.skin === "nbc" ||
              theme.skin === "yet" ||
              theme.skin === "fun" ||
              theme.skin === "yak")) ||
          theme.skin === "abc" ||
          theme.skin === "pub" ||
          theme.skin === "cac" ||
          theme.skin === "hyc"
            ? "0"
            : "3rem"};
        width: 100%;
        div {
          margin: ${({ maindescription }) =>
            maindescription ? "0.8rem 0 0 0" : "1rem 0 0 0"};
        }
      }
    }
    svg {
      padding-left: ${({ theme }) =>
        theme.skin === "abc" && !isMobileOnly && "5px"};

      @media (max-width: 768px) {
        padding-right: ${({ theme }) => theme.skin === "abc" && "5px"};
        margin-top: ${({ theme }) => (theme.skin === "abc" ? "" : "0.5rem")};
        float: right;
      }
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: ${({ theme }) =>
      theme.skin === "nbc" ? (isTablet ? "20px" : "0 20px 0 20px") : ""};
    font-size: ${({ theme }) =>
      theme.skin === "nbc" ? (isTablet ? "0.7rem" : "0.5rem") : ""};
  }
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  background-color: var(--mainDescriptionABC);
  /* background: ${({ theme }) =>
    theme.skin === "fun" &&
    "linear-gradient(181deg, rgba(166,202,191,1) 0%, rgba(67,193,207,1) 35%)"}; */
`;

export const DividerPub = styled.div`
  background-image: url(${divider});
  background-repeat: no-repeat;
  background-position: center;
  width: 170px;
  height: 20px;
  text-align: center;
  margin-top: 1rem;
  margin: ${({ footer }) => footer && "auto"};
`;
