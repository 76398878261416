import styled, { ThemeConsumer, ThemeContext } from "styled-components";
import footerLight from "../../../plc/src/data/img/footer_top_light.svg";
import footerDark from "../../../plc/src/data/img/footer_top.svg";
import PUBfooterSafari from "../../../pub/src/data/img/pub-footer-img.png";
import PUBfooter from "../../../pub/src/data/img/pub-footer-img.webp";
import { ReactComponent as FooterBottom } from "../../../plc/src/data/icon-component/footer_bottom.svg";
import { ReactComponent as Logo18img } from "common/src/assets/img/logo_18.svg";
import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
} from "react-device-detect";

export const FooterWrapper = styled.div`
  width: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc" || theme.skin === "pub") &&
    isMobile
      ? "90%"
      : "100vw"};
  max-width: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc") && "1600px"};
  margin: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc" || theme.skin === "pub") &&
    "2rem auto"};
  border-radius: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc") && "30px"};
  background-image: ${({ theme }) =>
    theme.skin === "plc"
      ? theme.current === "light"
        ? `url(${footerLight})`
        : `url(${footerDark})`
      : ""};
  background-position: top;
  background-repeat: no-repeat;
  padding-top: ${({ game, theme }) =>
    game || theme.skin !== "plc" ? "0" : "5rem"};
  background-color: ${({ game, theme }) =>
    game
      ? theme.skin === "abc" || theme.skin === "nbc"
        ? "var(--bgColorABC)"
        : theme.skin === "pub" ||
          theme.skin === "rac" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "yak"
        ? "var(--footerColor)"
        : "var(--gamebg)"
      : theme.skin === "rac" || theme.skin === "fun" || theme.skin === "yak"
      ? "var(--footerColor)"
      : ""};

  &::before {
    content: "";
    background-image: url(${footerLight});
    background-position: top;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 767px) {
    padding-top: ${({ game, theme }) =>
      game ||
      theme.skin === "abc" ||
      theme.skin === "pub" ||
      theme.skin === "cac" ||
      theme.skin === "hyc"
        ? "0"
        : "1.5rem"};
    padding-bottom: 30px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-top: ${({ game, theme }) =>
      game ||
      theme.skin === "abc" ||
      theme.skin === "pub" ||
      theme.skin === "cac" ||
      theme.skin === "hyc"
        ? "0"
        : "3rem"};
  }
`;

export const FooterLinksWrapper = styled.section`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.skin === "abc" ? "var(--bgColorABC)" : "var(--footerColor)"};
  text-align: center;
  /* height: ${({ theme }) => (theme.skin !== "pub" ? "" : "700px")}; */
  font-family: ${({ theme }) => (theme.skin === "pub" ? "Montserrat" : "")};
  border-radius: ${({ theme }) =>
    (theme.skin === "cac" || theme.skin === "hyc") && "30px 30px 0 0"};

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    padding: ${({ theme }) =>
      theme.skin !== "plc" && theme.skin !== "bnk"
        ? "0"
        : "40px 40px 100px 40px;"};
  }
  @media (min-width: 767px) and (max-width: 924px) {
    height: ${({ theme }) =>
      theme.skin !== "plc" && theme.skin !== "bnk" ? "" : "650px"};
  }
`;

export const StyledFooterLinks = styled.div`
  display: flex;
  justify-content: ${({ abc, theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak") &&
    abc
      ? "space-between"
      : "center"};
  flex-direction: row;
  background-color: ${({ theme, abc }) =>
    theme.skin === "abc" && !abc
      ? "#01143C"
      : theme.skin === "rac"
      ? "rgba(0,43,161,.04)"
      : ""};
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  width: ${({ theme, abcextra }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? abcextra
        ? "100%"
        : ""
      : theme.skin === "rac"
      ? "100%"
      : "800px"};
  max-width: ${({ theme, abcextra }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? abcextra
        ? "100%"
        : "1600px"
      : theme.skin === "rac"
      ? "100%"
      : "800px"};
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  padding: ${({ theme, pubsize }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak"
      ? "20px"
      : pubsize && !isMobile
      ? "40px 60px"
      : theme.skin === "rac"
      ? "10px"
      : theme.skin === "qbc"
      ? "30px 0"
      : "40px 0"};
  background-color: ${({ background }) => background && "var(--footerBubleBG)"};
  border-radius: ${({ background }) => background && "45px"};
  text-transform: ${({ capitalize }) =>
    capitalize ? "capitalize" : "uppercase"};
  border-bottom: ${({ theme, noborder, pubcolor }) =>
    noborder
      ? ""
      : theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
      ? "1px solid var(--borderABC)"
      : theme.skin === "pub" && pubcolor
      ? "1px solid #9A875D"
      : "1px solid var(--footerTextColor)"};

  a {
    flex-wrap: wrap;
    color: ${({ theme }) =>
      theme.skin === "rac" || theme.skin === "bnk"
        ? "var(--footerlinks)"
        : "#fff"};
    padding: ${({ theme, abc }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak") &&
      abc
        ? "0"
        : "20px"};
    text-transform: capitalize;
  }
  div {
    z-index: ${({ theme }) =>
      (theme.skin === "fun" || theme.skin === "yak") && "1"};
    flex-wrap: wrap;
    padding: 8px;
    font-size: ${({ theme, abc }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak") &&
      abc
        ? "16px"
        : "12px"};
    font-weight: ${({ theme }) => (theme.skin === "pub" ? "500" : "600")};
    a {
      text-transform: ${({ theme, abc }) =>
        (theme.skin === "abc" ||
          theme.skin === "nbc" ||
          theme.skin === "yet" ||
          theme.skin === "fun" ||
          theme.skin === "yak") &&
        abc
          ? "uppercase !important"
          : ""};
      color: ${({ theme, abc }) =>
        theme.skin === "abc" && abc
          ? "#0554b3"
          : theme.skin === "pub"
          ? "#9A875D"
          : theme.skin === "rac"
          ? "var(--footerlinks)"
          : "var(--footerTextColor)"};
    }
  }
  svg {
    cursor: pointer;
    width: 42px;
    height: 32px;
    padding-right: 6px;
  }

  @media (max-width: 568px) {
    a {
      padding: 10px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
    div {
      padding: 10px;
      a {
        padding: 10px 0;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "100%"
        : "90%"};
    justify-content: center;
  }

  /*  @media (max-width: 1148px) {
    padding: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc" || theme.skin === "yet"
      ? "0"
      : "20px 0"};
  } */
`;

export const StyledFooterText = styled.section.attrs((props) => ({
  className:
    props.theme.skin === "abc" ||
    props.theme.skin === "nbc" ||
    props.theme.skin === "yet" ||
    props.theme.skin === "fun" ||
    props.theme.skin === "yak" ||
    props.theme.skin === "qbc"
      ? "row"
      : "",
}))`
  text-align: ${({ theme }) => (theme.skin === "rac" ? "center" : "justify")};
  font-size: ${({ theme }) => theme.skin === "pub" && "0.8rem"};
  color: ${({ theme }) =>
    theme.skin === "abc" ? "#0554b3" : "var(--footerTextColor)"};
  width: ${({ expand, theme }) =>
    expand
      ? "100%"
      : theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak" ||
        theme.skin === "qbc"
      ? ""
      : theme.skin === "rac"
      ? "1200px"
      : "800px"};
  max-width: ${({ expand, theme }) =>
    expand
      ? "100%"
      : theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak" ||
        theme.skin === "qbc"
      ? "1600px"
      : theme.skin === "rac"
      ? "1200px"
      : "800px"};
  margin: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak" ||
      theme.skin === "qbc") &&
    isMobileOnly
      ? "0px auto"
      : "40px auto"};
  padding: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? "0"
      : "0 20px 0 0"};
  overflow-y: ${({ theme }) => (theme.skin === "rac" ? "" : "auto")};
  height: ${({ expand, theme, companyInfo }) =>
    companyInfo
      ? ""
      : expand
      ? "100%"
      : theme.skin === "abc" ||
        theme.skin === "rac" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak" ||
        theme.skin === "qbc"
      ? "100%"
      : "200px"};
  padding-bottom: ${({ expand }) => (expand ? "20px" : "")};
  scrollbar-color: #afafaf #000000;
  font-weight: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? "600"
      : ""};
  line-height: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? "1.8"
      : ""};
  border-bottom: ${({ theme }) =>
    theme.skin === "abc"
      ? "1px solid var(--borderABC)"
      : theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
      ? isMobileOnly
        ? ""
        : "1px solid var(--borderABC)"
      : ""};

  .col-xl {
    padding-bottom: 40px;
  }
  a {
    text-decoration: underline;
    color: ${({ theme }) =>
      theme.skin === "abc"
        ? "#0554b3"
        : theme.skin === "rac"
        ? "#fff"
        : theme.skin === "nbc"
        ? "#70328e"
        : "var(--footerTextColor)"};
  }
  p {
    font-size: inherit;
    text-align: justify;
    color: var(--footerTextColor);
  }
  h3 {
    font-size: 26px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: ${({ theme }) =>
      theme.skin === "fun" || theme.skin === "yak" ? "#fff" : "var(--footerh)"};
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.skin === "pub"
        ? "#9A875D"
        : theme.skin === "cac" || theme.skin === "hyc"
        ? "#AAA2A5"
        : "var(--footerTextColor)"};
    border-radius: 5px;
    border: 0px solid var(--scrollbarBG);
  }

  @media (max-width: 767px) {
    width: 100%;
    overflow-y: hidden;
    height: ${({ expand, theme, companyInfo }) =>
      companyInfo ? "" : expand || theme.skin === "rac" ? "100%" : "200px"};
    padding: ${({ theme, companyInfo }) =>
      (theme.skin === "cac" || theme.skin === "hyc") && !companyInfo
        ? "1rem"
        : "0"};
  }

  @media (min-width: 767px) and (max-width: 924px) {
    width: 90%;
  }
`;

export const FooterButton = styled.button`
  display: none;
  padding: ${({ theme }) =>
    theme.skin === "hyc" ? "0 0 10px 0" : "0 0 2rem 0"};

  .material-icons {
    vertical-align: bottom;
  }

  @media screen and (max-width: 767px) {
    display: block;
    border: none;
    background-color: inherit;
    color: ${({ theme }) =>
      theme.skin === "yet"
        ? "#358bb7"
        : theme.skin === "fun" ||
          theme.skin === "cac" ||
          theme.skin === "hyc" ||
          theme.skin === "yak"
        ? "#fff"
        : "var(--footerBorder)"};
    margin: 0 auto;
    padding: ${({ theme }) =>
      (theme.skin === "yet" || theme.skin === "fun" || theme.skin === "yak") &&
      "1rem"};
  }
`;

export const FooterBottomSection = styled.section`
  margin-top: ${({ theme }) => (theme.skin !== "plc" ? "0" : "-70px")};
  background-color: var(--footerColor);

  @media (min-width: 768px) and (max-width: 1024px) {
    width: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "bnk" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "90%"
        : ""};
    margin: 0 auto;
  }
`;

export const CacSectionWrapper = styled.div`
  border-radius: 30px;
  background-color: var(--footerColor);
  min-height: 500px;
`;

export const StyledFaderBottom = styled(FooterBottom)`
  fill: var(--footerFaderBottom);
  width: 100%;
`;

export const StyledFooterBootom = styled.div`
  margin-top: ${({ theme }) =>
    theme.skin !== "plc" && theme.skin !== "pub" ? "" : "-1.5rem"};
  width: 100%;
  height: ${({ theme }) =>
    theme.skin === "rac" ||
    theme.skin === "cac" ||
    theme.skin === "fun" ||
    theme.skin === "pub" ||
    theme.skin === "hyc" ||
    theme.skin === "yak"
      ? ""
      : "350px"};
  background-color: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "nbc"
      ? "var(--bgColorABC)"
      : "var(--footerFaderBottom)"};

  //ovde je problem za scroll
  @media (max-width: 768px) {
    height: ${({ theme }) =>
      theme.skin !== "pub" &&
      theme.skin !== "cac" &&
      theme.skin !== "hyc" &&
      theme.skin !== "fun" &&
      theme.skin !== "yak" &&
      "300px"};
  }
`;

export const StyledTrustlyline = styled.div`
  width: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? ""
      : "800px"};
  max-width: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? "1600px"
      : "800px"};
  padding-top: ${({ theme }) => theme.skin === "pub" && "3rem"};

  img {
    margin: 0;
  }
  p {
    color: #fff;
  }

  @media (max-width: 798px) {
    flex-direction: column;
    width: 350px;
    padding: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "pub" ||
      theme.skin === "yak"
        ? "0"
        : "0 10px"};
  }

  @media screen and (max-width: 767px) {
    img {
    }
    p {
      font-size: 0.8rem;
    }
  }
  @media (min-width: 767px) and (max-width: 924px) {
    width: 95%;
    margin: 0 40px;
    img {
    }
    p {
      font-size: 0.8rem;
    }
  }
`;

export const Logo18 = styled.div.attrs({
  className: `inline`,
})`
  width: ${({ theme }) => (theme.skin !== "plc" ? "" : "100%")};
  display: inline-block;

  div {
    display: inline-block;
    color: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "bnk"
        ? "#96a0b5"
        : theme.skin === "nbc"
        ? "#a790b1"
        : "#fff"};
    margin: ${isTablet ? "0 10px" : "20px"};
    line-height: 1.2;
    font-size: 0.8rem;
    font-weight: 400;
    vertical-align: middle;
  }
  img {
    width: 40px !important;
    margin: 0 auto !important;
  }

  @media screen and (max-width: 767px) {
    position: ${({ maindescription }) =>
      maindescription && !isTablet && "absolute"};
    width: ${({ theme, maindescription }) =>
      (theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak") &&
      maindescription
        ? "155px !important"
        : ""};
    ${(props) => {
      if (props.maindescription) {
        return `
        top: ${
          props.theme.skin === "nbc" ||
          ((props.theme.skin === "yet" ||
            props.theme.skin === "fun" ||
            props.theme.skin === "yak") &&
            isMobileOnly)
            ? "100vw"
            : /* (props.theme.skin === "abc" && isMobileOnly) ? "81vw" : */ "90vw"
        };
        left: ${
          props.theme.skin === "nbc" ||
          ((props.theme.skin === "yet" ||
            props.theme.skin === "fun" ||
            props.theme.skin === "yak") &&
            isMobileOnly)
            ? ""
            : "20px"
        };
        right: ${
          props.theme.skin === "nbc" ||
          ((props.theme.skin === "yet" ||
            props.theme.skin === "fun" ||
            props.theme.skin === "yak") &&
            isMobileOnly)
            ? "10px"
            : ""
        };
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        /* flex-direction: row-reverse; */
        /* flex-flow: row wrap; */
        flex-flow: wrap-reverse;
        `;
      }
    }}
    div {
      font-size: 10px;
    }

    padding: 0px;
    img {
      width: 14px;
    }
  }
`;

export const FotterLicences = styled.div`
  background-color: ${({ background }) => background && "var(--footerBubleBG)"};
  border-radius: ${({ background }) => background && "45px"};
  border-bottom: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? isMobileOnly
        ? ""
        : "1px solid var(--borderABC)"
      : "1px solid var(--footerBorder)"};
  display: flex;
  border-left: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak") &&
    isMobileOnly &&
    "1px solid var(--borderABC)"};
  border-right: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak") &&
    isMobileOnly &&
    "1px solid var(--borderABC)"};
  flex-direction: row;
  flex-wrap: wrap;
  align-items: space-between;
  align-content: space-between;
  padding: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "1rem 0 0 0"
      : theme.skin === "qbc"
      ? "2.5rem"
      : "4rem 0 0 0"};
  justify-content: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "cac" ||
    theme.skin === "hyc" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? "center"
      : "space-between"};
  max-width: ${({ theme }) =>
    theme.skin === "abc" || theme.skin === "qbc" ? "" : "1200px"};
  margin: ${({ theme }) =>
    (theme.skin === "fun" || theme.skin === "yak" || theme.skin === "qbc") &&
    "auto"};

  img {
    width: ${({ theme }) => (theme.skin === "bnk" ? "120px" : "80px")};
    margin-left: 10px;
  }
  div {
    margin: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "0 0 40px 0"
        : theme.skin === "cac" || theme.skin === "hyc"
        ? "10px"
        : theme.skin === "qbc"
        ? "auto"
        : "0 0 1rem 0"};
    display: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "flex"
        : ""};
    align-items: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "center"
        : ""};
    width: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "145px"
        : ""};
    height: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "80px"
        : ""};
    border-left: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? isMobileOnly
          ? ""
          : "1px solid var(--borderABC)"
        : ""};
    justify-content: center;
    text-align: center;
    border-right: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? isMobileOnly
          ? ""
          : "1px solid var(--borderABC)"
        : ""};
  }
  div:last-child {
    img {
      width: ${({ theme }) => (theme.skin === "bnk" ? "120px" : "30px")};
    }
    border-right: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? isMobileOnly
          ? ""
          : "1px solid var(--borderABC)"
        : ""};
  }
  div:last-child {
  }
  .st0,
  .st4 {
    fill: ${({ theme }) =>
      (theme.current === "dark" && theme.skin !== "rac") ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak" ||
      theme.skin === "qbc"
        ? "#fff"
        : theme.skin === "bnk"
        ? "#96a0b5"
        : theme.skin === "rac"
        ? "#2c5194"
        : "#0554b3"};
    justify-content: center;
  }
  .cls-1 {
    ${(props) => {
      if (
        props.theme.skin === "abc" ||
        props.theme.skin === "nbc" ||
        props.theme.skin === "yet" ||
        props.theme.skin === "fun" ||
        props.theme.skin === "yak"
      ) {
        if (props.theme.current === "dark") {
          return `fill: #fff`;
        } else {
          return props.theme.skin === "nbc" ||
            props.theme.skin === "yet" ||
            props.theme.skin === "fun" ||
            props.theme.skin === "yak"
            ? `fill: #fff`
            : `fill: #0554b3`;
        }
      } else if (props.theme.skin === "rac") {
        return `fill: #2c5194`;
      } else {
        return `fill: #fff`;
      }
    }}
  }

  @media screen and (max-width: 767px) {
    justify-content: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "center"
        : "space-around"};
    padding: 15px 0;
    div {
      margin: 0;
      width: 33.3%;
      height: 50px;
      padding: 10px;
      /* padding: 20px 20px 20px 0; */
    }
    img {
      width: 100%;
    }
    svg {
      width: 100%;
    }
  }
`;

export const Copyrightght = styled.div`
  padding: 10px;
  text-align: center;

  p {
    color: ${({ theme }) =>
      theme.skin === "abc" || theme.skin === "nbc"
        ? "var(--copyrightABC)"
        : "var(--footerTextColor)"};
    font-size: ${({ theme }) =>
      theme.skin === "pub" ||
      theme.skin === "rac" ||
      theme.skin === "cac" ||
      theme.skin === "hyc"
        ? "0.83rem"
        : "1rem"};
    padding: 10px;
    :first-child {
      padding-left: 0;
    }
  }

  @media (max-width: 767px) {
    p {
      font-size: 0.8rem;
    }
  }
`;

export const ProviderLogos = styled.img`
  margin: ${({ licences }) => !licences && "10px 12px"};
  width: 120px !important;

  @media (max-width: 767px) {
    width: 80px !important;
  }
  svg {
    height: 30px;
    width: ${!isMobileOnly && "100px"};
  }
`;
export const ProviderThemes = styled.img`
  margin: 10px 12px;
  width: 100%;
  padding: 0 !important;
  border-radius: 0.5rem;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const FilterByTheme = styled.div`
  margin: auto;
  text-align: center;
  width: 150px !important;
  height: 100px;
  font-size: 1.1rem;
  transform: rotate(-10deg);
  padding-top: 35px;

  p {
    /* color: #ae0005; */
    font-weight: 900;
    margin-top: 2rem;
    background: #0554b3;
    background: -webkit-linear-gradient(to right, #0554b3 0%, #cf1f2c 100%);
    background: -moz-linear-gradient(to right, #0554b3 0%, #cf1f2c 100%);
    background: linear-gradient(to right, #0554b3 0%, #cf1f2c 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 767px) {
    width: 110px !important;
    height: 65px !important;
    padding-top: 20px;
  }
`;

export const PaymentMethodsHeader = styled.h3`
  font-family: ${({ theme }) => theme.skin === "pub" && "Ancho"};
  color: var(--newsText);
  font-weight: ${({ theme }) => (theme.skin === "pub" ? "400" : "800")};
  font-size: 1.33rem;

  @media screen and (max-width: 767px) {
    padding: 10px 50px;
    line-height: 1.6;
  }
`;

export const PaymentProviderWrapper = styled.div`
  display: flex;
  justify-content: ${({ theme, ractrystly }) =>
    theme.skin === "rac"
      ? ractrystly
        ? "space-between"
        : "space-around"
      : "center"};
  align-items: center;
  width: 100%;
  height: ${({ theme }) =>
    theme.skin !== "plc" && theme.skin !== "bnk" ? "" : "120px"};
  min-height: ${({ theme }) =>
    (theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "cac" ||
      theme.skin === "hyc" ||
      theme.skin === "yak") &&
    "120px"};

  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${({ theme }) =>
      theme.skin !== "plc" ? isDesktop && "300px" : ""};

    img {
      width: 80px;
      height: 25px;
    }
  }
`;

export const RacLogo18 = styled(Logo18img)`
  width: 40px;
  .st4 {
    fill: #4674c8;
  }
`;

export const RacFooterTextTop = styled.div`
  font-weight: 550;
  font-size: 13pt;
  color: #4674c8;
  text-align: center;
  padding: 0px;
`;

export const PaymentProvider = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: ${({ theme }) =>
    theme.skin === "pub" || theme.skin === "cac" ? "baseline" : "center"};
  align-content: center;
  justify-content: ${({ theme }) =>
    theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc"
      ? "inherit"
      : "center"};
  max-width: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak" ||
    theme.skin === "qbc"
      ? "1600px"
      : "1130px"};
  min-height: ${({ theme }) =>
    (theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc") &&
    "100%"};
  border-right: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "1px solid var(--borderABC)"
      : ""};
  border-left: ${({ theme }) =>
    theme.skin === "abc" ||
    theme.skin === "nbc" ||
    theme.skin === "yet" ||
    theme.skin === "fun" ||
    theme.skin === "yak"
      ? "1px solid var(--borderABC)"
      : ""};

  .extra-border {
    :first-child {
      border-left: none;
    }
    :last-child {
      border-right: ${({ theme }) =>
        theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak"
          ? "1px solid var(--borderABC)"
          : ""};

      @media (max-width: 767px) {
        border-right: none;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        border-right: none;
      }
    }

    border-left: ${({ theme }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak") &&
      !isMobileOnly
        ? "1px solid var(--borderABC)"
        : ""};
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
  }

  @media (max-width: 640px) {
    justify-content: center;
    max-width: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? "330px"
        : ""};
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    justify-content: ${({ theme }) =>
      (theme.skin === "abc" ||
        theme.skin === "nbc" ||
        theme.skin === "yet" ||
        theme.skin === "fun" ||
        theme.skin === "yak") &&
      "center"};
    max-width: ${({ theme }) =>
      theme.skin === "abc" ||
      theme.skin === "nbc" ||
      theme.skin === "yet" ||
      theme.skin === "fun" ||
      theme.skin === "yak"
        ? ""
        : ""};
  }
`;

export const PUBFooterImg = styled.div`
  background-image: url(${PUBfooter});
  background-size: ${isMobile ? "contain" : "cover"};
  background-repeat: no-repeat;
  background-position: center;
  width: ${isMobile ? "100%" : "800px"};
  height: ${isMobileOnly ? "180px" : "400px"};
  margin: auto;
`;

export const PUBFooterImgSafari = styled(PUBFooterImg)`
  background-image: url(${PUBfooterSafari});
`;
