import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import React from "react";

export const TermsAlertDialog = ({
  showModal,
  handleProceed,
  currentLanguage,
  skin,
}) => {
  const { t } = useTranslation("");
  // let TCurl = `/${currentLanguage}/terms-and-conditions`;

  return (
    <StyledModal isOpen={showModal}>
      <StyledModalBody register>
        <div
          dangerouslySetInnerHTML={{ __html: t("terms_alert_message") }}
        ></div>
      </StyledModalBody>
      <StyledModalFooter>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleProceed()}
        >
          {t("label_proceed")}
        </StyledButton>
        <StyledButton
          dialog
          pub={skin === "pub"}
          as={Link}
          to={`/${currentLanguage}/logout`}
        >
          {t("label_cancel")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
