import styled from "styled-components";
import { isTablet, isMobile } from "react-device-detect";

export const BonusWrapper = styled.div.attrs({})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  align-items: auto;
  align-content: start;
`;

export const ActiveBonusColumn = styled.div.attrs({
  className: "col-xs-12 col-md-12 col-lg-9 desc-wrapper",
})`
  border-bottom: ${({ theme }) =>
    (theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc") &&
    "2px solid var(--progressBar)"};
  padding: ${({ theme }) =>
    (theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc") &&
    "0"};
  padding-bottom: ${({ theme }) =>
    (theme.skin === "pub" || theme.skin === "cac" || theme.skin === "hyc") &&
    "1rem"};
`;

export const BonusContainerLeft = styled.div`
  width: 80%;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const BonusContainerRight = styled.div`
  width: 20%;
  margin: auto;

  @media (max-width: 992px) {
    width: 100%;
    text-align: center;
  }
`;

export const BonusImg = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  img {
    flex-grow: 0;
    width: 154px;
    height: 100%;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const BonusDescriptionSection = styled.div`
  flex-grow: 2;
  padding: 1rem;
  div:first-child {
    padding-bottom: 0.5rem;
  }
`;

export const StyledRow = styled.div.attrs((props) => ({
  className: props.mt
    ? `row mt-3 ${props.mb}`
    : props.sort
    ? "row sort"
    : "row",
}))`
  background-color: ${({ theme, validformWithdrawal }) =>
    (theme.skin === "fun" || theme.skin === "yak") &&
    !validformWithdrawal &&
    "var(--racProfileMenuCol)"};
  border-radius: ${({ theme, landing }) =>
    (theme.skin === "fun" || theme.skin === "yak") && !landing && "20px"};
  margin: ${({ theme, landing, validformWithdrawal }) =>
    (theme.skin === "fun" || theme.skin === "yak") &&
    !landing &&
    !validformWithdrawal &&
    "1rem 0"};
  color: ${({ theme }) => (theme.skin === "abc" ? "#0554b3" : "")};
  input {
    color: ${({ theme }) => (theme.skin === "abc" ? "#0554b3 !important" : "")};
  }
  select {
    color: ${({ theme }) => (theme.skin === "abc" ? "#0554b3 !important" : "")};
  }
`;

export const ActiveBonusSecondColumn = styled.div.attrs({
  className:
    "col-xs-12 col-md-8 col-lg-8 d-flex flex-column justify-content-between description my-3",
})``;

export const ActiveBonusTerms = styled.div`
  ol,
  ul {
    margin-left: 20px;
    list-style-position: outside;
  }
  li {
    display: list-item !important;
    padding: 5px 10px;
  }
  ol:before {
    font-size: 15px;
  }
`;

export const ActiveBonusActions = styled.div.attrs({
  className: "col-xs-12 col-md-12 col-lg-3",
})`
  margin-top: 6px;
  margin-bottom: -45px;

  .btn-first {
    height: 30px;
    width: 90px;
    padding: 0;
    font-size: 10px;
  }
`;

export const ActiveBonusContent = styled.div.attrs((props) => ({
  className:
    props.theme.skin === "abc" || props.theme.skin === "nbc"
      ? "col-xs-12 image"
      : "col-xs-12 col-md-4 col-lg-4 image",
}))`
  img {
    width: 154px;
  }
`;

export const BonusInfoWrapper = styled.div.attrs({
  className: "col-xs-12 col-md-12 col-lg-3 actions",
})``;

//prazno!
export const BonusInfo = styled.div.attrs({
  className: "bonus-info",
})`
  button {
    font-size: 1rem !important;
    padding: 10px !important;
    width: 100% !important;
    height: 100% !important;
  }
`;
