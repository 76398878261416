import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledLimitWrapper,
  StyledLimitSelect,
  IncreaseLimit,
  RealityCheckWrapper,
  StyledCooldownExplanation,
  StyledCooldownExplanationSmall,
} from "../styledComponents/StyledLimits";
import {
  StyledButton,
  StyledInput,
  StyledSelect,
} from "../styledComponents/CommonStyledComponents";
import { AccountDescription } from "../styledComponents/AccountPage";
// import { HTML5_FMT } from "react-moment";

const DepositLimit = ({
  limitsData,
  skinCurrency,
  player,
  depositMaxLimit,
  depositLimits,
  hasLimits,
  handleSubmit,
  handleChange,
  resetLimits,
  isUk,
  realityCheck,
  skin,
}) => {
  const { t, i18n } = useTranslation("");
  const { country, limitsLocked } = player;
  //console.log("country: " + country);
  const localizedFormat = `${
    i18n.language || window.localStorage.i18nextLng || "nl"
  }`;

  //console.log(player);

  //const [maxValue, setMaxLimit] = useState(depositMaxLimit);
  const { depositValue, depositDate } = limitsData;
  //console.log(depositDate);

  const [hasIncrease /*setHasIncrease*/] = useState(false);
  const [increaseTime /*setIncreaseTime*/] = useState("");
  const [blockChange, setBlockChange] = useState(false);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    function hasLimit() {
      /* if (depositLimits[0].increaseAt !== '') {
        setHasIncrease(true);
        setIncreaseTime(depositLimits[0].increaseAt);
      } */

      if (
        depositLimits[0].expiresAt !== "" &&
        depositLimits[0].status === "active"
      ) {
        //console.log("expiresAt" + JSON.stringify(depositLimits));
        //setMaxLimit(depositValue);
        setBlockChange(true);
      }
    }
    depositLimits.length > 0 && hasLimit();
  }, [depositLimits]);

  return (
    <>
      {(skin === "fun" ||
        skin === "hyc" ||
        skin === "cac" ||
        skin === "yak") && (
        <AccountDescription
          expand={true}
          description
          dangerouslySetInnerHTML={{
            __html: t("lable_desposit_limit_description"),
          }}
        >
          {/* <p>
            Daily - a period of a single day*
            <br />
            Weekly - a period of seven days, from Monday to Sunday*
            <br />
            Monthly - a calendar month, from the first day of the month
            <br />
            till the last day of the month*
            <br />
            <br />
            *) From 00:00 till 23:59 UTC
            <br />
            <br />
            -Any increase in limits will go through a 24 hour cooling period
            before being applied.
            <br />
            <br />
          </p>
          <p>
            -Note that a weekly time limit can't be less than a daily time limit
            and monthly time limit can't be less than a weekly time limit.
            <br />
            <br />
            Players residing in the UK have the following default limits
            applied:
            <br />
            Deposit: 100.000
            <br />
            Bet: 100.000.000
            <br />
            Loss: 100.000.000
            <br />
            <br />
            Player residing in Sweden are not allowed to play without a deposit
            limit.
            <br />
            <br />
          </p> */}
        </AccountDescription>
      )}
      <StyledLimitWrapper>
        <StyledLimitSelect>
          <label>{t("label_choose_limit", "Period")}</label>
          <StyledSelect
            onChange={(e) => handleChange(e)}
            value={depositDate}
            name="depositDate"
            disabled={
              isUk &&
              player.ragAffordability &&
              player.ragAffordability === "Red"
            }
          >
            <option value="daily">{t("label_daily", "Daily*")}</option>
            <option value="weekly">{t("label_weekly", "Weekly*")}</option>
            <option value="monthly">{t("label_monthly", "Monthly*")}</option>
          </StyledSelect>
        </StyledLimitSelect>
        <StyledLimitSelect>
          <label>
            {t("label_amount", "Amount")} ({skinCurrency})
          </label>
          <div className="input-group">
            <StyledInput
              limit
              type="number"
              min="1"
              max={depositMaxLimit}
              step="1"
              value={depositValue}
              name="depositValue"
              onChange={(e) => handleChange(e)}
              disabled={
                hasIncrease ||
                (isUk && limitsLocked) ||
                (isUk &&
                  player.ragAffordability &&
                  player.ragAffordability === "Red")
              }
            />
            <div className="input-group-append">
              <span className="input-group-text">{skinCurrency}</span>
            </div>
          </div>
        </StyledLimitSelect>
        {hasLimits && isUk ? (
          <StyledButton
            limit
            onClick={() => resetLimits()}
            disabled={isUk && limitsLocked}
          >
            {t("label_reset", "Reset")}
          </StyledButton>
        ) : (
          <StyledButton
            limit
            pub={skin === "pub"}
            disabled={
              hasIncrease ||
              (isUk && limitsLocked) ||
              (isUk &&
                player.ragAffordability &&
                player.ragAffordability === "Red")
            }
            onClick={() => handleSubmit("deposit")}
          >
            {t("label_save", "Save")}
          </StyledButton>
        )}
        {blockChange && (
          <StyledCooldownExplanation cooldownprop>
            <StyledCooldownExplanationSmall>
              {t("cooldown_explanation")}{" "}
              {new Date(depositLimits[0].expiresAt).toLocaleString(
                localizedFormat
              )}
            </StyledCooldownExplanationSmall>
          </StyledCooldownExplanation>
        )}
        {skin === "rac" && hasIncrease && (
          <IncreaseLimit>
            <h3>
              {t("label_increased_limit")} {increaseTime}
            </h3>
          </IncreaseLimit>
        )}
        {skin === "rac" && (
          <RealityCheckWrapper>
            {realityCheck !== -1 && (
              <p>
                {t("label_reality_check")} {Math.floor(realityCheck / 60)}{" "}
                {t("label_reality_check_minutes")}
              </p>
            )}
          </RealityCheckWrapper>
        )}
      </StyledLimitWrapper>
      {(skin === "abc" || skin === "nbc") && (
        <RealityCheckWrapper>
          {realityCheck !== -1 && (
            <p>
              {t("label_reality_check")} {Math.floor(realityCheck / 60)}{" "}
              {t("label_reality_check_minutes")}
            </p>
          )}
        </RealityCheckWrapper>
      )}
      {skin !== "rac" && skin !== "fun" && (
        <AccountDescription
          expand={true}
          description
          dangerouslySetInnerHTML={{ __html: t("message_limit_details") }}
        >
          {/* <p>
            Daily - a period of a single day*
            <br />
            Weekly - a period of seven days, from Monday to Sunday*
            <br />
            Monthly - a calendar month, from the first day of the month
            <br />
            till the last day of the month*
            <br />
            <br />
            *) From 00:00 till 23:59 UTC
            <br />
            <br />
            -Any increase in limits will go through a 24 hour cooling period
            before being applied.
            <br />
            <br />
          </p>
          <p>
            -Note that a weekly time limit can't be less than a daily time limit
            and monthly time limit can't be less than a weekly time limit.
            <br />
            <br />
            Players residing in the UK have the following default limits
            applied:
            <br />
            Deposit: 100.000
            <br />
            Bet: 100.000.000
            <br />
            Loss: 100.000.000
            <br />
            <br />
            Player residing in Sweden are not allowed to play without a deposit
            limit.
            <br />
            <br />
          </p> */}
        </AccountDescription>
      )}
      {skin === "rac" && (
        <IncreaseLimit>
          <p>{t("message_cashLimit_description")}</p>
        </IncreaseLimit>
      )}
    </>
  );
};

export default DepositLimit;
