import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
  ModalClower,
  ModalLogoWrapper,
  ModalLogoPLC,
  ModalLogoABC,
  ModalLogoPUB,
  ModalLogoYET,
  ModalLogoFUN,
  ModalLogoCAC,
  ModalLogoHYC,
  ModalLogoYAK,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const LogoutDialog = ({ showModal, handleProceed, skin }) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      {skin === "plc" && <ModalClower bottomright="true" />}
      <ModalLogoWrapper>
        {skin === "plc" && <ModalLogoPLC />}
        {skin === "abc" && <ModalLogoABC />}
        {skin === "pub" && <ModalLogoPUB />}
        {skin === "yet" && <ModalLogoYET />}
        {skin === "fun" && <ModalLogoFUN />}
        {skin === "cac" && <ModalLogoCAC />}
        {skin === "hyc" && <ModalLogoHYC />}
        {skin === "yak" && <ModalLogoYAK />}
      </ModalLogoWrapper>
      <StyledModalBody register>{t("please_logout_message")}</StyledModalBody>
      <StyledModalFooter position>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleProceed()}
        >
          {t("label_ok", "Ok")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
