import { Route, Redirect } from 'react-router-dom';
// import { useQuery } from "@apollo/client";
// import { QUERY_QUERY_SERVER } from "common/src/graphql/mutations";
import { getToken } from 'common/src/helpers/session';

const ProtectedRoute = ({ pages, component: Component, render, ...rest }) => {
  // Send graphql to collect server setup
  // const { data } = useQuery(QUERY_QUERY_SERVER);
  // let currentLanguage = data.server.currentLanguage;

  //console.log(user);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!getToken())
          return (
            <Redirect
              to={{
                pathname: pages['login'],
                state: { from: props.location },
              }}
            />
          );
        return Component ? <Component {...props} {...rest} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
