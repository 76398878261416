import { ContentTop } from "common/src/component/ContentTop";
import GamesLoader from "common/src/component/GamesLoader";
import Lobby from "common/src/component/Lobby";
/* import SearchResults from "common/src/component/SearchResults"; */
/* import Tables from "common/src/component/Tables"; */
import { SERVER_SETUP, SWE_LIMIT_DIALOG } from "common/src/helpers/constants";
import { getToken } from "common/src/helpers/session";
import useGetGameLobby from "common/src/services/useGetGameLobby";
import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { LobbyWrapper } from "common/src/styledComponents/StyledGame";
import config from "../config.json";
import { GamesSearch } from "common/src/component/GamesSearch";
/* import { GameProvidersInLobby } from "common/src/component/GameProvidersInLobby"; */

const Games = ({
  currency,
  server,
  /* searchResults, */
  setSweLimitDialog,
  titles,
  width,
  player,
  skin,
  pages,
}) => {
  const [serverSetup] = useState(sessionStorage.getObject(SERVER_SETUP));

  const playerCountry =
    getToken() !== null
      ? player.country
      : serverSetup.geoIP.isoCode.toLowerCase()
      ? serverSetup.geoIP.isoCode.toLowerCase()
      : config.defaultLang;

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  }, []);

  useEffect(() => {
    document.title = titles["games"];
  }, [titles]);

  useEffect(() => {
    if (localStorage.getItem(SWE_LIMIT_DIALOG) && getToken()) {
      setSweLimitDialog(true);
    }
  }, [setSweLimitDialog]);

  let { orderedGameLobby /* tournamentGame */ } = useGetGameLobby(
    playerCountry,
    "",
    config.skin
  );

  return (
    <>
      <ContentTop
        showProfileMenu={false}
        showSearch={true}
        serverSetup={serverSetup}
        gameImagePath={config.gameImagePath}
        defaultLang={config.defaultLang}
        skin={skin}
        pages={pages}
      />
      <LobbyWrapper>
        <div>
          {/* <GameProvidersInLobby /> */}
          <GamesSearch
            serverSetup={serverSetup}
            gameImagePath={config.gameImagePath}
          />
          {orderedGameLobby === null ? (
            <GamesLoader skin={config.skin} />
          ) : (
            <Lobby
              gamesForCategory={false}
              gameLobby={orderedGameLobby.lobby}
              width={width}
              skin={config.skin}
              gameImagePath={config.gameImagePath}
              config={config}
              country={playerCountry}
              mobGamesInLobby={false}
              themes={false}
            />
          )}
        </div>
      </LobbyWrapper>
    </>
  );
};

export default withRouter(Games);
