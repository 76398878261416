import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import {
  StyledButton,
} from "../styledComponents/CommonStyledComponents";
import { StyledLimitWrapper } from "../styledComponents/StyledLimits";
import {QUERY_GET_DATA_LINK} from "../graphql/queries";
// import IFrame from "./IFrame";
import StepNewWindow from "./StepNewWindow";

const EddRgComponent = ({player, setSpinner}) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();
  const [redirectUrl, setRedirectUrl] = useState("");
  const [step, setStep] = useState(0);

  useEffect(() => {
    const handler = (event) => {
      // console.log("FRAME EVENT: " + JSON.stringify(event));
      // console.log(typeof event.data)
      // console.log("origin" + event.origin)
      // console.log("origin 2: " + window.location.origin)
      if (event.origin === window.location.origin) {
        // console.log("same origin!");
        let data = null;
        if (typeof event.data !== "undefined") {
          try {
            data = JSON.parse(event.data);
          } catch (e) {
            data = event.data;
          }
          // console.log("DATA: " + data)
          if (typeof data.stepEcospend !== "undefined") {
            // console.log(data.message)
            // try {
            // let msg = data.message
            // if (msg.stepEcospend && msg.stepEcospend !== "") {
              setStep(0)
              setSpinner(false);
              window.location.reload();
              return false;
            // }
            // } catch (e) {
            //   console.log(e);
            // }
          }
        }
      }
    };

    // console.log("add listener");

    window.addEventListener("message", handler);

    // clean up
    return () => window.removeEventListener("message", handler);
  }, []); // empty array => run only once

  async function getDataLink() {
    // console.log("getDataLink", player.nickName);
    setSpinner(true)
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_DATA_LINK,
      variables: {
        nickName: player.nickName,
      },
    });

    if (loading) return "Loading ...";
    if (errors) {
      //console.log(errors);
      //setLoginError(errors);
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      setSpinner(false)
      return false;
    } else
      setStep(1);
      data &&
        setRedirectUrl(data.getDataLink.url)

    return true;
  }

  return (
    <StyledLimitWrapper selfexcludion bonuscode>
      {step === 0 && (
          <StyledButton
            selfexcludion
            redemecode
            onClick={() => getDataLink()}
            dangerouslySetInnerHTML={{
              __html: t("label_get_data_link", "Get Data Link"),
            }}
          ></StyledButton>
      )}
      {redirectUrl !== "" && step > 0 && (
          // <IFrame
          //     src={redirectUrl}
          //     className="pnp-iframe"
          //     width={600}
          //     height={600}
          //     setDepositStep={null}
          //     requestMethod={""}
          //     requestParams={{}}
          //     srcDoc={null}
          // />
          <StepNewWindow setStep={setStep} height={600} labelText={'label_edd_rg_in_progress'} setSpinner={setSpinner} src={redirectUrl} width={600}></StepNewWindow>
      )}
    </StyledLimitWrapper>
  );
};

export default EddRgComponent;
