import React, { useState } from "react";
import _ from "lodash";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import {
  ListWrapper,
  PaginationArrowLeft,
  PaginationArrowRight,
  PaginationDoubleArrowLeft,
  PaginationDoubleArrowRight,
  PaginationLi,
  PaginationUl,
} from "../styledComponents/DescriptionPages";
import { PnPDescriptionPar } from "../styledComponents/StyledSignupLogin";

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCounts = Math.ceil(itemsCount / pageSize);
  const [newCounter, setNewCounter] = useState(1);
  if (pagesCounts === 1) return null;
  const pages = _.range(1, pagesCounts + 1);

  const handlePagesPlus = () => {
    setNewCounter(
      pages.splice(newCounter - 1, 5) % 5 === 0 ? null : newCounter + 5
    );
  };
  const handlePagesMinus = () => {
    setNewCounter(newCounter < 5 ? 1 : newCounter - 5);
  };
  const handleFirstPageChange = () => {
    setNewCounter(1);
    onPageChange(1);
  };
  const handleLastPageChange = () => {
    onPageChange(pagesCounts);
    setNewCounter(pagesCounts - 1);
  };

  return (
    pagesCounts > 1 && (
      <PnPDescriptionPar as="nav" pagination>
        <PaginationUl>
          <StyledButton
            disabled={currentPage === 1 && newCounter === 1}
            pagelink
            onClick={() => handleFirstPageChange()}
          >
            <PaginationDoubleArrowLeft />
          </StyledButton>
          <StyledButton
            disabled={newCounter === 1}
            pagelink
            onClick={() => handlePagesMinus()}
          >
            <PaginationArrowLeft />
          </StyledButton>
          <ListWrapper>
            {pages.splice(newCounter - 1, 5).map((page) => (
              <PaginationLi key={page} active={page === currentPage}>
                <StyledButton pagelink onClick={() => onPageChange(page)}>
                  {page}
                </StyledButton>
              </PaginationLi>
            ))}
          </ListWrapper>
          <StyledButton
            disabled={newCounter > pages.length}
            pagelink
            onClick={() => handlePagesPlus()}
          >
            <PaginationArrowRight />
          </StyledButton>
          <StyledButton
            disabled={currentPage === pagesCounts}
            pagelink
            onClick={() => handleLastPageChange()}
          >
            <PaginationDoubleArrowRight />
          </StyledButton>
        </PaginationUl>
      </PnPDescriptionPar>
    )
  );
};

export default Pagination;
