export const AUTH_TOKEN = "auth_token";
export const SERVER_SETUP = "serverSetup";
export const COOKIES_CONFIRMATION = "cookieAccepted";
export const SWE_LIMIT_DIALOG = "swe-limits-dialog";
export const NL_LIMIT_DIALOG = "nl-limits-dialog";
export const THEME = "theme";
export const THEME_LIGHT = "light";
export const THEME_DARK = "dark";
export const PNPUID = "puid";
export const PNPTS = "pts";
export const SEID = "seid";
export const PENDING_FINISH = "pending-finish";
export const PENDING_DEPOSIT = "pending-deposit";
export const LEVEL_OF_PROTECTION_DIALOG = "accepted_level_of_protection";
export const AFFORDABILITY_DIALOG = "affordability-dialog";
export const AFFORDABILITY_FORM = "affordability-form";
export const LOGIN_FLAG = "login-flag";
export const HOOYU_MULTIPLE = "hmulti";
export const REFRESH_APP = "refresh-app";
export const REFRESH_APP_POPUP = "show-refresh-popup";
