import { useTranslation } from "react-i18next";
import {
  AccountContainer,
  PageContent,
  PageContentWrapper,
} from "common/src/styledComponents/AccountPage";

const ExitGame = ({ pages }) => {
  const { t } = useTranslation("");
  // console.log("parent " + window.parent)
  if (window.self !== window.top) {
    // console.log("post message");
    window.top.postMessage(
      JSON.stringify({
        error: false,
        message: "games",
      }),
      window.location.origin
    );
  } else {
    window.location = pages["index"];
  }

  return (
    <PageContent>
      <PageContentWrapper>
        <AccountContainer container>
          {t("label_please_wait", "Please wait..")}
        </AccountContainer>
      </PageContentWrapper>
    </PageContent>
  );
};

export default ExitGame;
