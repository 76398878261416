import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import {
  IncreaseLimit,
  StyledCooldownExplanation,
  StyledCooldownExplanationSmall,
  StyledLimitSelect,
  StyledLimitWrapper,
} from "../styledComponents/StyledLimits";
import {
  StyledButton,
  StyledInput,
} from "../styledComponents/CommonStyledComponents";
import { AccountDescription } from "../styledComponents/AccountPage";

const MaxBalansLimit = ({
  limitsData,
  generalMaxLimit,
  skinCurrency,
  maxBalansLimit,
  hasLimits,
  handleSubmit,
  handleChange,
  resetLimits,
  isUk,
  realityCheck,
  player,
  skin,
}) => {
  const { t, i18n } = useTranslation("");
  const { limitsLocked } = player;
  const localizedFormat = `${
    i18n.language || window.localStorage.i18nextLng || "nl"
  }`;

  const { limitValue } = limitsData;
  const [hasIncrease, setHasIncrease] = useState(false);
  const [increaseTime, setIncreaseTime] = useState("");
  const [blockChange, setBlockChange] = useState(false);

  useEffect(() => {
    function hasLimit() {
      if (maxBalansLimit[0].increaseAt !== "" && skin !== "plc") {
        setHasIncrease(true);
        setIncreaseTime(maxBalansLimit[0].increaseAt);
      }
      if (
        maxBalansLimit[0].expiresAt !== "" &&
        maxBalansLimit[0].status === "active" &&
        skin === "plc"
      )
        setBlockChange(true);
    }
    maxBalansLimit.length > 0 && hasLimit();
  }, [maxBalansLimit]);

  return (
    <>
      <StyledLimitWrapper>
        <StyledLimitSelect>
          <label>
            {t("label_amount", "Amount")} ({skinCurrency})
          </label>
          <div className="input-group">
            <StyledInput
              limit
              type="number"
              value={limitValue}
              name="limitValue"
              onChange={(e) => handleChange(e)}
              min="1"
              max={generalMaxLimit}
              step="1"
              disabled={hasIncrease || (isUk && limitsLocked)}
            />
            <div className="input-group-append">
              <span className="input-group-text">{skinCurrency}</span>
            </div>
          </div>
        </StyledLimitSelect>
        {hasLimits && isUk ? (
          <StyledButton
            pub={skin === "pub"}
            limit
            onClick={() => resetLimits()}
            disabled={isUk && limitsLocked}
          >
            {t("label_reset", "Reset")}
          </StyledButton>
        ) : (
          <StyledButton
            pub={skin === "pub"}
            limit
            disabled={hasIncrease || blockChange || (isUk && limitsLocked)}
            onClick={() => handleSubmit("maxbalance")}
          >
            {t("label_save", "Save")}
          </StyledButton>
        )}
        {blockChange && (
          <StyledCooldownExplanation cooldownprop>
            <StyledCooldownExplanationSmall>
              {t("cooldown_explanation")}{" "}
              {new Date(maxBalansLimit[0].expiresAt).toLocaleString(
                localizedFormat
              )}
            </StyledCooldownExplanationSmall>
          </StyledCooldownExplanation>
        )}
      </StyledLimitWrapper>
      <AccountDescription
        description
        dangerouslySetInnerHTML={{ __html: t("message_limit_details") }}
      ></AccountDescription>
      {skin !== "plc" && (
        <div>
          <StyledLimitWrapper>
            {realityCheck !== -1 && (
              <p>
                {t("label_reality_check")} {Math.floor(realityCheck / 60)}{" "}
                {t("label_reality_check_minutes")}
              </p>
            )}
          </StyledLimitWrapper>
          {hasIncrease && (
            <IncreaseLimit>
              <h3>
                {t("label_increased_limit")} {increaseTime}
              </h3>
            </IncreaseLimit>
          )}
          <IncreaseLimit>
            <p>{t("message_maxbalanceLimit_description")}</p>
          </IncreaseLimit>
        </div>
      )}
    </>
  );
};

export default MaxBalansLimit;
