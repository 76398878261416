import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import { THEME } from "common/src/helpers/constants";
import { isMobileOnly } from "react-device-detect";

const GamesLoader = ({ gamesForCategory, skin }) => {
  const theme = localStorage.getItem(THEME);
  let blinkcolor = "#c0c1c2";
  let bgColorPerSkin = "#f3f3f3";
  if (skin === "fun") {
    blinkcolor = "#77d6e3";
    bgColorPerSkin = "#1e8ea6";
  }
  if (skin === "yet") {
    blinkcolor = "#11628d";
    bgColorPerSkin = "#07415f";
  }
  if (skin === "yak") {
    blinkcolor = "#62279d";
    bgColorPerSkin = "#3e076c";
  }
  if (skin === "cac") {
    blinkcolor = "#1E1D19";
    bgColorPerSkin = "#333333";
  }
  if (skin === "hyc") {
    blinkcolor = "#c0c1c2";
    bgColorPerSkin = "#070516";
  }
  if (skin === "nbc") {
    blinkcolor = "#350f46";
    bgColorPerSkin = "#1f0529";
  }

  const bgColor = theme === "dark" ? "#2d3641" : bgColorPerSkin;
  const fgColor = theme === "dark" ? "#1e2730" : blinkcolor;
  //Window height
  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  //Width on window resize
  function useCurrentWidth() {
    let [width, setWidth] = useState(getWidth());

    useEffect(() => {
      let timeout = null;
      const resizeListener = () => {
        clearTimeout(timeout);
        timeout = window.setTimeout(() => setWidth(getWidth()), 150);
      };
      window.addEventListener("resize", resizeListener);

      return () => {
        window.removeEventListener("resize", resizeListener);
      };
    }, []);

    return width;
  }

  const pageWidth = useCurrentWidth();
  const byCategory = isMobileOnly ? "" : " my-5";
  /* let sizeMinus = skin === "pub" ? 40 : 100; */

  let numberLines = skin === "rac" ? 2 : 2;
  let numberOfTime = skin === "rac" ? 2 : 2;
  let gridWidth = (pageWidth > 1600 ? 1600 : pageWidth) / 5;

  if (pageWidth < 770) {
    gridWidth = pageWidth / 2;
    numberLines = gamesForCategory ? 5 : 3;
    numberOfTime = 5;
  }
  let gridHeight = (gridWidth * 218) / 323;

  function leftRoundedRect(x, y, width, height, radius, element) {
    radius = element === 1 ? 0 : radius;
    var start = element === 0 ? x + radius : x;
    var leftToRight = element === 0 ? width - radius : width;
    var rightToLeft = element === 0 ? -width : -(width - radius);
    var bottomLeftCorner =
      element === 2
        ? " a" +
          radius +
          "," +
          radius +
          " 0 0 1 " +
          -radius +
          "," +
          -radius +
          " "
        : " ";
    var topLeftCorner =
      element === 0
        ? " a" +
          radius +
          "," +
          radius +
          " 0 0 1 " +
          radius +
          "," +
          -radius +
          " "
        : " ";

    return (
      "M" +
      start +
      "," +
      y +
      " h" +
      leftToRight +
      " v" +
      height +
      " h" +
      rightToLeft +
      bottomLeftCorner +
      "v" +
      (radius - height) +
      topLeftCorner +
      "z"
    );
  }

  function rightRoundedRect(x, y, width, height, radius, element) {
    radius = element === 1 ? 0 : radius;
    var leftToRight = element === 0 ? width - radius : width;
    var topRightCorner =
      element === 0
        ? " a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius
        : " ";
    var bottomRightCorner =
      element === 2
        ? " a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius
        : " ";
    var rightToLeft = element === 0 ? -width : radius - width;
    return (
      "M" +
      x +
      "," +
      y +
      " h" +
      leftToRight +
      topRightCorner +
      "v" +
      (height - radius) +
      bottomRightCorner +
      "h" +
      rightToLeft +
      "z"
    );
  }

  return (
    <ContentLoader
      className={"lobby-loader" + byCategory}
      height={gridHeight * numberOfTime + 67}
      width={"100%"}
      speed={3}
      backgroundColor={bgColor}
      foregroundColor={fgColor}
    >
      <rect x="0" y="16" rx="10" ry="10" width="150" height="28" />
      {!gamesForCategory &&
        (pageWidth > 770 ? (
          <rect
            x={gridWidth * 5 - 65}
            y="20"
            rx="0"
            ry="15"
            width="65"
            height="28"
          />
        ) : (
          <rect
            x={gridWidth * 2 - 65}
            y="18"
            rx="0"
            ry="0"
            width="65"
            height="28"
          />
        ))}
      {!gamesForCategory && (
        <rect
          x="0"
          y="67"
          rx="0"
          ry="0"
          width={gridWidth * 2}
          height={gridHeight * 2}
        />
      )}
      {new Array(numberLines).fill(" ").map((_, i) => {
        return (
          <React.Fragment key={i}>
            {pageWidth > 770 ? (
              <>
                {gamesForCategory && (
                  <>
                    {/* first col */}
                    <path
                      d={leftRoundedRect(
                        0,
                        i * (gridHeight + 2) + 67,
                        gridWidth,
                        gridHeight,
                        15,
                        i
                      )}
                    ></path>
                    <rect
                      x={gridWidth + 2}
                      y={i * (gridHeight + 2) + 67}
                      rx="0"
                      ry="0"
                      width={gridWidth}
                      height={gridHeight}
                    />
                  </>
                )}
                <rect
                  x={gridWidth * 2 + 4}
                  y={i * (gridHeight + 2) + 67}
                  rx="0"
                  ry="0"
                  width={gridWidth}
                  height={gridHeight}
                />
                <rect
                  x={gridWidth * 3 + 6}
                  y={i * (gridHeight + 2) + 67}
                  rx="0"
                  ry="0"
                  width={gridWidth}
                  height={gridHeight}
                />
                {/* last col */}
                <path
                  d={rightRoundedRect(
                    gridWidth * 4 + 8,
                    i * (gridHeight + 2) + 67,
                    gridWidth,
                    gridHeight,
                    15,
                    i
                  )}
                ></path>
                {/* <rect
                  x={gridWidth * 4 + 8}
                  y={i * (gridHeight + 2) + 67}
                  rx="0"
                  ry="0"
                  width={gridWidth}
                  height={gridHeight}
                /> */}
              </>
            ) : (
              <>
                {gamesForCategory && (
                  <>
                    <rect
                      x={0}
                      y={i * (gridHeight + 2) + 67}
                      rx="0"
                      ry="0"
                      width={gridWidth}
                      height={gridHeight}
                    />
                    <rect
                      x={gridWidth + 2}
                      y={i * (gridHeight + 2) + 67}
                      rx="0"
                      ry="0"
                      width={gridWidth}
                      height={gridHeight}
                    />
                  </>
                )}
                {!gamesForCategory && (
                  <>
                    <rect
                      x="0"
                      y={gridHeight * 2 + 69 + i * (gridHeight + 2)}
                      rx="0"
                      ry="0"
                      width={gridWidth - 23}
                      height={gridHeight}
                    />
                    <rect
                      x={gridWidth - 20}
                      y={gridHeight * 2 + 69 + i * (gridHeight + 2)}
                      rx="0"
                      ry="0"
                      width={gridWidth}
                      height={gridHeight}
                    />
                  </>
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
    </ContentLoader>
  );
};

export default GamesLoader;
