import React from "react";
import { useTranslation } from "react-i18next";
import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";
import {
  BonusDescription,
  PreviousBonusWrapper,
  TransactionNotFound,
} from "../styledComponents/AccountPage";
import {
  ActiveBonusColumn,
  ActiveBonusContent,
  BonusInfo,
  BonusInfoWrapper,
  StyledRow,
} from "../styledComponents/StyledActiveBonus";

const PreviousBonuses = ({ claimedBonuses, server, skin, bonusImagePath }) => {
  const { t } = useTranslation("");

  return (
    <>
      {claimedBonuses && Object.keys(claimedBonuses).length !== 0 ? (
        claimedBonuses.map((bonus, index) => (
          <PreviousBonusWrapper key={bonus.uniqueID}>
            <ActiveBonusColumn>
              <StyledRow>
                <ActiveBonusContent>
                  <img
                    alt=""
                    src={
                      skin === "plc" &&
                      bonus.bonusType === "free-spins" &&
                      bonus.bonusImage === ""
                        ? server.cdn +
                          bonusImagePath +
                          "bonus-page-free-spins.png"
                        : skin === "plc" &&
                          bonus.bonusType === "welcome-bonus" &&
                          bonus.bonusImage === ""
                        ? server.cdn +
                          bonusImagePath +
                          "bonus-page-welcome-bonus.png"
                        : bonus.bonusImage === ""
                        ? server.cdn + bonusImagePath + "bonus-page-bonus.png"
                        : server.cdn + bonusImagePath + bonus.bonusImage
                    }
                  />
                </ActiveBonusContent>
                <BonusDescription>
                  <p>{t(bonus.bonusDescription)}</p>

                  <div>
                    <span className="progress-text">
                      {t("label_wagering_progress")}
                    </span>{" "}
                    <span className="ui-label float-right">
                      <b>
                        {bonus.wagered === 0
                          ? "0"
                          : parseInt(
                              (bonus.wagered * 100) / bonus.wageringAmount
                            )}
                        %
                      </b>
                    </span>
                    <div className="my-3 progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width:
                            bonus.wagered === 0
                              ? "0%"
                              : parseInt(
                                  (bonus.wagered * 100) / bonus.wageringAmount
                                ) + "%",
                        }}
                        aria-valuenow={
                          bonus.wagered === 0
                            ? "0"
                            : bonus.bonusType === "free-spins" &&
                              bonus.status === "wagered"
                            ? "100"
                            : parseInt(
                                (bonus.wagered * 100) / bonus.wageringAmount
                              )
                        }
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>

                  <StyledRow>
                    <span className="col-md-6 align-self-center status">
                      <span className="left">{t("label_status")}</span>
                      <span className="right">
                        {" "}
                        {t("label_" + bonus.status)}
                      </span>
                    </span>

                    <span className="col-md-6 terms">
                      {bonus.bonusTerms !== "" && (
                        <>
                          <Button
                            btn
                            color="terms"
                            id={"popover-" + index}
                            type="button"
                          >
                            {t("label_terms_and_conditions")}
                          </Button>

                          <UncontrolledPopover
                            placement="bottom"
                            target={"popover-" + index}
                          >
                            <PopoverBody>{t(bonus.bonusTerms)}</PopoverBody>
                          </UncontrolledPopover>
                        </>
                      )}
                    </span>
                  </StyledRow>
                </BonusDescription>
              </StyledRow>
            </ActiveBonusColumn>
            <BonusInfoWrapper>
              <BonusInfo>
                <div></div>
              </BonusInfo>
            </BonusInfoWrapper>
          </PreviousBonusWrapper>
        ))
      ) : (
        <TransactionNotFound
          dangerouslySetInnerHTML={{ __html: t("bonuses_not_found") }}
        ></TransactionNotFound>
      )}
    </>
  );
};

export default PreviousBonuses;
