import styled from "styled-components";
import { isMobile } from "react-device-detect";

export const CookieConfirmationWrapper = styled.div`
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999998;
  background: ${({ refreshapp }) =>
    refreshapp ? "#212529" : "var(--cookiesbg)"};
  /* height: ${({ theme }) =>
    theme.skin === "nbc" && isMobile ? "" : "45px"}; */
  border-radius: ${({ theme, refreshapp }) =>
    theme.skin === "nbc" ||
    theme.skin === "abc" ||
    theme.skin === "pub" ||
    theme.skin === "bnk"
      ? "0.5rem"
      : refreshapp || theme.skin === "rac"
      ? "0.2rem"
      : "20px"};
  display: ${({ hidden }) => (hidden ? "none" : "")};
  box-shadow: 0 0 0 1px var(--cookiesbg);

  @media (max-width: 967px) {
    width: 255px;
  }
`;

export const CookieConfirmationDialog = styled.div`
  padding: 0px;
  display: inline;
`;

export const CookieMessage = styled.div`
  padding: 0 0 0 20px;
  float: left;
  font-size: 12px;
  color: ${({ theme }) =>
    theme.skin === "rac" ? "#007bff" : "var(--cookieReverseColor)"};
  line-height: 45px;
  font-weight: 600;

  a {
    color: var(--labelcolordark);
    text-decoration: underline;
    font-weight: 600;
  }
  @-moz-document url-prefix() {
    font-size: 10px !important;
  }
`;

export const CookieButtons = styled.div`
  display: table;
  float: right;
  height: 100%;
  padding: 0 20px 0 0;

  button {
    height: 100%;
    padding: 0px;
    margin-left: 15px;
    border: none;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 45px;
    font-size: 12px;
    color: ${({ theme }) =>
      theme.skin === "rac" ? "#fff" : "var(--cookieReverseColor)"};
    background-color: transparent;
    cursor: pointer;
    :hover {
      color: ${({ theme }) =>
        theme.skin === "rac"
          ? "#f2d500"
          : theme.skin === "nbc"
          ? "#350e46"
          : theme.skin === "pub"
          ? "#9A875D"
          : theme.skin === "yet"
          ? "#07415f"
          : theme.skin === "fun"
          ? "#f0d2a7"
          : theme.skin === "cac"
          ? "#fdb448"
          : theme.skin === "hyc"
          ? "#f4ef00"
          : theme.skin === "yak"
          ? "#fcc239"
          : "#ae0005"};
    }
  }
`;
