import { LanguageSelect } from "../styledComponents/StyledTimeAndBulb";
import { useTranslation } from "react-i18next";

const LanguageChange = ({ serverSetup, userInfoProp }) => {
  const { i18n } = useTranslation("");

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("i18nextLng", e.target.value);
    document.documentElement.setAttribute("lang", e.target.value);
    window.location.replace("/" + e.target.value + "/index");
  };

  const addFlag = (code) => {
    let flag;
    switch (code) {
      case "en":
        flag = "en";
        break;
      case "fi":
        flag = "fi";
        break;
      case "de":
        flag = "de";
        break;
      case "sv":
        flag = "sv";
        break;
      case "no":
        flag = "no";
        break;
      default:
        null;
    }
    return flag;
  };

  return (
    <LanguageSelect
      userInfoProp={userInfoProp}
      onChange={(e) => changeLanguage(e)}
      value={i18n.language}
    >
      {serverSetup.languages.map((value, index) => (
        <option key={index} value={value.code}>
          {addFlag(value.code)}
        </option>
      ))}
    </LanguageSelect>
  );
};

export default LanguageChange;
