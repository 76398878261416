import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
  ModalClower,
  ModalLogoWrapper,
  ModalLogoPLC,
  ModalLogoABC,
  ModalLogoPUB,
  ModalLogoNBC,
  ModalLogoRAC,
  ModalLogoYET,
  ModalLogoFUN,
  ModalLogoCAC,
  ModalLogoHYC,
  ModalLogoYAK,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import { QUERY_PRAGMATIC_RTP_CONFIRMED } from "../graphql/queries";
import { useApolloClient } from "@apollo/client";

export const RtpUkDialog = ({ showModal, skin, setRtpUkPopup }) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();

  const handleProceed = () => {
    async function pragmaticRTP() {
      const { loading } = await apolloClient.query({
        query: QUERY_PRAGMATIC_RTP_CONFIRMED,
      });
      if (loading) return "Loading ...";
    }

    pragmaticRTP();
    localStorage.setItem("rtpUkPopup", false);
    setRtpUkPopup(false);
  };

  return (
    <StyledModal isOpen={showModal}>
      {skin === "plc" && <ModalClower bottomright="true" />}
      <ModalLogoWrapper>
        {skin === "plc" && <ModalLogoPLC />}
        {skin === "abc" && <ModalLogoABC />}
        {skin === "pub" && <ModalLogoPUB />}
        {skin === "rac" && <ModalLogoRAC />}
        {skin === "yet" && <ModalLogoYET />}
        {skin === "fun" && <ModalLogoFUN />}
        {skin === "cac" && <ModalLogoCAC />}
        {skin === "hyc" && <ModalLogoHYC />}
        {skin === "yak" && <ModalLogoYAK />}
      </ModalLogoWrapper>
      <StyledModalBody register>{t("rtp_uk_message")}</StyledModalBody>
      <StyledModalFooter position>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleProceed()}
        >
          {t("label_understood", "Ok")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
