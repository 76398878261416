import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { QUERY_GET_GAME_ROUNDS } from "common/src/graphql/queries";
import { printAmount } from "common/src/helpers/functions";
import Pagination from "common/src/component/Pagination";
import { paginate } from "common/src/helpers/functions";
import { format } from "date-fns";

import {
  GameHistoryWrapper,
  GameTransactionButton,
  GameTransactionButtonWrapper,
  GameTransactionWrapper,
  StyledDateRangePicker,
  StyledTableCell,
  StyledTableContent,
  StyledTableHeading,
  StyledTableRow,
  StyledTransactionWrapper,
  TableHeader,
  TransactionNotFound,
} from "../styledComponents/AccountPage";

const GameTransactions = ({ playerCurrency, server }) => {
  const { t, i18n } = useTranslation("");
  const apolloClient = useApolloClient();

  let pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [gamesHistory, setGamesHistory] = useState({});

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDate = (date) => {
    if (date.length > 0) {
      setStartDate(date[0]);
      setEndDate(date[1]);
    }
  };

  const gameTransactionsPart = paginate(
    gamesHistory.gameRounds,
    currentPage,
    pageSize
  );

  async function getGameRounds() {
    /* let providers = [
      'netent',
      'evolution',
      'amatic',
      'microgaming',
      'nyx',
      'igt',
      'novomatic',
      'playngo',
    ]; */
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_GET_GAME_ROUNDS,
      variables: {
        from: format(startDate, "yyyy-MM-dd"),
        to: format(endDate, "yyyy-MM-dd"),
        /* provider: JSON.stringify(providers), */
      },
    });
    if (loading) return <h3>Loading ...</h3>;
    if (errors) return <h1>`Error: ${errors}`</h1>;
    //console.log("rounds " + JSON.stringify(data));
    setGamesHistory(data.getGameRounds);
    //winners = recentWinners;
    return null;
  }

  const currentLanguage = i18n.language || window.localStorage.i18nextLng;
  const formatedCurrentLanguage =
    currentLanguage === "en" ? "en-GB" : currentLanguage;

  return (
    <>
      <GameTransactionWrapper>
        <StyledDateRangePicker
          onChange={handleDate}
          value={[startDate, endDate]}
          locale={formatedCurrentLanguage}
          closeCalendar={true}
          maxDate={new Date()}
          clearIcon={false}
          choseDate={t("label_chose_date")}
        />

        <GameTransactionButtonWrapper>
          <GameTransactionButton onClick={() => getGameRounds()}>
            {t("label_submit", "Submit")}
          </GameTransactionButton>
        </GameTransactionButtonWrapper>
      </GameTransactionWrapper>
      {gamesHistory.gameRounds !== undefined && (
        <>
          <GameHistoryWrapper>
            <TableHeader col={"col-2"}>{t("label_game", "Game")}</TableHeader>
            <TableHeader col={"col-4"}>{t("label_time", "Time")}</TableHeader>
            <TableHeader col={"col-2"}>{t("label_bet", "Bet")}</TableHeader>
            <TableHeader col={"col-2"}>{t("label_win", "Win")}</TableHeader>
            <TableHeader col={"col-2"}>
              {t("label_balance", "Balance")}
            </TableHeader>
          </GameHistoryWrapper>
          {gamesHistory.gameRounds === undefined ||
            (gamesHistory.gameRounds.length === 0 && (
              <TransactionNotFound
                dangerouslySetInnerHTML={{
                  __html: t("game_history_available"),
                }}
              ></TransactionNotFound>
            ))}
          <StyledTransactionWrapper>
            {(gamesHistory.gameRounds !== undefined ||
              gamesHistory.gameRounds.length === 0) &&
              gameTransactionsPart.map((round) => (
                <StyledTableRow key={round.internalID}>
                  <StyledTableCell col={"col-md-2"} cell={"topic-cell"}>
                    <StyledTableContent>{round.gameName}</StyledTableContent>
                  </StyledTableCell>
                  <StyledTableCell col={"col-md-4"} cell={"date-cell"}>
                    <StyledTableHeading>{t("label_time")}</StyledTableHeading>
                    <StyledTableContent>
                      {round.transactionTime}
                    </StyledTableContent>
                  </StyledTableCell>
                  <StyledTableCell col={"col-md-2"} cell={"type-cell"}>
                    <StyledTableHeading>{t("label_bet")}</StyledTableHeading>
                    <StyledTableContent>
                      {round.transactionType === "bet"
                        ? printAmount(
                            round.amount,
                            playerCurrency,
                            server.currencies,
                            true
                          )
                        : "-"}
                    </StyledTableContent>
                  </StyledTableCell>
                  <StyledTableCell col={"col-md-2"} cell={"status-cell"}>
                    <StyledTableHeading>{t("label_win")}</StyledTableHeading>
                    <StyledTableContent>
                      {round.transactionType === "win"
                        ? printAmount(
                            round.amount,
                            playerCurrency,
                            server.currencies,
                            true
                          )
                        : "-"}
                    </StyledTableContent>
                  </StyledTableCell>
                  <StyledTableCell col={"col-md-2"} cell={"amount-cell"}>
                    <StyledTableHeading>
                      {t("label_balance")}
                    </StyledTableHeading>
                    <StyledTableContent>
                      {printAmount(
                        round.available,
                        playerCurrency,
                        server.currencies,
                        true
                      )}
                    </StyledTableContent>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </StyledTransactionWrapper>
          <Pagination
            itemsCount={gamesHistory.gameRounds.length}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={(e) => handlePageChange(e)}
          />
        </>
      )}
    </>
  );
};

export default GameTransactions;
