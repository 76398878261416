import { useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import { ContentTop } from "common/src/component/ContentTop";
import GameTransactions from "common/src/component/GameTransactions";
import {
  AccountContainer,
  PageContent,
  PageContentWrapper,
  StyledCol,
} from "common/src/styledComponents/AccountPage";

const CasinoHistory = ({
  playerCurrency,
  serverSetup,
  pages,
  server,
  skin,
}) => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  }, []);

  return (
    <>
      <ContentTop
        serverSetup={serverSetup}
        showProfileMenu={!isMobileOnly}
        pages={pages}
        skin={skin}
        isMyAccount={"label_history"}
      />
      <PageContent account="true" nobg="true">
        <PageContentWrapper>
          <AccountContainer history>
            <StyledCol>
              <GameTransactions
                playerCurrency={playerCurrency}
                server={server}
              />
            </StyledCol>
          </AccountContainer>
        </PageContentWrapper>
      </PageContent>
    </>
  );
};

export default CasinoHistory;
