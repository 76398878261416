import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { MUTATION_AWARD_MANUAL_BONUS_CODE } from "common/src/graphql/mutations";
import { toast } from "react-toastify";
import {
  StyledButton,
  StyledFelxTableRow,
  StyledFormGroup,
  StyledInput,
} from "../styledComponents/CommonStyledComponents";
import { StyledLimitWrapper } from "../styledComponents/StyledLimits";

const BonusCode = () => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();
  const [bonusCode, setBonusCode] = useState("");

  async function redeemCodeManual() {
    //check is uk and Experian redeemBonusCodeManual

    const { loading, errors, data } = await apolloClient.mutate({
      mutation: MUTATION_AWARD_MANUAL_BONUS_CODE,
      variables: {
        bonusCode: bonusCode,
      },
      errorPolicy: "all",
    });

    if (loading) return "Loading ...";
    if (errors) {
      //console.log(errors);
      //setLoginError(errors);
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      return false;
    } else
      data &&
        toast.success(
          <span
            dangerouslySetInnerHTML={{ __html: t("label_bonus_success") }}
          ></span>,
          {
            position: "bottom-center",
          }
        );

    return true;
  }

  return (
    <StyledLimitWrapper selfexcludion bonuscode>
      <StyledFormGroup margin={"m-0"} col={"col-md-8 text-left"}>
        <label
          dangerouslySetInnerHTML={{ __html: t("label_insert_code") }}
        ></label>
        <StyledInput
          bonus
          name="code"
          value={bonusCode}
          onChange={(e) => setBonusCode(e.target.value)}
        />
      </StyledFormGroup>

      <StyledButton
        selfexcludion
        redemecode
        onClick={() => redeemCodeManual()}
        dangerouslySetInnerHTML={{
          __html: t("label_activate_bonus", "Activate"),
        }}
      ></StyledButton>
    </StyledLimitWrapper>
  );
};

export default BonusCode;
