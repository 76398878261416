import styled from "styled-components";

export const StyledPageWrapp = styled.div`
  position: relative;
  width: 100%;
  margin: 50px auto;
  background: none;
  text-align: center;
  min-height: ${({ theme }) =>
    theme.skin === "plc" ? "calc(100vh - 150px)" : ""};
  max-width: ${({ theme }) => (theme.skin === "plc" ? "400px" : "")};
  margin: ${({ theme }) => (theme.skin === "plc" ? "0 auto" : "50px auto")};
  font-size: ${({ theme }) => (theme.skin === "plc" ? "16px" : "")};
  padding-top: ${({ theme }) => (theme.skin === "plc" ? "10rem" : "20px")};
  color: ${({ theme }) => (theme.skin === "plc" ? "var(--tabletext)" : "")};

  p {
    display: block;
    font: 15px/1.5 Arial, Sans-Serif;
    color: #4d79ff;
    margin: 0 0 30px 0;
    margin: 50px auto;
    text-align: center;
  }
  h1 {
    font: 30px/1 Arial, Sans-Serif;
    margin: 0 0 30px 0;
    margin: 50px auto;
    text-align: center;
    margin-top: 115px;
    color: ${({ theme }) =>
      theme.skin === "plc" ? "var(--tabletext)" : "#ffffff"};
    font-weight: ${({ theme }) => (theme.skin === "plc" ? "700" : "")};
  }
  a {
    font: 15px/1.5 Arial, Sans-Serif;
    color: ${({ theme }) =>
      theme.skin === "plc" ? "var(--tabletext)" : "#ffffff"};
    margin: 0 0 30px 0;
    margin: 50px auto;
    padding: 20px 38px;
    text-align: center;
  }
  a:hover {
    color: #fff;
  }
`;

export const SupportLogoWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  pointer-events: none;
`;
