import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DepositLimit from "common/src/component/DepositLimit";
/* import PlaySessionLimit from "common/src/component/PlaySessionLimit"; */
import DailyTimeLimit from "common/src/component/DailyTimeLimit";
import BetLimit from "common/src/component/BetLimit";
import LossLimit from "common/src/component/LossLimit";
import SelfExclusion from "common/src/component/SelfExclusion";
import Timeout from "common/src/component/Timeout";
import ExcludedGames from "common/src/component/ExcludedGames";
import { useApolloClient } from "@apollo/client";
import {
  QUERY_GET_LIMITS,
  QUERY_CLEAR_LIMITS,
} from "common/src/graphql/queries";
import { MUTATION_CREATE_LIMITS } from "common/src/graphql/mutations";
import { SweSetDepositLimitDialog } from "common/src/modal/SweSetDepositLimitDialog";
import { PaymentServer } from "common/src/helpers/connectors";
import { PNPTS, PNPUID } from "common/src/helpers/constants";
import { ContentTop } from "common/src/component/ContentTop";
import { isMobileOnly } from "react-device-detect";
import SessionCountLimit from "common/src/component/SessionCountLimit";
import MaxBalansLimit from "common/src/component/MaxBalansLimit";
import {
  AccountContainer,
  PageContent,
  PageContentWrapper,
  StyledCol,
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  StyledTabs,
  TabContent,
} from "common/src/styledComponents/AccountPage";

const MyLimits = ({
  player,
  server,
  pages,
  titles,
  setSpinner,
  serverSetup,
  currentLanguage,
  currentTheme,
  skin,
}) => {
  const { realityCheck, country, currency, nickName } = player;
  const location = useLocation();
  const { currencies } = server;
  const { t, i18n } = useTranslation("");
  const apolloClient = useApolloClient();

  const [allLimits, setAllLimits] = useState([]);
  const [depositLimits, setDepositLimits] = useState([]);
  const [timeLimit, setTimeLimit] = useState([]);
  const [loginCountLimit, setLoginCountLimit] = useState([]);
  const [maxBalanceLimit, setMaxBalanceLimit] = useState([]);
  const [betLimits, setBetLimits] = useState([]);
  const [lossLimits, setLossLimits] = useState([]);
  const [, /* sessionLimits */ setSessionLimits] = useState([]);

  const [hasLimits, setHasLimits] = useState(false);

  const index = location.tab ? location.tab : 0;

  const [showSweSetDepositLimit, setShowSweSetDepositLimit] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  //const [showResetLimit, setShowResetLimit] = useState(false);

  useEffect(() => {
    // console.log(titles)
    document.title = titles["my-limits"];
  }, [titles]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
      });
    };
    scrollToTop();
  }, []);

  const centsModifier = 100;
  const typeDeposit = "deposit";
  const typeLogincount = "logincount";
  const typeBet = "bet";
  const typeLoss = "loss";

  let maxLimitDeposit = 100000;
  let maxLimit = 100000;
  let multi = 1;

  switch (player.currency) {
    case "nok":
    case "sek":
    case "zar":
      multi = 10;
      maxLimit = 10000000;
      break;
    case "inr":
      multi = 100;
      break;
    default:
      break;
  }

  const isUk = country === "gb" ? true : false;
  const isSE = country === "se" ? true : false;
  const isNL = country === "nl" ? true : false;
  const isSWEIp =
    server.geoIP.isoCode.toLowerCase() === "se" || country === "se"
      ? true
      : false;
  /* const isNLIp =
    server.geoIP.isoCode.toLowerCase() === "nl" || country === "nl"
      ? true
      : false; */

  //console.log(isSWEIp);

  if (isUk) maxLimit = 100000000;
  if (isSWEIp) maxLimitDeposit = 500;

  let generalMaxLimit = maxLimit * multi;
  let depositMaxLimit = maxLimitDeposit * multi;

  const skinCurrency =
    currency && currencies.filter((x) => x.code === currency)[0].html;

  //test
  const initialState = {
    /* deposit limit */
    depositValue: "",
    depositDate: "daily",
    /* session limit */
    timeoutValue: "1",
    timeoutDate: "",
    /* session count*/
    logincountValue: "",
    logincountDate: "daily",
    /* max balans */
    limitValue: "",
    /* dailytime limit */
    dailyValue: "1",
    weeklyValue: "1",
    monthlyValue: "1",
    /* loss */
    lossDate: "daily",
    lossValue: "",
    /* bet */
    betDate: "daily",
    betValue: "",
  };
  const [limitsData, setLimitsData] = useState(initialState);

  //console.log(limitsData);

  useEffect(() => {
    async function getLimits() {
      const { loading, errors, data } = await apolloClient.query({
        query: QUERY_GET_LIMITS,
      });
      if (loading) return <h3>Loading ...</h3>;
      if (errors) return false;
      setAllLimits(data.getLimits.limits);
      return true;
    }
    setTabIndex(index);
    if (localStorage.getItem("sweSetDepositLimit")) {
      setShowSweSetDepositLimit(true);
    }
    getLimits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloClient]);

  useEffect(() => {
    if (allLimits.length > 0) {
      const initialAmounts = allLimits.filter(
        (limit) => limit.initialAmount !== ""
      );
      let oldValues = {
        depositDate: "daily",
        logincountDate: "daily",
        lossDate: "daily",
        betDate: "daily",
      };
      //console.log(initialAmounts);
      initialAmounts.forEach((element) => {
        let typeValueName =
          element.limit === "timelimit"
            ? element.period + "Value"
            : element.limit + "Value";
        let typeValue = element.initialAmount;
        let typeDataName =
          element.limit === "timelimit"
            ? element.period + "Data"
            : element.limit + "Date";
        let typeDataValue = element.period;
        if (element.limit === "timelimit" || element.limit === "timeout") {
          typeValue = element.initialAmount / 3600;
        } else if (element.limit === "logincount") {
          typeValue = element.initialAmount;
        } else {
          typeValue = element.initialAmount / centsModifier;
        }
        oldValues[typeValueName] = typeValue;
        oldValues[typeDataName] = typeDataValue;
      });
      //console.log(limitsData.concat(oldValues));

      setLimitsData(oldValues);

      const depositLimits = allLimits.filter(
        (limit) => limit.limit === "deposit"
      );
      const logincountLimits = allLimits.filter(
        (limit) => limit.limit === "logincount"
      );
      const maxBalanceLimits = allLimits.filter(
        (limit) => limit.limit === "limit"
      );
      const lossLimits = allLimits.filter((limit) => limit.limit === "loss");
      const betLimits = allLimits.filter((limit) => limit.limit === "bet");
      const sessionLimit = allLimits.filter(
        (limit) => limit.limit === "timeout"
      );
      const timelimit = allLimits.filter(
        (limit) => limit.limit === "timelimit"
      );

      if (depositLimits) setDepositLimits(depositLimits);
      if (logincountLimits) setLoginCountLimit(logincountLimits);
      if (maxBalanceLimits) setMaxBalanceLimit(maxBalanceLimits);
      if (lossLimits) setLossLimits(lossLimits);
      if (betLimits) setBetLimits(betLimits);
      if (sessionLimit) setSessionLimits(sessionLimit);
      if (timelimit) setTimeLimit(timelimit);

      const hasExpires =
        allLimits.filter((limit) => limit.expiresAt !== "-1") === 0
          ? false
          : true;
      let total = 0;
      if (sessionLimit.length > 0 && sessionLimit[0].initialAmount === 86400) {
        total = allLimits.length - 1;
      } else {
        total = allLimits.length;
      }

      if (!hasExpires && total) {
        setHasLimits(true);
      }
    }
  }, [apolloClient, allLimits, isSE, index]);

  function sweSetDepositLimitProceed() {
    localStorage.removeItem("sweSetDepositLimit");
    setShowSweSetDepositLimit(false);
  }

  const handleChange = (event) => {
    event.persist();
    let { name, value, min, max } = event.target;
    /* if (name === "depositValue" && isSWEIp) {
      if (value > 5000) value = 5000;
    } */

    if (typeof min !== "undefined" && typeof max !== "undefined") {
      if (
        value === "" ||
        (parseInt(min) <= parseInt(value) && parseInt(value) <= parseInt(max))
      ) {
        setLimitsData({ ...limitsData, [name]: value });
      }
    } else {
      if (value !== "") {
        setLimitsData({ ...limitsData, [name]: value });
      }
    }
  };

  const handleSubmit = (type) => {
    let deposit = false;
    const {
      depositValue,
      depositDate,
      timeoutValue,
      logincountValue,
      logincountDate,
      limitValue,
      dailyValue,
      weeklyValue,
      monthlyValue,
      betValue,
      betDate,
      lossValue,
      lossDate,
    } = limitsData;

    let daily = timeLimit.filter((limit) => limit.period === "daily");
    let weekly = timeLimit.filter((limit) => limit.period === "weekly");
    let monthly = timeLimit.filter((limit) => limit.period === "monthly");

    //console.log(daily);

    let limits = [];
    if (depositValue > 0 && type === "deposit") {
      const initialAmount = depositValue * centsModifier;
      const limitType = depositDate + "_" + typeDeposit;
      const limit = { initial_amount: initialAmount, type: limitType };
      if (isSWEIp) {
        //if (depositValue <= 5000 && depositDate === "weekly") {
        limits.push(limit);
        if (localStorage.getItem(PNPUID) && localStorage.getItem(PNPTS)) {
          deposit = true;
        }
        //}
      } else {
        limits.push(limit);
      }
    }
    if (timeoutValue <= 24 && type === "session") {
      const initialAmount = timeoutValue * 3600;
      const limitType = "session_timeout";
      const limit = { initial_amount: initialAmount, type: limitType };
      limits.push(limit);
    }
    if (
      parseInt(dailyValue) > 0 &&
      parseInt(dailyValue) <= 24 &&
      type === "time"
    ) {
      if (
        (daily && daily.length === 0) ||
        (daily &&
          daily.length > 0 &&
          daily[0].expiresAt === "" &&
          daily[0].status === "active")
      ) {
        const initialAmount = parseInt(dailyValue) * 3600;
        const limitType = "daily_timelimit";
        if (
          (daily &&
            daily.length > 0 &&
            daily[0].initialAmount !== initialAmount) ||
          (daily && daily.length === 0)
        ) {
          limits.push({ initial_amount: initialAmount, type: limitType });
        }
      }
    }
    if (
      parseInt(weeklyValue) > 24 &&
      parseInt(weeklyValue) <= 167 &&
      type === "time"
    ) {
      if (
        (weekly && weekly.length === 0) ||
        (weekly &&
          weekly.length > 0 &&
          weekly[0].expiresAt === "" &&
          weekly[0].status === "active")
      ) {
        const initialAmount = parseInt(weeklyValue) * 3600;
        const limitType = "weekly_timelimit";
        if (
          (weekly &&
            weekly.length > 0 &&
            weekly[0].initialAmount !== initialAmount) ||
          (weekly && weekly.length === 0)
        ) {
          limits.push({ initial_amount: initialAmount, type: limitType });
        }
      }
    }
    if (
      parseInt(monthlyValue) > 167 &&
      parseInt(monthlyValue) <= 720 &&
      type === "time"
    ) {
      if (
        (monthly && monthly.length === 0) ||
        (monthly &&
          monthly.length > 0 &&
          monthly[0].expiresAt === "" &&
          monthly[0].status === "active")
      ) {
        const initialAmount = parseInt(monthlyValue) * 3600;
        const limitType = "monthly_timelimit";
        if (
          (monthly &&
            monthly.length > 0 &&
            monthly[0].initialAmount !== initialAmount) ||
          (monthly && monthly.length === 0)
        ) {
          limits.push({ initial_amount: initialAmount, type: limitType });
        }
      }
    }

    if (logincountValue > 0 && type === "logincount") {
      const initialAmount = parseInt(logincountValue);
      const limitType = logincountDate + "_" + typeLogincount;
      const limit = { initial_amount: initialAmount, type: limitType };
      limits.push(limit);
    }

    if (limitValue > 0 && type === "maxbalance") {
      const initialAmount = limitValue * centsModifier;
      const limitType = "maxbalance_limit";
      const limit = { initial_amount: initialAmount, type: limitType };
      limits.push(limit);
    }

    if (betValue > 0 && type === "bet") {
      const initialAmount = betValue * centsModifier;
      const limitType = betDate + "_" + typeBet;
      const limit = { initial_amount: initialAmount, type: limitType };
      limits.push(limit);
    }

    if (lossValue > 0 && type === "loss") {
      const initialAmount = lossValue * centsModifier;
      const limitType = lossDate + "_" + typeLoss;
      const limit = { initial_amount: initialAmount, type: limitType };
      limits.push(limit);
    }
    /* if (
      type === 'time' &&
      (weeklyValue === undefined || dailyValue === undefined || monthlyValue === undefined)
    ) {
      toast.error(t('err_time_limit_missing'), {
        position: 'bottom-center',
      });
    } */

    //console.log(JSON.stringify(limits));
    if (limits.length !== 0) limitAccount(limits, deposit);
    /*  else toast.error(t('err_limit_missing'), {
      position: 'bottom-center',
    }); */
  };

  async function limitAccount(limits, deposit) {
    console.log(JSON.stringify(limits));
    // return;
    setSpinner(true);
    const { loading, errors, data } = await apolloClient.mutate({
      mutation: MUTATION_CREATE_LIMITS,
      variables: { limits: JSON.stringify(limits) },
      errorPolicy: "all",
    });
    if (loading) return "Loading ...";
    if (errors) {
      setSpinner(false);
      toast.error(t(errors[0].message, errors[0].message), {
        position: "bottom-center",
      });
      return false;
    }
    if (data) {
      toast.success(t("suc_limit_message"), {
        position: "bottom-center",
      });
      if (deposit && skin === "rac") {
        await completePNP();
      }
      setSpinner(false);
      window.location = pages["my-limits"];
      return true;
    }
  }

  async function completePNP() {
    if (
      nickName !== "" &&
      typeof nickName !== "undefined" &&
      localStorage.getItem(PNPUID) &&
      localStorage.getItem(PNPTS)
    ) {
      PaymentServer.post("/fhnd/v1/pnp/finish", {
        ip: server.geoIP.ipAddress,
        pnpuid: localStorage.getItem(PNPUID),
        pnpts: localStorage.getItem(PNPTS),
        nickname: nickName,
        geoip:
          server.geoIP.isoCode.toLowerCase() + " | | " + server.geoIP.ipAddress,
        domain: window.location.hostname,
        locale: i18n.language,
      })
        .then(() => {
          localStorage.removeItem(PNPUID);
        })
        .catch(() => {});
    }
    // setSpinner(false);
  }

  async function resetLimits() {
    const { loading, errors, data } = await apolloClient.query({
      query: QUERY_CLEAR_LIMITS,
    });
    if (loading) return "Loading ...";
    if (errors) {
      toast.error(t(errors[0].message, errors[0].message), {
        position: "bottom-center",
      });
      return false;
    }
    if (data) {
      toast.success(t("success_reset_limits"), {
        position: "bottom-center",
      });
      sessionStorage.setItem("show-limits-reseted", true);
      window.location = pages["my-limits"];
      return true;
    }
  }
  const props = {
    player,
    server,
    skinCurrency,
    hasLimits,
    limitsData,
    handleChange,
    handleSubmit,
    resetLimits,
    isUk,
    setSpinner,
    realityCheck,
    skin,
  };

  let plcConfig = skin === "plc" ? true : false;

  return (
    <>
      <ContentTop
        serverSetup={serverSetup}
        showProfileMenu={!isMobileOnly}
        pages={pages}
        skin={skin}
        isMyAccount={"label_limits"}
      />
      <PageContent account="true" nobg="true">
        <PageContentWrapper limits="true">
          <AccountContainer limits>
            <StyledCol>
              <TabContent limits="true">
                <StyledTabs
                  limits="true"
                  selectedIndex={tabIndex}
                  onSelect={(index) => setTabIndex(index)}
                  selectedTabClassName="active"
                >
                  <StyledTabList limits="true">
                    <StyledTab>{t("label_cash_limit")}</StyledTab>
                    {/* <StyledTab>{t("label_time_limit")}</StyledTab> */}
                    {plcConfig && (
                      <StyledTab>{t("label_session_count_limit")}</StyledTab>
                    )}
                    {plcConfig && (
                      <StyledTab>{t("label_max_balans_limit")}</StyledTab>
                    )}

                    <StyledTab>{t("label_timelimit_limit")}</StyledTab>

                    <StyledTab>{t("label_bet_limit")}</StyledTab>
                    <StyledTab>{t("label_loss_limit")}</StyledTab>
                    {plcConfig && (
                      <StyledTab>{t("label_self_exclusion")}</StyledTab>
                    )}
                    <StyledTab>{t("label_timeout")}</StyledTab>

                    <StyledTab>{t("label_exclude_from_game")}</StyledTab>
                  </StyledTabList>
                  <StyledTabPanel>
                    <DepositLimit
                      depositMaxLimit={depositMaxLimit}
                      depositLimits={depositLimits}
                      {...props}
                    />
                  </StyledTabPanel>
                  {/*  {!plcConfig && (
                    <StyledTabPanel>
                      <PlaySessionLimit
                        sessionLimits={sessionLimits}
                        {...props}
                      />
                    </StyledTabPanel>
                  )} */}
                  {plcConfig && (
                    <StyledTabPanel>
                      <SessionCountLimit
                        generalMaxLimit={generalMaxLimit}
                        loginCountLimit={loginCountLimit}
                        {...props}
                      />
                    </StyledTabPanel>
                  )}
                  {plcConfig && (
                    <StyledTabPanel>
                      <MaxBalansLimit
                        generalMaxLimit={generalMaxLimit}
                        maxBalansLimit={maxBalanceLimit}
                        {...props}
                      />
                    </StyledTabPanel>
                  )}
                  <StyledTabPanel>
                    <DailyTimeLimit timeLimit={timeLimit} {...props} />
                  </StyledTabPanel>
                  <StyledTabPanel>
                    <BetLimit
                      generalMaxLimit={generalMaxLimit}
                      betLimits={betLimits}
                      {...props}
                    />
                  </StyledTabPanel>
                  <StyledTabPanel>
                    <LossLimit
                      generalMaxLimit={generalMaxLimit}
                      lossLimits={lossLimits}
                      {...props}
                    />
                  </StyledTabPanel>
                  {plcConfig && (
                    <StyledTabPanel>
                      <SelfExclusion
                        {...props}
                        serverSetup={server}
                        currentLanguage={currentLanguage}
                        isSE={isSE}
                        isNL={isNL}
                        isUk={isUk}
                      />
                    </StyledTabPanel>
                  )}
                  <StyledTabPanel>
                    <Timeout
                      {...props}
                      pages={pages}
                      isSE={isSE}
                      isNL={isNL}
                      isUk={isUk}
                    />
                  </StyledTabPanel>
                  <StyledTabPanel>
                    <ExcludedGames
                      skin={skin}
                      pages={pages}
                      {...props}
                      currentTheme={currentTheme}
                    />
                  </StyledTabPanel>
                </StyledTabs>
              </TabContent>
            </StyledCol>
          </AccountContainer>
        </PageContentWrapper>
        <SweSetDepositLimitDialog
          showModal={showSweSetDepositLimit}
          handleProceed={() => sweSetDepositLimitProceed()}
        />
      </PageContent>
    </>
  );
};

export default withRouter(MyLimits);
