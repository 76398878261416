import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
  ModalLogoWrapper,
  ModalLogoPLC,
  ModalLogoABC,
  ModalLogoPUB,
  ModalLogoYET,
  ModalLogoFUN,
  ModalLogoCAC,
  ModalLogoHYC,
  ModalLogoYAK,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const BonusAvailableDialog = ({
  showModal,
  handleProceed,
  handleCancel,
  skin,
}) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      <ModalLogoWrapper>
        {skin === "plc" && <ModalLogoPLC />}
        {skin === "abc" && <ModalLogoABC />}
        {skin === "pub" && <ModalLogoPUB />}
        {skin === "yet" && <ModalLogoYET />}
        {skin === "fun" && <ModalLogoFUN />}
        {skin === "cac" && <ModalLogoCAC />}
        {skin === "hyc" && <ModalLogoHYC />}
        {skin === "yak" && <ModalLogoYAK />}
      </ModalLogoWrapper>
      <StyledModalBody register>
        <span
          dangerouslySetInnerHTML={{
            __html: t("label_bonus_available_message"),
          }}
        ></span>
      </StyledModalBody>
      <StyledModalFooter>
        <StyledButton
          dialog
          pub={skin === "pub"}
          onClick={() => handleCancel()}
        >
          {t("label_dont_show_this_again")}
        </StyledButton>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleProceed()}
        >
          {t("label_go_to_bonuses")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
