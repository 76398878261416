import { SweTopBar } from "common/src/component/SweTopBar";
import React, { useEffect, useState } from "react";
import { matchPath, useHistory, withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useScrollPosition } from "common/src/helpers/useScrollPosition";
import Logo from "common/src/component/Logo";
import PlayerBalance from "common/src/component/PlayerBalance";
import { ThemeSwitcher } from "common/src/component/ThemeSwitcher";
import { isMobileOnly, isTablet } from "react-device-detect";
import {
  BalanceWrapper,
  HeaderWrapper,
  LinkForDeposit,
  LinkToHome,
  StyledHeader,
  StyledHeaderLink,
} from "../styledComponents/StyledHeader";
import Time from "./Time";
import { getToken } from "../helpers/session";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

import LanguageChange from "./LanguageChange";
import QuickGameCategories from "./QuickGameCategories";

const Header = ({
  serverSetup,
  currentTheme,
  setTheme,
  player,
  balance,
  pages,
  inGame,
  isOpen,
  setIsOpen,
  headerUserExpand,
  setHeaderUserExpand,
  sidepanel,
  gameCategories,
  skin,
  defaultLang,
  expandWallet,
  setExpandWallet,
  startDeposit,
}) => {
  let location = useLocation();
  const { t, i18n } = useTranslation("");
  const [hideOnScroll, setHideOnScroll] = useState(true);
  /*   const [scrollChecker, setScrollChecker] = useState(false); */
  const { country } = player;
  const { geoIP } = serverSetup;
  const isSWEIp =
    geoIP.isoCode.toLowerCase() === "se" || country === "se" ? true : false;
  const indexPage =
    useLocation().pathname.split("/")[2] === "index" ? true : false;
  const baseUrl = `/${i18n.language || window.localStorage.i18nextLng}`;
  const history = useHistory();
  useEffect(() => {
    location.pathname === pages["register"] ? setHideOnScroll(true) : null;
  }, [location.pathname === pages["register"]]);

  const notAccountRoute =
    location.pathname !== pages["my-account"] &&
    location.pathname !== pages["my-profile"] &&
    location.pathname !== pages["cashback"] &&
    location.pathname !== pages["bonuses"] &&
    location.pathname !== pages["change-password"] &&
    location.pathname !== pages["transactions"] &&
    location.pathname !== pages["casino-history"] &&
    location.pathname !== pages["my-limits"] &&
    location.pathname !== pages["complaints"] &&
    location.pathname !== pages["my-verification"];

  useScrollPosition(
    ({ currPos }) => {
      let isShow;
      if (isMobileOnly && headerUserExpand && notAccountRoute) {
        isShow = Math.abs(currPos.y) < 500;
      } else if (isMobileOnly && !headerUserExpand) {
        isShow = Math.abs(currPos.y) < 50;
      } else if (isMobileOnly) {
        isShow = Math.abs(currPos.y) < 150;
      } else if (isTablet && headerUserExpand && notAccountRoute) {
        isShow = Math.abs(currPos.y) < 300;
      } else {
        isShow = Math.abs(currPos.y) < 70;
      }
      //console.log(currPos.y);
      if (isShow !== hideOnScroll) {
        if (currPos.y === 0) {
          setHideOnScroll(isShow);
        } else {
          setHeaderUserExpand(false);
          setHideOnScroll(isShow);
        }
      }
    },
    [(hideOnScroll, headerUserExpand)]
  );

  const scrollToTop = () => {
    setIsOpen(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickOnNav = (goTo) => {
    if (goTo && goTo === "deposit" && location.pathname === pages["deposit"]) {
      // console.log("click on deposit button on deposit page, set step 0");
      window.top.postMessage(
        JSON.stringify({
          error: false,
          list: true,
        }),
        window.location.origin
      );
    }
    setIsOpen(!isOpen);
    scrollToTop();
  };

  let checkLocation =
    location.pathname === `/${serverSetup.currentLanguage}/index` &&
    serverSetup.geoIP.isoCode === "SE" &&
    skin === "abc"
      ? true
      : false;

  let mobilePUB =
    (skin === "pub" || skin === "cac" || skin === "hyc") && isMobileOnly;
  let mobileABC =
    (skin === "abc" ||
      skin === "nbc" ||
      skin === "yet" ||
      skin === "fun" ||
      skin === "yak") &&
    isMobileOnly;
  let racInGame = skin === "rac" && inGame;
  let mobileRAC = skin === "rac" && isMobileOnly;

  let checkPlayerMob = isMobileOnly && !player;
  let showThemeSwticherMobABC =
    (skin === "abc" ||
      skin === "nbc" ||
      skin === "yet" ||
      skin === "fun" ||
      skin === "yak") &&
    isMobileOnly &&
    player;
  let showThemeSwticherMobRAC = skin === "rac" && isMobileOnly && player;

  const handleTriggerPnp = () => {
    sessionStorage.setItem("startPnpFromHeader", true);
    /* history.push(baseUrl + "/index"); */
    window.location = pages["index"];
  };

  return (
    <>
      {isSWEIp && <SweTopBar pages={pages} />}
      <HeaderWrapper
        hideOnScroll={hideOnScroll}
        player={player}
        liveAndSports={notAccountRoute}
      />
      <StyledHeader
        player={player}
        id="header"
        notAccountRoute={notAccountRoute}
        headerUserExpand={headerUserExpand}
        hideOnScroll={hideOnScroll}
        isSWEIp={isSWEIp}
        style={
          sidepanel
            ? {
                width: "calc(100% - 320px)",
                transition: "0.5s",
              }
            : { width: "100%", transition: "0s" }
        }
      >
        {(skin === "abc" || skin === "pub" || skin === "qbc") &&
          notAccountRoute && (
            <QuickGameCategories
              allLinks={skin === "qbc"}
              currentLanguage={serverSetup.currentLanguage}
              player={player}
              hideOnScroll={hideOnScroll}
            />
          )}
        {skin === "abc" && matchPath(location.pathname, gameCategories) && (
          <Time
            player={player}
            isInGame={false}
            skin={skin}
            server={serverSetup}
          />
        )}
        {(skin === "plc" ||
          (skin === "pub" && isMobileOnly) ||
          skin === "fun" ||
          skin === "cac" ||
          skin === "hyc") && (
          <ThemeSwitcher
            currentTheme={currentTheme}
            setTheme={setTheme}
            player={player}
            skin={skin}
            server={serverSetup}
          />
        )}
        {skin === "plc" &&
        location.pathname !== `${baseUrl}/register` &&
        location.pathname !== `${baseUrl}/login` &&
        location.pathname !== `${baseUrl}/forgot-password` &&
        location.pathname !== `${baseUrl}/error` &&
        !location.pathname.includes(`${baseUrl}/reset-password`) &&
        !matchPath(location.pathname, gameCategories) ? (
          !hideOnScroll && (
            <Logo
              isSWEIp={isSWEIp}
              setOpen={setIsOpen}
              classOnScroll={hideOnScroll}
              defaultLang={defaultLang}
              player={player}
            />
          )
        ) : (
          <>
            <Logo
              isSWEIp={isSWEIp}
              setOpen={setIsOpen}
              classOnScroll={hideOnScroll}
              defaultLang={defaultLang}
              player={player}
            />
            {skin !== "abc" &&
              matchPath(location.pathname, gameCategories) &&
              (skin === "abc" ||
                skin === "nbc" ||
                skin === "bnk" ||
                skin === "yet") &&
              location.pathname !==
                `/${serverSetup.currentLanguage}/sports-book/sports` && (
                <Time
                  player={player}
                  isInGame={true}
                  skin={skin}
                  server={serverSetup}
                />
              )}
          </>
        )}
        {/*{skin === "abc" && (*/}
        {/*    <QuickGameCategories />*/}
        {/*)}*/}
        {((skin === "pub" && isMobileOnly) ||
          skin === "plc" ||
          skin === "cac") && (
          <LinkToHome
            onClick={() =>
              indexPage ? scrollToTop() : history.push(baseUrl + "/index")
            }
          ></LinkToHome>
        )}
        {skin === "rac" && racInGame && (
          <LinkForDeposit>
            {getToken() && (
              <StyledButton btn gamedeposit onClick={startDeposit}>
                {t("label_deposit", "deposit")}
              </StyledButton>
            )}
          </LinkForDeposit>
        )}
        <BalanceWrapper player={player}>
          {showThemeSwticherMobABC
            ? null
            : (skin === "abc" || (skin === "pub" && !isMobileOnly)) && (
                <ThemeSwitcher
                  currentTheme={currentTheme}
                  setTheme={setTheme}
                  player={player}
                  skin={skin}
                  server={serverSetup}
                />
              )}
          {(skin === "nbc" ||
            skin === "yet" ||
            skin === "fun" ||
            skin === "yak" ||
            (skin === "hyc" && isMobileOnly) ||
            (skin === "cac" && isMobileOnly)) &&
            !player && <LanguageChange serverSetup={serverSetup} />}
          {showThemeSwticherMobRAC
            ? null
            : skin === "rac" && (
                <ThemeSwitcher
                  currentTheme={currentTheme}
                  setTheme={setTheme}
                  player={player}
                  skin={skin}
                  server={serverSetup}
                />
              )}
          {player ? (
            balance && (
              <PlayerBalance
                player={player}
                serverSetup={serverSetup}
                balance={balance}
                headerUserExpand={headerUserExpand}
                setHeaderUserExpand={setHeaderUserExpand}
                skin={skin}
                hideOnScroll={hideOnScroll}
                expandWallet={expandWallet}
                setExpandWallet={setExpandWallet}
                racInGame={racInGame}
              />
            )
          ) : skin === "rac" ? null : (
            <StyledHeaderLink
              to={pages["login"]}
              deposit="true"
              checklocation={checkLocation ? 1 : 0}
              onClick={() => handleClickOnNav()}
              login="true"
            >
              {t("label_login", "login")}
            </StyledHeaderLink>
          )}
          {player ? (
            mobilePUB || mobileABC || mobileRAC || racInGame ? null : (
              <StyledHeaderLink
                prime={skin === "pub" || skin === "cac" || skin === "hyc"}
                to={pages["deposit"]}
                onClick={() => handleClickOnNav("deposit")}
                deposit="true"
              >
                {t("label_deposit", "Deposit")}
              </StyledHeaderLink>
            )
          ) : skin === "rac" ? (
            location.pathname === pages["index"] ? null : (
              <StyledHeaderLink
                deposit="true"
                checklocation={checkLocation ? 1 : 0}
                dangerouslySetInnerHTML={{
                  __html: t("label_register_pnp"),
                }}
                onClick={() => handleTriggerPnp()}
              ></StyledHeaderLink>
            )
          ) : (
            <StyledHeaderLink
              login="true"
              prime="true"
              register="true"
              checklocation={checkLocation ? 1 : 0}
              to={pages["register"]}
              dangerouslySetInnerHTML={{
                __html: t("label_register", "register"),
              }}
              onClick={() => handleClickOnNav()}
            ></StyledHeaderLink>
          )}
          {(skin === "nbc" ||
            skin === "yet" ||
            skin === "yak" ||
            skin === "rac" ||
            skin === "abc" ||
            skin === "qbc") &&
            !matchPath(location.pathname, gameCategories) && (
              <Time
                player={player}
                isInGame={false}
                skin={skin}
                server={serverSetup}
              />
            )}
          {location.pathname ===
            `/${serverSetup.currentLanguage}/sports-book/sports` &&
            skin !== "pub" && (
              <Time
                player={player}
                isInGame={false}
                skin={skin}
                server={serverSetup}
              />
            )}
        </BalanceWrapper>
      </StyledHeader>
    </>
  );
};

export default withRouter(Header);
