import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const WithdrawAlertDialog = ({
  showModal,
  handleProceed,
  handleCancel,
  type,
  player,
  skin,
}) => {
  const { t } = useTranslation("");
  const { country } = player;
  const isUK = country === "gb" ? true : false;
  const isSE = country === "se" ? true : false;

  return (
    <StyledModal isOpen={showModal}>
      <StyledModalBody
        register
        dangerouslySetInnerHTML={{
          __html:
            type === "deposit"
              ? t("withdraw_alert_deposit_message")
              : t("withdraw_alert_message"),
        }}
      >
        {/* {type === "deposit"
          ? t("withdraw_alert_deposit_message")
          : t("withdraw_alert_message")} */}
      </StyledModalBody>
      <StyledModalFooter position={isUK || isSE}>
        {!isUK && !isSE && (
          <StyledButton
            btn
            pub={skin === "pub"}
            spancol={"col-md-3 col-sm-4 col-5"}
            onClick={() => handleProceed()}
            dangerouslySetInnerHTML={{ __html: t("label_proceed") }}
          >
            {/* {t("label_proceed")} */}
          </StyledButton>
        )}
        <StyledButton
          dialog
          pub={skin === "pub"}
          onClick={() => handleCancel()}
          dangerouslySetInnerHTML={{ __html: t("label_cancel") }}
        >
          {/* {t("label_cancel")} */}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
