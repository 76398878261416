import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { isMobile } from "react-device-detect";
import Register from "../pages/Register";
import config from "../config.json";
import { StyledRow } from "common/src/styledComponents/StyledActiveBonus";
import {
  StyledLandingSection,
  StyledLandingWrapper,
  StyledLendingImg,
} from "common/src/styledComponents/StyledIndex";
import i18n from "../i18n";
import {
  AccountContainer,
  AccountDescription,
  PageContentWrapper,
} from "common/src/styledComponents/AccountPage";
import { useTranslation } from "react-i18next";

const Landing = ({
  server,
  setSpinner,
  titles,
  pages,
  setPxRegisterStart,
  setPxRegister,
}) => {
  const [landing, setLanding] = useState("");
  /* const redirectTo = config.skin === "plc" ? "index" : "games"; */
  const { t } = useTranslation();

  useEffect(() => {
    if (Cookies.get("land")) {
      sessionStorage.setItem("land", Cookies.get("land"));
      setLanding(JSON.parse(Cookies.get("land")));
      Cookies.remove("land");
    } else {
      if (
        sessionStorage.getItem("land") &&
        sessionStorage.getItem("land").length !== null
      ) {
        setLanding(JSON.parse(sessionStorage.getItem("land")));
      }
    }
  }, []);

  if (landing && !landing.is_enabled) {
    window.location = pages["index"];
  }

  let geoIp = [];
  let playerIp = server.geoIP.isoCode.toLowerCase();

  //swe players are not allowed to landings on most skins - there are exceptions, check the php code when moving the skin to react
  if (playerIp === "se") {
    window.location = pages["index"];
  }

  if (landing && landing.geoip_rules !== "") {
    //console.log('ovo je  ' + landing.geoip_rules);
    geoIp = JSON.parse(landing.geoip_rules);
    //console.log('ovo je 2  ' + geoIp.length);
  }

  let img = "";
  let imgMob = "";

  if (geoIp.length > 0) {
    geoIp.forEach((element, index) => {
      //console.log(element.includes(playerIp));
      if (
        (playerIp !== "" || playerIp !== null) &&
        element.includes(playerIp)
      ) {
        img = JSON.parse(landing.image)[index];
        imgMob = JSON.parse(landing.mobile_image)[index];
      } else {
        img = JSON.parse(landing.image)[1];
        imgMob = JSON.parse(landing.mobile_image)[1];
      }
    });
  } else {
    img = landing.image;
    imgMob = landing.mobile_image;
  }
  let bannerLang = landing.page_language === "en" ? "" : landing.page_language;

  return (
    <>
      <StyledLandingSection>
        <StyledRow>
          <StyledLandingWrapper
            registerform={landing.register_form}
            onClick={() => (window.location = pages["register"])}
          >
            {!isMobile ? (
              <StyledLendingImg
                src={
                  server.cdn + config.landingImagePath + bannerLang + "/" + img
                }
                alt=""
              />
            ) : (
              <StyledLendingImg
                src={
                  server.cdn +
                  config.landingImagePath +
                  bannerLang +
                  "/" +
                  imgMob
                }
                alt=""
              />
            )}
          </StyledLandingWrapper>
          {landing.register_form && (
            <StyledLandingWrapper registerform="true" register>
              <Register
                serverSetup={server}
                pages={pages}
                setSpinner={setSpinner}
                titles={titles}
                setPxRegisterStart={setPxRegisterStart}
                setPxRegister={setPxRegister}
                landing={true}
              />
            </StyledLandingWrapper>
          )}
        </StyledRow>
        {i18n.exists("landing_" + landing.unique_id + "_text") && (
          <StyledRow>
            <PageContentWrapper>
              <AccountContainer lending>
                <AccountDescription expand terms>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("landing_" + landing.unique_id + "_text"),
                    }}
                  />
                </AccountDescription>
              </AccountContainer>
            </PageContentWrapper>
          </StyledRow>
        )}
        {/* <div className="row text landing-text">{t("landing-page-text")}</div> */}
      </StyledLandingSection>
    </>
  );
};

export default Landing;
