import { THEME } from "common/src/helpers/constants";
import {
  StyledLight,
  StyledLightABC,
} from "../styledComponents/StyledTimeAndBulb";
import Time from "./Time";
import React from "react";
import LanguageChange from "./LanguageChange";
import { isMobileOnly } from "react-device-detect";

export function ThemeSwitcher({
  currentTheme,
  setTheme,
  player,
  skin,
  server,
}) {
  document.documentElement.setAttribute("data-theme", currentTheme);
  const nextTheme = currentTheme === "light" ? "dark" : "light";

  const switchTheme = () => {
    setTheme(nextTheme);
    document.documentElement.setAttribute("data-theme", nextTheme);
    localStorage.setItem(THEME, nextTheme);
  };

  return (
    <>
      {skin === "rac" && <StyledLightABC onClick={switchTheme} />}
      {(skin === "cac" || skin === "hyc") && !isMobileOnly && (
        <LanguageChange serverSetup={server} />
      )}
      {skin === "plc" && <StyledLight onClick={switchTheme} />}
      {(skin === "plc" ||
        skin === "pub" ||
        skin === "fun" ||
        skin === "cac" ||
        skin === "hyc" ||
        skin === "yak") && (
        <Time player={player} isInGame={false} skin={skin} server={server} />
      )}
    </>
  );
}
