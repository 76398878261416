import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const CruksSEDialog = ({ showModal, handleProceed, skin }) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      <StyledModalBody>
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "message_se_cruks_link",
              'a <a href="https://kansspelautoriteit.nl/wet-koa/cruks/">link to CRUKS</a> (popup after SE is set)'
            ),
          }}
        ></p>
      </StyledModalBody>
      <StyledModalFooter>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleProceed()}
        >
          {t("label_ok")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
