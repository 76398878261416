import * as ibantools from "ibantools";

export default function validate(values, errors) {
  //let errors = {};

  const isUK = values.country === "gb" ? true : false;
  const isNL = values.country === "nl" ? true : false;
  // const isNL = true;

  if (values.country !== "") {
    // console.log("set ls country: " + values.country);
    localStorage.setItem("pc", values.country);
  }

  // console.log("values: " + JSON.stringify(values));

  /* Check if parameter is valid email. */
  const re =
    /^(([^<>().,;:\s@"]+(\.[^<>().,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,7}))$/;

  if (values.email === "") {
    errors.email = "err_empty_email";
  } else if (!re.test(values.email)) {
    errors.email = "err_invalid_email";
  } else {
    if (errors.email && errors.email !== "err_email_already_exists") {
      delete errors.email;
    }
  }

  /* Check if parameter is valid password */
  const upperCase = new RegExp("[A-Z]");
  const numbers = new RegExp("[0-9]");
  if (values.password === "") {
    errors.password = "err_empty_password";
  } else if (values.password.length < 8) {
    errors.password = "err_invalid_minimum_password";
  } else if (
    (!values.password.match(upperCase) && values.password.length < 20) ||
    !values.password.match(numbers)
  ) {
    //allow no uppercase letters in pw if pw lenght is over 20 chars (zignsec pw)
    errors.password = "err_not_strong_password";
  } else {
    delete errors.password;
  }
  if (values.confirmPassword === "") {
    errors.confirmPassword = "err_empty_confirm_password";
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "err_not_match_password";
  } else {
    delete errors.confirmPassword;
  }
  /* Check if parameter is valid currency*/
  if (values.currency === "") {
    errors.currency = "err_empty_currency";
  } else {
    delete errors.currency;
  }
  /* Check if parameter is valid country*/
  if (values.country === "") {
    errors.country = "err_empty_country";
  } else {
    delete errors.country;
  }
  /* Check if parameter is valid name*/
  if (values.firstName === "") {
    errors.firstName = "err_empty_name";
  } else {
    delete errors.firstName;
  }
  /* Check if parameter is valid last name*/
  if (values.lastName === "") {
    errors.lastName = "err_empty_last_name";
  } else {
    delete errors.lastName;
  }
  /* Check if parameter is valid gender*/
  if (values.gender === "") {
    errors.gender = "err_empty_gender";
  } else {
    delete errors.gender;
  }
  /* Check if parameter is valid  date of birth*/
  //console.log(underAgeValidate(values.dateOfBirth));
  if (values.dateOfBirth === null) {
    errors.dateOfBirth = "err_empty_date_of_birth";
  } else {
    delete errors.dateOfBirth;
  }

  /* Check if parameter is valid zipCode*/
  if (values.zipCode === "") {
    errors.zipCode = "err_empty_current_post_code";
  } else {
    delete errors.zipCode;
  }
  /* Check if parameter is valid address*/
  if (values.address === "") {
    errors.address = "err_empty_current_street";
  } else {
    delete errors.address;
  }
  /* Check if parameter is valid city*/
  if (values.city === "") {
    errors.city = "err_empty_current_city";
  } else {
    delete errors.city;
  }
  /* Check if parameter is valid state -> not mandatory */
  // if (isUK && values.state === "") {
  //   errors.state = "err_empty_current_county";
  // } else {
  //   delete errors.state;
  // }
  /* Check if parameter is valid buildingName*/
  if (
    isUK &&
    values.buildingName === "" &&
    values.buildingNumber === "" &&
    values.abode === ""
  ) {
    errors.buildingName = "err_empty_current_building_name";
  } else {
    delete errors.buildingName;
  }
  /* Check if parameter is valid buildingNumber*/
  if (
    isUK &&
    values.buildingNumber === "" &&
    values.buildingName === "" &&
    values.abode === ""
  ) {
    errors.buildingNumber = "err_empty_current_house_number";
  } else {
    delete errors.buildingNumber;
  }
  /* Check if parameter is valid abode*/
  if (
    isUK &&
    values.abode === "" &&
    values.buildingName === "" &&
    values.buildingNumber === ""
  ) {
    errors.abode = "err_empty_current_adobe";
  } else {
    delete errors.abode;
  }
  // console.log("validate");
  if (values.country === "se" || values.currency === "sek") {
    if (values.identID === "") {
      errors.identID = "err_empty_national_id";
    } else {
      delete errors.identID;
    }
  } else {
    delete errors.identID;
  }

  if (values.idinBank === "") {
    errors.zipCode = "err_empty_idin_bank";
  } else {
    delete errors.idinBank;
  }

  if (values.phone === "") {
    errors.phone = "err_empty_phone";
  } else {
    delete errors.phone;
  }

  if(isUK && values.title === "") {
    errors.title = "err_empty_title"
  } else {
    delete errors.title
  }

  // if (isNL && values.loginCountValue === "") {
  //   errors.loginCountValue = "err_empty_limit_login_count";
  // } else {
  //   delete errors.loginCountValue;
  // }

  if (isNL) {
    if (values.timeLimitValue === "") {
      errors.timeLimitValue = "err_empty_time_limit";
    } else {
      let tlval = parseInt(values.timeLimitValue);
      // console.log("tlval: " + tlval);
      let valerr = false;
      if (!isNaN(tlval)) {
        if (values.timeLimitDate === "" || values.timeLimitDate === "daily") {
          if (tlval > 24) {
            errors.timeLimitValue = "err_time_limit_daily_max";
            valerr = true;
          }
        } else if (values.timeLimitDate === "weekly") {
          if (tlval > 168) {
            errors.timeLimitValue = "err_time_limit_weekly_max";
            valerr = true;
          }
        } else if (values.timeLimitDate === "monthly") {
          if (tlval > 720) {
            errors.timeLimitValue = "err_time_limit_monthly_max";
            valerr = true;
          }
        }
      } else {
        errors.timeLimitValue = "err_time_limit_format";
        valerr = true;
      }
      if (!valerr) {
        delete errors.timeLimitValue;
      }
    }
  }
  if (isNL && values.depositValue === "") {
    errors.depositValue = "err_empty_limit_deposit";
  } else {
    delete errors.depositValue;
  }

  if (isNL && values.maxBalansLimit === "") {
    errors.maxBalansLimit = "err_empty_limit_max_balance";
  } else {
    delete errors.maxBalansLimit;
  }

  if (isNL && values.bankIBAN === "") {
    errors.bankIBAN = "err_empty_iban";
  } else {
    if (isNL) {
      if (ibantools.isValidIBAN(values.bankIBAN)) {
        delete errors.bankIBAN;
      } else {
        errors.bankIBAN = "err_iban_format";
      }
    } else {
      delete errors.bankIBAN;
    }
  }

  if (isNL && values.bankAccountHolder === "") {
    errors.bankAccountHolder = "err_empty_account_holder";
  } else {
    delete errors.bankAccountHolder;
  }



  // if (isNL && (values.kycDocumentType === '' || values.kycDocumentType === 'none')) {
  //   errors.kycDocumentType = 'err_empty_document_type';
  // } else {
  //   delete errors.kycDocumentType;
  // }
  //
  // if (isNL && values.kycIDNumber === '') {
  //   errors.kycIDNumber = 'err_empty_kyc_id_number';
  // } else {
  //   delete errors.kycIDNumber;
  // }
  //
  // if (isNL && values.kycDocumentCity === '') {
  //   errors.kycDocumentCity = 'err_empty_document_city';
  // } else {
  //   delete errors.kycDocumentCity;
  // }
  //
  // if (isNL && values.documentValidFrom === '') {
  //   errors.documentValidFrom = 'err_empty_document_valid_from';
  // } else {
  //   delete errors.documentValidFrom;
  // }
  //
  // if (isNL && values.documentValidTo === '') {
  //   errors.documentValidTo = 'err_empty_document_valid_to';
  // } else {
  //   delete errors.documentValidTo;
  // }

  /* if (values.termsAndConditions === false) {
    errors.termsAndConditions = "err_invalid_terms_conditions";
  }
  if (values.gdprAccepted === false) {
    errors.gdprAccepted = "err_invalid_privacy";
  } */
  // console.log("validate errors: " + JSON.stringify(errors));
  return errors;
}
