import React, { useEffect, useRef } from "react";
import { getToken } from "common/src/helpers/session";
import Cookies from "js-cookie";
import { PaymentServer } from "common/src/helpers/connectors";
import { useTranslation } from "react-i18next";
import "../../../plc/src/data/scss/components/pixels.scss";
import {deviceType, getUA} from "react-device-detect";

const TrackingPixels = ({
  player,
  names,
  location,
  serverSetup,
  ftd,
  pixelConversion,
  callback,
  skin,
}) => {
  //types: postback, image, iframe, script
  const { i18n } = useTranslation();
  const myDivElement = useRef(null);

  // console.log("tp player: " + JSON.stringify(player));
  // console.log("tp names: " + JSON.stringify(names));
  // console.log("tp location: " + location.pathname);
  // console.log("tp server setup: " + JSON.stringify(serverSetup.geoIP));
  // console.log("tp ftd: " + ftd);
  // console.log("tp pixelConversion: " + pixelConversion);

  // console.log(ftd);

  // if (pixelConversion === "deposit" && !ftd) {
  //   if (localStorage.getItem("ftd") === "true") {
  //     //it should enter here only for pnp process on rac
  //     ftd = true;
  //   }
  // }
  // console.log("ftd: " + ftd);
  // console.log(player)
  useEffect(() => {
    // console.log("tracking pixels");
    //do the check when parameters are ready (if player is logged in player and first deposit values need to be available)
    // console.log(ftd);
    // console.log(getToken())
    // console.log(typeof player)
    //for register pixel player has saved token, but not player object (on register success page)
    if ((getToken() === null || pixelConversion === "register") || (typeof player !== "string" && ftd !== null)) {
      if(typeof myDivElement.current !== "undefined") {
        // console.log("pixels check " + pixelConversion + " on " + location.pathname);
        console.log("pixels trigger " + pixelConversion);
        let div = myDivElement.current;
        //pixels section
        let resultCode = 0;
        let aid = "";
        let affCode = "";
        // console.log("player type: " + typeof player);
        if (typeof player === "string") {
          // console.log("-----set from aff code");
          affCode = Cookies.get("affCode" + skin.toUpperCase());
          // console.log(affCode)
        } else {
          // console.log("-----set from player");
          affCode = player.affiliateTrackingCode;
        }
        // let affCode = (getToken() === null) ? Cookies.get("affCodeRAC") : player.affiliateTrackingCode;
        // console.log("aff code cookies: " + Cookies.get("affCodeRAC"));
        // console.log("aff code player: " + player.affiliateTrackingCode);
        // console.log(typeof affCode)
        if (typeof affCode !== "undefined") {
          let splited = affCode.split("-");
          // console.log("splited: " + JSON.stringify(splited))
          if (splited.length > 2) {
            let tmp = parseInt(splited[1]);
            // console.log("tmp: " + tmp)
            if (!isNaN(tmp)) {
              resultCode = tmp;
            }
          }
          if (splited.length > 3) {
            aid = splited[3];
          }
        }

        let country = "all";
        if (typeof player === "string") {
          // console.log("no session, localstorage:" + localStorage.getItem("pc"));
          // let lspc = localStorage.getItem("pc");
          if (localStorage.getItem("pc")) {
            country = localStorage.getItem("pc");
          } else if (serverSetup.geoIP.isoCode !== "") {
            country = serverSetup.geoIP.isoCode.toLowerCase();
          }
          //else default
        } else {
          country = player.country;
        }
        if (country === "all") {
          if (localStorage.getItem("pc")) {
            country = localStorage.getItem("pc");
          }
        }

        //dont search for tracking pixels on maintenance page to avoid redirection loop
        if (country !== "all" && location.pathname.indexOf("/maintenance") < 0) {
          // console.log(names[location.pathname] ? names[location.pathname] : location.pathname)
          // if (typeof names[location.pathname] !== "undefined") {
          PaymentServer.post("/fhnd/v1/tracking-pixels/" + pixelConversion, {
            affiliateId: resultCode,
            page: names[location.pathname] ? names[location.pathname] : location.pathname,
            country: country,
            loggedIn: getToken() === null ? false : true,
            language: i18n.language,
            ftd: getToken() === null ? false : ftd,
            aid: aid,
            s3: Cookies.get("landl_aff_" + skin + "_s3")
              ? Cookies.get("landl_aff_" + skin + "_s3")
              : "",
            ipAddress: serverSetup.geoIP.ipAddress,
            device: deviceType,
            agent: getUA,
            tk: getToken() === null ? "" : getToken(),
          })
            .then((res) => {
              if (res.data !== null && myDivElement.current !== null) {
                if (typeof res.data.maintenance !== "undefined") {
                  window.location.replace("/" + i18n.language + "/maintenance");
                }

                // console.log("res data: " + JSON.stringify(res.data));
                // console.log(typeof res.data);
                // console.log("res data count: " + res.data.length);
                try {
                  Object.values(res.data).forEach((pixel) => {
                    // console.log("loop" + JSON.stringify(pixel));
                    if (pixel.type === "script") {
                      // console.log("set script pixel: " + pixel.name)
                      const script = document.createElement("script");
                      script.type = "text/javascript";
                      script.innerHTML = pixel.code;

                      script.async = true;
                      myDivElement.current.appendChild(script);
                    } else if (pixel.type === "image") {
                      // console.log("set image pixel: " + pixel.name)
                      const img = document.createElement("img");
                      img.style.width = "1px";
                      img.style.height = "1px";
                      img.style.border = "0px";
                      img.src = pixel.url_template;
                      myDivElement.current.appendChild(img);
                      //width: 1px; height: 1px; border: 0px;
                    } else if (pixel.type === "iframe") {
                      // console.log("set iframe pixel: " + pixel.name)
                      const iframe = document.createElement("iframe");
                      iframe.setAttribute("id", "pixel-frame");
                      iframe.setAttribute("width", "1");
                      iframe.setAttribute("height", "1");
                      iframe.setAttribute("frameborder", "0");
                      iframe.src = pixel.url_template;
                      myDivElement.current.appendChild(iframe);
                    }
                  });
                } catch (e) {
                  console.log(e);
                }
              }
            })
            .catch(() => {
              // console.log("error: " + JSON.stringify(error.data));
            });
          // }
        }
        return () => {
          console.log("pixels cleanup");
          if (pixelConversion === "deposit" && ftd) {
            //this will actually be used only for rac (pnp process uses ftd in localstorage)
            localStorage.removeItem("ftd");
          }
          div.innerHTML = "";
        };
      } else {
        console.log("pixels ref empty " + pixelConversion);
      }
    } else {
      // console.log(getToken())
      // console.log(typeof player)
      console.log("pixels skip trigger " + pixelConversion);
    }
  }, [
    location,
    i18n.language,
    names,
    player,
    ftd,
    serverSetup.geoIP.ipAddress,
    serverSetup.geoIP.isoCode,
    pixelConversion,
  ]);

  if (typeof callback === "function") {
    // if (pixelConversion === "register") {
    //   callback(false);
    // } else {
    setTimeout(() => {
      callback(false);
    }, 1000);
    // }
  }

  return <div className={"pixels " + pixelConversion} ref={myDivElement}></div>;
};

export default TrackingPixels;
