import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  IncreaseLimit,
  RealityCheckWrapper,
  StyledLimitSelect,
  StyledLimitWrapper,
  StyledCooldownExplanation,
  StyledCooldownExplanationSmall,
} from "../styledComponents/StyledLimits";
import {
  StyledButton,
  StyledInput,
  StyledSelect,
} from "../styledComponents/CommonStyledComponents";
import { AccountDescription } from "../styledComponents/AccountPage";

const BetLimit = ({
  limitsData,
  generalMaxLimit,
  skinCurrency,
  betLimits,
  hasLimits,
  handleSubmit,
  handleChange,
  resetLimits,
  isUk,
  realityCheck,
  player,
  skin,
}) => {
  const { t, i18n } = useTranslation("");
  const { limitsLocked } = player;

  //max for cooldown
  //const [maxValue, setMaxLimit] = useState(generalMaxLimit);
  const { betValue, betDate } = limitsData;
  const [hasIncrease /*setHasIncrease*/] = useState(false);
  const [increaseTime /*setIncreaseTime*/] = useState("");
  const [blockChange, setBlockChange] = useState(false);
  const localizedFormat = `${
    i18n.language || window.localStorage.i18nextLng || "nl"
  }`;

  useEffect(() => {
    function hasLimit() {
      /*  if (betLimits[0].increaseAt !== '') {
        setHasIncrease(true);
        setIncreaseTime(betLimits[0].increaseAt);
      } */
      if (betLimits[0].expiresAt !== "" && betLimits[0].status === "active") {
        //setMaxLimit(betValue);
        setBlockChange(true);
      }
    }
    betLimits.length > 0 && hasLimit();
  }, [betLimits]);

  return (
    <>
      {(skin === "fun" ||
        skin === "cac" ||
        skin === "hyc" ||
        skin === "yak") && (
        <AccountDescription
          expand={true}
          description
          dangerouslySetInnerHTML={{ __html: t("lable_bet_limit_description") }}
        >
          {/*  <p>
            Daily - a period of a single day*
            <br />
            Weekly - a period of seven days, from Monday to Sunday*
            <br />
            Monthly - a calendar month, from the first day of the month
            <br />
            till the last day of the month*
            <br />
            <br />
            *) From 00:00 till 23:59 UTC
            <br />
            <br />
            -Any increase in limits will go through a 24 hour cooling period
            before being applied.
            <br />
            <br />
          </p>
          <p>
            -Note that a weekly time limit can't be less than a daily time limit
            and monthly time limit can't be less than a weekly time limit.
            <br />
            <br />
            Players residing in the UK have the following default limits
            applied:
            <br />
            Deposit: 100.000
            <br />
            Bet: 100.000.000
            <br />
            Loss: 100.000.000
            <br />
            <br />
            Player residing in Sweden are not allowed to play without a deposit
            limit.
            <br />
            <br />
          </p> */}
        </AccountDescription>
      )}
      <StyledLimitWrapper>
        <StyledLimitSelect>
          <label>{t("label_choose_limit", "Period")}</label>
          <StyledSelect
            onChange={(e) => handleChange(e)}
            value={betDate}
            name="betDate"
            //disabled={betLimits.length > 0}
          >
            <option value="daily">{t("label_daily", "Daily*")}</option>
            <option value="weekly">{t("label_weekly", "Weekly*")}</option>
            <option value="monthly">{t("label_monthly", "Monthly*")}</option>
          </StyledSelect>
        </StyledLimitSelect>
        <StyledLimitSelect>
          <label>
            {t("label_amount", "Amount")} ({skinCurrency})
          </label>
          <div className="input-group">
            <StyledInput
              limit
              type="number"
              value={betValue}
              name="betValue"
              onChange={(e) => handleChange(e)}
              min="1"
              max={generalMaxLimit}
              step="1"
              disabled={hasIncrease || (isUk && limitsLocked)}
            />
            <div className="input-group-append">
              <span className="input-group-text">{skinCurrency}</span>
            </div>
          </div>
        </StyledLimitSelect>
        {hasLimits && isUk ? (
          <StyledButton
            pub={skin === "pub"}
            limit
            onClick={() => resetLimits()}
            disabled={isUk && limitsLocked}
          >
            {t("label_reset", "Reset")}
          </StyledButton>
        ) : (
          <StyledButton
            pub={skin === "pub"}
            limit
            disabled={hasIncrease || (isUk && limitsLocked)}
            onClick={() => handleSubmit("bet")}
          >
            {t("label_save", "Save")}
          </StyledButton>
        )}
        {blockChange && (
          <StyledCooldownExplanation cooldownprop>
            <StyledCooldownExplanationSmall>
              {t("cooldown_explanation")}{" "}
              {new Date(betLimits[0].expiresAt).toLocaleString(localizedFormat)}
            </StyledCooldownExplanationSmall>
          </StyledCooldownExplanation>
        )}
        {skin === "rac" && hasIncrease && (
          <IncreaseLimit>
            <h3>
              {t("label_increased_limit")} {increaseTime}
            </h3>
          </IncreaseLimit>
        )}
        {skin === "rac" && (
          <RealityCheckWrapper>
            {realityCheck !== -1 && (
              <p>
                {t("label_reality_check")} {Math.floor(realityCheck / 60)}{" "}
                {t("label_reality_check_minutes")}
              </p>
            )}
          </RealityCheckWrapper>
        )}
      </StyledLimitWrapper>

      {skin !== "rac" && skin !== "fun" && (
        <AccountDescription
          expand={true}
          description
          dangerouslySetInnerHTML={{ __html: t("message_limit_details") }}
        >
          {/*  <p>
            Daily - a period of a single day*
            <br />
            Weekly - a period of seven days, from Monday to Sunday*
            <br />
            Monthly - a calendar month, from the first day of the month
            <br />
            till the last day of the month*
            <br />
            <br />
            *) From 00:00 till 23:59 UTC
            <br />
            <br />
            -Any increase in limits will go through a 24 hour cooling period
            before being applied.
            <br />
            <br />
          </p>
          <p>
            -Note that a weekly time limit can't be less than a daily time limit
            and monthly time limit can't be less than a weekly time limit.
            <br />
            <br />
            Players residing in the UK have the following default limits
            applied:
            <br />
            Deposit: 100.000
            <br />
            Bet: 100.000.000
            <br />
            Loss: 100.000.000
            <br />
            <br />
            Player residing in Sweden are not allowed to play without a deposit
            limit.
            <br />
            <br />
          </p> */}
        </AccountDescription>
      )}

      {skin === "rac" && (
        <IncreaseLimit>
          <p>{t("message_betLimit_description")}</p>
        </IncreaseLimit>
      )}
    </>
  );
};

export default BetLimit;
