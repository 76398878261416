import React from "react";
import { useTranslation } from "react-i18next";
import {
  GameHistoryWrapper,
  StyledTableCell,
  StyledTableContent,
  StyledTableHeading,
  StyledTableRow,
  TableHeader,
} from "../styledComponents/AccountPage";
import { printAmount } from "common/src/helpers/functions";

const FinancialTotals = ({ financialTotal, playerCurrency, server, skin }) => {
  const { t } = useTranslation("");
  return (
    <>
      <GameHistoryWrapper>
        <TableHeader col={"col-3"}>
          {t("label_bonus_total", "Total bonus")}
        </TableHeader>
        <TableHeader col={"col-3b"}>
          {t("label_bonus_cash_total", "Total cash bonus")}
        </TableHeader>
        <TableHeader col={"col-3"}>
          {t("label_deposit_total", "Deposit total")}
        </TableHeader>
        <TableHeader col={"col-3"}>
          {t("label_withdrawal_total", "Total Withdrawals")}
        </TableHeader>
      </GameHistoryWrapper>

      <StyledTableRow financial>
        <StyledTableCell cell={"amount-cell"} col={"col-md-3"}>
          <StyledTableHeading>
            {t("label_bonus_total", "Total bonus")}
          </StyledTableHeading>
          <StyledTableContent left>
            {financialTotal && Object.keys(financialTotal).length === 0
              ? ""
              : printAmount(
                  financialTotal.bonusTotal,
                  playerCurrency,
                  server.currencies,
                  true,
                )}
          </StyledTableContent>
        </StyledTableCell>
        <StyledTableCell
          cell={`amount-cell ${skin === "plc" && "px-0"}`}
          col={"col-md-3"}
        >
          <StyledTableHeading>
            {t("label_bonus_cash_total", "Total cash bonus")}
          </StyledTableHeading>
          <StyledTableContent>
            {financialTotal && Object.keys(financialTotal).length === 0
              ? ""
              : printAmount(
                  financialTotal && financialTotal.cashBonusTotal,
                  playerCurrency,
                  server.currencies,
                  true,
                )}
          </StyledTableContent>
        </StyledTableCell>
        <StyledTableCell cell={"amount-cell"} col={"col-md-3"}>
          <StyledTableHeading>
            {t("label_deposit_total", "Deposit total")}
          </StyledTableHeading>
          <StyledTableContent>
            {financialTotal && Object.keys(financialTotal).length === 0
              ? ""
              : printAmount(
                  financialTotal && financialTotal.depositTotal,
                  playerCurrency,
                  server.currencies,
                  true,
                )}
          </StyledTableContent>
        </StyledTableCell>
        <StyledTableCell cell={"amount-cell"} col={"col-md-3"}>
          <StyledTableHeading>
            {t("label_withdrawal_total", "Total Withdrawals")}
          </StyledTableHeading>
          <StyledTableContent>
            {financialTotal && Object.keys(financialTotal).length === 0
              ? ""
              : printAmount(
                  financialTotal && financialTotal.withdrawalTotal,
                  playerCurrency,
                  server.currencies,
                  true,
                )}
          </StyledTableContent>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default FinancialTotals;
