import { QUERY_LOGOUT } from "common/src/graphql/queries";
import { useApolloClient } from "@apollo/client";
import { destroySession } from "common/src/helpers/session";
import { cleanLocalStorage } from "common/src/helpers/functions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const useLogout = (setSpinner) => {
  const apolloClient = useApolloClient();
  const { i18n } = useTranslation("");
  const baseUrl = `/${i18n.language || window.localStorage.i18nextLng || "en"}`;

  useEffect(() => {
    async function logout() {
      setSpinner(true);

      const { errors, data } = await apolloClient.query({
        query: QUERY_LOGOUT,
      });
      if (errors) {
        toast.error(errors[0].message, {
          position: "bottom-center",
        });
        destroySession();
        setSpinner(false);

        return null;
      } else {
        destroySession();
        cleanLocalStorage();
        window.location = baseUrl + "/index";
        return data.logout;
      }
    }
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useLogout;
