import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SWE_LIMIT_DIALOG } from "common/src/helpers/constants";
import { QUERY_GET_LIMITS } from "common/src/graphql/queries";
import { printAmount } from "common/src/helpers/functions";
import { useApolloClient } from "@apollo/client";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const SweLimitDialog = ({
  player,
  server,
  grossResults,
  showModal,
  skin,
}) => {
  const { t } = useTranslation("");
  const apolloClient = useApolloClient();

  const [showModel, setShowModel] = useState(showModal);
  const [allLimits, setAllLimits] = useState([]);
  const [sweDialogData, setSweDialogData] = useState({});
  //console.log("period1 " + sweDialogData);

  useEffect(() => {
    async function getLimits() {
      const { loading, errors, data } = await apolloClient.query({
        query: QUERY_GET_LIMITS,
      });
      if (loading) return <h3>Loading ...</h3>;
      if (errors) return false;
      setAllLimits(data.getLimits.limits);
      return true;
    }

    function getDataForSweDialog() {
      let forSweDialog = {};
      allLimits.forEach((limit) => {
        if (limit.limit === "timeout" && skin !== "plc") {
          forSweDialog[limit.limit + "_remaining"] =
            (limit.remainingAmount / 3600).toFixed(2) +
            (skin === "plc" ? " uur" : ` ${t("label_swe_time", "hour(s)")}`);
        } else {
          forSweDialog[limit.limit + "_remaining"] = printAmount(
            limit.remainingAmount,
            player.currency,
            server.currencies,
            true
          );
        }
        if (limit.limitType === "daily_timelimit") {
          forSweDialog[limit.limit + "_remaining_daily"] =
            (limit.remainingAmount / 3600).toFixed(2) +
            (skin === "plc" ? " uur" : ` ${t("label_swe_time", "hour(s)")}`);
          forSweDialog[limit.limit + "_period_daily"] = limit.period;
        } else if (limit.limitType === "weekly_timelimit") {
          forSweDialog[limit.limit + "_remaining_weekly"] =
            (limit.remainingAmount / 3600).toFixed(2) +
            (skin === "plc" ? " uur" : ` ${t("label_swe_time", "hour(s)")}`);
          forSweDialog[limit.limit + "_period_weekly"] = limit.period;
        } else if (limit.limitType === "monthly_timelimit") {
          forSweDialog[limit.limit + "_remaining_monthly"] =
            (limit.remainingAmount / 3600).toFixed(2) +
            (skin === "plc" ? " uur" : ` ${t("label_swe_time", "hour(s)")}`);
          forSweDialog[limit.limit + "_period_monthly"] = limit.period;
        } else {
          forSweDialog[limit.limit + "_period"] = limit.period;
        }
      });

      if (Object.keys(forSweDialog).length > 0) {
        forSweDialog["grossResults"] = printAmount(
          grossResults,
          player.currency,
          server.currencies,
          false
        );
        return forSweDialog;
      }
    }

    // console.log("limits dialog use effect " + JSON.stringify(player));
    // if (player && localStorage.getItem(SWE_LIMIT_DIALOG)) {
    // console.log("GET LIMITS");
    if (allLimits.length === 0) {
      setShowModel(true);
      getLimits();
    }

    if (allLimits) {
      setSweDialogData(getDataForSweDialog);
    }
    // }
  }, [allLimits, player, apolloClient, grossResults, server.currencies]);

  function handleProceed() {
    setShowModel(false);
    localStorage.removeItem(SWE_LIMIT_DIALOG);
  }

  // function cancelSweLimitDialog() {
  //   setSweLimitDialog(false);
  //   localStorage.setItem(SWE_LIMIT_DIALOG, false);
  // }
  //console.log("swe limts show model: " + JSON.stringify(showModel));
  return (
    <StyledModal isOpen={showModel}>
      {sweDialogData && Object.keys(sweDialogData).length > 0 && (
        <StyledModalBody position>
          <h2
            dangerouslySetInnerHTML={{ __html: t("label_swe_limits_title") }}
          ></h2>
          <span
            dangerouslySetInnerHTML={{ __html: t("label_swe_limits_deposit") }}
          ></span>
          : {sweDialogData.deposit_remaining} -{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: t("label_" + sweDialogData.deposit_period),
            }}
          ></span>
          <br />
          <span
            dangerouslySetInnerHTML={{ __html: t("label_swe_limits_loss") }}
          ></span>
          : {sweDialogData.loss_remaining} -{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: t("label_" + sweDialogData.loss_period),
            }}
          ></span>
          <br />
          <span
            dangerouslySetInnerHTML={{ __html: t("label_swe_limits_bet") }}
          ></span>
          : {sweDialogData.bet_remaining} -{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: t("label_" + sweDialogData.bet_period),
            }}
          ></span>
          {skin !== "plc" && (
            <>
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: t("label_swe_limits_session"),
                }}
              ></span>
              : {sweDialogData.timeout_remaining}
            </>
          )}
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: t("label_swe_limits_timelimit"),
            }}
          ></span>
          : {sweDialogData.timelimit_remaining_daily} -{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: t("label_" + sweDialogData.timelimit_period_daily),
            }}
          ></span>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: t("label_swe_limits_timelimit"),
            }}
          ></span>
          : {sweDialogData.timelimit_remaining_weekly} -{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: t("label_" + sweDialogData.timelimit_period_weekly),
            }}
          ></span>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: t("label_swe_limits_timelimit"),
            }}
          ></span>
          : {sweDialogData.timelimit_remaining_monthly} -{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: t("label_" + sweDialogData.timelimit_period_monthly),
            }}
          ></span>
          <br />
          <span
            dangerouslySetInnerHTML={{ __html: t("label_swe_limits_result") }}
          ></span>
          : {sweDialogData.grossResults}
        </StyledModalBody>
      )}
      <StyledModalFooter position>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleProceed()}
        >
          {t("label_ok")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
