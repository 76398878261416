import { PaymentServer } from "common/src/helpers/connectors";
import { SERVER_SETUP } from "common/src/helpers/constants";
import { getToken, destroySession } from "common/src/helpers/session";
import { Component } from "react";
import config from "./config.json";
let serverSetup = sessionStorage.getItem(SERVER_SETUP);

class ErrorBoundary extends Component {
  state = {
    errorMessage: "",
    hasError: false,
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.toString(), error };
  }
  componentDidCatch(error, info) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  // A fake logging service
  logErrorToServices = console.log;
  render() {
    console.log(config.env);
    const { errorMessage, hasError, error } = this.state;
    if (hasError) {
      console.log(error);
      //any of the three parameters can't be ampty
      PaymentServer.post("/fhnd/v1/slog/error", {
        message: errorMessage ? errorMessage : "unknown",
        id: serverSetup
          ? JSON.parse(serverSetup).geoIP.ipAddress +
            " ON " +
            window.location.pathname
          : "unknown" + " ON " + window.location.pathname,
        sid: getToken() ? getToken() : "logged-out",
      });

      if (error.name === "ChunkLoadError") {
        //reload page on chunk load error (happens when site is deployed and player switch page after deploy)
        console.log("chunk load error");
        return window.location.reload();
        //   if (window.location.pathname.indexOf("/maintenance") < 0) {
        //     window.location.replace(`/maintenance`);
        //   }
      }

      if (
        window.location.pathname.indexOf("/error") < 0 &&
        config.env !== "development"
      ) {
        //if not on error page destroy session and redirect to error page
        destroySession(); //??
        const arr = window.location.pathname.split("/");
        if (window.location.pathname.split("/").length > 2) {
          return window.location.replace("/" + arr[1] + `/error`);
        } else {
          return window.location.replace(`/error`);
        }
      }

      //todo: see what to do here, when error page breaks into errorboundary, atm redirects to maintenance
      return config.env && config.env === "development" ? (
        <div className="card-body">
          <details className="error-details">{errorMessage}</details>
        </div>
      ) : (
        <div>deploy in progress...</div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
