import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
} from "../styledComponents/StyledModals";
import { StyledButton } from "../styledComponents/CommonStyledComponents";

export const SetLimitsDialog = ({
  showModal,
  handleProceed,
  handleCancel,
  skin,
}) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      <StyledModalBody register>
        {t("message_please_set_limits")}
      </StyledModalBody>
      <StyledModalFooter>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleProceed()}
        >
          {t("label_proceed")}
        </StyledButton>
        <StyledButton
          dialog
          pub={skin === "pub"}
          onClick={() => handleCancel()}
        >
          {t("label_not_now")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
