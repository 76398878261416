import { gql } from "@apollo/client";

// Graphql registration
export const MUTATION_REGISTER = gql`
  mutation Register(
    $password: String!
    $firstName: String!
    $middleName: String!
    $lastName: String!
    $email: String!
    $state: String!
    $city: String!
    $country: String!
    $zipCode: String!
    $address: String!
    $phone: String!
    $gender: String!
    $title: String!
    $dateOfBirth: String!
    $affiliateTrackingCode: String!
    $language: String!
    $receiveNews: Boolean!
    $receiveSMS: Boolean!
    $abode: String!
    $buildingNumber: Int!
    $buildingName: String!
    $currency: String!
    $gdprAccepted: Boolean!
    $pep: Boolean!
    $identID: String!
    $identBankID: String!
    $geoIP: String!
    $birthPlace: String!
    $BSN: String!
    $inserts: String!
    $addictionPolicy: Boolean!
    $legalCapacity: Boolean!
    $amlPolicy: Boolean!
    $receiveBonus: Boolean!
    $activate: Boolean!
    #    $kycDocumentType: String!
    #    $kycIDNumber: String!
    #    $kycDocumentCity: String!
    #    $documentValidFrom: String!
    #    $documentValidTo: String!
    $accountHolder: String!
    $accountNumber: String!
    $limits: String!
  ) {
    register(
      password: $password
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      email: $email
      state: $state
      city: $city
      country: $country
      zipCode: $zipCode
      address: $address
      phone: $phone
      gender: $gender
      title: $title
      dateOfBirth: $dateOfBirth
      affiliateTrackingCode: $affiliateTrackingCode
      language: $language
      receiveNews: $receiveNews
      receiveSMS: $receiveSMS
      abode: $abode
      buildingNumber: $buildingNumber
      buildingName: $buildingName
      currency: $currency
      gdprAccepted: $gdprAccepted
      pep: $pep
      identID: $identID
      identBankID: $identBankID
      geoIP: $geoIP
      birthPlace: $birthPlace
      BSN: $BSN
      inserts: $inserts
      addictionPolicy: $addictionPolicy
      legalCapacity: $legalCapacity
      amlPolicy: $amlPolicy
      receiveBonus: $receiveBonus
      activate: $activate
      #      kycDocumentType: $kycDocumentType
      #      kycIDNumber: $kycIDNumber
      #      kycDocumentCity: $kycDocumentCity
      #      documentValidFrom: $documentValidFrom
      #      documentValidTo: $documentValidTo
      accountHolder: $accountHolder
      accountNumber: $accountNumber
      limits: $limits
    ) {
      token
      code
    }
  }
`;

// Gaphql login to system
export const MUTATION_AUTH = gql`
  mutation Auth(
    $username: String!
    $password: String!
    $client: String!
    $nationalID: String!
  ) {
    auth(
      username: $username
      password: $password
      client: $client
      nationalID: $nationalID
    ) {
      token
      code
    }
  }
`;

// Graphql update profile
export const MUTATION_UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $phone: String
    $bonus: Boolean
    $news: Boolean
    $sms: Boolean
    $realityCheck: Float
    $tc: Boolean
    $gdpr: Boolean
    $email: String
    $isDummy: Boolean
    $state: String
  ) {
    updateProfile(
      phone: $phone
      bonus: $bonus
      news: $news
      sms: $sms
      realityCheck: $realityCheck
      tc: $tc
      gdpr: $gdpr
      email: $email
      isDummy: $isDummy
      state: $state
    ) {
      ok
    }
  }
`;

// Graphql reset password
export const MUTATION_RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      ok
    }
  }
`;

// Graphql reset password change
export const MUTATION_RESET_PASSWORD_CHANGE = gql`
  mutation ChangeResetPassword(
    $code: String!
    $password: String!
    $cPassword: String!
  ) {
    changeResetPassword(
      code: $code
      password: $password
      cPassword: $cPassword
    ) {
      ok
    }
  }
`;

// Graphql change password
export const MUTATION_CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $oldPassword: String!
    $newPassword: String!
    $cNewPassword: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      cNewPassword: $cNewPassword
    ) {
      ok
    }
  }
`;

// Graphql login as player
export const MUTATION_LOGIN_AS_PLAYER = gql`
  mutation LoginAsPlayer($sessionID: String!) {
    loginAsPlayer(sessionID: $sessionID) {
      token
    }
  }
`;

// Graphql update banktransfer data
export const MUTATION_UPDATE_BANKTRANSFER = gql`
  mutation UpdateBanktransfer(
    $bankID: Float!
    $accountHolder: String!
    $accountNumber: String!
    $iBan: String!
    $bic: String!
    $sortCode: String!
    $bankName: String!
    $bankCountry: String!
    $clearingNumber: String!
    $bankBranch: String!
    $bankIFSC: String!
    $bankAddress: String!
    $accountType: String!
  ) {
    updateBanktransfer(
      bankID: $bankID
      accountHolder: $accountHolder
      accountNumber: $accountNumber
      iBan: $iBan
      bic: $bic
      sortCode: $sortCode
      bankName: $bankName
      bankCountry: $bankCountry
      clearingNumber: $clearingNumber
      bankBranch: $bankBranch
      bankIFSC: $bankIFSC
      bankAddress: $bankAddress
      accountType: $accountType
    ) {
      ok
    }
  }
`;

export const MUTATION_SET_BANKTRANSFER = gql`
  mutation SetBanktransfer(
    $accountHolder: String!
    $accountNumber: String!
    $iBan: String!
    $bic: String!
    $sortCode: String!
    $bankName: String!
    $bankCountry: String!
    $clearingNumber: String!
    $bankBranch: String!
    $bankIFSC: String!
    $bankAddress: String!
    $accountType: String!
  ) {
    setBanktransfer(
      accountHolder: $accountHolder
      accountNumber: $accountNumber
      iBan: $iBan
      bic: $bic
      sortCode: $sortCode
      bankName: $bankName
      bankCountry: $bankCountry
      clearingNumber: $clearingNumber
      bankBranch: $bankBranch
      bankIFSC: $bankIFSC
      bankAddress: $bankAddress
      accountType: $accountType
    ) {
      ok
    }
  }
`;

// Graphql set leaderboard name
export const MUTATION_SET_LEADERBOARD_NAME = gql`
  mutation SetLeaderboardName($leaderboard: String!) {
    setLeaderboardName(leaderboard: $leaderboard) {
      ok
    }
  }
`;

// Graphql redeem loyalty points
export const MUTATION_REDEEM_LOYALTY_POINTS = gql`
  mutation RedeemLoyaltyPoints($points: Float!) {
    redeemLoyaltyPoints(points: $points) {
      ok
    }
  }
`;

// Graphql block game by ID
export const MUTATION_BLOCK_GAME_BY_ID = gql`
  mutation BlockGameByID($gameID: String!, $duration: Float!) {
    blockGameByID(gameID: $gameID, duration: $duration) {
      ok
    }
  }
`;

// Graphql block game type
export const MUTATION_BLOCK_GAME_TYPE = gql`
  mutation BlockGameType($name: String!, $duration: Float!) {
    blockGameType(name: $name, duration: $duration) {
      ok
    }
  }
`;

// Graphql self exclude player
export const MUTATION_SELF_EXCLUDE = gql`
  mutation SelfExclude($duration: Float!) {
    selfExclude(duration: $duration) {
      ok
    }
  }
`;

// Graphql create player timeout
export const MUTATION_CREATE_TIMEOUT = gql`
  mutation CreateTimeout($duration: Float!) {
    createTimeout(duration: $duration) {
      ok
    }
  }
`;

// Graphql create player limits
export const MUTATION_CREATE_LIMITS = gql`
  mutation CreateLimits($limits: String!) {
    createLimits(limits: $limits) {
      ok
    }
  }
`;

// Graphql ack responsible gaming
export const MUTATION_ACK_RESPONSIBLE_GAMING_THRESHOLD = gql`
  mutation AckResponsibleGamingThreshold($thresholdType: String!) {
    ackResponsibleGamingThreshold(thresholdType: $thresholdType) {
      ok
    }
  }
`;

// Graphql award bonus code
export const MUTATION_AWARD_BONUS_CODE = gql`
  mutation AwardBonusCode($bonusCode: String!) {
    awardBonusCode(bonusCode: $bonusCode) {
      ok
    }
  }
`;

// Graphql award manual bonus code
export const MUTATION_AWARD_MANUAL_BONUS_CODE = gql`
  mutation AwardManualBonusCode($bonusCode: String!) {
    awardManualBonusCode(bonusCode: $bonusCode) {
      ok
    }
  }
`;

// Graphql cancel bonus
export const MUTATION_CANCEL_BONUS = gql`
  mutation CancelBonus($uniqueID: String!) {
    cancelBonus(uniqueID: $uniqueID) {
      ok
    }
  }
`;

// Graphql affordability check on fist deposit
export const MUTATION_AFFORDABILLITY_CHECK = gql`
  mutation AffordabilityCheck(
    $occupation: String!
    $sourceOfWealth: String!
    $sourceOfFunds: String!
    $annualIncome: String!
    $gamblingBudget: String!
  ) {
    affordabilityCheck(
      occupation: $occupation
      sourceOfWealth: $sourceOfWealth
      sourceOfFunds: $sourceOfFunds
      annualIncome: $annualIncome
      gamblingBudget: $gamblingBudget
    ) {
      popupVersion
      limitAmount
    }
  }
`;

// Graphql set hooyu journey to pending
export const MUTATION_HOOYU_PENDING = gql`
  mutation SetJourneyPendingStatus($link: String!) {
    setJourneyPendingStatus(link: $link) {
      ok
    }
  }
`;

// Graphql Nett Deposit Limit set
export const MUTATION_SET_NETT_DEPOSIT_LIMIT = gql`
  mutation SetNettDepositLimit($limitType: String!) {
    setNettDepositLimit(limitType: $limitType) {
      ok
    }
  }
`;

// Graphql update data link
export const MUTATION_UPDATE_DATA_LINK = gql`
  mutation UpdateDataLink($consentId: String!, $bankReferenceId: String!, $dataLinkId: String!) {
    updateDataLink(consentId: $consentId, bankReferenceId: $bankReferenceId, dataLinkId: $dataLinkId, ) {
      ok
    }
  }
`;
