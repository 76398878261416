import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { isMobile, deviceType } from "react-device-detect";
import { CarouselProviderWrapper } from "../styledComponents/StyledGame";
import { ProviderLogos } from "../styledComponents/StyledFooter";
import { useApolloClient } from "@apollo/client";
import { QUERY_SEARCH_GAMES } from "common/src/graphql/queries";

export const GameProvidersInLobby = ({
  serverSetup,
  vendorsImagePath,
  setSelectedGameType,
  categoryNameForSearch,
  /* lobby, */
  setSearchResults,
  clearResults,
  setSpinner,
  setSearchTerm,
}) => {
  const [providerName, setProviderName] = useState("");
  const apolloClient = useApolloClient();
  const themeContext = useContext(ThemeContext);

  const gamesforProvider = async (term, providerType) => {
    if (providerName !== term || providerName === "" || clearResults) {
      setSpinner(true);
      const { loading, errors, data } = await apolloClient.query({
        query: QUERY_SEARCH_GAMES,
        fetchPolicy: "cache-first",
        variables: {
          query: term,
          device: isMobile ? "mob" : "desk",
          country: "rs",
          themeSearch: "false",
          vendorSearch: providerType === "vendor" ? "true" : "false",
          subVendorSearch: providerType === "subVendor" ? "true" : "false",
        },
        //fetchPolicy: 'cache-first',
      });

      if (loading) return <h3>Loading ...</h3>;
      if (errors) return <h1>`Error: ${errors}`</h1>;

      setSearchResults(data.searchGames);
      setSearchTerm && setSearchTerm("");
      setSelectedGameType([
        {
          games: data.searchGames.games,
          categoryName: term,
        },
      ]);
      setProviderName(term);
      setSpinner(false);
    }
  };

  // FILTER ONLY GAMES FROM SELECTED CATEGORY BY PROVIDER

  /* const gamesforProvider = async (term) => {
    let filteredLobby = [];
    lobby.map((el) => {
      el.games.filter((game) => {
        if (game.vendor === term) {
          return filteredLobby.push(game);
        }
      });
    });

    setSelectedGameType([
      {
        games: filteredLobby,
        categoryName: categoryNameForSearch,
      },
    ]);
  }; */

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
      slidesToSlide: 8,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1366 },
      items: 8,
      slidesToSlide: 8,
    },
    smallDesktop: {
      breakpoint: { max: 1366, min: 1024 },
      items: 6,
      slidesToSlide: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      <Carousel
        responsive={responsive}
        swipeable={isMobile}
        draggable={false}
        showDots={false}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all 0.5s ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container"
        deviceType={deviceType}
        itemClass="p-2"
      >
        {serverSetup.footerLogosGames.map((value, index) => {
          let imageName =
            themeContext.current === "light"
              ? value.footerLogo
              : value.footerLogoDark;
          return (
            <CarouselProviderWrapper
              /* id="scroll-to-category" */
              key={index}
              onClick={() =>
                gamesforProvider(value.name.toLowerCase(), value.providerType)
              }
            >
              {value.footerLogo && value.footerLogoDark && (
                <ProviderLogos
                  alt=""
                  loading="lazy"
                  src={serverSetup.cdn + vendorsImagePath + "/" + imageName}
                  height="30"
                />
              )}
            </CarouselProviderWrapper>
          );
        })}
      </Carousel>
    </>
  );
};
