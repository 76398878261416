import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { MUTATION_AWARD_BONUS_CODE } from "common/src/graphql/mutations";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";
import {
  BonusCol,
  BonusDescription,
  PreviousBonusWrapper,
  StyledFormRow,
  StyledTerms,
  TransactionNotFound,
} from "../styledComponents/AccountPage";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
/* import marketingBnsManual from "common/src/assets/img/marketing_bns_manual.png"; */
import React from "react";
import {
  BonusWrapper,
  BonusContainerLeft,
  BonusContainerRight,
  BonusDescriptionSection,
  BonusImg,
} from "../styledComponents/StyledActiveBonus";

const AvailableBonus = ({
  availableBonuses,
  server,
  pages,
  bonusImagePath,
  skin,
}) => {
  const { t, i18n } = useTranslation("");
  const apolloClient = useApolloClient();
  const history = useHistory();
  const baseUrl = `/${
    i18n.language || window.localStorage.i18nextLng || defaultLang
  }`;
  // const { currentLanguage } = server;

  async function redeemCode(bonusCode) {
    console.log(bonusCode);
    const { loading, errors } = await apolloClient.mutate({
      mutation: MUTATION_AWARD_BONUS_CODE,
      variables: {
        bonusCode: bonusCode,
      },
    });

    if (loading) return "Loading ...";
    if (errors) {
      //console.log(errors);
      //setLoginError(errors);
      toast.error(t(errors[0].message), {
        position: "bottom-center",
      });
      return false;
    } else window.location = pages["bonuses"];

    return true;
  }

  const redeemBonus = (bonusType, activationType, bonusCode) => {
    if (
      bonusType === "login" ||
      (bonusType === "free-spins" && activationType === "manual")
    ) {
      redeemCode(bonusCode);
    } else {
      history.push(pages["deposit"], {
        bonusCode: bonusCode,
      });
    }
  };

  return (
    <>
      {availableBonuses.bonuses && availableBonuses.bonuses.length !== 0 ? (
        availableBonuses.bonuses.map((bonus, index) => (
          <BonusWrapper key={index}>
            <BonusContainerLeft>
              <BonusImg>
                <img
                  src={
                    skin === "plc" &&
                    bonus.bonusType === "free-spins" &&
                    bonus.bonusImage === ""
                      ? server.cdn +
                        bonusImagePath +
                        "bonus-page-free-spins.png"
                      : skin === "plc" &&
                        bonus.bonusType === "welcome-bonus" &&
                        bonus.bonusImage === ""
                      ? server.cdn +
                        bonusImagePath +
                        "bonus-page-welcome-bonus.png"
                      : bonus.bonusImage === ""
                      ? server.cdn + bonusImagePath + "bonus-page-bonus.png"
                      : server.cdn + bonusImagePath + bonus.bonusImage
                  }
                  alt="marketing_bns_manual.png"
                />
                <BonusDescriptionSection>
                  <div>
                    {" "}
                    {bonus.bonusDescription === ""
                      ? t("label_empty_bonus_description")
                      : t(bonus.bonusDescription)}
                  </div>
                  <div>
                    <Button
                      btn
                      color="terms"
                      id={"popover-" + index}
                      type="button"
                    >
                      {t(
                        "label_terms_and_conditions",
                        "Bonus terms and conditions"
                      )}
                    </Button>
                    <UncontrolledPopover
                      placement="bottom"
                      target={"popover-" + index}
                    >
                      <PopoverBody
                        dangerouslySetInnerHTML={{
                          __html: t(bonus.bonusTerms),
                        }}
                      />
                    </UncontrolledPopover>
                  </div>
                </BonusDescriptionSection>
              </BonusImg>
            </BonusContainerLeft>
            <BonusContainerRight>
              <StyledButton
                btn
                onClick={() =>
                  redeemBonus(
                    bonus.bonusType,
                    bonus.activationType,
                    bonus.bonusCode
                  )
                }
              >
                {t("label_redeem_bonus")}
              </StyledButton>
            </BonusContainerRight>
          </BonusWrapper>
        ))
      ) : (
        <TransactionNotFound
          dangerouslySetInnerHTML={{ __html: t("bonuses_not_found") }}
        ></TransactionNotFound>
      )}
    </>
  );
};

export default AvailableBonus;
