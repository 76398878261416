import React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../styledComponents/CommonStyledComponents";
import {
  StyledModal,
  StyledModalBody,
  StyledModalFooter,
} from "../styledComponents/StyledModals";
import { PulseAnimation, RotateLogo } from "../styledComponents/StyledIndex";
import { ReactComponent as Logo } from "../../../abc/src/data/icon-component/abc-logo.svg";

export const QueueDialog = ({ showModal, handleCancel, skin, loaderLogo }) => {
  const { t } = useTranslation("");

  return (
    <StyledModal isOpen={showModal}>
      {skin === "abc" ? (
        <RotateLogo>
          <Logo />
        </RotateLogo>
      ) : (
        <PulseAnimation noHeight>
          <img src={loaderLogo} alt="loader" />
        </PulseAnimation>
      )}
      <StyledModalBody queue>{t("label_waiting_queue")}</StyledModalBody>
      <StyledModalFooter position>
        <StyledButton
          banktransfer
          pub={skin === "pub"}
          onClick={() => handleCancel()}
        >
          {t("label_cancel")}
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );
};
