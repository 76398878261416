import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PnPColWrapper,
  StyledCustomCheckboxWrapper,
  StyledPnPInvalidTC,
  StyledPnPStatusCell,
  StyledPnPStatusTopicCell,
} from "../styledComponents/StyledSignupLogin";
import {
  StyledTableContent,
  StyledTableHeading,
  TableHeader,
} from "../styledComponents/AccountPage";
import {
  StyledCustomControlLabel,
  StyledFelxTableRow,
  StyledButton,
  StyledFlexTable,
  StyledInput,
} from "../styledComponents/CommonStyledComponents";
import {
  StyledModalBody,
  StyledModal,
  StyledModalFooter,
} from "../styledComponents/StyledModals";

export const GDPRDialog = ({ showModal, handleProceed, skin }) => {
  const { t } = useTranslation("");
  const [gdpr, setGDPR] = useState(false);
  const [receiveNews, setreceiveNews] = useState(false);
  const [receiveSMS, setreceiveSMS] = useState(false);

  return (
    <StyledModal isOpen={showModal}>
      <StyledModalBody>
        <p
          dangerouslySetInnerHTML={{
            __html: t("label_privacy_options_message"),
          }}
        ></p>
        <StyledFlexTable>
          <PnPColWrapper>
            <TableHeader col={"col-md-3"}>{t("label_category")}</TableHeader>
            <TableHeader col={"col-md-7"}>{t("label_description")}</TableHeader>
            <TableHeader col={"status col-md-2"}>
              {t("label_status")}
            </TableHeader>
          </PnPColWrapper>
          <StyledFelxTableRow row={"row"}>
            <StyledPnPStatusTopicCell>
              {t("label_privacy_policy")}
            </StyledPnPStatusTopicCell>
            <StyledPnPInvalidTC>
              <StyledTableHeading>{t("label_description")}</StyledTableHeading>
              <StyledTableContent
                dangerouslySetInnerHTML={{
                  __html: t("label_privacy_policy_description"),
                }}
              >
                {/*{t("label_privacy_policy_description")}*/}
              </StyledTableContent>
            </StyledPnPInvalidTC>
            <StyledPnPStatusCell>
              <StyledTableHeading>{t("label_status")}</StyledTableHeading>
              <StyledTableContent>
                <StyledCustomCheckboxWrapper>
                  <StyledInput
                    customControl
                    type="checkbox"
                    onChange={(e) => setGDPR(e.target.checked)}
                    name="gdpr"
                    id="gdpr"
                    checked={gdpr}
                  />
                  <StyledCustomControlLabel htmlFor="gdpr"></StyledCustomControlLabel>
                </StyledCustomCheckboxWrapper>
              </StyledTableContent>
            </StyledPnPStatusCell>
          </StyledFelxTableRow>

          <StyledFelxTableRow row={"row"}>
            <StyledPnPStatusTopicCell>
              {t("label_email_marketing")}
            </StyledPnPStatusTopicCell>
            <StyledPnPInvalidTC>
              <StyledTableHeading>{t("label_description")}</StyledTableHeading>
              <StyledTableContent>
                {t("label_email_marketing_description")}
              </StyledTableContent>
            </StyledPnPInvalidTC>
            <StyledPnPStatusCell>
              <StyledTableHeading>{t("label_status")}</StyledTableHeading>
              <StyledTableContent>
                <StyledCustomCheckboxWrapper>
                  <StyledInput
                    customControl
                    type="checkbox"
                    onChange={(e) => setreceiveNews(e.target.checked)}
                    name="receiveNews"
                    id="receiveNews"
                    checked={receiveNews}
                  />
                  <StyledCustomControlLabel htmlFor="receiveNews"></StyledCustomControlLabel>
                </StyledCustomCheckboxWrapper>
              </StyledTableContent>
            </StyledPnPStatusCell>
          </StyledFelxTableRow>
          <StyledFelxTableRow row={"row"}>
            <StyledPnPStatusTopicCell>
              {t("label_sms_marketing")}
            </StyledPnPStatusTopicCell>
            <StyledPnPInvalidTC>
              <StyledTableHeading>{t("label_description")}</StyledTableHeading>
              <StyledTableContent>
                {t("label_sms_marketing_description")}
              </StyledTableContent>
            </StyledPnPInvalidTC>
            <StyledPnPStatusCell second>
              <StyledTableHeading>{t("label_status")}</StyledTableHeading>
              <StyledTableContent>
                <StyledCustomCheckboxWrapper>
                  <StyledInput
                    customControl
                    type="checkbox"
                    onChange={(e) => setreceiveSMS(e.target.checked)}
                    name="receiveSMS"
                    id="receiveSMS"
                    checked={receiveSMS}
                  />
                  <StyledCustomControlLabel htmlFor="receiveSMS"></StyledCustomControlLabel>
                </StyledCustomCheckboxWrapper>
              </StyledTableContent>
            </StyledPnPStatusCell>
          </StyledFelxTableRow>
        </StyledFlexTable>
      </StyledModalBody>
      <StyledModalFooter position>
        {gdpr && (
          <StyledButton
            banktransfer
            pub={skin === "pub"}
            onClick={() => handleProceed({ gdpr, receiveNews, receiveSMS })}
          >
            {t("label_proceed")}
          </StyledButton>
        )}
      </StyledModalFooter>
    </StyledModal>
  );
};
