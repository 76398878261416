import styled from "styled-components";
import BanerDarkABC from "./../../../abc/src/data/img/header_bg_dark.jpg";
import BanerNBC from "./../../../nbc/src/data/img/header_bg_nbc.jpg";
import BanerYET from "./../../../yet/src/data/img/header_bg.jpg";
import BanerFUN from "./../../../fun/src/data/img/header_bg.jpg";
import BanerCAC from "./../../../cac/src/data/img/header_bg.jpg";
import BanerHYC from "./../../../hyc/src/data/img/bg_desktop.jpg";
import BanerRAC from "./../../../rac/src/data/img/bg1.jpg";

export const StyledMaintenanceWrapp = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: ${({ theme }) => {
    if (theme.skin === "abc") return `url(${BanerDarkABC})`;
    if (theme.skin === "hyc") return `url(${BanerHYC})`;
    if (theme.skin === "fun") return `url(${BanerFUN})`;
    if (theme.skin === "cac") return `url(${BanerCAC})`;
    if (theme.skin === "yet") return `url(${BanerYET})`;
    if (theme.skin === "nbc") return `url(${BanerNBC})`;
    if (theme.skin === "rac") return `url(${BanerRAC})`;
  }};
  background-color: var(--maintenenceBg);
  justify-content: center;
  align-items: center;
  background-position: bottom;
  background-size: ${({ theme }) =>
    theme.skin === "cac" || theme.skin === "rac" ? "cover" : "contain"};
  background-repeat: no-repeat;

  p {
    display: block;
    font: 15px/1.5 Arial, Sans-Serif;
    color: ${({ theme }) => (theme.skin === "abc" ? "#4d79ff" : "#fff")};
    margin: 0 0 30px 0;
    margin: 50px auto;
    text-align: center;
  }
  h1 {
    font: 30px/1 Arial, Sans-Serif;
    margin: 0 0 30px 0;
    margin: 50px auto;
    text-align: center;
    margin-top: 200px;
    color: ${({ theme }) =>
      theme.skin === "plc" ? "var(--tabletext)" : "#ffffff"};
    font-weight: ${({ theme }) => (theme.skin === "plc" ? "700" : "")};
  }
  a {
    font: 15px/1.5 Arial, Sans-Serif;
    color: ${({ theme }) =>
      theme.skin === "plc" ? "var(--tabletext)" : "#ffffff"};
    margin: 0 0 30px 0;
    margin: 50px auto;
    padding: 20px 38px;
    text-align: center;
  }
  a:hover {
    color: #fff;
  }
  svg {
    fill: ${({ theme }) => theme.skin === "rac" && "#f7e500"};
  }
`;
